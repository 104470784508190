var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('document-section', {
    attrs: {
      "tag": "div"
    }
  }, [_c('div', {
    staticClass: "banner-wrapper",
    class: {
      // We will use the route here until we migrate the home page
      'banner-wrapper-spacing': _vm.$route.name !== 'home' || _vm.migratePageMainFeedEnabled
    }
  }, [_c('div', {
    staticClass: "banner-background banner-background-left-padding night-gradient",
    class: [_vm.classBackground, {
      'banner-background-y-padding banner-background-right-padding': !_vm.lifetimeIsActive
    }]
  }, [_vm.isShowingLifetimeInMobile ? _c('div', {
    staticClass: "lifetime-mobile-header-banner accent white--text"
  }, [_vm._v(" " + _vm._s(_vm.lifetimeSaveMoney) + " ")]) : _vm._e(), _c('router-link', {
    staticClass: "heading heading-s-small white--text text-decoration-none login-link",
    attrs: {
      "to": {
        name: 'login'
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.handleClickLoginLink.apply(null, arguments);
      }
    }
  }, [_vm._v(" Log in ")]), _vm.lifetimeIsActive ? _c('v-carousel', {
    attrs: {
      "show-arrows": false,
      "hide-delimiter-background": true,
      "height": _vm.carrouselHeight,
      "hide-delimiters": _vm.isSingleBannerResource
    },
    model: {
      value: _vm.carrousel,
      callback: function ($$v) {
        _vm.carrousel = $$v;
      },
      expression: "carrousel"
    }
  }, _vm._l(_vm.bannerResource, function (banner, index) {
    return _c('v-carousel-item', {
      key: index
    }, [_c('BannerItemComponent', {
      attrs: {
        "banner-resource": banner
      }
    })], 1);
  }), 1) : _c('BannerItemComponent', {
    attrs: {
      "banner-resource": _vm.bannerResource
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }