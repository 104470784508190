import axios from 'axios';
import { toastHandler, responseHandler, getResponseDataNotWrapped } from '@/api/utilities';

/**
 * @deprecated This function is deprecated in favor of getUserKeywords.
 */
const doGetUserKeywords = async () => {
  return axios.get('/user/keywords');
};

const getUserKeywords = async () => {
  return responseHandler(axios.get('/user/keywords'), {
    responseDataGetter: getResponseDataNotWrapped,
  });
};

const doGetAllKeywords = async (payload) => {
  return axios.get('/keywords', payload);
};

/**
 * @deprecated This function is deprecated in favor of subscribeUserToKeywords.
 */
const doSubscribeKeywordsToUser = (payload, showToast) => {
  return toastHandler(axios.post('/user/keywords/subscribe', payload), showToast);
};

const subscribeUserToKeywords = async ({ keywords }) => {
  return responseHandler(axios.post('/user/keywords/subscribe', { keywords }), {
    responseDataGetter: getResponseDataNotWrapped,
    hasToShowSuccessToasts: true,
  });
};

/**
 * @deprecated This function is deprecated in favor of unsubscribeUserToKeywords.
 */
const doUnsubscribeKeywordsToUser = async (payload, showToast) => {
  return toastHandler(axios.post('/user/keywords/unsubscribe', payload), showToast);
};

const unsubscribeUserToKeywords = async (payload) => {
  return responseHandler(axios.post('/user/keywords/unsubscribe', payload), {
    responseDataGetter: getResponseDataNotWrapped,
    hasToShowSuccessToasts: true,
  });
};

const doSearchByTerm = async (payload) => {
  return toastHandler(axios.get('/keywords/search', payload), false);
};

export {
  doGetUserKeywords,
  doGetAllKeywords,
  doSubscribeKeywordsToUser,
  doUnsubscribeKeywordsToUser,
  doSearchByTerm,
  getUserKeywords,
  subscribeUserToKeywords,
  unsubscribeUserToKeywords,
};
