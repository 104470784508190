<template>
  <div
    v-if="hasToShowOnboarding"
    ref="onboardingPanel"
    class="onboarding-panel onboarding-panel-size border-radius-small d-flex"
  >
    <v-row
      no-gutters
      class="justify-space-around fix-last-step-overflow pa-6 pa-sm-10 mb-0"
      :class="{ 'align-content-start': $breakpoint.mobileAndDown }"
    >
      <v-col
        class="col-12 col-sm-4 col-lg-auto text-center"
        :class="[{ 'adjust-images-for-tablet-and-up': $breakpoint.tabletAndUp }]"
      >
        <OnboardingImagesHandlerComponent
          :current-step-index="currentStepIndex"
          :enum-steps="enumOnboarding"
        />
      </v-col>
      <v-col class="content-onboarding col-12 col-sm-8 col-lg pl-sm-3 py-0">
        <v-row
          no-gutters
          align="center"
        >
          <h3
            class="heading primary--text mt-1 mt-sm-0"
            :class="titleClassName"
          >
            {{ titleForSteps }}
          </h3>
        </v-row>
        <v-row no-gutters>
          <p
            ref="descriptions"
            class="descriptions mt-2 mb-4 text text-l-large-regular word-wrap-all"
            v-html="items[currentStepIndex].desc"
          />
        </v-row>
        <v-row
          no-gutters
          class="mt-sm-0 pl-sm-3"
          justify-sm="space-between"
          :class="[
            {
              'fixed-bottom-mobile': $breakpoint.mobileAndDown,
              'fixed-actions-nonmobile': $breakpoint.tabletAndUp,
            },
          ]"
        >
          <v-col
            class="col-12 text-right align-self-center col-sm-12 mb-sm-1"
            :class="{
              'buttons-disposition-mobile': $breakpoint.mobileAndDown,
            }"
          >
            <v-btn
              ripple
              depressed
              :block="$breakpoint.mobileAndDown"
              :height="$breakpoint.mobileAndDown ? '44px' : '36px'"
              color="primary white--text"
              class="heading heading-s-small align-self-center unaltered-text-cap shadow-light-button px-6"
              data-testid="goToFeedOnboardingBtn"
              @click="nextStep"
            >
              {{ items[currentStepIndex].btnPrimary }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OnboardingImagesHandlerComponent from '@/components/onboarding/images/OnboardingImagesHandlerComponent';

export default {
  components: { OnboardingImagesHandlerComponent },
  props: ['currentRoute', 'currentUser'],
  data: () => ({
    currentStepIndex: 0,
    items: [
      {
        id: 0,
        title: 'You’re ready to earn CME credits!',
        desc: 'Find interesting articles or premium courses in your feed and earn CME credits. ',
        btnPrimary: 'Go to feed',
      },
    ],
    enumOnboarding: {
      FINAL: 0,
    },
    descriptionClasses: 'descriptions mt-2 mb-4 text text-l-large-regular',
    animatedClass: 'animate-text',
    mountedDone: false,
    touchendX: 0,
    touchstartX: 0,
    touchendY: 0,
    touchstartY: 0,
    touchEventsDone: false,
    isLoading: true,
  }),
  computed: {
    ...mapGetters('feed', ['isOnboardingFinished', 'isOnboardingRequestDone']),
    hasToShowOnboarding() {
      if (this.isLoading) {
        return false;
      }

      if (
        !this.isOnboardingFinished &&
        this.isOnboardingRequestDone &&
        this.currentUser &&
        (this.currentRoute === 'home' || this.currentRoute === '/')
      ) {
        this.setGeneralVeil(true);
        document.querySelector(':root').classList.add('no-scroll');
        return true;
      } else {
        this.setGeneralVeil(false);
        document.querySelector(':root').classList.remove('no-scroll');
      }

      return false;
    },
    titleForSteps() {
      return this.items[this.currentStepIndex].title;
    },
    titleClassName() {
      if (this.$breakpoint.smallMobileAndDown) {
        return 'heading-m-small onboarding-title-regular-mobile';
      }

      if (this.$breakpoint.mobileOnly) {
        return 'heading-xl-large onboarding-title-regular-mobile';
      }

      if (this.$breakpoint.tabletAndUp) {
        return 'heading-m-large onboarding-title-regular-mobile';
      }

      return '';
    },
  },
  updated() {
    if (this.$refs.onboardingPanel && !this.touchEventsDone) {
      const that = this;
      this.$refs.onboardingPanel.addEventListener(
        'touchstart',
        function (event) {
          that.touchstartX = event.changedTouches[0].screenX;
          that.touchstartY = event.changedTouches[0].screenY;
        },
        false,
      );

      this.$refs.onboardingPanel.addEventListener(
        'touchend',
        function (event) {
          that.touchendX = event.changedTouches[0].screenX;
          that.touchendY = event.changedTouches[0].screenY;

          that.handleGesture();
        },
        false,
      );

      this.touchEventsDone = true;
    }

    if (this.$refs.descriptions && !this.mountedDone) {
      this.$refs.descriptions.addEventListener('animationend', () => {
        this.$refs.descriptions.className = this.descriptionClasses;
      });

      this.mountedDone = true;
    }
  },
  async mounted() {
    const response = await this.getUserSubscription();
    const alreadyHasFreeSubscription = response?.data?.alreadyHasFreeSubscription;

    if (!alreadyHasFreeSubscription) {
      await this.postConfirmFreeSubscription({ id: this.currentUser.id });
    }
    this.currentStepIndex = this.enumOnboarding.FINAL;

    this.$eventNotifier.notify('view_welcome_onboarding', {
      eventCategory: 'Welcome Onboarding',
    });

    this.isLoading = false;
  },
  methods: {
    ...mapActions('feed', ['finishOnboarding']),
    ...mapActions('menu', ['setGeneralVeil']),
    ...mapActions('subscriptions', ['getUserSubscription', 'postConfirmFreeSubscription']),
    nextStep() {
      if (this.currentStepIndex === this.enumOnboarding.FINAL) {
        this.$eventNotifier.notify('complete_welcome_onboarding', {
          eventCategory: 'Welcome Onboarding',
        });

        this.finishOnboardingFlow();
      }

      this.currentStepIndex = this.enumOnboarding.FINAL;

      if (this.$refs.descriptions) {
        this.$refs.descriptions.className = `${this.descriptionClasses} ${this.animatedClass}`;
      }
    },
    closeOnboarding() {
      this.finishOnboardingFlow();
    },
    async finishOnboardingFlow() {
      await this.finishOnboarding();
      this.setGeneralVeil(false);
      document.querySelector(':root').classList.remove('no-scroll');
    },
    handleGesture() {
      const passThresholdTolerance =
        Math.abs(this.touchendY - this.touchstartY) < 100 && Math.abs(this.touchendX - this.touchstartX) > 50;

      if (this.$breakpoint.tabletAndDown) {
        if (this.touchendX < this.touchstartX && passThresholdTolerance) {
          this.nextStep();
        }
      }
    },
  },
};
</script>
<style scoped>
.onboarding-panel {
  position: fixed;
  background-color: var(--v-background-base);
  z-index: 99;
  box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.07);
}
.content-onboarding {
  flex-direction: column;
  display: flex;
}
.onboarding-panel p.descriptions {
  height: 72px;
}
.buttons-disposition-mobile {
  display: flex;
  flex-direction: column-reverse;
}
.fix-last-step-overflow {
  overflow-x: hidden;
  overflow-y: scroll;
}
.adjust-images-for-tablet-and-up {
  display: flex;
  align-items: center;
}
@keyframes keyframe-animation-display {
  from {
    opacity: 0;
    transform: translateX(15px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
.animate-text {
  animation-name: keyframe-animation-display;
  animation-duration: 1s;
  animation-iteration-count: 1;
}
.fixed-actions-nonmobile {
  position: absolute;
  z-index: 5;
  bottom: 0px;
  right: 0px;
  left: 0px;
}
@media (max-width: 600px) {
  .onboarding-panel {
    top: 0px;
    left: 0px;
  }
  .onboarding-panel-size {
    width: 100%;
    height: 100%;
  }
  .onboarding-panel-size > .row {
    background-color: var(--v-background-base);
  }
}
@media (min-width: 600px) and (max-width: 1023px) {
  .onboarding-panel-size {
    height: 27vh;
    min-height: 292px;
    width: 87.5%;
    left: 0;
    right: 0;
    margin: auto;
    top: calc(50% - 146px);
  }
}
@media (min-width: 1024px) and (max-width: 1263px) {
  .onboarding-panel-size {
    height: 30vh;
    min-height: 260px;
    width: 792px !important;
    left: 0;
    right: 0;
    margin: auto;
    top: calc(50% - 130px);
  }
}
@media (min-width: 1264px) {
  .onboarding-panel-size {
    height: 25vh;
    min-height: 268px;
    width: 55%;
    max-width: 792px;
    left: 0;
    right: 0;
    margin: auto;
    top: calc(50% - 134px);
  }
}
@media (max-width: 320px) {
  .onboarding-title-regular-mobile {
    flex: 80%;
  }
}
@media (min-width: 321px) and (max-width: 600px) {
  .onboarding-title-regular-mobile {
    flex: 100%;
  }
}
/* Default adjust */
@media (min-width: 600px) {
  .content-onboarding {
    display: block;
    position: relative;
  }
}

.fixed-bottom-mobile {
  position: relative;
  z-index: 5;
  margin-left: 0px;
  margin-right: 0px;
  bottom: -24px;
}

@media (min-height: 568px) and (max-width: 320px) {
  .fixed-bottom-mobile {
    position: fixed;
    width: calc(100% - 48px);
    bottom: 24px;
  }
}

@media (min-height: 460px) and (min-width: 375px) {
  .fixed-bottom-mobile {
    bottom: 0px;
  }
}

@media (min-height: 610px) and (min-width: 375px) and (max-width: 600px) {
  .fixed-bottom-mobile {
    position: fixed;
    width: calc(100% - 48px);
    bottom: 24px;
  }
}
</style>
