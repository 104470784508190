var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "line-height": "0"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_c('svg', {
    attrs: {
      "width": _vm.checkScreenSize.width,
      "height": _vm.checkScreenSize.height,
      "viewBox": "0 0 141 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('title', [_vm._v("Acapedia")]), _c('path', {
    attrs: {
      "d": "M21.953 23.495C21.3647 23.5004 20.7955 23.2939 20.356 22.9157C19.6395 22.2412 18.9741 21.5176 18.3651 20.7506C16.6963 18.7606 14.6175 16.2891 12.1529 16.2865C9.70152 16.2865 7.59618 18.7683 5.90605 20.7635C5.29499 21.5248 4.62975 22.244 3.91516 22.9157C3.46696 23.2954 2.89051 23.5033 2.2947 23.5C1.69888 23.4966 1.12494 23.2824 0.681295 22.8977C-0.0772667 22.1588 -0.207686 21.1548 0.316653 20.1379C0.401825 19.9783 8.46919 4.78131 9.71483 2.3742C10.0502 1.72286 10.673 0.520596 12.1103 0.5H12.1449C13.5928 0.520596 14.213 1.72286 14.5403 2.3742C15.786 4.78131 23.856 19.9783 23.9359 20.1379C24.4682 21.1677 24.3324 22.1666 23.5765 22.8977C23.1335 23.2891 22.553 23.5027 21.953 23.495ZM12.1502 14.7418C15.3761 14.7418 17.721 17.5325 19.6028 19.7749C20.1614 20.4797 20.7697 21.1463 21.4233 21.7701C21.5701 21.8815 21.7501 21.9441 21.9366 21.9488C22.1231 21.9534 22.3062 21.8998 22.4587 21.7958C22.6343 21.6285 22.7994 21.3633 22.5225 20.8278C22.448 20.6837 14.3727 5.47898 13.1297 3.07445C12.6905 2.21458 12.4536 2.04467 12.1369 2.04467C11.8202 2.04467 11.5833 2.21458 11.1415 3.07445C9.89316 5.48928 1.82579 20.694 1.74062 20.8381C1.47446 21.3659 1.63415 21.6311 1.80982 21.7984C1.9628 21.9012 2.14567 21.9541 2.33186 21.9495C2.51806 21.9448 2.69788 21.8829 2.84519 21.7727C3.49877 21.151 4.10875 20.4879 4.67106 19.7878C6.56614 17.5506 8.92965 14.7676 12.1236 14.7444H12.1609L12.1502 14.7418Z",
      "fill": _vm.fill0
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "12.1105",
      "cy": "21.2254",
      "rx": "2.26238",
      "ry": "2.18828",
      "fill": _vm.fill1
    }
  }), _c('g', {
    attrs: {
      "clip-path": _vm.urlClipPath0
    }
  }, [_c('g', {
    attrs: {
      "clip-path": _vm.urlClipPath1
    }
  }, [_c('path', {
    attrs: {
      "d": "M44.4491 16.9505C44.4491 17.0277 44.4225 17.0792 44.3692 17.1307C44.316 17.1822 44.2628 17.208 44.1829 17.208H43.2247C43.1449 17.208 43.0917 17.1822 43.0384 17.1307C42.9852 17.0792 42.9586 17.0277 42.9586 16.9505V14.6078C42.9586 14.5305 42.932 14.479 42.8787 14.4276C42.8255 14.3761 42.7723 14.3503 42.6924 14.3503H37.6087C37.5289 14.3503 37.4756 14.3246 37.4224 14.2731C37.3692 14.2216 37.3426 14.1701 37.3426 14.0929V13.1918C37.3426 13.1146 37.3692 13.0631 37.4224 13.0116C37.4756 12.9601 37.5289 12.9344 37.6087 12.9344H42.719C42.7989 12.9344 42.8521 12.9086 42.9054 12.8571C42.9586 12.8057 42.9852 12.7542 42.9852 12.6769V8.89249C42.9852 8.81526 42.9586 8.76377 42.9054 8.71228C42.8521 8.66079 42.7989 8.63505 42.719 8.63505H37.662C37.1563 8.63505 36.6772 8.73803 36.2247 8.91824C35.7988 9.09845 35.3996 9.3559 35.0536 9.69057C34.7076 10.0253 34.4414 10.4114 34.2551 10.8233C34.0688 11.261 33.9623 11.7244 33.9623 12.2135V16.9505C33.9623 17.0277 33.9357 17.0792 33.8825 17.1307C33.8292 17.1822 33.776 17.208 33.6961 17.208H32.738C32.6581 17.208 32.6049 17.1822 32.5517 17.1307C32.4984 17.0792 32.4718 17.0277 32.4718 16.9505V12.2135C32.4718 11.7759 32.525 11.3125 32.6581 10.9006C32.7912 10.4887 32.9509 10.0767 33.1904 9.71632C33.4034 9.3559 33.6961 8.99547 33.9889 8.71228C34.3083 8.40335 34.6543 8.1459 35.0536 7.9142C35.4528 7.6825 35.8521 7.52804 36.2779 7.39932C36.7304 7.24485 37.2095 7.19336 37.662 7.19336H44.1829C44.2628 7.19336 44.316 7.2191 44.3692 7.27059C44.4225 7.32208 44.4491 7.37357 44.4491 7.4508V16.9505Z",
      "fill": "#263684"
    }
  }), _c('path', {
    attrs: {
      "d": "M59.4607 16.9505C59.4607 17.0277 59.4341 17.0792 59.3808 17.1307C59.3276 17.1822 59.2744 17.208 59.1945 17.208H52.7268C52.0348 17.208 51.3693 17.0792 50.7306 16.8218C50.145 16.5643 49.6393 16.2039 49.2134 15.7663C48.7876 15.3029 48.4416 14.7622 48.2286 14.1701C47.9891 13.5522 47.8826 12.8829 47.8826 12.2135C47.8826 11.5442 47.9891 10.9006 48.2286 10.2827C48.4416 9.69057 48.7876 9.14994 49.2134 8.68654C49.6393 8.22314 50.145 7.86272 50.7306 7.60527C51.3693 7.32208 52.0348 7.19336 52.7268 7.19336H59.1945C59.2744 7.19336 59.3276 7.2191 59.3808 7.27059C59.4341 7.32208 59.4607 7.37357 59.4607 7.4508V8.35186C59.4607 8.42909 59.4341 8.48058 59.3808 8.53207C59.3276 8.58356 59.2744 8.60931 59.1945 8.60931H52.7268C52.2477 8.60931 51.7686 8.71228 51.3161 8.89249C50.9169 9.07271 50.5442 9.33015 50.2781 9.66483C49.9853 9.99951 49.7724 10.3857 49.6127 10.7976C49.453 11.2352 49.3731 11.7244 49.3998 12.1878C49.3998 12.6512 49.4796 13.1403 49.6127 13.578C49.7724 13.9899 49.9853 14.3761 50.2781 14.7107C50.5709 15.0454 50.9169 15.3029 51.3161 15.4831C51.742 15.6633 52.2211 15.7663 52.7268 15.7663H59.1945C59.2744 15.7663 59.3276 15.792 59.3808 15.8435C59.4341 15.895 59.4607 15.9465 59.4607 16.0237V16.9505Z",
      "fill": "#263684"
    }
  }), _c('path', {
    attrs: {
      "d": "M74.4988 16.9509C74.4988 17.0281 74.4722 17.0796 74.419 17.1311C74.3658 17.1826 74.3125 17.2083 74.2327 17.2083H73.3011C73.2213 17.2083 73.168 17.1826 73.1148 17.1311C73.0616 17.0796 73.0349 17.0281 73.0349 16.9509V14.6081C73.0349 14.5309 73.0083 14.4794 72.9551 14.4279C72.9019 14.3764 72.8486 14.3507 72.7688 14.3507H63.8524C63.7725 14.3507 63.7193 14.3249 63.666 14.2734C63.6128 14.222 63.5862 14.1705 63.5862 14.0932V13.1922C63.5862 13.1149 63.6128 13.0635 63.666 13.012C63.7193 12.9605 63.7725 12.9347 63.8524 12.9347H72.7422C72.822 12.9347 72.8753 12.909 72.9285 12.8575C72.9817 12.806 73.0083 12.7545 73.0083 12.6773V8.89285C73.0083 8.81561 72.9817 8.76413 72.9285 8.71264C72.8753 8.66115 72.822 8.6354 72.7422 8.6354H67.6851C67.206 8.6354 66.7003 8.73838 66.2478 8.91859C65.7953 9.0988 65.3961 9.35625 65.0767 9.69093C64.7307 10.0256 64.4645 10.4118 64.2782 10.8237C64.0919 11.2613 63.9854 11.7247 63.9854 12.2139V16.9509C63.9854 17.0281 63.9588 17.0796 63.9056 17.1311C63.8524 17.1826 63.7991 17.2083 63.7193 17.2083H62.7877C62.7079 17.2083 62.6546 17.1826 62.6014 17.1311C62.5482 17.0796 62.5215 17.0281 62.5215 16.9509V12.1881C62.5215 11.7505 62.5748 11.2871 62.7079 10.8494C62.9474 9.99986 63.3999 9.25327 64.0387 8.6354C64.6775 8.01754 65.4493 7.57988 66.3277 7.34818C66.7801 7.21946 67.2326 7.16797 67.7117 7.16797H74.2593C74.3391 7.16797 74.3924 7.19371 74.4456 7.2452C74.4988 7.29669 74.5255 7.34818 74.5255 7.42541L74.4988 16.9509Z",
      "fill": "#263684"
    }
  }), _c('path', {
    attrs: {
      "d": "M90.5217 10.7461C90.5217 11.2352 90.4153 11.6986 90.2289 12.1363C89.8563 12.9859 89.1377 13.681 88.2593 14.0414C87.8069 14.2216 87.3012 14.3246 86.8221 14.3246H83.2821C83.2023 14.3246 83.149 14.2988 83.0958 14.2473C83.0426 14.1959 83.0159 14.1444 83.0159 14.0671V13.1918C83.0159 13.1146 83.0426 13.0631 83.0958 13.0116C83.149 12.9601 83.2023 12.9344 83.2821 12.9344H86.8221C87.1148 12.9344 87.4076 12.8829 87.7004 12.7799C88.2327 12.5482 88.6586 12.1363 88.8715 11.6214C88.978 11.364 89.0578 11.055 89.0578 10.7718C89.0578 10.4887 89.0046 10.2055 88.8715 9.92227C88.765 9.66483 88.6054 9.43313 88.3924 9.25292C88.1795 9.04696 87.9399 8.89249 87.6738 8.78952C87.4076 8.68654 87.1148 8.63505 86.8221 8.63505H80.3011C80.2212 8.63505 80.168 8.66079 80.1148 8.71228C80.0616 8.76377 80.0349 8.81526 80.0349 8.89249V16.9505C80.0349 17.0277 80.0083 17.0792 79.9551 17.1307C79.9019 17.1822 79.8486 17.208 79.7688 17.208H78.8372C78.7574 17.208 78.7041 17.1822 78.6509 17.1307C78.5977 17.0792 78.571 17.0277 78.571 16.9505V7.4508C78.571 7.37357 78.5977 7.32208 78.6509 7.27059C78.7041 7.2191 78.7574 7.19336 78.8372 7.19336H86.8487C87.3544 7.19336 87.8335 7.29634 88.286 7.47655C89.1643 7.83697 89.8829 8.53207 90.2556 9.38164C90.4419 9.8193 90.5483 10.2827 90.5217 10.7461Z",
      "fill": "#263684"
    }
  }), _c('path', {
    attrs: {
      "d": "M104.336 16.9505C104.336 17.0277 104.309 17.0792 104.256 17.1307C104.202 17.1822 104.149 17.208 104.069 17.208H93.8487C93.7689 17.208 93.7157 17.1822 93.6624 17.1307C93.6092 17.0792 93.5826 17.0277 93.5826 16.9505V7.4508C93.5826 7.37357 93.6092 7.32208 93.6624 7.27059C93.7157 7.2191 93.7689 7.19336 93.8487 7.19336H104.069C104.149 7.19336 104.202 7.2191 104.256 7.27059C104.309 7.32208 104.336 7.37357 104.336 7.4508V8.35186C104.336 8.42909 104.309 8.48058 104.256 8.53207C104.202 8.58356 104.149 8.60931 104.069 8.60931H95.3392C95.2594 8.60931 95.2062 8.63505 95.1529 8.68654C95.0997 8.73803 95.0731 8.78952 95.0731 8.86675V15.5088C95.0731 15.5861 95.0997 15.6375 95.1529 15.689C95.2062 15.7405 95.2594 15.7663 95.3392 15.7663H104.069C104.149 15.7663 104.202 15.792 104.256 15.8435C104.309 15.895 104.336 15.9465 104.336 16.0237V16.9505ZM103.085 12.6512C103.085 12.7284 103.058 12.7799 103.005 12.8314C102.951 12.8829 102.898 12.9086 102.818 12.9086H94.6738C94.594 12.9086 94.5408 12.8829 94.4875 12.8314C94.4343 12.7799 94.4077 12.7284 94.4077 12.6512V11.7501C94.4077 11.6729 94.4343 11.6214 94.4875 11.5699C94.5408 11.5184 94.594 11.4927 94.6738 11.4927H102.792C102.872 11.4927 102.925 11.5184 102.978 11.5699C103.031 11.6214 103.058 11.6729 103.058 11.7501L103.085 12.6512Z",
      "fill": "#263684"
    }
  }), _c('path', {
    attrs: {
      "d": "M119.959 12.1878C119.959 12.8571 119.853 13.5265 119.613 14.1444C119.4 14.7365 119.054 15.2771 118.628 15.7405C118.176 16.2039 117.644 16.5901 117.032 16.8218C116.419 17.0792 115.781 17.208 115.115 17.1822H107.849C107.769 17.1822 107.716 17.1565 107.663 17.105C107.609 17.0535 107.583 17.002 107.583 16.9248V16.0237C107.583 15.9465 107.609 15.895 107.663 15.8435C107.716 15.792 107.769 15.7663 107.849 15.7663H115.142C115.621 15.7663 116.1 15.689 116.526 15.4831C116.925 15.3029 117.298 15.0454 117.564 14.7107C117.857 14.3761 118.07 13.9899 118.229 13.578C118.389 13.1403 118.469 12.6512 118.469 12.1878C118.469 11.7244 118.389 11.2352 118.229 10.7976C118.096 10.3857 117.857 9.99951 117.564 9.66483C117.271 9.33015 116.925 9.07271 116.526 8.89249C116.1 8.68654 115.621 8.60931 115.142 8.60931H109.339C109.26 8.60931 109.206 8.63505 109.153 8.68654C109.1 8.73803 109.073 8.78952 109.073 8.86675V13.1661C109.073 13.2433 109.047 13.2948 108.993 13.3463C108.94 13.3978 108.887 13.4235 108.807 13.4235H107.849C107.769 13.4235 107.716 13.3978 107.663 13.3463C107.609 13.2948 107.583 13.2433 107.583 13.1661V7.4508C107.583 7.37357 107.609 7.32208 107.663 7.27059C107.716 7.2191 107.769 7.19336 107.849 7.19336H115.115C115.781 7.19336 116.419 7.32208 117.032 7.55378C117.644 7.81123 118.176 8.17165 118.628 8.63505C119.054 9.09845 119.4 9.63908 119.613 10.2312C119.853 10.8748 119.986 11.5184 119.959 12.1878Z",
      "fill": "#263684"
    }
  }), _c('path', {
    attrs: {
      "d": "M124.857 16.9505C124.857 17.0277 124.83 17.0792 124.777 17.1307C124.724 17.1822 124.644 17.208 124.564 17.208H123.526C123.446 17.208 123.366 17.1822 123.313 17.1307C123.26 17.0792 123.233 17.0277 123.233 16.9505V7.4508C123.233 7.37357 123.26 7.32208 123.313 7.27059C123.366 7.2191 123.446 7.19336 123.526 7.19336H124.564C124.644 7.19336 124.724 7.2191 124.777 7.27059C124.83 7.32208 124.857 7.37357 124.857 7.4508V16.9505Z",
      "fill": "#263684"
    }
  }), _c('path', {
    attrs: {
      "d": "M140.64 16.9505C140.64 17.0277 140.613 17.0792 140.56 17.1307C140.507 17.1822 140.454 17.208 140.374 17.208H139.442C139.362 17.208 139.309 17.1822 139.256 17.1307C139.203 17.0792 139.176 17.0277 139.176 16.9505V14.6078C139.176 14.5305 139.15 14.479 139.096 14.4276C139.043 14.3761 138.99 14.3503 138.91 14.3503H133.8C133.72 14.3503 133.667 14.3246 133.613 14.2731C133.56 14.2216 133.534 14.1701 133.534 14.0929V13.1918C133.534 13.1146 133.56 13.0631 133.613 13.0116C133.667 12.9601 133.72 12.9344 133.8 12.9344H138.883C138.963 12.9344 139.016 12.9086 139.07 12.8571C139.123 12.8057 139.15 12.7542 139.15 12.6769V8.89249C139.15 8.81526 139.123 8.76377 139.07 8.71228C139.016 8.66079 138.963 8.63505 138.883 8.63505H133.853C133.347 8.63505 132.868 8.73803 132.416 8.91824C131.963 9.09845 131.564 9.3559 131.245 9.69057C130.899 10.0253 130.632 10.4114 130.446 10.8233C130.26 11.261 130.153 11.7244 130.153 12.2135V16.9505C130.153 17.0277 130.127 17.0792 130.073 17.1307C130.02 17.1822 129.967 17.208 129.887 17.208H128.956C128.876 17.208 128.822 17.1822 128.769 17.1307C128.716 17.0792 128.689 17.0277 128.689 16.9505V12.2135C128.689 11.3382 128.929 10.4629 129.381 9.69057C129.594 9.33015 129.887 8.96973 130.18 8.68654C130.499 8.37761 130.845 8.12016 131.245 7.88846C131.644 7.65676 132.043 7.50229 132.469 7.37357C132.921 7.24485 133.374 7.19336 133.853 7.19336H140.374C140.454 7.19336 140.507 7.2191 140.56 7.27059C140.613 7.32208 140.64 7.37357 140.64 7.4508V16.9505Z",
      "fill": "#263684"
    }
  })])]), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": _vm.paintLinear0,
      "x1": "12.1369",
      "y1": "1.00202",
      "x2": "12.1369",
      "y2": "22.9929",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#FD6B51"
    }
  }), _c('stop', {
    attrs: {
      "offset": "0.53",
      "stop-color": "#FF8718"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FFB018"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": _vm.paintLinear1,
      "x1": "12.1105",
      "y1": "19.0371",
      "x2": "12.1105",
      "y2": "23.4137",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#8BB8FF"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#4C70DF"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#4C70DF"
    }
  })], 1), _c('clipPath', {
    attrs: {
      "id": _vm.clip0
    }
  }, [_c('rect', {
    attrs: {
      "width": "108.221",
      "height": "10.0146",
      "fill": "white",
      "transform": "translate(32.4718 7.19336)"
    }
  })]), _c('clipPath', {
    attrs: {
      "id": _vm.clip1
    }
  }, [_c('rect', {
    attrs: {
      "width": "108.221",
      "height": "10.0146",
      "fill": "white",
      "transform": "translate(32.4718 7.19336)"
    }
  })])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }