var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shouldShowCompleteProfileBannerNotification,
      expression: "shouldShowCompleteProfileBannerNotification"
    }],
    staticClass: "alert-container warning",
    class: {
      'alert-container-sticky': _vm.hasToBeSticky
    },
    attrs: {
      "id": "completeProfileAlert"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "text",
    class: {
      'text-m-large-bold': _vm.$breakpoint.mobileAndDown,
      'text-l-large-bold': _vm.$breakpoint.tabletAndUp
    }
  }, [_vm._v("Provide your medical board to report your MOC and CME credits.")]), _c('v-btn', {
    staticClass: "heading heading-s-small warning--text shadow-light-button",
    class: {
      'ml-2': _vm.$breakpoint.mobileAndDown,
      'ml-4': _vm.$breakpoint.tabletAndUp
    },
    attrs: {
      "ripple": "",
      "depressed": "",
      "height": "36px",
      "width": "auto"
    },
    on: {
      "click": _vm.showCompleteProfileDialog
    }
  }, [_vm._v(" Complete profile ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }