import axios from 'axios';
import { responseHandler, getResponseDataNotWrapped } from '@/api/utilities';

export const acceptCourseDisclosure = async (courseId) => {
  return responseHandler(axios.post(`/course/${courseId}/activity/disclosure/agree`));
};

export const answerToPretestQuestion = async ({ takenPretestId, questionId, choiceIndex }) => {
  return responseHandler(
    axios.post(`/courses/taken-pretests/${takenPretestId}/questions/${questionId}/answers`, {
      choiceIndex,
    }),
    {
      hasToShowToasts: false,
    },
  );
};

export const answerToQuestion = async ({ takenQuizId, questionId, choiceIndex }) => {
  return responseHandler(
    axios.post(`/courses/taken-quizzes/${takenQuizId}/questions/${questionId}/answers`, {
      choiceIndex,
    }),
  );
};

export const confirmAnswer = async (answerId) => {
  return responseHandler(axios.post(`/courses/taken-quiz-answers/${answerId}/confirmation`));
};

export const downVoteCourse = async (courseId) => {
  return responseHandler(axios.post(`/courses/${courseId}/down-vote`));
};

export const enrollInCourse = async (courseId) => {
  return responseHandler(axios.post(`/courses/${courseId}/enrollment`));
};

export const enrollInCourseModule = async (courseId, moduleId) => {
  return responseHandler(axios.post(`/courses/${courseId}/modules/${moduleId}/enrollment`));
};

export const getEvaluation = async (courseId) => {
  return responseHandler(axios.get(`/course/${courseId}/activity/evaluation`), {
    responseDataGetter: getResponseDataNotWrapped,
  });
};

export const listPremiumCoursesPendingFeed = async ({ nextCursorPagination }) => {
  const enrollmentStatus = 'pending-enrollment';

  return listPremiumCoursesFeedByStatus({ nextCursorPagination, enrollmentStatus });
};

export const listPremiumCoursesInProgressFeed = async ({ nextCursorPagination }) => {
  const enrollmentStatus = 'in-progress';

  return listPremiumCoursesFeedByStatus({ nextCursorPagination, enrollmentStatus });
};

export const listPremiumCoursesCompletedFeed = async ({ nextCursorPagination }) => {
  const enrollmentStatus = 'completed';

  return listPremiumCoursesFeedByStatus({ nextCursorPagination, enrollmentStatus });
};

const listPremiumCoursesFeedByStatus = async ({ nextCursorPagination, enrollmentStatus }) => {
  const requestPayload = {
    params: {
      cursor: nextCursorPagination,
      enrollmentStatus,
    },
  };

  return responseHandler(axios.get('/feed/premium-courses', requestPayload), { isPaginated: true });
};

export const retakeQuiz = async (courseId, moduleId) => {
  return responseHandler(axios.post(`/courses/${courseId}/modules/${moduleId}/quiz-retake`));
};

export const saveEvaluation = async (payload) => {
  return responseHandler(axios.post(`/course/${payload.id}/activity/evaluation/answer`, payload), {
    responseDataGetter: getResponseDataNotWrapped,
  });
};

export const showCourseEnrollment = async (courseId) => {
  return responseHandler(axios.get(`/courses/${courseId}/enrollment`));
};

export const showPremiumCourseAbstract = async ({ courseId, courseSlug, isSpecialRequirement }) => {
  const id = courseSlug ?? courseId;
  const isSlugQueryParam = courseSlug ? 1 : 0;
  const isSpecialRequirementQueryParam = isSpecialRequirement ? 1 : 0;

  return responseHandler(
    axios.get(`/feed/premium-courses/${id}`, {
      params: {
        isSlug: isSlugQueryParam,
        isSpecialRequirement: isSpecialRequirementQueryParam,
      },
    }),
  );
};

export const showPremiumModuleContent = async (courseId, moduleId) => {
  return responseHandler(axios.post(`/premium-courses/${courseId}/modules/${moduleId}/content`));
};

export const showTakenPretest = async (courseId) => {
  return responseHandler(axios.get(`/courses/${courseId}/pretest`));
};

export const showTakenQuiz = async (courseId, moduleId) => {
  return responseHandler(axios.get(`/courses/${courseId}/modules/${moduleId}/quiz`));
};

export const upVoteCourse = async (courseId) => {
  return responseHandler(axios.post(`/courses/${courseId}/up-vote`));
};

export const takePretest = async (courseId) => {
  return responseHandler(axios.post(`/courses/${courseId}/pretest`));
};

export const takeQuiz = async (courseId, moduleId) => {
  return responseHandler(axios.post(`/courses/${courseId}/modules/${moduleId}/quiz`));
};

export const showCourseCertificate = async (courseId) => {
  return responseHandler(axios.get(`/courses/${courseId}/certificate`));
};
