<template>
  <div
    v-if="hasToShowSpecialReqAlertPro"
    class="alert-container"
    :class="[
      !isSpecialRequirementCourseAbstractRoute && isFreeTrialUser
        ? 'alert-container-warning'
        : 'alert-container-special-requirement-course-abstract',
    ]"
  >
    <div class="d-flex align-center">
      <ph-info
        v-if="hasToShowIcon && (!isFreeTrialUser || isSpecialRequirementCourseAbstractRoute)"
        size="24"
        weight="light"
        class="mr-2"
      />
      <span
        class="text"
        :class="$breakpoint.tabletAndUp ? 'text-l-large-regular' : 'text-m-large-regular'"
        >{{ alertText }}</span
      >
      <v-btn
        v-if="isGuestUser"
        class="heading heading-s-small primary--text shadow-light-button"
        :class="{
          'ml-2': $breakpoint.mobileAndDown,
          'ml-4': $breakpoint.tabletAndUp,
        }"
        ripple
        depressed
        height="36px"
        width="auto"
        :to="{ name: 'register' }"
      >
        <crown-filled-yellow-icon
          v-if="hasToShowCrownIcon"
          class="mr-2"
        ></crown-filled-yellow-icon>
        Go Pro
      </v-btn>
      <v-btn
        v-if="isLoggedInUser"
        class="heading heading-s-small primary--text shadow-light-button"
        :class="{
          'ml-2': $breakpoint.mobileAndDown,
          'ml-4': $breakpoint.tabletAndUp,
        }"
        ripple
        depressed
        height="36px"
        width="auto"
        :to="{ name: 'my-plan', params: { upgrade: true } }"
      >
        <crown-filled-yellow-icon
          v-if="hasToShowCrownIcon"
          class="mr-2"
        ></crown-filled-yellow-icon>
        Upgrade {{ '' }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { PhInfo } from 'phosphor-vue';
import CrownFilledYellowIcon from '@/assets/CrownFilledYellowIcon.vue';
import { upgradeAlertsMixin } from '@/mixins/upgradeAlertsMixin';

export default {
  name: 'SpecialRequirementsAlert',
  components: {
    PhInfo,
    CrownFilledYellowIcon,
  },
  mixins: [upgradeAlertsMixin],
  computed: {
    isFreeTrialUser() {
      return this.currentUser && !this.hasActiveSubscription;
    },
    alertText() {
      if (this.isFreeTrialUser) {
        if (this.isSpecialRequirementCourseAbstractRoute) {
          return 'You need a paid account to access special requirements';
        } else {
          return 'You have used up your free trial credits.';
        }
      }

      return 'You need a paid account to access special requirements';
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-container {
  position: sticky;
  top: 56px;
  z-index: 1;

  min-height: 44px;
  padding: 8px 24px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-weight: 400;
}

.alert-container-special-requirement-course-abstract {
  background-color: var(--v-secondary-base);
}

.alert-container-warning {
  background-color: #ffab18;
}

@media #{map-get($display-breakpoints, 'small-desktop-and-up')} {
  .alert-container {
    top: 60px;
  }
}
</style>
