var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": _vm.width,
      "height": _vm.height,
      "viewBox": "0 0 45 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M18.4001 18.9577L22.7507 0L27.1031 18.9577L44.0269 23.4176L27.1031 27.8754L22.7507 47.8375L18.4001 27.8754L9.15527e-05 23.4176L18.4001 18.9577Z",
      "fill": _vm.fill
    }
  }), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": _vm.idLinear,
      "x1": "44.0258",
      "y1": "23.9183",
      "x2": "-0.000512771",
      "y2": "23.9183",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#80B1FF"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#88B5FE",
      "stop-opacity": "0"
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }