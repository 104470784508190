<template>
  <!-- Deprecated in favor of the new component ExpirationCardAlertV2 -->
  <v-row
    v-show="finishInitLoad"
    :no-gutters="$route.name !== 'home' || ($route.name === 'home' && $vuetify.breakpoint.xsOnly)"
    justify="center"
    class="px-sm-2 px-md-4"
  >
    <v-col
      class="card-alert border-radius-general pa-4 mt-6 mt-sm-12"
      :class="getWidthAccordingToRoute"
    >
      <div
        class="d-flex justify-space-between"
        :class="[{ 'flex-wrap': $vuetify.breakpoint.xsOnly }]"
      >
        <div class="d-flex">
          <div
            class="d-flex flex-start"
            :class="[
              {
                'align-center': $vuetify.breakpoint.lgAndUp || ($route.name === 'home' && $vuetify.breakpoint.smAndUp),
              },
            ]"
          >
            <ph-warning-octagon
              class="clickable"
              weight="light"
              size="28"
              color="var(--v-error-base)"
            />
          </div>
          <div class="d-flex align-center pl-2 pl-md-3 pr-sm-4">
            <h3 class="text text-l-large-regular error--text text--error--base">
              {{ getMsgForAlertExpire }}
            </h3>
          </div>
        </div>
        <div
          class="d-flex align-center"
          :class="[
            { 'pt-6': $vuetify.breakpoint.xsOnly },
            { 'flex-grow-1': $route.name !== 'home' || $vuetify.breakpoint.xsOnly },
          ]"
        >
          <v-btn
            ripple
            depresseds
            height="36px"
            color="var(--v-error-surface-base)"
            class="heading heading-s-small error--text text--error--base shadow-dark-button flex-grow-1"
            @click="updateCard"
          >
            Update card
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { PhWarningOctagon } from 'phosphor-vue';
import { mapGetters } from 'vuex';

export default {
  components: { PhWarningOctagon },
  props: ['finishInitLoad'],
  data: () => ({
    updateBillingRouteName: 'update-billing',
  }),
  computed: {
    ...mapGetters('profile', ['getMsgForAlertExpire']),
    getWidthAccordingToRoute() {
      if (this.$route.name === 'home') return 'col-12 col-lg-10';
      if (this.$route.name === 'transcripts') return 'col-12 col-sm-8 col-md-8 col-lg-6 col-xl-4';
      if (this.$route.name === 'my-plan') return 'col-12 col-sm-8 col-md-8 col-lg-6 col-xl-4';
      if (this.$route.name === 'profile') return 'col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4';
      if (this.$route.name === 'billing') return 'col-12 col-sm-8 col-md-8 col-lg-6 col-xl-4';

      return '';
    },
  },
  methods: {
    updateCard() {
      this.$router.push({ name: this.updateBillingRouteName, params: { comingFrom: this.$route.name } });
    },
  },
};
</script>

<style scoped>
.card-alert {
  background-color: var(--v-error-surface-base);
}
</style>
