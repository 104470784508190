export const formatToCompactNotation = (number) => {
  if (typeof number !== 'number') return number;

  try {
    const formatter = Intl.NumberFormat('en', {
      notation: 'compact',
      minimumSignificantDigits: 1,
      maximumSignificantDigits: 3,
    });

    return formatter.format(number);
  } catch (error) {
    console.error(error);

    // Fallback to default number format
    return number.toString();
  }
};
