import {
  doGetSpecialties,
  doSaveSpecialtiesToUser,
  saveUserSpecialties,
  doGetSpecialtiesSelected,
} from '@/api/specialties';
import { isSuccessfulResponse } from '@/api/utilities';

export default {
  namespaced: true,
  state: () => ({
    selecteds: null,
    specialties: [],
  }),
  mutations: {
    setSelected(state, data) {
      state.selecteds = data;
    },
    setSpecialties(state, data) {
      state.specialties = data;
    },
  },
  actions: {
    async getAll(context) {
      if (context.getters.allSpecialties?.length > 0) {
        context.dispatch('refreshListFromSavedData');
        return { specialties: context.getters.allSpecialties };
      }
      const data = await doGetSpecialties();
      if (data?.specialties) {
        const specialtiesWithVisualProps = data.specialties.map((spec) => {
          spec.show = true;
          spec.forceSelection = !!context.getters.currentSelecteds?.specialties?.find((initialSpecialty) => {
            return initialSpecialty.id === spec.id;
          });

          spec.subspecialties = spec.subspecialties?.map((subspecialty) => {
            subspecialty.forceSelection = !!context.getters.currentSelecteds?.specialties?.find(
              (initialSpecialty) => initialSpecialty.id === subspecialty.id,
            );
            return subspecialty;
          });

          return spec;
        });

        context.commit('setSpecialties', specialtiesWithVisualProps);
      }
      return data;
    },
    saveSpecialtiesToUser(context, payload) {
      return doSaveSpecialtiesToUser(payload);
    },
    async getSpecialtiesSelected(context) {
      const response = await doGetSpecialtiesSelected();
      if (isSuccessfulResponse(response)) {
        context.commit('setSelected', response.data);
      }

      return response;
    },
    async loadSpecialties({ commit, dispatch }, currentUser) {
      if (currentUser) {
        const response = await dispatch('getSpecialtiesSelected');
        if (isSuccessfulResponse(response)) {
          commit('setSelected', response.data);
        }
      } else {
        const storedSpecialties = localStorage.getItem('acapedia_guest_specialties');
        const specialties = storedSpecialties ? JSON.parse(storedSpecialties) : [];
        commit('setSelected', { specialties: specialties });
      }
    },
    getSelected({ getters }) {
      return getters.currentSelecteds;
    },
    getGuestSpecialtiesSelected(context, payload) {
      let mergedSpecialties = {};
      let guestSpecs = JSON.parse(localStorage.getItem('acapedia_guest_specialties'));

      if (context.getters.currentSelecteds?.specialties.length) {
        mergedSpecialties = [...(mergedSpecialties.specialties ?? []), ...context.getters.currentSelecteds.specialties];
      }

      if (guestSpecs?.specialties.length) {
        mergedSpecialties = [...(mergedSpecialties.specialties ?? []), ...guestSpecs.specialties];
      }

      if (guestSpecs?.specialties.length) {
        let uniqueSpecs = Array.from(new Set(mergedSpecialties.map((spec) => spec.id)));

        let toSave = uniqueSpecs.map((spec) => {
          return { id: spec };
        });

        if (!payload?.isOpeningDialogInGuestMode) {
          context.commit('setSelected', { specialties: toSave });
        }
      }

      return guestSpecs;
    },
    saveGuestSpecialties(context, payload) {
      let uniqueSpecs = Array.from(new Set(payload));

      let toSave = uniqueSpecs.map((spec) => {
        return { id: spec };
      });
      context.commit('setSelected', { specialties: toSave });

      localStorage.setItem('acapedia_guest_specialties', JSON.stringify({ specialties: toSave }));
    },
    async updateSpecialties({ commit, rootState }, specialties, subSpecialties) {
      if (rootState.auth.user) {
        await saveUserSpecialties(specialties, subSpecialties);
      } else {
        localStorage.setItem('acapedia_guest_specialties', JSON.stringify(specialties));
      }

      commit('setSelected', specialties);
    },
    async updateSpecialtiesFromCustomizer({ commit, rootState }, payload) {
      let specialtiesToSave = payload.specialties.map((specialty) => {
        return { id: specialty };
      });

      let subSpecialtiesToSave = payload.subSpecialties.map((subspecialty) => {
        return { id: subspecialty };
      });

      let dataToSave = [...specialtiesToSave, ...subSpecialtiesToSave];

      if (rootState.auth.user) {
        await saveUserSpecialties({ specialties: payload.specialties, subSpecialties: payload.subSpecialties });
      } else {
        localStorage.setItem('acapedia_guest_specialties', JSON.stringify(dataToSave));
      }

      commit('setSelected', { specialties: dataToSave });
    },
    refreshListFromSavedData(context) {
      const specialtiesWithVisualProps = context.state.specialties.map((spec) => {
        spec.show = true;
        spec.forceSelection = !!context.getters.currentSelecteds?.specialties?.find((initialSpecialty) => {
          return initialSpecialty.id === spec.id;
        });

        spec.subspecialties = spec.subspecialties?.map((subspecialty) => {
          subspecialty.forceSelection = !!context.getters.currentSelecteds?.specialties?.find(
            (initialSpecialty) => initialSpecialty.id === subspecialty.id,
          );
          return subspecialty;
        });

        return spec;
      });

      context.commit('setSpecialties', specialtiesWithVisualProps);
    },
  },
  getters: {
    currentSelecteds: (state) => {
      return state.selecteds;
    },
    allSpecialties: (state) => {
      return state.specialties;
    },
    state(state) {
      return state;
    },
  },
};
