/**
 * `FeatureFlagAlwaysOnImpl` is a concrete implementation of a Feature Flag provider
 * where all feature flags are always considered as 'enabled'. This is typically used
 * in testing or development environments where you want to simulate the state
 * of having all features turned on, regardless of their actual configuration.
 *
 * While this implementation might not reflect the real-world states of the features,
 * it is quite useful for ensuring that the application behaves correctly when all
 * features are enabled. This could help in identifying issues that might only arise
 * under such circumstances, and mitigate potential risks in advance.
 *
 * Please note that this should not be used in a production environment, as it
 * bypasses the true feature flag configurations.
 */
export class FeatureFlagAlwaysOnImpl {
  #isReady = true;

  async init() {
    console.info('FeatureFlagAlwaysOnImpl is ready');

    return Promise.resolve();
  }

  isReady() {
    return this.#isReady;
  }

  isFeatureEnabled() {
    return true;
  }
}
