import axios from 'axios';
import { toastHandler, responseHandler, getResponseDataNotWrapped } from '@/api/utilities';

const doGetSpecialties = async () => {
  return responseHandler(axios.get('/specialties'), { responseDataGetter: getResponseDataNotWrapped });
};

/** @deprecated Use saveUserSpecialties instead. */
const doSaveSpecialtiesToUser = async (payload) => {
  return toastHandler(axios.post('/user/specialties', payload), true);
};

const saveUserSpecialties = async ({ specialties, subSpecialties }) => {
  return responseHandler(
    axios.post('user/specialties', {
      specialties,
      subspecialties: subSpecialties,
    }),
    {
      responseDataGetter: getResponseDataNotWrapped,
      hasToShowSuccessToasts: false,
    },
  );
};

const doGetSpecialtiesSelected = async () => {
  return axios.get('/user/specialties');
};

export { doGetSpecialties, doSaveSpecialtiesToUser, doGetSpecialtiesSelected, saveUserSpecialties };
