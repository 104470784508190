var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasToShowOnboarding ? _c('div', {
    ref: "onboardingPanel",
    staticClass: "onboarding-panel onboarding-panel-size border-radius-small d-flex"
  }, [_c('v-row', {
    staticClass: "justify-space-around fix-last-step-overflow pa-6 pa-sm-10 mb-0",
    class: {
      'align-content-start': _vm.$vuetify.breakpoint.xsOnly
    },
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "col-sm-4 col-lg-auto text-center",
    class: [{
      'adjust-images-for-tablet-and-up': _vm.$vuetify.breakpoint.smAndUp
    }, _vm.hasToAdjustImagesHeadingForSEPhones ? 'mb-4 mt-2' : 'col-12']
  }, [_c('OnboardingImagesHandlerComponent', {
    attrs: {
      "current-step-index": _vm.currentStepIndex,
      "enum-steps": _vm.enumOnboarding
    }
  })], 1), _vm.hasToAdjustImagesHeadingForSEPhones ? _c('v-col', {
    staticClass: "align-self-center"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('h3', {
    staticClass: "heading primary--text",
    class: _vm.titleClassName
  }, [_vm._v(" " + _vm._s(_vm.titleForSteps) + " ")])])], 1) : _vm._e(), _c('v-col', {
    staticClass: "content-onboarding col-12 col-sm-8 col-lg pl-sm-3 py-0"
  }, [!_vm.hasToAdjustImagesHeadingForSEPhones ? _c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('h3', {
    staticClass: "heading primary--text mt-1 mt-sm-0",
    class: _vm.titleClassName
  }, [_vm._v(" " + _vm._s(_vm.titleForSteps) + " ")])]) : _vm._e(), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('p', {
    ref: "descriptions",
    staticClass: "descriptions mt-2 mb-4 text text-l-large-regular word-wrap-all",
    domProps: {
      "innerHTML": _vm._s(_vm.items[_vm.currentStepIndex].desc)
    }
  })]), _c('v-row', {
    staticClass: "mt-sm-0 pl-sm-3",
    class: [{
      'fixed-bottom-mobile': _vm.$vuetify.breakpoint.xsOnly,
      'fixed-actions-nonmobile': !_vm.$vuetify.breakpoint.xsOnly
    }],
    attrs: {
      "no-gutters": "",
      "justify-sm": "space-between"
    }
  }, [_c('v-col', {
    staticClass: "col-12 col-sm-12 mb-sm-1 text-right align-self-center",
    class: {
      'buttons-disposition-mobile': _vm.$vuetify.breakpoint.xsOnly
    }
  }, [_c('v-btn', {
    staticClass: "heading heading-s-small align-self-center unaltered-text-cap shadow-light-button px-6",
    attrs: {
      "ripple": "",
      "depressed": "",
      "block": _vm.$vuetify.breakpoint.xsOnly,
      "height": _vm.$vuetify.breakpoint.xsOnly ? '44px' : '36px',
      "color": "primary white--text"
    },
    on: {
      "click": _vm.nextStep
    }
  }, [_vm._v(" " + _vm._s(_vm.items[_vm.currentStepIndex].useBtnPrimaryAlternative ? _vm.items[_vm.currentStepIndex].btnPrimaryAlternative : _vm.items[_vm.currentStepIndex].btnPrimary) + " ")])], 1)], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }