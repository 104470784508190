<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 385 308"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M96.6613 105.423C76.1706 117.272 49.9757 110.251 38.1533 89.7433C26.3309 69.2353 33.3579 43.0054 53.8485 31.1572C74.3392 19.309 100.534 26.3292 112.357 46.8372C124.179 67.3452 117.152 93.5751 96.6613 105.423Z"
      fill="url(#paint0_linear_9240_946659)"
    />
    <mask
      id="mask0_9240_946659"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="19"
      y="33"
      width="111"
      height="67"
    >
      <path
        d="M33.4143 77.5005C33.6518 78.5844 33.9335 79.6633 34.2592 80.7355C34.0581 80.9074 33.8479 81.0812 33.6504 81.2518C23.5331 89.9991 22.0413 94.4944 22.7548 95.7721C23.4683 97.0497 28.0742 98.1281 40.8115 94.0694C51.8723 90.5453 65.7099 84.2639 79.7761 76.3826C93.8423 68.5013 106.427 59.9772 115.212 52.3817C125.33 43.6344 126.821 39.1391 126.108 37.8615C125.395 36.5838 120.789 35.5055 108.051 39.5642C107.803 39.6436 107.545 39.7312 107.293 39.8132C106.551 38.9743 105.781 38.1694 104.983 37.4C117.131 33.2746 126.375 32.2171 128.714 36.402C133.179 44.3944 108.207 63.8764 81.2324 78.9902C54.2579 94.1047 24.6141 105.224 20.1491 97.2316C17.8115 93.0463 23.548 85.71 33.4143 77.5005Z"
        fill="url(#paint1_linear_9240_946659)"
      />
    </mask>
    <g mask="url(#mask0_9240_946659)">
      <path
        d="M33.4144 77.5004C33.6519 78.5843 33.9335 79.6632 34.2592 80.7354C34.0582 80.9073 33.8479 81.0811 33.6504 81.2518C23.5332 89.9991 22.0414 94.4944 22.7549 95.772C23.4684 97.0496 28.0743 98.128 40.8116 94.0693C51.8724 90.5453 65.71 84.2639 79.7762 76.3826C93.8424 68.5013 106.427 59.9772 115.213 52.3817C125.33 43.6344 126.822 39.1391 126.108 37.8614C125.395 36.5838 120.789 35.5054 108.051 39.5641C107.803 39.6435 107.545 39.7312 107.294 39.8131C106.551 38.9743 105.781 38.1693 104.983 37.3999C117.131 33.2745 126.375 32.217 128.714 36.4019C133.179 44.3944 108.207 63.8764 81.2325 78.9902C54.258 94.1047 24.6142 105.224 20.1492 97.2315C17.8116 93.0462 23.548 85.7099 33.4144 77.5004Z"
        fill="#B8D2FF"
      />
    </g>
    <path
      d="M296.01 307C205.08 307 137.006 236.335 162.942 139.1"
      stroke="url(#paint2_linear_9240_946659)"
      stroke-width="2"
    />
    <path
      d="M295.954 264.683C246.774 264.683 206.907 224.762 206.907 175.517C206.907 126.273 246.774 86.3517 295.954 86.3517C345.133 86.3517 385 126.273 385 175.517C385 224.762 345.133 264.683 295.954 264.683Z"
      fill="#88B5FE"
    />
    <path
      d="M295.748 264.626C246.569 264.626 206.702 224.705 206.702 175.461C206.702 126.216 246.569 86.2951 295.748 86.2951C344.927 86.2951 384.795 126.216 384.795 175.461C384.795 224.705 344.927 264.626 295.748 264.626Z"
      fill="url(#paint3_linear_9240_946659)"
    />
    <path
      d="M206.906 175.516C206.906 162.646 209.634 150.415 214.534 139.365C249.738 139.113 291.117 191.104 324.807 199.218C346.349 204.406 368.203 199.835 382.854 195.031C373.966 234.889 338.436 264.682 295.952 264.682C246.773 264.682 206.906 224.761 206.906 175.516Z"
      fill="url(#paint4_linear_9240_946659)"
    />
    <path
      d="M206.905 175.518C206.905 169.773 207.452 164.156 208.49 158.714C244.746 165.537 285.698 210.22 319.539 217.693C339.68 222.14 360.066 219.415 375.544 215.522C360.902 244.677 330.764 264.684 295.952 264.684C246.773 264.684 206.905 224.763 206.905 175.518Z"
      fill="url(#paint5_linear_9240_946659)"
      fill-opacity="0.8"
    />
    <path
      d="M295.953 264.683C248.161 264.683 209.167 226.981 207.006 179.66C243.625 185.511 285.237 231.318 319.541 238.892C332.461 241.745 345.483 241.643 357.247 240.178C341.284 255.361 319.709 264.683 295.953 264.683Z"
      fill="url(#paint6_linear_9240_946659)"
      fill-opacity="0.8"
    />
    <path
      d="M210.482 200.578C246.241 208.609 286.319 251.808 319.543 259.144C321.13 259.494 322.719 259.796 324.307 260.06C315.402 263.056 305.869 264.685 295.955 264.685C255.466 264.685 221.295 237.623 210.482 200.578Z"
      fill="url(#paint7_linear_9240_946659)"
      fill-opacity="0.8"
    />
    <path
      d="M295.954 86.3517C345.132 86.3517 385 126.273 385 175.517C385 176.171 384.989 176.821 384.976 177.47C383.938 131.287 346.227 94.1667 299.856 94.1667C252.832 94.1667 214.712 132.338 214.712 179.425C214.712 225.858 251.782 263.618 297.904 264.658C297.254 264.673 296.605 264.683 295.954 264.683C246.775 264.683 206.907 224.762 206.907 175.517C206.907 126.273 246.775 86.3517 295.954 86.3517Z"
      fill="url(#paint8_linear_9240_946659)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9240_946659"
        x1="112.611"
        y1="47.3357"
        x2="37.7767"
        y2="89.1305"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1592D1" />
        <stop
          offset="1"
          stop-color="#21409A"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9240_946659"
        x1="67.094"
        y1="51.3023"
        x2="79.3998"
        y2="77.3454"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#666666" />
        <stop
          offset="1"
          stop-color="#999999"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_9240_946659"
        x1="229.476"
        y1="139.1"
        x2="229.476"
        y2="307"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#CDE9F9"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="#CDE9F9"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_9240_946659"
        x1="320.968"
        y1="92.3558"
        x2="311.783"
        y2="227.13"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#21409A" />
        <stop
          offset="1"
          stop-color="#2484C6"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint4_linear_9240_946659"
        x1="303.12"
        y1="227.858"
        x2="283.202"
        y2="136.928"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#FD6B51"
          stop-opacity="0.41"
        />
        <stop
          offset="1"
          stop-color="#2484C6"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint5_linear_9240_946659"
        x1="287.607"
        y1="226.337"
        x2="291.52"
        y2="190.458"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#FD6B51"
          stop-opacity="0.36"
        />
        <stop
          offset="1"
          stop-color="#2484C6"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint6_linear_9240_946659"
        x1="288.271"
        y1="241.055"
        x2="273.651"
        y2="174.313"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#FD6B51"
          stop-opacity="0.72"
        />
        <stop
          offset="1"
          stop-color="#2484C6"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint7_linear_9240_946659"
        x1="265.12"
        y1="254.515"
        x2="267.966"
        y2="224.321"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#FD6B51"
          stop-opacity="0.78"
        />
        <stop
          offset="1"
          stop-color="#2484C6"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint8_linear_9240_946659"
        x1="385"
        y1="175.517"
        x2="206.907"
        y2="175.517"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#88B5FE" />
        <stop
          offset="1"
          stop-color="#88B5FE"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: '79px',
      type: String,
    },
    height: {
      default: '83px',
      type: String,
    },
  },
};
</script>

<style></style>
