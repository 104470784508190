import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

export const useCreditStore = defineStore('credit', () => {
  const awardedCreditsInCurrentWindow = ref(0);
  const creditLimit = ref(0);
  const pendingCredits = ref(0);

  const creditQuota = computed(() => creditLimit.value - awardedCreditsInCurrentWindow.value);
  const hasCreditQuota = computed(() => creditQuota.value > 0);
  const hasPendingCredits = computed(() => pendingCredits.value > 0);
  const hasReachedCreditLimit = computed(() => hasPendingCredits.value > 0);

  function setCreditsFromUser(user) {
    awardedCreditsInCurrentWindow.value = user?.awardedCredits ?? 0;
    creditLimit.value = parseInt(user?.credit_limit ?? 0);
    pendingCredits.value = user?.unawardedCredits ?? 0;
  }

  return {
    awardedCreditsInCurrentWindow,
    creditLimit,
    creditQuota,
    hasCreditQuota,
    hasReachedCreditLimit,
    pendingCredits,
    setCreditsFromUser,
  };
});
