<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.2117 3.91744C10.5442 3.70118 10.7641 3.32634 10.7641 2.90016C10.7641 2.23043 10.2211 1.6875 9.55141 1.6875C8.88167 1.6875 8.33875 2.23043 8.33875 2.90016C8.33875 3.32625 8.55849 3.701 8.89084 3.91729L6.38033 8.96348L3.42211 6.93294C3.4647 6.80919 3.48783 6.67637 3.48783 6.53815C3.48783 5.86841 2.9449 5.32549 2.27516 5.32549C1.60543 5.32549 1.0625 5.86841 1.0625 6.53815C1.0625 7.20788 1.60543 7.75081 2.27516 7.75081C2.28844 7.75081 2.30166 7.7506 2.31483 7.75017L4.55458 14.6993C4.57714 14.7668 4.61492 14.8285 4.66513 14.8799C4.71535 14.9312 4.77671 14.9709 4.8447 14.9959C4.91268 15.021 4.98555 15.0308 5.05793 15.0246C5.1303 15.0184 5.20033 14.9964 5.26285 14.9602C5.27801 14.9513 6.82855 14.0777 9.55004 14.0777C12.2715 14.0777 13.8221 14.9513 13.836 14.9596C13.8986 14.9962 13.9687 15.0185 14.0413 15.0249C14.1138 15.0313 14.1869 15.0216 14.2551 14.9966C14.3234 14.9716 14.3849 14.9319 14.4353 14.8804C14.4857 14.8289 14.5236 14.767 14.5461 14.6993L16.7875 7.75016C16.8008 7.75059 16.8142 7.75081 16.8277 7.75081C17.4974 7.75081 18.0403 7.20788 18.0403 6.53815C18.0403 5.86841 17.4974 5.32549 16.8277 5.32549C16.1579 5.32549 15.615 5.86841 15.615 6.53815C15.615 6.67651 15.6382 6.80945 15.6808 6.93332L12.722 8.96348L10.2117 3.91744Z"
      fill="#FFAB18"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.2117 3.91744C10.5442 3.70118 10.7641 3.32634 10.7641 2.90016C10.7641 2.23043 10.2211 1.6875 9.55141 1.6875C8.88167 1.6875 8.33875 2.23043 8.33875 2.90016C8.33875 3.32625 8.55849 3.701 8.89084 3.91729L6.38033 8.96348L3.42211 6.93294C3.4647 6.80919 3.48783 6.67637 3.48783 6.53815C3.48783 5.86841 2.9449 5.32549 2.27516 5.32549C1.60543 5.32549 1.0625 5.86841 1.0625 6.53815C1.0625 7.20788 1.60543 7.75081 2.27516 7.75081C2.28844 7.75081 2.30166 7.7506 2.31483 7.75017L4.55458 14.6993C4.57714 14.7668 4.61492 14.8285 4.66513 14.8799C4.71535 14.9312 4.77671 14.9709 4.8447 14.9959C4.91268 15.021 4.98555 15.0308 5.05793 15.0246C5.1303 15.0184 5.20033 14.9964 5.26285 14.9602C5.27801 14.9513 6.82855 14.0777 9.55004 14.0777C12.2715 14.0777 13.8221 14.9513 13.836 14.9596C13.8986 14.9962 13.9687 15.0185 14.0413 15.0249C14.1138 15.0313 14.1869 15.0216 14.2551 14.9966C14.3234 14.9716 14.3849 14.9319 14.4353 14.8804C14.4857 14.8289 14.5236 14.767 14.5461 14.6993L16.7875 7.75016C16.8008 7.75059 16.8142 7.75081 16.8277 7.75081C17.4974 7.75081 18.0403 7.20788 18.0403 6.53815C18.0403 5.86841 17.4974 5.32549 16.8277 5.32549C16.1579 5.32549 15.615 5.86841 15.615 6.53815C15.615 6.67651 15.6382 6.80945 15.6808 6.93332L12.722 8.96348L10.2117 3.91744Z"
      fill="#FFAB18"
    />
  </svg>
</template>

<script>
export default {};
</script>
