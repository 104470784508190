import Vue from 'vue';
import {
  acceptCourseDisclosure as doAcceptCourseDisclosure,
  listPremiumCoursesPendingFeed as doListPremiumCoursesPendingFeed,
  listPremiumCoursesCompletedFeed as doListPremiumCoursesCompletedFeed,
  listPremiumCoursesInProgressFeed as doListPremiumCoursesInProgressFeed,
  upVoteCourse as doUpVoteCourse,
  downVoteCourse as doDownVoteCourse,
  showPremiumCourseAbstract as doShowPremiumCourseAbstract,
  enrollInCourse as doEnrollInCourse,
  enrollInCourseModule as doEnrollInCourseModule,
  showPremiumModuleContent as doShowPremiumModuleContent,
  takeQuiz as doTakeQuiz,
  showTakenQuiz as doShowTakenQuiz,
  answerToQuestion as doAnswerToQuestion,
  confirmAnswer as doConfirmAnswer,
  showTakenPretest as doShowTakenPretest,
  takePretest as doTakePretest,
  answerToPretestQuestion as doAnswerToPretestQuestion,
  retakeQuiz as doRetakeQuiz,
  getEvaluation as doGetEvaluation,
  saveEvaluation as doSaveEvaluation,
  showCourseCertificate as doShowCourseCertificate,
} from '@/api/courses';

export default {
  namespaced: true,
  state: {
    currentCourseAbstract: null,
    isLoadingCourseAbstract: false,

    courseDisclosures: JSON.parse(localStorage.getItem('courseDisclosures')) || {},
    courseEnrollment: null,
    hasToHidePretestWelcomeByUserPreference:
      JSON.parse(localStorage.getItem('hasToHidePretestWelcomeByUserPreference')) ?? false,

    premiumCoursesPendingFeed: [],
    previousCursorPaginationForPendingFeed: null,
    nextCursorPaginationForPendingFeed: null,

    premiumCoursesInProgressFeed: [],
    nextCursorPaginationForInProgressFeed: null,

    premiumCoursesCompletedFeed: [],
    nextCursorPaginationForCompletedFeed: null,
    isShowingResultPage: false,
    isShowingResultDetailsPage: false,
  },
  mutations: {
    setAcceptDisclosure(state, courseId) {
      if (state.currentCourseAbstract?.id !== courseId) {
        return;
      }

      Vue.set(state.currentCourseAbstract, 'hasAcceptedActivityDisclosure', true);
    },
    setCourseDisclosures(state, courseDisclosures) {
      state.courseDisclosures = courseDisclosures;
    },
    setCourseEnrollment(state, courseEnrollment) {
      state.courseEnrollment = courseEnrollment;
    },
    setCourseEnrollmentAsCompleted(state) {
      Vue.set(state.courseEnrollment, 'status', 'completed');
      Vue.set(state.courseEnrollment, 'completedAt', new Date());
    },
    setCurrentCourseAbstract(state, currentCourseAbstract) {
      state.currentCourseAbstract = currentCourseAbstract;
    },
    setHasCompletedCmeEvaluation(state, hasCompletedCmeEvaluation) {
      Vue.set(state.courseEnrollment, 'hasCompletedCmeEvaluation', hasCompletedCmeEvaluation);
    },
    setHasCompletedPretestForCurrentCourseEnrollment(state) {
      Vue.set(state.courseEnrollment, 'hasCompletedPretest', true);
    },
    setHasToHidePretestWelcomeByUserPreference(state, hasToHidePretestWelcomeByUserPreference) {
      localStorage.setItem(
        'hasToHidePretestWelcomeByUserPreference',
        JSON.stringify(hasToHidePretestWelcomeByUserPreference),
      );

      state.hasToHidePretestWelcomeByUserPreference = hasToHidePretestWelcomeByUserPreference;
    },
    setIsLoadingCourseAbstract(state, isLoadingCourseAbstract) {
      state.isLoadingCourseAbstract = isLoadingCourseAbstract;
    },
    setIsShowingResultPage(state, isShowingResultPage) {
      state.isShowingResultPage = isShowingResultPage;
    },
    setIsShowingResultDetailsPage(state, isShowingResultDetailsPage) {
      state.isShowingResultDetailsPage = isShowingResultDetailsPage;
    },
    setNextCursorPaginationForPendingFeed(state, nextCursorPagination) {
      state.nextCursorPaginationForPendingFeed = nextCursorPagination;
    },
    setNextCursorPaginationForCompletedFeed(state, nextCursorPagination) {
      state.nextCursorPaginationForCompletedFeed = nextCursorPagination;
    },
    setNextCursorPaginationForInProgressFeed(state, nextCursorPagination) {
      state.nextCursorPaginationForInProgressFeed = nextCursorPagination;
    },
    setPremiumCoursesPendingFeed(state, premiumCourses) {
      state.premiumCoursesPendingFeed = premiumCourses;
    },
    setPremiumCoursesCompletedFeed(state, premiumCourses) {
      state.premiumCoursesCompletedFeed = premiumCourses;
    },
    setPremiumCoursesInProgressFeed(state, premiumCourses) {
      state.premiumCoursesInProgressFeed = premiumCourses;
    },
    setPreviousCursorPaginationForPendingFeed(state, previousCursorPagination) {
      state.previousCursorPaginationForPendingFeed = previousCursorPagination;
    },
  },
  actions: {
    async acceptDisclosure({ commit }, courseId) {
      await doAcceptCourseDisclosure(courseId);
      commit('setAcceptDisclosure', courseId);
    },
    async answerToPretestQuestion(_context, { takenPretestId, questionId, choiceIndex }) {
      return doAnswerToPretestQuestion({ takenPretestId, questionId, choiceIndex });
    },
    async answerToQuestion(_context, { takenQuizId, questionId, choiceIndex }) {
      return doAnswerToQuestion({ takenQuizId, questionId, choiceIndex });
    },
    async confirmAnswer(_context, { answerId }) {
      return doConfirmAnswer(answerId);
    },
    async downVoteCourse(_context, courseId) {
      return doDownVoteCourse(courseId);
    },
    // This store method not being used (the API method it is)
    async enrollInCourse(_context, courseId) {
      return doEnrollInCourse(courseId);
    },
    async enrollInCourseModule({ commit }, { courseId, moduleId }) {
      const courseEnrollment = await doEnrollInCourseModule(courseId, moduleId);

      commit('setCourseEnrollment', courseEnrollment);

      return courseEnrollment;
    },
    async getEvaluation(_context, courseId) {
      return doGetEvaluation(courseId);
    },
    async listPremiumCoursesCompletedFeed({ commit, dispatch, state }, { hasToReloadList = false } = {}) {
      if (hasToReloadList) {
        dispatch('resetPremiumCoursesCompletedFeed');
      }

      const { data: fetchedPremiumCourses, nextCursorPagination } = await doListPremiumCoursesCompletedFeed({
        nextCursorPagination: state.nextCursorPaginationForCompletedFeed,
      });

      if (!fetchedPremiumCourses?.length) {
        commit('setNextCursorPaginationForCompletedFeed', nextCursorPagination);

        return [];
      }

      const premiumCourses = [...state.premiumCoursesCompletedFeed, ...fetchedPremiumCourses];

      commit('setNextCursorPaginationForCompletedFeed', nextCursorPagination);
      commit('setPremiumCoursesCompletedFeed', premiumCourses);

      return premiumCourses;
    },
    async listPremiumCoursesPendingFeed(
      { commit, dispatch, state },
      { hasToReloadList = false, cursorPagination = null } = {},
    ) {
      if (hasToReloadList) {
        dispatch('resetPremiumCoursesPendingFeed');
      }

      const {
        data: fetchedPremiumCourses,
        nextCursorPagination,
        previousCursorPagination,
      } = await doListPremiumCoursesPendingFeed({
        nextCursorPagination: cursorPagination ?? state.nextCursorPaginationForPendingFeed,
      });

      if (!fetchedPremiumCourses?.length) {
        commit('setPreviousCursorPaginationForPendingFeed', previousCursorPagination);
        commit('setNextCursorPaginationForPendingFeed', nextCursorPagination);

        return [];
      }

      const premiumCourses = [...state.premiumCoursesPendingFeed, ...fetchedPremiumCourses];

      commit('setPreviousCursorPaginationForPendingFeed', previousCursorPagination);
      commit('setNextCursorPaginationForPendingFeed', nextCursorPagination);
      commit('setPremiumCoursesPendingFeed', premiumCourses);

      return premiumCourses;
    },
    async listPremiumCoursesInProgressFeed({ commit, dispatch, state }, { hasToReloadList = false } = {}) {
      if (hasToReloadList) {
        dispatch('resetPremiumCoursesInProgressFeed');
      }

      const { data: fetchedPremiumCourses, nextCursorPagination } = await doListPremiumCoursesInProgressFeed({
        nextCursorPagination: state.nextCursorPaginationForInProgressFeed,
      });

      if (!fetchedPremiumCourses?.length) {
        commit('setNextCursorPaginationForInProgressFeed', nextCursorPagination);

        return [];
      }

      const premiumCourses = [...state.premiumCoursesInProgressFeed, ...fetchedPremiumCourses];

      commit('setNextCursorPaginationForInProgressFeed', nextCursorPagination);
      commit('setPremiumCoursesInProgressFeed', premiumCourses);

      return premiumCourses;
    },
    async retakeQuiz(_context, { courseId, moduleId }) {
      return doRetakeQuiz(courseId, moduleId);
    },
    resetPremiumCoursesCompletedFeed({ commit }) {
      commit('setNextCursorPaginationForCompletedFeed', null);
      commit('setPremiumCoursesCompletedFeed', []);
    },
    resetPremiumCoursesInProgressFeed({ commit }) {
      commit('setNextCursorPaginationForInProgressFeed', null);
      commit('setPremiumCoursesInProgressFeed', []);
    },
    resetPremiumCoursesPendingFeed({ commit }) {
      commit('setPreviousCursorPaginationForPendingFeed', null);
      commit('setNextCursorPaginationForPendingFeed', null);
      commit('setPremiumCoursesPendingFeed', []);
    },
    async saveEvaluation({ commit }, payload) {
      const response = await doSaveEvaluation(payload);

      if (response?.success) {
        commit('setHasCompletedCmeEvaluation', true);
        commit('setCourseEnrollmentAsCompleted');
      }

      return response;
    },
    async showOrCreateCourseEnrollment(
      { dispatch, commit, state },
      { courseId, topicSlug, stateSlug, ignoreCache = false },
    ) {
      if (state.courseEnrollment?.course?.id === courseId && !ignoreCache) {
        return state.courseEnrollment;
      }

      const courseEnrollment = await doEnrollInCourse(courseId);
      commit('setCourseEnrollment', courseEnrollment);

      if (topicSlug && stateSlug && courseEnrollment?.id) {
        dispatch(
          'specialRequirements/startJourney',
          { topicSlug, stateSlug, cmeExperienceId: courseId, cmeExperienceType: 'course' },
          { root: true },
        );
      }

      return courseEnrollment;
    },
    async showPremiumCourseAbstract({ commit, state }, { courseId, courseSlug, isSpecialRequirement }) {
      if (courseSlug && state.currentCourseAbstract?.slug === courseSlug) {
        return state.currentCourseAbstract;
      }

      if (courseId && state.currentCourseAbstract?.id === courseId) {
        return state.currentCourseAbstract;
      }

      commit('setIsLoadingCourseAbstract', true);
      try {
        const courseDetails = await doShowPremiumCourseAbstract({ courseId, courseSlug, isSpecialRequirement });
        commit('setCurrentCourseAbstract', courseDetails);

        return courseDetails;
      } finally {
        commit('setIsLoadingCourseAbstract', false);
      }
    },
    async showPremiumModuleContent(_context, { courseId, moduleId }) {
      return doShowPremiumModuleContent(courseId, moduleId);
    },
    async showTakenPretest(_context, { courseId }) {
      return doShowTakenPretest(courseId);
    },
    async showTakenQuiz(_context, { courseId, moduleId }) {
      return doShowTakenQuiz(courseId, moduleId);
    },

    async takePretest(_context, { courseId }) {
      return doTakePretest(courseId);
    },
    async takeQuiz(_context, { courseId, moduleId }) {
      return doTakeQuiz(courseId, moduleId);
    },
    async upVoteCourse(_context, courseId) {
      return doUpVoteCourse(courseId);
    },
    async showCourseCertificate(_context, courseId) {
      return doShowCourseCertificate(courseId);
    },
    setIsShowingResultPage({ commit }, isShowingResultPage) {
      commit('setIsShowingResultPage', isShowingResultPage);
    },
    setIsShowingResultDetailsPage({ commit }, isShowingResultDetailsPage) {
      commit('setIsShowingResultDetailsPage', isShowingResultDetailsPage);
    },
  },
};
