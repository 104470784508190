var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "id": "checkListAnimation",
      "width": _vm.width,
      "height": _vm.height,
      "viewBox": "0 0 447 294",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_10885_27265)"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip1_10885_27265)"
    }
  }, [_c('path', {
    attrs: {
      "opacity": "0.2",
      "d": "M313.475 726.425C481.259 726.425 617.275 590.409 617.275 422.625C617.275 254.841 481.259 118.825 313.475 118.825C145.691 118.825 9.67529 254.841 9.67529 422.625C9.67529 590.409 145.691 726.425 313.475 726.425Z",
      "fill": "#FFAB18"
    }
  }), _c('path', {
    attrs: {
      "d": "M229.646 149.259C79.7302 193.978 -5.54891 351.761 39.17 501.677C83.889 651.593 241.672 736.872 391.588 692.153C541.504 647.434 626.783 489.651 582.064 339.735C537.345 189.819 379.562 104.54 229.646 149.259Z",
      "fill": "#FF8718",
      "fill-opacity": "0.87"
    }
  }), _c('path', {
    attrs: {
      "d": "M229.083 149.611C79.1669 194.33 -6.11231 352.113 38.6066 502.029C83.3256 651.946 241.109 737.225 391.025 692.506C540.941 647.787 626.22 490.004 581.501 340.088C536.782 190.172 378.999 104.892 229.083 149.611Z",
      "fill": "url(#paint0_linear_10885_27265)"
    }
  }), _c('path', {
    attrs: {
      "d": "M39.1753 501.679C50.8631 540.861 70.2857 575.614 95.2585 604.798C202.803 573.555 281.727 377.65 377.058 322.316C438.018 286.933 508.788 280.976 557.81 282.277C494.521 169.022 359.158 110.63 229.652 149.261C79.7364 193.98 -5.54342 351.764 39.1753 501.679Z",
      "fill": "url(#paint1_linear_10885_27265)"
    }
  }), _c('path', {
    attrs: {
      "d": "M39.0345 501.08C44.252 518.571 51.0194 535.173 59.1251 550.798C163.451 497.058 247.714 323.788 344.087 270.269C401.445 238.416 466.064 228.174 516.782 225.952C445.673 150.509 335.632 117.007 229.511 148.662C79.5955 193.381 -5.68424 351.165 39.0345 501.08Z",
      "fill": "url(#paint2_linear_10885_27265)"
    }
  }), _c('path', {
    attrs: {
      "d": "M229.65 149.258C83.9614 192.716 -0.670185 342.949 35.7139 488.976C142.029 437.864 227.281 260.574 324.974 206.325C361.77 185.891 401.558 174.359 438.75 168.123C376.299 136.415 302.067 127.656 229.65 149.258Z",
      "fill": "url(#paint3_linear_10885_27265)",
      "fill-opacity": "0.8"
    }
  }), _c('path', {
    attrs: {
      "d": "M27.3056 422.136C129.018 365.171 211.962 197.216 306.581 144.673C311.1 142.164 315.67 139.8 320.27 137.551C290.405 136.53 259.867 140.236 229.645 149.251C106.218 186.069 26.628 299.527 27.3056 422.136Z",
      "fill": "url(#paint4_linear_10885_27265)",
      "fill-opacity": "0.8"
    }
  }), _c('path', {
    attrs: {
      "d": "M88.239 128.257L85.0916 115.149L81.943 128.257L69.7 131.34L81.943 134.423L85.0916 148.224L88.239 134.423L101.55 131.34L88.239 128.257Z",
      "fill": "url(#paint5_linear_10885_27265)"
    }
  }, [_c('animate', {
    attrs: {
      "id": "starAnimation",
      "attributeName": "opacity",
      "values": "0;1;0",
      "dur": "2.5s",
      "repeatCount": "indefinite"
    }
  })]), _c('path', {
    attrs: {
      "d": "M95.1071 243.097C85.8204 239.566 79.0022 232.405 75.711 223.908C75.0851 222.293 74.5869 220.63 74.2233 218.934C73.8535 217.209 73.6231 215.451 73.5398 213.675C73.3384 209.396 73.9911 205.018 75.6046 200.774C82.0085 183.93 101.123 175.568 118.298 182.097C135.473 188.627 144.204 207.575 137.8 224.42C131.396 241.264 112.282 249.626 95.1071 243.097Z",
      "fill": "url(#paint6_linear_10885_27265)"
    }
  }), _c('path', {
    attrs: {
      "d": "M158.327 186.332C158.398 186.473 158.477 186.837 158.125 187.629C157.781 188.403 157.109 189.384 156.066 190.557C153.99 192.892 150.649 195.766 146.257 198.99C137.489 205.426 124.722 213.12 110.035 220.533C95.3483 227.946 81.5755 233.646 71.1921 236.876C65.9909 238.494 61.6959 239.475 58.5848 239.757C57.0229 239.899 55.8352 239.857 55.0084 239.674C54.163 239.486 53.9169 239.206 53.8461 239.066C53.7752 238.925 53.6965 238.561 54.0483 237.769C54.3924 236.994 55.0648 236.014 56.1073 234.841C58.1837 232.505 61.5244 229.632 65.9163 226.408C74.684 219.972 87.4519 212.277 102.138 204.865C116.825 197.452 130.598 191.751 140.981 188.521C146.183 186.903 150.478 185.923 153.589 185.641C155.151 185.499 156.338 185.541 157.165 185.724C158.01 185.912 158.257 186.192 158.327 186.332Z",
      "stroke": "url(#paint7_linear_10885_27265)",
      "stroke-width": "2.45"
    }
  }), _c('path', {
    attrs: {
      "d": "M163.372 200.752C163.404 200.906 163.385 201.279 162.841 201.953C162.308 202.612 161.405 203.385 160.094 204.248C157.484 205.966 153.513 207.877 148.437 209.855C138.302 213.802 123.978 217.93 107.873 221.289C91.7682 224.648 76.9892 226.59 66.1236 227.022C60.6809 227.239 56.2785 227.074 53.2004 226.542C51.6549 226.274 50.5185 225.927 49.7673 225.536C48.9993 225.136 48.8341 224.801 48.802 224.647C48.77 224.493 48.7883 224.121 49.333 223.447C49.8658 222.788 50.7691 222.015 52.0796 221.152C54.6897 219.433 58.6604 217.522 63.737 215.545C73.8717 211.598 88.1961 207.47 104.301 204.111C120.405 200.752 135.184 198.81 146.05 198.378C151.493 198.161 155.895 198.326 158.973 198.858C160.519 199.125 161.655 199.473 162.406 199.864C163.174 200.264 163.34 200.598 163.372 200.752Z",
      "stroke": "url(#paint8_linear_10885_27265)",
      "stroke-width": "2.45"
    }
  }), _c('g', {
    attrs: {
      "clip-path": "url(#clip2_10885_27265)"
    }
  }, [_c('rect', {
    attrs: {
      "x": "134.625",
      "y": "89.897",
      "width": "185.619",
      "height": "222.532",
      "rx": "10.5465",
      "fill": "#21409A"
    }
  }), _c('rect', {
    attrs: {
      "x": "134.625",
      "y": "89.897",
      "width": "185.619",
      "height": "222.532",
      "rx": "10.5465",
      "fill": "url(#paint9_linear_10885_27265)"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M203.247 80.1775V71.9677C203.247 70.2203 204.664 68.8037 206.411 68.8037L259.232 68.8037C260.979 68.8037 262.396 70.2203 262.396 71.9677V80.1775H292.218C293.966 80.1775 295.382 81.594 295.382 83.3414V100.9C295.382 102.648 293.966 104.064 292.218 104.064H173.424C171.677 104.064 170.26 102.648 170.26 100.9V83.3414C170.26 81.594 171.677 80.1775 173.424 80.1775H203.247Z",
      "fill": "#1E2A67"
    }
  }), _c('rect', {
    attrs: {
      "opacity": "0.5",
      "width": "105.04",
      "height": "7.97769",
      "rx": "2.27495",
      "transform": "matrix(1 0 0 -1 155.637 129.186)",
      "fill": "url(#paint10_linear_10885_27265)"
    }
  }), _c('rect', {
    attrs: {
      "opacity": "0.5",
      "width": "105.04",
      "height": "7.97769",
      "rx": "2.27495",
      "transform": "matrix(1 0 0 -1 155.637 263.477)",
      "fill": "url(#paint11_linear_10885_27265)"
    }
  }), _c('rect', {
    attrs: {
      "opacity": "0.5",
      "width": "105.04",
      "height": "7.97769",
      "rx": "2.27495",
      "transform": "matrix(1 0 0 -1 155.637 174.393)",
      "fill": "url(#paint12_linear_10885_27265)"
    }
  }), _c('rect', {
    attrs: {
      "opacity": "0.5",
      "width": "105.04",
      "height": "6.64807",
      "rx": "2.27495",
      "transform": "matrix(1 0 0 -1 155.637 219.6)",
      "fill": "url(#paint13_linear_10885_27265)"
    }
  }), _c('rect', {
    attrs: {
      "opacity": "0.5",
      "width": "50.5254",
      "height": "7.97769",
      "rx": "2.27495",
      "transform": "matrix(1 0 0 -1 155.637 143.811)",
      "fill": "url(#paint14_linear_10885_27265)"
    }
  }), _c('rect', {
    attrs: {
      "opacity": "0.5",
      "width": "50.5254",
      "height": "7.97769",
      "rx": "2.27495",
      "transform": "matrix(1 0 0 -1 155.637 278.103)",
      "fill": "url(#paint15_linear_10885_27265)"
    }
  }), _c('rect', {
    attrs: {
      "opacity": "0.5",
      "width": "87.7546",
      "height": "7.97769",
      "rx": "2.27495",
      "transform": "matrix(1 0 0 -1 155.637 189.019)",
      "fill": "url(#paint16_linear_10885_27265)"
    }
  }), _c('rect', {
    attrs: {
      "opacity": "0.5",
      "width": "72.771",
      "height": "7.38256",
      "rx": "2.27495",
      "transform": "matrix(1 0 0 -1 155.718 235.438)",
      "fill": "url(#paint17_linear_10885_27265)"
    }
  }), _c('rect', {
    attrs: {
      "x": "272.71",
      "y": "121.292",
      "width": "22.7495",
      "height": "22.7495",
      "rx": "3.41242",
      "fill": "url(#paint18_linear_10885_27265)"
    }
  }), _c('rect', {
    attrs: {
      "x": "272.71",
      "y": "255.515",
      "width": "22.7495",
      "height": "22.7495",
      "rx": "3.41242",
      "fill": "url(#paint19_linear_10885_27265)"
    }
  }), _c('rect', {
    attrs: {
      "x": "272.71",
      "y": "166.792",
      "width": "22.7495",
      "height": "22.7495",
      "rx": "3.41242",
      "fill": "url(#paint20_linear_10885_27265)"
    }
  }), _c('rect', {
    attrs: {
      "x": "272.71",
      "y": "212.291",
      "width": "22.7495",
      "height": "22.7495",
      "rx": "3.41242",
      "fill": "url(#paint21_linear_10885_27265)"
    }
  })]), _c('path', {
    attrs: {
      "id": "tick1",
      "d": "M299.721 121.275L282.976 137.922L276.9 130.788",
      "stroke": "#13B577",
      "stroke-width": "3.41242",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('animate', {
    attrs: {
      "id": "tick1animation",
      "attributeName": "opacity",
      "from": "0",
      "to": "1",
      "dur": ".7s",
      "begin": "2s;tick4animation.end+1s",
      "fill": "freeze"
    }
  }), _c('animate', {
    attrs: {
      "attributeName": "opacity",
      "to": "0",
      "begin": "0s;tick4animation.end",
      "dur": ".4s",
      "fill": "freeze"
    }
  })]), _c('path', {
    attrs: {
      "id": "tick2",
      "d": "M299.721 167.825L282.976 184.472L276.9 177.338",
      "stroke": "#13B577",
      "stroke-width": "3.41242",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('animate', {
    attrs: {
      "id": "tick2animation",
      "attributeName": "opacity",
      "from": "0",
      "to": "1",
      "dur": ".7s",
      "begin": "tick1animation.end",
      "fill": "freeze"
    }
  }), _c('animate', {
    attrs: {
      "attributeName": "opacity",
      "to": "0",
      "begin": "0s;tick4animation.end",
      "dur": ".4s",
      "fill": "freeze"
    }
  })]), _c('path', {
    attrs: {
      "id": "tick3",
      "d": "M299.721 213.15L282.976 229.797L276.9 222.663",
      "stroke": "#13B577",
      "stroke-width": "3.41242",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('animate', {
    attrs: {
      "id": "tick3animation",
      "attributeName": "opacity",
      "from": "0",
      "to": "1",
      "dur": ".7s",
      "begin": "tick2animation.end",
      "fill": "freeze"
    }
  }), _c('animate', {
    attrs: {
      "attributeName": "opacity",
      "to": "0",
      "begin": "0s;tick4animation.end",
      "dur": ".4s",
      "fill": "freeze"
    }
  })]), _c('path', {
    attrs: {
      "id": "tick4",
      "d": "M299.721 257.25L282.976 273.897L276.9 266.763",
      "stroke": "#13B577",
      "stroke-width": "3.41242",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('animate', {
    attrs: {
      "id": "tick4animation",
      "attributeName": "opacity",
      "from": "0",
      "to": "1",
      "dur": ".7s",
      "begin": "tick3animation.end",
      "fill": "freeze"
    }
  }), _c('animate', {
    attrs: {
      "attributeName": "opacity",
      "to": "0",
      "begin": "0s;tick4animation.end",
      "dur": ".4s",
      "fill": "freeze"
    }
  })])])]), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": "paint0_linear_10885_27265",
      "x1": "462.399",
      "y1": "651.123",
      "x2": "312.085",
      "y2": "249.155",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#FF8718"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FFB018",
      "stop-opacity": "0.42"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint1_linear_10885_27265",
      "x1": "284.941",
      "y1": "254.844",
      "x2": "306.963",
      "y2": "549.765",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#FF8718"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FFAB18"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint2_linear_10885_27265",
      "x1": "238.896",
      "y1": "272.99",
      "x2": "283.374",
      "y2": "378.67",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#FF8718"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FFAB18",
      "stop-opacity": "0.36"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint3_linear_10885_27265",
      "x1": "148.812",
      "y1": "177.936",
      "x2": "357.127",
      "y2": "433.31",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#FFAB18",
      "stop-opacity": "0.72"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FF8718"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint4_linear_10885_27265",
      "x1": "144.882",
      "y1": "208.251",
      "x2": "180.954",
      "y2": "297.594",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#FFAB18",
      "stop-opacity": "0.78"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FF8718",
      "stop-opacity": "0.07"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint5_linear_10885_27265",
      "x1": "101.55",
      "y1": "131.368",
      "x2": "69.7",
      "y2": "131.368",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#FFB018"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FFB018",
      "stop-opacity": "0"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint6_linear_10885_27265",
      "x1": "76.9622",
      "y1": "201.295",
      "x2": "136.439",
      "y2": "223.906",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#FFB018"
    }
  }), _c('stop', {
    attrs: {
      "offset": "0.53",
      "stop-color": "#FF8718"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FD6B51"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint7_linear_10885_27265",
      "x1": "101.587",
      "y1": "203.771",
      "x2": "110.597",
      "y2": "221.622",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#FFB018",
      "stop-opacity": "0"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FFB018"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint8_linear_10885_27265",
      "x1": "104.051",
      "y1": "202.911",
      "x2": "108.134",
      "y2": "222.486",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#FFB018",
      "stop-opacity": "0"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FFB018"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint9_linear_10885_27265",
      "x1": "227.435",
      "y1": "89.897",
      "x2": "227.435",
      "y2": "312.429",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#80B1FF",
      "stop-opacity": "0.49"
    }
  }), _c('stop', {
    attrs: {
      "offset": "0.8911",
      "stop-color": "#80B1FF",
      "stop-opacity": "0.08"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint10_linear_10885_27265",
      "x1": "105.04",
      "y1": "9.75058",
      "x2": "-2.13744",
      "y2": "9.75058",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white",
      "stop-opacity": "0.34"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "white"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint11_linear_10885_27265",
      "x1": "105.04",
      "y1": "9.75058",
      "x2": "-2.13744",
      "y2": "9.75058",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white",
      "stop-opacity": "0.34"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "white"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint12_linear_10885_27265",
      "x1": "105.04",
      "y1": "9.75058",
      "x2": "-2.13744",
      "y2": "9.75058",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white",
      "stop-opacity": "0.34"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "white"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint13_linear_10885_27265",
      "x1": "105.04",
      "y1": "8.12548",
      "x2": "-2.13744",
      "y2": "8.12548",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white",
      "stop-opacity": "0.34"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "white"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint14_linear_10885_27265",
      "x1": "50.5254",
      "y1": "9.75058",
      "x2": "-1.02813",
      "y2": "9.75058",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white",
      "stop-opacity": "0.34"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "white"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint15_linear_10885_27265",
      "x1": "50.5254",
      "y1": "9.75058",
      "x2": "-1.02813",
      "y2": "9.75058",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white",
      "stop-opacity": "0.34"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "white"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint16_linear_10885_27265",
      "x1": "87.7546",
      "y1": "9.75058",
      "x2": "-1.78571",
      "y2": "9.75058",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white",
      "stop-opacity": "0.34"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "white"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint17_linear_10885_27265",
      "x1": "72.771",
      "y1": "9.0232",
      "x2": "-1.48081",
      "y2": "9.0232",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white",
      "stop-opacity": "0.34"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "white"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint18_linear_10885_27265",
      "x1": "284.085",
      "y1": "121.292",
      "x2": "284.085",
      "y2": "144.042",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "white",
      "stop-opacity": "0.84"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint19_linear_10885_27265",
      "x1": "284.085",
      "y1": "255.515",
      "x2": "284.085",
      "y2": "278.264",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "white",
      "stop-opacity": "0.84"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint20_linear_10885_27265",
      "x1": "284.085",
      "y1": "166.792",
      "x2": "284.085",
      "y2": "189.541",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "white",
      "stop-opacity": "0.84"
    }
  })], 1), _c('linearGradient', {
    attrs: {
      "id": "paint21_linear_10885_27265",
      "x1": "284.085",
      "y1": "212.291",
      "x2": "284.085",
      "y2": "235.04",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "white"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "white",
      "stop-opacity": "0.84"
    }
  })], 1), _c('clipPath', {
    attrs: {
      "id": "clip0_10885_27265"
    }
  }, [_c('rect', {
    attrs: {
      "width": _vm.width,
      "height": _vm.height,
      "fill": "white"
    }
  })]), _c('clipPath', {
    attrs: {
      "id": "clip1_10885_27265"
    }
  }, [_c('rect', {
    attrs: {
      "width": "500",
      "height": _vm.height,
      "fill": "white",
      "transform": "translate(-52.8)"
    }
  })]), _c('clipPath', {
    attrs: {
      "id": "clip2_10885_27265"
    }
  }, [_c('rect', {
    attrs: {
      "width": "186.2",
      "height": "231.525",
      "fill": "white",
      "transform": "translate(134.625 62.4751)"
    }
  })])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }