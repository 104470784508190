import { mapGetters, mapState } from 'vuex';
import { specialReqsMixin } from '@/mixins/specialReqsMixin';

export const abstractMainActionMixin = {
  mixins: [specialReqsMixin],
  created() {},
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('subscriptions', ['hasActiveSubscription']),
    ...mapState('courses', ['currentCourseAbstract', 'isLoadingCourseAbstract']),
    $courseId() {
      return this.currentCourseAbstract?.id;
    },
    hasAcceptedActivityDisclosure() {
      return this.currentCourseAbstract?.hasAcceptedActivityDisclosure;
    },
    hasActiveCourseEnrollment() {
      return this.currentCourseAbstract?.enrollmentStatus === 'in-progress';
    },
    hasCompletedCourseEnrollment() {
      return this.currentCourseAbstract?.enrollmentStatus === 'completed';
    },
    hasToShowActions() {
      if (!this.isShowingCourseAbstract) {
        return false;
      }

      if (!this.isPremiumUser) {
        return false;
      }

      if (this.isLoadingCourseAbstract) {
        return false;
      }

      return true;
    },
    hasToShowMainAction() {
      return this.$breakpoint.tabletAndUp;
    },
    isPremiumUser() {
      return this.hasActiveSubscription;
    },
    isShowingCourseAbstract() {
      return this.$route.name === 'course-abstract' || this.$route.name === 'special-requirements-course-abstract';
    },
    mainActionText() {
      if (!this.isShowingCourseAbstract) {
        return;
      }

      if (this.hasActiveCourseEnrollment) {
        return 'Resume course';
      }

      if (this.hasCompletedCourseEnrollment) {
        return 'Review course';
      }

      return 'Start course';
    },
  },
  methods: {
    goToCourseEnrollment() {
      let params = {
        courseId: this.$courseId,
      };

      this.$router.push({ name: 'course-enrollment', params });
    },
    goToSpecialRequirementsCourseEnrollment() {
      const params = {
        courseId: this.$courseId,
        topicSlug: this.topicSlug,
        stateSlug: this.stateSlug,
      };

      this.$router.push({ name: 'special-requirements-course-enrollment', params });
    },
    handleClickMainAction() {
      if (this.hasSpecialRequirements) {
        this.goToSpecialRequirementsCourseEnrollment();
      } else {
        this.goToCourseEnrollment();
      }
    },
  },
};
