<template>
  <div
    class="px-1"
    @click="$emit('clicked', index)"
  >
    <svg
      v-show="index === currentStepIndex"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="5"
        cy="5"
        r="5"
        fill="#76AAFF"
      />
    </svg>

    <svg
      v-show="index !== currentStepIndex"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="5"
        cy="5"
        r="5"
        fill="#E0E0E0"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: ['index', 'currentStepIndex'],
};
</script>
