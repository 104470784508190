var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "clickable d-flex pl-4 pb-2",
    on: {
      "click": _vm.goToContactSupport
    }
  }, [_c('ph-info', {
    attrs: {
      "color": "var(--v-primary-base)",
      "size": 24,
      "weight": "light"
    }
  }), _c('h3', {
    staticClass: "text text-m-large-regular align-self-center pl-2 grey--text text--darken-4"
  }, [_vm._v("Contact support")])], 1), _vm.currentUser ? _c('v-list-item', {
    staticClass: "pl-3 clickable",
    on: {
      "click": _vm.performLogout
    }
  }, [_c('v-list-item-icon', {
    staticClass: "pl-1 mr-2"
  }, [_c('ph-sign-out', {
    attrs: {
      "color": "var(--v-primary-base)",
      "size": 24,
      "weight": "light"
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text text-m-large-regular grey--text text--darken-4",
    domProps: {
      "textContent": _vm._s('Logout')
    }
  })], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex pt-4 pl-3 mb-5 align-center ml-2"
  }, [_c('AcaLink', {
    attrs: {
      "color": "grey",
      "to": {
        name: 'privacy'
      }
    }
  }, [_vm._v(" Privacy Policy ")]), _c('p', {
    staticClass: "text text-l-large-regular grey--text text--darken-1 mb-0 px-1"
  }, [_vm._v("&")]), _c('AcaLink', {
    attrs: {
      "color": "grey",
      "to": {
        name: 'terms'
      }
    }
  }, [_vm._v(" Terms of Use ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }