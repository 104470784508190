import axios from 'axios';
import { responseHandler, getResponseDataNoContent } from '@/api/utilities';

export const notifyFailedPayment = async ({ userEmail }) => {
  return responseHandler(
    axios.post('/slack/notify-failed-payment', {
      userEmail,
    }),
    {
      responseDataGetter: getResponseDataNoContent,
    },
  );
};
