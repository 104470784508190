var render = function render(){
  var _vm$bannerResource, _this$bannerResource;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "max-height"
  }, [_vm.isLifetime ? _c('WrapperLifetimeIllustrationsComponent', {
    attrs: {
      "id": 'lifetime'
    }
  }) : _vm._e(), _vm.isCourses ? _c('WrapperCourseIllustrationsComponent', {
    attrs: {
      "id": 'courses'
    }
  }) : _vm._e(), _vm.isArticles ? _c('WrapperArticleIllustrationsComponent') : _vm._e(), _vm.isSpecialRequirements ? _c('WrapperSpecialReqsIllustrationsComponent') : _vm._e(), _c('v-container', {
    staticClass: "max-height d-flex align-center"
  }, [_c('v-row', {
    attrs: {
      "justify-sm": "center"
    }
  }, [_c('v-col', {
    staticClass: "px-lg-11",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "row": "",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "col-12 col-sm-8 col-lg-7 col-xl-7"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pr-3 pr-sm-0 pr-md-10 pr-lg-3"
  }, [_vm.$breakpoint.tabletAndUp ? _c('document-heading', {
    staticClass: "heading white--text mb-2",
    class: [{
      'heading-m-small': _vm.$breakpoint.smallDesktopAndDown && !_vm.isNewHeaderTitle
    }, {
      'heading-t-small-new-header': _vm.$breakpoint.smallDesktopAndDown && _vm.isNewHeaderTitle
    }, {
      'heading-l-small': _vm.$breakpoint.desktopOnly && _vm.$vuetify.breakpoint.width < 1905 && !_vm.isNewHeaderTitle
    }, {
      'heading-l-small-new-header': _vm.$breakpoint.desktopOnly && _vm.$vuetify.breakpoint.width < 1905 && _vm.isNewHeaderTitle
    }, {
      'heading-l-large': _vm.$vuetify.breakpoint.width >= 1905 && !_vm.isNewHeaderTitle
    }, {
      'heading-l-large-new-header': _vm.$vuetify.breakpoint.width >= 1905 && _vm.isNewHeaderTitle
    }],
    attrs: {
      "level": "1"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }) : _c('document-heading', {
    staticClass: "heading white--text mb-2",
    class: [{
      'heading-m-large-new-header': _vm.isNewHeaderTitle
    }, {
      'heading-m-small': !_vm.isNewHeaderTitle
    }],
    attrs: {
      "level": "1"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.titleMobile)
    }
  }), _vm.$vuetify.breakpoint.width >= 1905 ? _c('p', {
    staticClass: "text text-xl-large-regular white--text mb-4 mb-md-6 mb-xl-8",
    domProps: {
      "innerHTML": _vm._s(_vm.paragraph)
    }
  }) : _vm.$breakpoint.tabletAndUp ? _c('p', {
    staticClass: "text white--text mb-4 mb-md-6 mb-xl-8",
    class: [{
      'heading-m-small-new-header': _vm.isNewHeaderTitle,
      'text-l-large-regular': !_vm.isNewHeaderTitle
    }],
    domProps: {
      "innerHTML": _vm._s(_vm.paragraph)
    }
  }) : _vm.paragraphMobile ? _c('p', {
    staticClass: "text text-l-large-regular white--text mb-4 mb-md-6",
    class: [{
      'heading-m-small-new-header': _vm.isNewHeaderTitle,
      'text-l-large-regular': !_vm.isNewHeaderTitle
    }],
    domProps: {
      "innerHTML": _vm._s(_vm.paragraphMobile)
    }
  }) : _vm._e(), (_vm$bannerResource = _vm.bannerResource) !== null && _vm$bannerResource !== void 0 && _vm$bannerResource.btnSecondaryText ? _c('div', {
    staticClass: "text-center text-sm-left"
  }, [_c('v-btn', {
    staticClass: "px-5 heading heading-s-small mt-2 mb-6 my-sm-0",
    attrs: {
      "to": {
        name: 'register'
      },
      "width": _vm.$breakpoint.tabletAndUp ? _vm.btnSize : '100%',
      "height": "40px",
      "ripple": "",
      "depressed": "",
      "color": _vm.btnColor
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.handleClickSignupWithLifetimeLink.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.btnText) + " ")]), _vm.$breakpoint.tabletAndUp ? _c('span', {
    staticClass: "text text-l-large-regular white--text mx-4"
  }, [_vm._v("or")]) : _vm._e(), _c('router-link', {
    staticClass: "text text-l-large-regular-link white--text",
    attrs: {
      "to": {
        name: 'register',
        params: {
          preselectFreeTrial: true
        }
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.handleClickSignupWithOtherLink.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s((_this$bannerResource = this.bannerResource) === null || _this$bannerResource === void 0 ? void 0 : _this$bannerResource.btnSecondaryText) + " ")])], 1) : _c('v-btn', {
    staticClass: "px-5 mr-2 heading heading-s-small",
    class: {
      'mb-5': _vm.$breakpoint.mobileAndDown
    },
    attrs: {
      "to": {
        name: 'register'
      },
      "width": _vm.$breakpoint.tabletAndUp ? _vm.btnSize : '100%',
      "height": "40px",
      "ripple": "",
      "depressed": "",
      "color": _vm.btnColor
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.handleClickSignupWithRegularPlanLink.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.btnText) + " ")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "col-12 col-sm-4 col-lg-3 col-xl-4 px-sm-10 px-xl-13 d-none d-sm-flex"
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }