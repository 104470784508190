<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.2653 6.96433C17.8564 6.57987 18.2472 5.9135 18.2472 5.15585C18.2472 3.9652 17.282 3 16.0914 3C14.9008 3 13.9355 3.9652 13.9355 5.15585C13.9355 5.91333 14.3262 6.57956 14.917 6.96407L10.4539 15.9351L5.19486 12.3252C5.27058 12.1052 5.31169 11.8691 5.31169 11.6234C5.31169 10.4327 4.34649 9.46753 3.15585 9.46753C1.9652 9.46753 1 10.4327 1 11.6234C1 12.814 1.9652 13.7792 3.15585 13.7792C3.17944 13.7792 3.20295 13.7788 3.22636 13.7781L7.20814 26.132C7.24824 26.2521 7.31541 26.3618 7.40468 26.4531C7.49395 26.5444 7.60304 26.6149 7.72391 26.6595C7.84477 26.704 7.97432 26.7214 8.10298 26.7104C8.23165 26.6994 8.35614 26.6603 8.46728 26.5959C8.49423 26.5801 11.2508 25.027 16.089 25.027C20.9272 25.027 23.6837 26.5801 23.7085 26.5949C23.8197 26.6599 23.9444 26.6995 24.0734 26.7109C24.2024 26.7223 24.3323 26.7051 24.4536 26.6606C24.5749 26.6162 24.6843 26.5455 24.7739 26.454C24.8634 26.3625 24.9308 26.2525 24.9709 26.132L28.9555 13.7781C28.9792 13.7788 29.003 13.7792 29.0269 13.7792C30.2176 13.7792 31.1828 12.814 31.1828 11.6234C31.1828 10.4327 30.2176 9.46753 29.0269 9.46753C27.8363 9.46753 26.8711 10.4327 26.8711 11.6234C26.8711 11.8693 26.9123 12.1057 26.9882 12.3259L21.728 15.9351L17.2653 6.96433Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.2653 6.96433C17.8564 6.57987 18.2472 5.9135 18.2472 5.15585C18.2472 3.9652 17.282 3 16.0914 3C14.9008 3 13.9355 3.9652 13.9355 5.15585C13.9355 5.91333 14.3262 6.57956 14.917 6.96407L10.4539 15.9351L5.19486 12.3252C5.27058 12.1052 5.31169 11.8691 5.31169 11.6234C5.31169 10.4327 4.34649 9.46753 3.15585 9.46753C1.9652 9.46753 1 10.4327 1 11.6234C1 12.814 1.9652 13.7792 3.15585 13.7792C3.17944 13.7792 3.20295 13.7788 3.22636 13.7781L7.20814 26.132C7.24824 26.2521 7.31541 26.3618 7.40468 26.4531C7.49395 26.5444 7.60304 26.6149 7.72391 26.6595C7.84477 26.704 7.97432 26.7214 8.10298 26.7104C8.23165 26.6994 8.35614 26.6603 8.46728 26.5959C8.49423 26.5801 11.2508 25.027 16.089 25.027C20.9272 25.027 23.6837 26.5801 23.7085 26.5949C23.8197 26.6599 23.9444 26.6995 24.0734 26.7109C24.2024 26.7223 24.3323 26.7051 24.4536 26.6606C24.5749 26.6162 24.6843 26.5455 24.7739 26.454C24.8634 26.3625 24.9308 26.2525 24.9709 26.132L28.9555 13.7781C28.9792 13.7788 29.003 13.7792 29.0269 13.7792C30.2176 13.7792 31.1828 12.814 31.1828 11.6234C31.1828 10.4327 30.2176 9.46753 29.0269 9.46753C27.8363 9.46753 26.8711 10.4327 26.8711 11.6234C26.8711 11.8693 26.9123 12.1057 26.9882 12.3259L21.728 15.9351L17.2653 6.96433Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  props: {
    size: {
      type: Number,
      default: 32,
    },
  },
};
</script>
