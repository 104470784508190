export const specialReqsMixin = {
  computed: {
    topicSlug() {
      return this.$route.params?.topicSlug;
    },
    stateSlug() {
      return this.$route.params?.stateSlug;
    },
    cmeExperienceSlug() {
      return this.$route.params?.slug ?? this.$route.params?.courseSlug;
    },
    hasSpecialRequirements() {
      if (!this.topicSlug || !this.stateSlug) return false;

      return true;
    },
    isSpecialRequirementAbstractRoute() {
      return (
        this.$route.name === 'special-requirements-article-abstract' ||
        this.$route.name === 'special-requirements-course-abstract' ||
        this.$route.name === 'special-requirements-article-pdf'
      );
    },
    isSpecialRequirementFileRoute() {
      return this.$route.name === 'special-requirements-article-pdf';
    },
  },
  methods: {
    // assume here that each slug topic will be full compliant
    capitalizeSlug(slug) {
      const words = slug.split('-');
      const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
      return capitalizedWords.join(' ');
    },
  },
};
