import {
  doGetFeedInProgress,
  doGetFeedCompleted,
  doGetTopicStatesForCmeExperience,
  doGetFeed,
  doPostStartJourney,
  doPostResetCmeExperience,
  doPostResetGuestCmeExperience,
  showSpecialRequirementCertificate as doShowSpecialRequirementCertificate,
} from '@/api/specialRequirements';

export default {
  namespaced: true,
  state: {
    currentTopicStates: null,
    lastTopicStateVisited: null,
    certificate: null,
    triggerDownloadCertificate: false,
  },
  mutations: {
    setCurrentTopicStates(state, data) {
      state.currentTopicStates = data;
    },
    setPretestForSpecialRequirements(state, data) {
      state.lastTopicStateVisited = data;
    },
    setCertificate(state, data) {
      state.certificate = data;
    },
    setTriggerDownloadCertificate(state, data) {
      state.triggerDownloadCertificate = data;
    },
  },
  actions: {
    async listTopicStatesForCmeExperience(context, payload) {
      const response = await doGetTopicStatesForCmeExperience(payload);

      context.commit('setCurrentTopicStates', response);

      return response;
    },
    async getFeedInProgress() {
      return await doGetFeedInProgress();
    },
    async getFeedCompleted() {
      return await doGetFeedCompleted();
    },
    async getTopicStateFeed(context, payload) {
      let response = await doGetFeed(payload);

      return response;
    },
    async startJourney(context, payload) {
      return await doPostStartJourney(payload);
    },
    async resetCmeExperience(context, payload) {
      return await doPostResetCmeExperience(payload);
    },
    async resetGuestCmeExperience(context, payload) {
      return await doPostResetGuestCmeExperience(payload);
    },
    showPretestForSpecialRequirements(context, payload) {
      context.commit('setPretestForSpecialRequirements', payload);
    },
    async showSpecialRequirementCertificate(context, params) {
      const certificate = await doShowSpecialRequirementCertificate(params);

      context.commit('setCertificate', certificate);

      return certificate;
    },
    setTriggerDownloadCertificate(context, payload) {
      context.commit('setTriggerDownloadCertificate', payload);
    },
  },
  getters: {
    getCurrentTopicStates: (state) => {
      return state.currentTopicStates;
    },
    lastVisitedTopicState: (state) => {
      return state.lastTopicStateVisited;
    },
    certificate: (state) => {
      return state.certificate;
    },
    triggerDownloadCertificate: (state) => {
      return state.triggerDownloadCertificate;
    },
  },
};
