<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 364 383"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M308.159 82.0404C313.094 84.8915 316.257 91.0195 317.294 100.424C318.325 109.778 317.191 121.945 313.983 136.142C307.571 164.513 292.982 200.535 271.692 237.436C250.402 274.337 226.521 304.992 205.174 324.734C194.491 334.613 184.529 341.678 175.918 345.462C167.26 349.266 160.375 349.588 155.441 346.737C150.506 343.886 147.343 337.758 146.306 328.354C145.275 319 146.408 306.833 149.617 292.636C156.028 264.265 170.618 228.242 191.908 191.341C213.198 154.441 237.078 123.786 258.426 104.044C269.108 94.165 279.071 87.0993 287.682 83.316C296.339 79.5122 303.224 79.1894 308.159 82.0404Z"
      :stroke="stroke0"
      stroke-opacity="0.7"
      stroke-width="4"
    />
    <path
      d="M332.373 141.9C335.777 143.867 338.049 148.195 338.763 155.131C339.471 162.011 338.587 171.012 336.154 181.569C331.293 202.656 320.363 229.493 304.475 257.032C288.586 284.57 270.826 307.461 255.006 322.217C247.087 329.603 239.739 334.871 233.431 337.697C227.071 340.546 222.189 340.741 218.785 338.774C215.38 336.807 213.108 332.479 212.394 325.543C211.686 318.663 212.571 309.662 215.004 299.105C219.864 278.018 230.794 251.181 246.682 223.642C262.571 196.104 280.331 173.213 296.151 158.457C304.071 151.071 311.418 145.803 317.726 142.977C324.086 140.128 328.968 139.933 332.373 141.9Z"
      :stroke="stroke1"
      stroke-opacity="0.7"
      stroke-width="4"
    />
    <path
      d="M273.803 30.5899C279.879 34.1001 283.681 41.6494 284.882 53.08C286.077 64.4562 284.634 79.2337 280.688 96.4522C272.801 130.864 255.023 174.568 229.182 219.357C203.34 264.146 174.407 301.405 148.569 325.446C135.641 337.475 123.573 346.114 113.13 350.766C102.638 355.441 94.2029 355.92 88.1272 352.41C82.0515 348.9 78.2491 341.351 77.0482 329.92C75.8531 318.544 77.2958 303.766 81.242 286.548C89.1287 252.136 106.907 208.432 132.748 163.643C158.59 118.854 187.523 81.5949 213.361 57.5542C226.289 45.5251 238.357 36.8861 248.8 32.2337C259.293 27.5591 267.727 27.0797 273.803 30.5899Z"
      :stroke="stroke2"
      stroke-opacity="0.7"
      stroke-width="4"
    />
    <defs>
      <linearGradient
        :id="linear0"
        x1="190.176"
        y1="190.341"
        x2="273.452"
        y2="238.387"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#FFB018"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="#FFB018"
          stop-opacity="0.6"
        />
      </linearGradient>
      <linearGradient
        :id="linear1"
        x1="244.951"
        y1="222.642"
        x2="306.228"
        y2="257.996"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#FFB018"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="#FFB018"
          stop-opacity="0.8"
        />
      </linearGradient>
      <linearGradient
        :id="linear2"
        x1="131.017"
        y1="162.642"
        x2="230.948"
        y2="220.298"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#FFB018"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="#FFB018"
          stop-opacity="0.35"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'courses'
    },
    width: {
      default: '111px',
      type: String,
    },
    height: {
      default: '111px',
      type: String,
    },
  },
  computed: {
    stroke0() {
      return `url(#paint0_linear_698_555026_${this.id})`;
    },
    stroke1() {
      return `url(#paint1_linear_698_555026_${this.id})`;
    },
    stroke2() {
      return `url(#paint2_linear_698_555026_${this.id})`;
    },
    linear0() {
      return `paint0_linear_698_555026_${this.id}`;
    },
    linear1() {
      return `paint1_linear_698_555026_${this.id}`;
    },
    linear2() {
      return `paint2_linear_698_555026_${this.id}`;
    }
  }
};
</script>
