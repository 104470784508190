import {
  doGetArticle,
  doPostUpVoteArticle,
  doPostDownVoteArticle,
  doGetArticleQuiz,
  doPostQuizAnswer,
  doGetQuizResults,
  doUpdateQuizStatus,
  doPostPretest,
  doUpdateShowPretestOnboarding,
  doSaveQuizUserStarted,
  doSaveQuizUserFinished,
  doSavePretestStarted,
  doGetEvaluation,
  doSaveEvaluation,
  doAgreeActivityDisclosure,
  doCheckIfActivityDisclosureShouldBeShown,
  doGetMostRecentActiveActivityByArticleId,
} from '@/api/article';
import { isSuccessfulResponse } from '@/api/utilities';

const GUEST_QUIZZES_LOCAL_STORAGE_KEY = 'acapedia_guest_quizzes';

export default {
  namespaced: true,
  state: {
    isShowingResultPage: false,
    isShowingResultDetailsPage: false,
    shouldShowEvaluation: true,
  },
  mutations: {
    setIsShowingResultPage(state, isShowingResultPage) {
      state.isShowingResultPage = isShowingResultPage;
    },
    setIsShowingResultDetailsPage(state, isShowingResultDetailsPage) {
      state.isShowingResultDetailsPage = isShowingResultDetailsPage;
    },
    setShouldShowEvaluation(state, shouldShowEvaluation) {
      state.shouldShowEvaluation = shouldShowEvaluation;
    },
  },
  actions: {
    async fetchArticle(context, params) {
      const response = await doGetArticle(params);

      if (response.data?.article) {
        const previousVisitedArticles = JSON.parse(localStorage.getItem('acapedia_visited_articles')) ?? [];

        localStorage.setItem(
          'acapedia_visited_articles',
          JSON.stringify(Array.from(new Set([...previousVisitedArticles, parseInt(response.data.id)]))),
        );
      }

      return response;
    },
    async fetchArticleQuiz(context, payload) {
      const articleId = payload?.articleId;
      let article = null;
      const quizPreviouslyStoredByGuest = await context.dispatch('findGuestQuizHash', {
        articleId,
        topicSlug: payload?.topicSlug,
        stateSlug: payload?.stateSlug,
      });

      const requestParams = { params: { id: articleId, quizPreviouslyStoredByGuest: quizPreviouslyStoredByGuest } };

      const topicSlug = payload?.topicSlug;
      const stateSlug = payload?.stateSlug;

      if (topicSlug && stateSlug) {
        requestParams.params.topicSlug = topicSlug;
        requestParams.params.stateSlug = stateSlug;
      }

      let response = await doGetArticleQuiz(requestParams);

      if (isSuccessfulResponse(response)) {
        article = response.data;

        if (article.guestQuiz && !quizPreviouslyStoredByGuest) {
          let previousGuestQuizzes = JSON.parse(localStorage.getItem(GUEST_QUIZZES_LOCAL_STORAGE_KEY)) ?? [];

          const topicSlug = payload?.topicSlug;
          const stateSlug = payload?.stateSlug;

          // We only keep one SR quiz per user at a time
          const nonSrGuestQuizzes = previousGuestQuizzes.filter((quiz) => !quiz.topicSlug && !quiz.stateSlug);

          localStorage.setItem(
            GUEST_QUIZZES_LOCAL_STORAGE_KEY,
            JSON.stringify([
              ...nonSrGuestQuizzes,
              {
                quizId: article.quizId.toString(),
                hash: article.guestQuiz,
                articleId: article.articleId.toString(),
                startedAt: new Date().toISOString(),
                topicSlug,
                stateSlug,
              },
            ]),
          );
        }

        article.guestQuiz = article.guestQuiz ?? quizPreviouslyStoredByGuest;
      }

      return article;
    },
    async fetchArticleQuizForPretest(context, { articleId, articleSlug }) {
      let article = null;
      const params = articleId ? { params: { id: articleId } } : { params: { slug: articleSlug } };

      let response = await doGetArticleQuiz(params);

      if (isSuccessfulResponse(response)) {
        article = response.data;
      }

      return article;
    },
    postQuizAnswer(context, payload) {
      return doPostQuizAnswer(payload);
    },
    updateQuizAnswer(context, payload) {
      return doPostQuizAnswer(payload);
    },
    getQuizResults(context, payload) {
      return doGetQuizResults(payload);
    },
    upVoteArticle(context, articleId) {
      return doPostUpVoteArticle(articleId);
    },
    downVoteArticle(context, articleId) {
      return doPostDownVoteArticle(articleId);
    },
    updateQuizStatus(context, quizId) {
      return doUpdateQuizStatus(quizId);
    },
    findGuestQuizHash(context, { articleId, topicSlug, stateSlug }) {
      let previousGuestQuizzes = JSON.parse(localStorage.getItem(GUEST_QUIZZES_LOCAL_STORAGE_KEY)) ?? [];

      const guestQuiz = previousGuestQuizzes.find(
        (guestQuiz) =>
          guestQuiz.articleId === articleId.toString() &&
          guestQuiz.topicSlug === topicSlug &&
          guestQuiz.stateSlug === stateSlug,
      );

      return guestQuiz?.hash ?? null;
    },
    postPretest(context, payload) {
      return doPostPretest(payload);
    },
    UpdateShowPretestOnboarding(context, payload) {
      return doUpdateShowPretestOnboarding();
    },
    saveQuizUserStarted(context, payload) {
      return doSaveQuizUserStarted(payload);
    },
    saveQuizUserFinished(context, payload) {
      return doSaveQuizUserFinished(payload);
    },
    savePretestStarted(context, payload) {
      return doSavePretestStarted(payload);
    },
    getEvaluation(context, payload) {
      return doGetEvaluation(payload);
    },
    saveEvaluation(context, payload) {
      return doSaveEvaluation(payload);
    },
    async agreeActivityDisclosure(context, payload) {
      const response = await doAgreeActivityDisclosure(payload);
      if (response.success) {
        localStorage.setItem('acapediaGuestHashSession', response.hashId);
      }

      return response;
    },
    checkIfActivityDisclosureShouldBeShown(context, payload) {
      return doCheckIfActivityDisclosureShouldBeShown(payload);
    },
    getMostRecentActiveActivityByArticleId(context, payload) {
      return doGetMostRecentActiveActivityByArticleId(payload);
    },
    removeGuestQuizzesForSpecialRequirements() {
      let guestQuizzes = JSON.parse(localStorage.getItem(GUEST_QUIZZES_LOCAL_STORAGE_KEY)) ?? [];

      const nonSrGuestQuizzes = guestQuizzes.filter((quiz) => !quiz.topicSlug && !quiz.stateSlug);

      localStorage.setItem(GUEST_QUIZZES_LOCAL_STORAGE_KEY, JSON.stringify(nonSrGuestQuizzes));
    },
    setIsShowingResultPage({ commit }, isShowingResultPage) {
      commit('setIsShowingResultPage', isShowingResultPage);
    },
    setIsShowingResultDetailsPage({ commit }, isShowingResultDetailsPage) {
      commit('setIsShowingResultDetailsPage', isShowingResultDetailsPage);
    },
    setShouldShowEvaluation({ commit }, shouldShowEvaluation) {
      commit('setShouldShowEvaluation', shouldShowEvaluation);
    },
  },
};
