var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasToShowAlert ? _c('div', {
    staticClass: "alert-container"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "text",
    class: _vm.$breakpoint.tabletAndUp ? 'text-l-large-bold' : 'text-m-large-bold'
  }, [_vm._v(" You have used up your free credits. ")]), _c('v-btn', {
    staticClass: "heading heading-s-small primary--text shadow-light-button",
    class: {
      'ml-2': _vm.$breakpoint.mobileAndDown,
      'ml-4': _vm.$breakpoint.tabletAndUp
    },
    attrs: {
      "ripple": "",
      "depressed": "",
      "height": "36px",
      "width": "auto",
      "to": {
        name: 'my-plan',
        params: {
          upgrade: true
        }
      }
    }
  }, [_c('CrownFilledYellowIcon', {
    staticClass: "mr-2"
  }), _vm._v(" Upgrade ")], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }