<template>
  <div class="illustrations-container">
    <div v-if="$breakpoint.tabletAndUp">
      <StarArticleComponent
        :id="'star'"
        class="star"
        :class="[starClass]"
        :width="widthStar"
        :height="heightStar"
      />
      <StarArticleComponent
        :id="'star_small'"
        class="small-star"
        :class="[starSmallClass]"
        :width="widthStarSmall"
        :height="heightStarSmall"
      />
      <PlanetsComponent
        class="planets"
        :class="[planetsClass]"
        :width="widthPlanets"
        :height="heightPlanets"
      />
    </div>
  </div>
</template>

<script>
import StarArticleComponent from '@/assets/banner/StarArticleComponent';
import PlanetsComponent from './PlanetsComponent';
export default {
  components: { StarArticleComponent, PlanetsComponent },
  computed: {
    starClass() {
      if (this.$breakpoint.desktopAndUp) {
        return 'star-desktop-and-up';
      }

      if (this.$breakpoint.smallDesktopOnly) {
        return 'star-desktop-small';
      }

      return 'star-tablet-only';
    },
    widthStar() {
      if (this.starClass === 'star-desktop-and-up') {
        return '44px';
      }

      if (this.starClass === 'star-desktop-small') {
        return '47px';
      }

      return '40px';
    },
    heightStar() {
      if (this.starClass === 'star-desktop-and-up') {
        return '48px';
      }

      if (this.starClass === 'star-desktop-small') {
        return '51px';
      }

      return '43px';
    },
    starSmallClass() {
      if (this.$breakpoint.desktopAndUp) {
        return 'star-small-desktop-and-up';
      }

      if (this.$breakpoint.smallDesktopOnly) {
        return 'star-small-desktop-small';
      }

      return 'star-small-tablet-only';
    },
    widthStarSmall() {
      if (this.starSmallClass === 'star-small-desktop-and-up') {
        return '28px';
      }

      if (this.starSmallClass === 'star-small-desktop-small') {
        return '30px';
      }

      return '26px';
    },
    heightStarSmall() {
      if (this.starSmallClass === 'star-small-desktop-and-up') {
        return '31px';
      }

      if (this.starSmallClass === 'star-small-desktop-small') {
        return '33px';
      }

      return '28px';
    },
    planetsClass() {
      if (this.$breakpoint.desktopAndUp) {
        return 'planets-desktop-and-up';
      }

      if (this.$breakpoint.smallDesktopOnly) {
        return 'planets-desktop-small';
      }

      return 'planets-tablet-only';
    },
    widthPlanets() {
      if (this.planetsClass === 'planets-desktop-and-up') {
        return '385px';
      }

      if (this.planetsClass === 'planets-desktop-small') {
        return '409px';
      }

      return '350px';
    },
    heightPlanets() {
      if (this.planetsClass === 'planets-desktop-and-up') {
        return '307px';
      }

      if (this.planetsClass === 'planets-desktop-small') {
        return '326px';
      }

      return '279px';
    },
    widthStaticIllustration() {
      if (this.$breakpoint.smallMobileOnly) {
        return '323px';
      }

      return '350px';
    },
    heightStaticIllustration() {
      if (this.$breakpoint.smallMobileOnly) {
        return '259px';
      }

      return '279px';
    },
  },
};
</script>

<style scoped lang="scss">
.star {
  position: absolute;
  z-index: 2;
  animation: fadeinout 3.6s infinite;
}

.star-desktop-and-up {
  bottom: 42px;
  right: 213px;
}

.star-desktop-small {
  bottom: 28px;
  right: 237px;
}

.star-tablet-only {
  bottom: 53px;
  right: 145px;
}

.small-star {
  position: absolute;
  z-index: 2;
  animation: fadeinout 3s infinite;
}

.star-small-desktop-and-up {
  top: 55px;
  right: 123px;
}

.star-small-desktop-small {
  top: 51px;
  right: 141px;
}

.star-small-tablet-only {
  top: 50px;
  right: 63px;
}

.planets {
  position: absolute;
  z-index: 2;
}

.planets-desktop-and-up {
  top: 20px;
  right: -53px;
}

.planets-desktop-small {
  top: -8px;
  right: -47px;
}

.planets-tablet-only {
  top: 0px;
  right: -97px;
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media #{map-get($display-breakpoints, 'mobile-and-down')} {
  .illustrations-container {
    position: relative;
  }
}

@media (min-width: 1905px) {
  .star {
    bottom: 53px;
    right: 386px;
  }
  .small-star {
    top: 71px;
    right: 296px;
  }
  .planets {
    top: 16px;
    right: 120px;
  }
}
</style>
