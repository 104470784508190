import { mapGetters } from 'vuex';

export const completeProfileAlertMixin = {
  computed: {
    ...mapGetters('profile', ['hasIncompleteProfile']),
    // TODO: stop doing this :pray:
    isAllowedRoutes() {
      return (
        this.$route.name === 'home' ||
        this.$route.name === 'special-requirements' ||
        this.$route.name === 'premium-courses-feed'
      );
    },
    shouldShowCompleteProfileBannerNotification() {
      if (!this.isAllowedRoutes) return false;

      return this.hasIncompleteProfile;
    },
  },
};
