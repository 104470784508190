<template>
  <div>
    <div
      class="clickable d-flex pl-4 pb-2"
      @click="goToContactSupport"
    >
      <ph-info
        color="var(--v-primary-base)"
        :size="24"
        weight="light"
      />
      <h3 class="text text-m-large-regular align-self-center pl-2 grey--text text--darken-4">Contact support</h3>
    </div>
    <v-list-item
      v-if="currentUser"
      class="pl-3 clickable"
      @click="performLogout"
    >
      <v-list-item-icon class="pl-1 mr-2">
        <ph-sign-out
          color="var(--v-primary-base)"
          :size="24"
          weight="light"
        />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          class="text text-m-large-regular grey--text text--darken-4"
          v-text="'Logout'"
        />
      </v-list-item-content>
    </v-list-item>
    <div class="d-flex pt-4 pl-3 mb-5 align-center ml-2">
      <AcaLink
        color="grey"
        :to="{ name: 'privacy' }"
      >
        Privacy Policy
      </AcaLink>
      <p class="text text-l-large-regular grey--text text--darken-1 mb-0 px-1">&</p>
      <AcaLink
        color="grey"
        :to="{ name: 'terms' }"
      >
        Terms of Use
      </AcaLink>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PhSignOut, PhInfo } from 'phosphor-vue';

import AcaLink from '@components/atoms/AcaLink.vue';

export default {
  components: { PhSignOut, PhInfo, AcaLink },
  props: {
    currentUser: {
      type: Object,
      default: null,
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('feed', ['onBoardingDefaultValues']),
    goToContactSupport() {
      window.open('https://acapedia.zendesk.com/hc/en-us/requests/new', '_blank', 'noopener');
    },
    async performLogout() {
      await this.logout();

      this.$eventNotifier.logout();

      this.onBoardingDefaultValues();
      document.cookie = '';
      this.$router.push('/login');
    },
  },
};
</script>

<style scoped lang="scss">
.menu-item {
  min-height: 56px;
}
</style>
