import axios from 'axios';
import { responseHandler, getResponseData } from '@/api/utilities';

const doGetTranscripts = async (params) => {
  let transcripts = await responseHandler(axios.get('/transcripts', { params: params }), {
    responseDataGetter: getResponseData,
  });

  return transcripts;
};

const doGetTranscriptsQuizResults = async (payload) => {
  return axios.get('/transcripts/quiz/results', payload);
};

const doGetTranscriptByTopic = async (params) => {
  return await responseHandler(axios.get('/special-requirements/transcripts-by-topic', { params: params }));
};

export { doGetTranscripts, doGetTranscriptsQuizResults, doGetTranscriptByTopic };
