<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 159 159"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.2697 135.515C16.2507 128.491 20.9046 113.756 17.3188 105.129C13.733 96.5019 0 88.9436 0 79.4003C0 69.857 13.5804 62.6041 17.3188 53.6716C21.0572 44.739 16.2507 30.3095 23.2697 23.2857C30.2888 16.2618 45.0136 20.9189 53.6348 17.3306C62.256 13.7424 69.8092 0 79.3459 0C88.8827 0 96.1306 13.5897 105.057 17.3306C113.983 21.0716 128.403 16.2618 135.422 23.2857C142.441 30.3095 137.787 45.0444 141.373 53.6716C144.959 62.2987 158.692 69.857 158.692 79.4003C158.692 88.9436 145.112 96.1965 141.373 105.129C137.635 114.062 142.441 128.491 135.422 135.515C128.403 142.539 113.678 137.882 105.057 141.47C96.4358 145.058 88.8827 158.801 79.3459 158.801C69.8092 158.801 62.5612 145.211 53.6348 141.47C44.7084 137.729 30.2888 142.539 23.2697 135.515Z"
      :fill="fill"
    />
    <defs>
      <linearGradient
        :id="linear"
        x1="79.3555"
        y1="3.4698"
        x2="79.3555"
        y2="155.329"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFB018" />
        <stop
          offset="0.53"
          stop-color="#FF8718"
        />
        <stop
          offset="1"
          stop-color="#FD6B51"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'courses'
    },
    width: {
      default: '174px',
      type: String,
    },
    height: {
      default: '174px',
      type: String,
    },
  },
  computed: {
    fill() {
      return `url(#paint0_linear_698_555116_${this.id})`;
    },
    linear() {
      return `paint0_linear_698_555116_${this.id}`;
    }
  }
};
</script>
