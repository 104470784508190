import Vue from 'vue';

Vue.filter('creditsDecimalNotation', function (credits) {
  function isNumeric(number) {
    return !isNaN(number);
  }

  if (!isNumeric(credits) || credits === 0) {
    return credits;
  }

  if (typeof credits === 'string') {
    credits = parseInt(credits);
  }

  if (credits === null) {
    return 0.0;
  }

  return credits.toFixed(1);
});
