<template>
  <document-section tag="div">
    <div
      class="banner-wrapper"
      :class="{
        // We will use the route here until we migrate the home page
        'banner-wrapper-spacing': $route.name !== 'home' || migratePageMainFeedEnabled,
      }"
    >
      <div
        class="banner-background banner-background-left-padding night-gradient"
        :class="[classBackground, { 'banner-background-y-padding banner-background-right-padding': !lifetimeIsActive }]"
      >
        <div
          v-if="isShowingLifetimeInMobile"
          class="lifetime-mobile-header-banner accent white--text"
        >
          {{ lifetimeSaveMoney }}
        </div>
        <router-link
          :to="{ name: 'login' }"
          class="heading heading-s-small white--text text-decoration-none login-link"
          @click.native="handleClickLoginLink"
        >
          Log in
        </router-link>
        <v-carousel
          v-if="lifetimeIsActive"
          v-model="carrousel"
          :show-arrows="false"
          :hide-delimiter-background="true"
          :height="carrouselHeight"
          :hide-delimiters="isSingleBannerResource"
        >
          <v-carousel-item
            v-for="(banner, index) in bannerResource"
            :key="index"
          >
            <BannerItemComponent :banner-resource="banner" />
          </v-carousel-item>
        </v-carousel>
        <BannerItemComponent
          v-else
          :banner-resource="bannerResource"
        />
      </div>
    </div>
  </document-section>
</template>

<script>
import BannerItemComponent from '@/components/common/BannerItemComponent';
import { DocumentSection } from 'vue-headings';

import { featureFlagsMixin, MIGRATE_PAGE_MAIN_FEED_ENABLED } from '@/mixins/featureFlagsMixin';

export default {
  components: {
    DocumentSection,
    BannerItemComponent,
  },
  mixins: [
    featureFlagsMixin({
      [MIGRATE_PAGE_MAIN_FEED_ENABLED]: 'migratePageMainFeedEnabled',
    }),
  ],
  props: {
    bannerResource: {
      default: null,
      type: [Array, Object],
    },
    lifetimeIsActive: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      carrousel: 0,
    };
  },
  computed: {
    carrouselHeight() {
      if (this.$vuetify.breakpoint.xlOnly) {
        return 320;
      }
      if (this.$breakpoint.smallDesktopOnly) {
        return 270;
      }
      if (this.$breakpoint.desktopAndUp) {
        return 290;
      }
      if (this.$breakpoint.tabletAndUp) {
        return 290;
      }

      return 258;
    },
    classBackground() {
      return this.lifetimeIsActive ? this.bannerResource[this.carrousel]?.bgClass : this.bannerResource?.bgClass;
    },
    isShowingLifetimeInMobile() {
      return (
        this.bannerResource &&
        this.bannerResource[this.carrousel]?.type === 'lifetime' &&
        this.$breakpoint.mobileAndDown
      );
    },
    lifetimeSaveMoney() {
      return this.bannerResource && this.bannerResource[this.carrousel]?.saveMoney;
    },
    isSingleBannerResource() {
      return this.bannerResource?.length === 1;
    },
  },
  watch: {
    '$route.path': function () {
      this.carrousel = 0;
    },
  },
  methods: {
    handleClickLoginLink() {
      this.$eventNotifier.notify('click_login', {
        eventCategory: 'Login',
        eventLabel: 'Navbar Login Link',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
  z-index: 2;
  padding: 0px;
}
.banner-background-y-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}
.banner-wrapper {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  clip-path: content-box;
}
.login-link {
  position: absolute;
  top: 18px;
  right: 40px;
  z-index: 4;
}

.lifetime-mobile-header-banner {
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 16px;
  left: 16px;
  width: calc(100% - 32px);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.1px;
}

@media (min-width: 1905px) {
  .banner-background-right-padding {
    padding-right: 22px;
  }
  .banner-background-left-padding {
    padding-left: 22px;
  }
}

// sidebar showing from this point
@media (max-width: 1263px) {
  .login-link {
    z-index: 3;
    display: none !important;
  }
}

@media (min-width: 1022px) and (max-width: 1263px) {
  .banner-background-left-padding {
    padding-left: 120px;
  }
}

@media #{map-get($display-breakpoints, 'tablet-and-down')} {
  .banner-background-right-padding {
    padding-right: 48px;
  }
  .banner-background-left-padding {
    padding-left: 48px;
  }
  .banner-background-y-padding {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
@media #{map-get($display-breakpoints, 'mobile-only')} {
  // Remove this spacing when MIGRATE_PAGE_MAIN_FEED_ENABLED is deleted
  .banner-wrapper {
    padding: 16px;
  }

  .banner-wrapper.banner-wrapper-spacing {
    padding: 16px 16px 0px;
  }

  .v-carousel__controls {
    bottom: -18px !important;
  }
  .banner-background.mobile-articles-banner-bg {
    padding: 16px;
    border-radius: 8px;
    overflow: hidden;
    background-size: cover !important;
    background: url('~@/assets/banner/MobileArticlesBackground.svg');
  }
  .banner-background.mobile-courses-banner-bg {
    padding: 16px;
    border-radius: 8px;
    overflow: hidden;
    background-size: cover !important;
    background: url('~@/assets/banner/MobileCoursesBackground.svg');
  }
  .mobile-special-requirements-banner-bg {
    padding: 16px;
    border-radius: 8px;
    overflow: hidden;
    background-size: cover !important;
    background: url('~@/assets/banner/MobileSpecialRequirementsBackground.svg');
  }
  .mobile-lifetime-banner-bg {
    padding: 16px;
    border-radius: 8px;
    overflow: hidden;
    background-size: cover !important;
    background: url('~@/assets/banner/MobileLifetimeBackground.svg');
  }
}
@media #{map-get($display-breakpoints, 'small-mobile-only')} {
  // Remove this spacing when MIGRATE_PAGE_MAIN_FEED_ENABLED is deleted
  .banner-wrapper {
    padding: 16px;
  }

  .banner-wrapper.banner-wrapper-spacing {
    padding: 16px 16px 0px;
  }

  .banner-background.mobile-articles-banner-bg {
    padding: 16px;
    border-radius: 8px;
    overflow: hidden;
    background-size: cover !important;
    background: url('~@/assets/banner/MobileSmallArticlesBackground.svg');
  }
  .banner-background.mobile-courses-banner-bg {
    padding: 16px;
    border-radius: 8px;
    overflow: hidden;
    background-size: cover !important;
    background: url('~@/assets/banner/MobileSmallCoursesBackground.svg');
  }
  .mobile-special-requirements-banner-bg {
    padding: 16px;
    border-radius: 8px;
    overflow: hidden;
    background-size: cover !important;
    background: url('~@/assets/banner/MobileSmallSpecialRequirementsBackground.svg');
  }
  .mobile-lifetime-banner-bg {
    padding: 16px;
    border-radius: 8px;
    overflow: hidden;
    background-size: cover !important;
    background: url('~@/assets/banner/MobileSmallLifetimeBackground.svg');
  }
}
</style>
<style>
.v-carousel__controls {
  background: none !important;
}
.banner-wrapper .v-carousel__controls button {
  color: var(--v-grey-lighten3) !important;
}
.banner-wrapper .v-carousel__controls button.v-btn--active {
  color: var(--v-secondary-base) !important;
}
.banner-wrapper .v-carousel__controls button .v-icon {
  font-size: 12px !important;
  opacity: 1 !important;
}
.banner-wrapper .v-carousel__controls button >>> .v-btn--active::before {
  opacity: 0 !important;
}
.banner-wrapper .theme--dark.v-btn--active::before {
  opacity: 0 !important;
}
.v-carousel__controls__item {
  margin: 0 0 !important;
}
.banner-wrapper .v-carousel__controls button {
  width: 10px !important;
  height: 10px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
}
.banner-wrapper .v-carousel__controls button >>> .v-ripple__container {
  display: none !important;
}
</style>
<style lang="scss">
@media #{map-get($display-breakpoints, 'mobile-and-down')} {
  .v-carousel__controls {
    bottom: -18px !important;
  }
}
</style>
