<template>
    <WrapperCourseIllustrationsComponent is-lifetime :id="'lifetime'" />
</template>
<script>
  import WrapperCourseIllustrationsComponent from '@/assets/banner/WrapperCourseIllustrationsComponent';
  export default {
    components: {
        WrapperCourseIllustrationsComponent,
    }
  }
</script>
