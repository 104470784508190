<template>
  <AcaDialog
    title="Complete profile to get your credits"
    description="To download your transcript or certificate, we need some basic information about you to award you your CME and MOC credits."
    :confirm-enabled="canConfirm"
    :confirm-is-loading="loadingConfirm"
    persistent
    show-close
    :show-x-button="hasToShowCloseBtn"
    data-testid="complete-profile-dialog"
    @on-close="handleDialogClose"
    @on-confirm="handleDialogConfirm"
  >
    <template #append>
      <slot name="append">
        <form
          id="profileForm"
          @submit.prevent="submitProfile"
        >
          <BoardComponent
            :id="boardId"
            :board-name="boardName"
            :board-number="boardNumber"
            :degree="degree"
            :date-of-birth="dateOfBirth"
            :type="'dialog'"
            :custom-key="customKey"
            :show-required-asterisk="true"
            @degree="onChangeDegree"
            @medical-board="onChangeMedicalBoard"
            @has-to-required-board-information="onHasToRequiredBoardInformation"
            @board-number="onChangeBoardNumber"
            @date-of-birth="onChangeDateOfBirth"
            @allow-submit-without-full-details="onBoardDegreeChanges"
          />
        </form>
      </slot>
    </template>
  </AcaDialog>
</template>

<script>
import AcaDialog from '@components/organisms/AcaDialog';
import BoardComponent from '@/components/common/BoardComponent';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CompleteProfileDialog',
  components: {
    AcaDialog,
    BoardComponent,
  },
  data: () => ({
    firstName: null,
    lastName: null,
    degree: null,
    boardName: null,
    boardId: null,
    boardNumber: null,
    dateOfBirth: null,
    allowDegreeWithoutBoardDetails: false,
    customKey: 0,
    degreeAllowBoards: ['M.D.', 'D.O.', 'M.D., Ph.D.', 'M.D., MPH'],
    loadingConfirm: false,
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('profile', ['currentProfile']),
    hasToShowMedicalBoard() {
      return this.enabledMedicalBoard || this.degree === this.otherDegree;
    },
    hasToShowCloseBtn() {
      return this.$route.name !== 'transcripts' && this.$route.name !== 'profile';
    },
    computedDateFormatted() {
      return this.localDateOfBirth ? this.formatDate(this.localDateOfBirth) : '';
    },
    canConfirm() {
      const hasBoardInformation = this.boardNumber?.length > 0 && this.dateOfBirth?.length > 0;

      return (
        (!this.allowDegreeWithoutBoardDetailshasBoardInformation && hasBoardInformation) ||
        this.allowDegreeWithoutBoardDetails
      );
    },
  },
  mounted() {
    this.firstName = this.currentProfile?.firstName;
    this.lastName = this.currentProfile?.lastName;
    this.degree = this.currentProfile?.degree;
    this.boardName = this.currentProfile?.medicalBoard?.name;
    this.boardNumber = this.currentProfile?.medicalBoard?.board_number;
    this.boardId = this.currentProfile?.medicalBoard?.board_id;
    this.dateOfBirth = this.currentProfile?.dateOfBirthLong;
  },
  methods: {
    ...mapActions('profile', ['updateProfileNames', 'getProfile']),
    ...mapActions('auth', ['getUser']),
    ...mapActions('boards', ['updateMedicalBoard']),
    async submitProfile() {
      await this.validateMedicalBoard();
      await this.getProfile();
      this.handleDialogClose();
    },
    handleDialogClose() {
      this.$emit('on-close');
    },
    async handleDialogConfirm() {
      try {
        this.loadingConfirm = true;
        await this.submitProfile();
      } finally {
        this.loadingConfirm = false;
      }
    },
    onChangeDegree(newDegree) {
      this.degree = newDegree;
    },
    onChangeMedicalBoard(newBoardId) {
      this.boardId = newBoardId;
    },
    onChangeDateOfBirth(newDateOfBirth) {
      this.dateOfBirth = newDateOfBirth;
    },
    onChangeBoardNumber(newBoardNumber) {
      this.boardNumber = newBoardNumber;
    },
    onBoardDegreeChanges(allowDegreeWithoutBoardDetails) {
      this.allowDegreeWithoutBoardDetails = allowDegreeWithoutBoardDetails;
    },
    async validateMedicalBoard() {
      try {
        await this.updateMedicalBoard(this.setParamsForMedicalBoard());
      } catch (error) {
        console.error(error);
      }
    },
    setParamsForMedicalBoard() {
      let params = {
        degree: this.degree,
        boardId: this.boardId,
        boardNumber: this.allowDegreeWithoutBoardDetails ? null : this.boardNumber,
        dateOfBirth: this.allowDegreeWithoutBoardDetails ? null : this.dateOfBirth,
      };

      return params;
    },
    onHasToRequiredBoardInformation(hasToRequiredBoardInformation) {
      this.allowDegreeWithoutBoardDetails = !hasToRequiredBoardInformation;
    },
  },
};
</script>

<style scoped>
.aca-profile-dialog-legal-names {
  display: flex;
  gap: 24px;
}

.aca-profile-dialog-board-id {
  display: flex;
  gap: 24px;
}
</style>

<style scoped lang="scss">
@media #{map-get($display-breakpoints, 'mobile-and-down')} {
  .aca-profile-dialog-legal-names {
    display: block;
  }
  .aca-profile-dialog-board-id {
    display: block;
  }
}
</style>
