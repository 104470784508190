var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "aca-text-field"
  }, [_vm.label ? _c('AcaLabel', {
    staticClass: "mb-1",
    attrs: {
      "id": _vm.id,
      "enable-bold": _vm.isFocused,
      "error": _vm.hasError,
      "disabled": _vm.disabled
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]) : _vm._e(), _c('v-text-field', _vm._g(_vm._b({
    ref: "acaTextField",
    staticClass: "text",
    class: [_vm.isMobile ? 'text-l-large-regular' : 'text-m-large-regular', {
      'hide-details': _vm.hideDetails
    }],
    attrs: {
      "id": _vm.id,
      "solo": "",
      "disabled": _vm.disabled,
      "rules": _vm.rules,
      "hide-details": _vm.hideDetails,
      "height": _vm.localHeight,
      "label": null
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_vm._t("append", function () {
          return [_vm.hasError ? _c('PhWarningOctagon', {
            attrs: {
              "weight": "light",
              "size": "24"
            }
          }) : _vm._e()];
        }, {
          "appendClass": "append-container",
          "size": "24",
          "weight": "light"
        })];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }, 'v-text-field', _vm.$attrs, false), _vm.$listeners))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }