import { computed } from 'vue';
import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

const AUTH_TOKEN_STORAGE_KEY = 'authToken';

export const useAuthStore = defineStore('auth', () => {
  const authToken = useStorage(AUTH_TOKEN_STORAGE_KEY, null);

  const isLoggedInUser = computed(() => !!authToken.value);

  function setAuthToken(token) {
    authToken.value = token;
  }

  return {
    isLoggedInUser,
    setAuthToken,
  };
});
