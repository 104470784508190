import { mapGetters } from 'vuex';
import { specialReqsMixin } from '@/mixins/specialReqsMixin';

export const upgradeAlertsMixin = {
  mixins: [specialReqsMixin],
  computed: {
    ...mapGetters('auth', ['currentUser', 'hasUnAwardedCredits']),
    ...mapGetters('subscriptions', ['hasActiveSubscription', 'getAttemptToLoadSubscriptionDone']),
    hasToShowIcon() {
      return this.$breakpoint.tabletAndUp;
    },
    hasToShowCrownIcon() {
      return this.$breakpoint.mobileAndUp;
    },
    hasToShowSpecialReqAlertGuest() {
      if (!this.getAttemptToLoadSubscriptionDone) {
        return false;
      }

      return (
        this.hasToShowLoggedInFreeUser ||
        this.hasToShowLoggedInFreeUserTakeSpecialRequirementsMilestone ||
        this.hasToShowGuestUser ||
        this.hasToShowGuestUserEncourageTakeSpecialRequirementsMilestone
      );
    },
    hasToShowSpecialReqAlertPro() {
      if (!this.getAttemptToLoadSubscriptionDone) {
        return false;
      }

      if (this.isSpecialRequirementCourseAbstractRoute) {
        return false;
      }

      const isUnderTopicFeedOrAbstractRoute =
        this.isSpecialRequirementsTopicFeedRoute || this.isSpecialRequirementAbstract;

      const isLoggedFreeUserNewLogicForMilestone =
        this.isLoggedInUser && !this.hasActiveSubscription && this.isSpecialRequirementCourseAbstractRoute;

      const isGuestUser = !this.isLoggedInUser && isUnderTopicFeedOrAbstractRoute;

      return isLoggedFreeUserNewLogicForMilestone || isGuestUser;
    },
    hasToShowGuestUser() {
      const isUnderTopicFeedOrAbstractRoute =
        this.isSpecialRequirementsTopicFeedRoute || this.isSpecialRequirementAbstract;

      return this.isGuestUser && isUnderTopicFeedOrAbstractRoute;
    },
    hasToShowLoggedInFreeUser() {
      return this.isFreeUser && this.isUnderSpecialRequirements;
    },
    hasToShowLoggedInFreeUserTakeSpecialRequirementsMilestone() {
      return this.isFreeUser && this.isSpecialRequirementCourseAbstractRoute;
    },
    hasToShowGuestUserEncourageTakeSpecialRequirementsMilestone() {
      return (
        this.isGuestUser &&
        (this.isUnderSpecialRequirements || this.$route.name === 'special-requirements-topic-states')
      );
    },
    isUnderSpecialRequirements() {
      return (
        this.isSpecialRequirementsRoute || this.isSpecialRequirementsTopicFeedRoute || this.isSpecialRequirementAbstract
      );
    },
    isSpecialRequirementsRoute() {
      return this.$route.name === 'special-requirements';
    },
    isSpecialRequirementsTopicFeedRoute() {
      return this.$route.name === 'special-requirements-topic-state-feed';
    },
    isSpecialRequirementAbstract() {
      return (
        this.$route.name === 'special-requirements-article-abstract' ||
        this.$route.name === 'special-requirements-course-abstract'
      );
    },
    isLoggedInUser() {
      return !!this.currentUser;
    },
    isGuestUser() {
      return !this.currentUser;
    },
    isFreeUser() {
      return this.isLoggedInUser && !this.hasActiveSubscription;
    },
    hasToShowProIndicator() {
      return !this.hasActiveSubscription;
    },
    isSpecialRequirementCourseAbstractRoute() {
      return this.$route.name === 'special-requirements-course-abstract';
    },
    isCourseAbstractRoute() {
      return this.$route.name === 'course-abstract';
    },
    isCourseRoute() {
      return this.$route.name === 'premium-courses-feed';
    },
    isSpecialRequirementArticleAbstractRoute() {
      return this.$route.name === 'special-requirements-article-abstract';
    },
    hasPendingCredits() {
      return this.hasUnAwardedCredits;
    },
  },
  methods: {
    handleClickSignup() {
      this.$router.push({ name: 'register' });
    },
    handleClickUpgrade() {
      this.$router.push({ name: 'my-plan', params: { upgrade: true } });
    },
  },
};
