import { render, staticRenderFns } from "./SpecialRequirementsAlert.vue?vue&type=template&id=003acf94&scoped=true&"
import script from "./SpecialRequirementsAlert.vue?vue&type=script&lang=js&"
export * from "./SpecialRequirementsAlert.vue?vue&type=script&lang=js&"
import style0 from "./SpecialRequirementsAlert.vue?vue&type=style&index=0&id=003acf94&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "003acf94",
  null
  
)

export default component.exports