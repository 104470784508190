var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "badge-container text-end"
  }, [_c('GraduationCapBackgroundComponent', {
    staticClass: "background-badge-animation",
    class: [_vm.badgeAnimationClass],
    attrs: {
      "width": _vm.widthBackground,
      "height": _vm.heightBackground,
      "id": _vm.id
    }
  }), _vm.id === 'courses' ? _c('GraduationCapComponent', {
    staticClass: "badge",
    class: [_vm.badgeClass],
    attrs: {
      "width": _vm.widthBadge,
      "height": _vm.heightBadge
    }
  }) : _vm._e(), _vm.id === 'lifetime' ? _c('LimitedOfferTextComponent', {
    staticClass: "badge",
    class: [_vm.badgeCircularTextClass],
    attrs: {
      "width": _vm.widthTextCircleBadge,
      "height": _vm.heightTextCircleBadge
    }
  }) : _vm._e(), _vm.id === 'lifetime' ? _c('div', {
    staticClass: "badge",
    class: [_vm.badgeInnerTextClass, 'text-center']
  }, [_c('div', {
    staticClass: "heading white--text heading-offer-number"
  }, [_vm._v("40%")]), _c('div', {
    staticClass: "text white--text heading-off"
  }, [_vm._v("OFF")])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }