<template>
  <document-section
    :class="{ 'max-height': shouldShowBackdrop }"
    tag="div"
  >
    <div
      v-show="shouldShowBackdrop"
      data-testid="veil"
      class="black-veil"
    ></div>
    <WelcomeRegisteredUserComponent
      v-if="hasToShowRegisterUserOnboarding"
      :current-route="$route.path"
      :current-user="currentUser"
    />
    <WelcomePromoUserComponent
      v-else-if="hasToShowPromoUserOnboarding"
      :current-route="$route.path"
      :current-user="currentUser"
    />

    <v-navigation-drawer
      v-if="hasToRenderNavigationDrawer"
      id="app-drawer"
      v-model="drawer"
      color="var(--v-card-base)"
      width="280px"
      app
      touchless
      class="shadow-sidebar sidebar-border px-1"
      :permanent="$breakpoint.isSidebarFixed"
    >
      <v-list-item id="logo-container">
        <v-list-item-content class="pt-7">
          <v-list-item-title class="title d-flex justify-space-between">
            <LogoMenuComponent
              :id="'673_11252'"
              :key="2"
              class="clickable"
              :has-custom-size-for-mobile="false"
            />
            <ph-x
              v-if="$vuetify.breakpoint.mdAndDown"
              color="var(--v-grey-darken4)"
              :size="28"
              weight="thin"
              @click="drawer = !drawer"
            />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list
        :class="{
          'mt-3': $breakpoint.tabletAndUp,
        }"
        nav
        dense
      >
        <!-- guest user menu options -->
        <v-list-item-group
          color="primary"
          active-class="list-item-group-active"
        >
          <!-- Guest user menu options -->
          <v-list-item
            class="no-border-radius pr-3 menu-item mb-0"
            to="/"
          >
            <v-list-item-icon class="mr-2 mt-4">
              <ph-newspaper
                color="var(--v-primary-base)"
                :size="24"
                weight="light"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="text text-m-large-regular grey--text text--darken-4"
                @click="handleClickFeedAsGuestUser"
              >
                Feed
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="isEnabledPremiumCourses"
            class="no-border-radius pr-3 menu-item mb-0 mt-0"
            to="/premium-courses"
            @click="handleClickPremiumCoursesAsGuestUser"
          >
            <v-list-item-icon class="mr-2 mt-4">
              <ph-book-bookmark
                color="var(--v-primary-base)"
                :size="24"
                weight="light"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="d-flex justify-space-between align-center text-m-large-regular text grey--text text--darken-4"
                style="white-space: normal"
              >
                FluoroSafety
                <v-chip
                  class="text text-m-small-regular pro-chip-special-requirements"
                  color="#FFAB18"
                  dark
                  label
                >
                  <CrownFilledIconComponent
                    class="mr-1"
                    :size="14"
                  />
                  Pro
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="no-border-radius pr-3 menu-item mb-0 mt-0"
            to="/special-requirements"
          >
            <v-list-item-icon class="mr-2 mt-4">
              <ph-clipboard-text
                color="var(--v-primary-base)"
                :size="24"
                weight="light"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="d-flex align-center justify-space-between text text-m-large-regular grey--text text--darken-4"
                style="white-space: normal"
              >
                Special requirements
                <v-chip
                  v-if="hasToShowProBadge"
                  class="text text-m-small-regular pro-chip-special-requirements"
                  color="#FFAB18"
                  dark
                  label
                >
                  <CrownFilledIconComponent
                    class="mr-1"
                    :size="14"
                  />
                  Pro
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-list-group v-if="currentUser">
          <template #activator>
            <v-list-item class="menu-item mb-0 pl-0">
              <v-list-item-icon class="mr-2 mt-4">
                <ph-student
                  color="var(--v-primary-base)"
                  :size="24"
                  weight="light"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="d-flex text text-m-large-regular justify-space-between grey--text text--darken-4"
                >
                  Account
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item
            v-for="(item, i) in menuItemOptions.slice(2)"
            :key="i"
            :class="[
              {
                'primary-surface': checkIfMenuIsActive(item),
              },
            ]"
            class="no-border-radius pl-10 menu-item mb-0"
            @click="menuAction(item.id)"
          >
            <v-list-item-icon class="mr-2 mt-4">
              <ph-trophy
                v-if="item.text === 'Transcript'"
                color="var(--v-primary-base)"
                :size="24"
                weight="light"
              />
              <ph-rocket
                v-if="item.text === 'My Plan'"
                color="var(--v-primary-base)"
                :size="24"
                weight="light"
              />
              <ph-warning
                v-if="hasIncompleteProfile && item.text === 'Profile'"
                color="var(--v-warning-base)"
                :size="24"
                weight="light"
              />
              <ph-user-circle
                v-else-if="item.text === 'Profile'"
                color="var(--v-primary-base)"
                :size="24"
                weight="light"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-if="hasIncompleteProfile && item.text === 'Profile'"
                class="d-flex text text-m-large-regular justify-space-between warning--text"
              >
                Complete profile
              </v-list-item-title>
              <v-list-item-title v-else-if="item.text === 'Premium Courses'"> FluoroSafety </v-list-item-title>
              <v-list-item-title
                v-else
                class="d-flex text text-m-large-regular justify-space-between grey--text text--darken-4"
              >
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-list
        v-if="!hasToFixBottomHelpLinks"
        class="px-0"
        nav
      >
        <!-- Scrolleable bottom section -->
        <v-list-item-group color="primary">
          <BottomLinksSectionComponent
            :current-user="currentUser"
            class="mt-7"
          />
        </v-list-item-group>
      </v-list>
      <template
        v-if="hasToFixBottomHelpLinks"
        #append
      >
        <v-list-item-group color="primary">
          <BottomLinksSectionComponent :current-user="currentUser" />
        </v-list-item-group>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      v-if="shouldShowTopBar"
      app
      class="app-bar"
      :height="$breakpoint.tabletAndUp ? '60px' : '56px'"
      :color="navBarBackground"
      flat
    >
      <v-app-bar-nav-icon
        v-if="hasToRenderNavigationDrawer"
        class="d-inline d-lg-none"
        data-testid="menu-drawer-btn"
        @click="drawer = !drawer"
      >
        <ph-list
          color="var(--v-grey-darken1)"
          :size="32"
          weight="thin"
        />
      </v-app-bar-nav-icon>

      <div
        v-if="!hasToShowBackNavigation && $route.name === 'home' && $vuetify.breakpoint.mdAndDown"
        class="d-block mr-auto mx-lg-0 clickable"
        @click="menuAction(0)"
      >
        <LogoMenuComponent
          :id="'673_11253'"
          :key="1"
          :is-auditor="isAuditor"
        />
      </div>
      <!-- only for PDF and Content view orphan routing -->
      <div
        v-if="
          !hasToShowBackNavigation &&
          ($route.name === 'article-file-view' ||
            $route.name === 'article-disclosure-pdf' ||
            $route.name === 'course-disclosure-pdf' ||
            $route.name === 'course-module-content-preview' ||
            $route.name === 'auditor-article-file-view')
        "
        class="d-block mr-auto mx-lg-0 clickable"
        @click="menuAction(0)"
      >
        <LogoMenuComponent
          :id="'673_11254'"
          :key="2"
        />
      </div>
      <div
        v-if="hasToShowBackNavigation"
        class="d-flex container-back"
      >
        <ph-caret-left
          v-show="getTitle"
          class="clickable"
          weight="thin"
          size="24"
          color="var(--v-grey-darken4)"
          @click="goBack"
        />
        <h6
          class="text text-l-large-regular grey--text text--darken-3 ml-2 clickable"
          :class="{ 'text-truncate fixed-back-text': $breakpoint.mobileAndDown }"
          @click="goBack"
        >
          {{ getTitle }}
        </h6>
      </div>
      <v-spacer class="d-block" />
      <AvatarBadgeComponent
        :has-to-show-unawarded-badge="hasToShowUnawardedBadge"
        :awarded-credits-to-show="awardedCreditsToShow"
        :full-name="fullName"
        :is-auditor="isAuditor"
        :has-to-disabled-quiz-button="getTakeQuizButtonDisabledInNavBar"
        @go-to-login="goToLogin"
        @go-to-signup="goToSignup"
      />
    </v-app-bar>
    <v-main :class="[shouldShowBackdrop || (!currentUser && isFeedRoute) ? 'pt-0' : 'pt-14 pt-sm-15', mainBackground]">
      <CompleteProfileAlert @show-complete-profile-dialog="showCompleteProfileDialog" />
      <CompleteProfileDialog
        v-if="
          shouldShowCompleteProfileDialog ||
          shouldShowCompleteProfileDialogOnTranscript ||
          shouldShowCompleteProfileDialogOnProfile
        "
        @on-close="hideCompleteProfileDialog"
      />
      <FreeTrialReachedLimitsAlert v-if="!hasIncompleteProfile" />
      <premium-alert v-if="!hasIncompleteProfile"></premium-alert>
      <!-- TODO: delete this components if they are not being used anymore -->
      <special-requirements-alert v-if="currentUser" />
      <header
        id="headerBanner"
        :class="{ 'pt-14 pt-sm-15 pt-md-15 pt-lg-0': !currentUser && isFeedRoute }"
      >
        <WrapperBannersComponent v-if="!currentUser && isFeedRoute" />
      </header>
      <v-container :class="[containerBackgroundAndSpacing, { 'container-full-width': hasToApplyFullWidth }]">
        <!-- TODO: see if this is working as it should -->
        <v-row
          v-if="showExpirationAlert"
          no-gutters
          justify-sm="center"
        >
          <v-col
            class="px-sm-11 px-lg-0 px-6"
            :class="{
              'mb-4': !hasToApplyFullWidth,
            }"
            cols="12"
            sm="12"
            lg="12"
          >
            <ExpirationAlertCardComponent :finish-init-load="finishInitLoad" />
          </v-col>
        </v-row>
        <v-row
          no-gutters
          :class="[{ 'center-content-update-card': hasToCenterContentToUpdateCard }]"
          justify-sm="center"
        >
          <v-col
            class="px-sm-12 px-lg-0 px-6"
            :class="{
              'mb-4': !hasToApplyFullWidth,
              'px-md-11': isMainFeedRoute,
            }"
            cols="12"
            sm="12"
            lg="12"
          >
            <slot name="content" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </document-section>
</template>

<script>
import WelcomeRegisteredUserComponent from '@/components/onboarding/WelcomeRegisteredUserComponent';
import WelcomePromoUserComponent from '@/components/onboarding/WelcomePromoUserComponent';
import AvatarBadgeComponent from '@/components/common/AvatarBadgeComponent';
import LogoMenuComponent from '@/assets/LogoMenuComponent';
import CrownFilledIconComponent from '@/assets/CrownFilledIconComponent';
import ExpirationAlertCardComponent from '@/components/common/ExpirationAlertCardComponent';
import SpecialRequirementsAlert from '@/components/common/SpecialRequirementsAlert.vue';
import FreeTrialReachedLimitsAlert from '@/components/common/FreeTrialReachedLimitsAlert';
import CompleteProfileAlert from '@/components/common/CompleteProfileAlert';
import { specialReqsMixin } from '@/mixins/specialReqsMixin';
import { upgradeAlertsMixin } from '@/mixins/upgradeAlertsMixin';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  PhCaretLeft,
  PhList,
  PhBookBookmark,
  PhNewspaper,
  PhTrophy,
  PhRocket,
  PhUserCircle,
  PhX,
  PhClipboardText,
  PhStudent,
  PhWarning,
} from 'phosphor-vue';
import { DocumentSection } from 'vue-headings';
import WrapperBannersComponent from '@/components/common/WrapperBannersComponent';
import BottomLinksSectionComponent from './menu/BottomLinksSectionComponent';

import DotComponent from '@/components/common/DotComponent';
import CompleteProfileDialog from '@/components/profile/CompleteProfileDialog';
import { completeProfileAlertMixin } from '@/mixins/completeProfileAlertMixin';
import PremiumAlert from '@/components/premiumCourses/abstract/PremiumAlert';

export default {
  name: 'LayoutComponent',
  components: {
    WelcomeRegisteredUserComponent,
    DocumentSection,
    WelcomePromoUserComponent,
    AvatarBadgeComponent,
    LogoMenuComponent,
    CrownFilledIconComponent,
    PhCaretLeft,
    PhList,
    PhNewspaper,
    PhTrophy,
    PhRocket,
    PhUserCircle,
    PhBookBookmark,
    PhX,
    PhClipboardText,
    PhStudent,
    PhWarning,
    ExpirationAlertCardComponent,
    SpecialRequirementsAlert,
    WrapperBannersComponent,
    BottomLinksSectionComponent,
    DotComponent,
    FreeTrialReachedLimitsAlert,
    CompleteProfileAlert,
    CompleteProfileDialog,
    PremiumAlert,
  },
  mixins: [completeProfileAlertMixin, specialReqsMixin, upgradeAlertsMixin],
  data: () => {
    const menuItemOptions = [
      { id: 0, text: 'Feed', routeName: 'home' },
      { id: 'premium-courses', text: 'Premium Courses', routeName: 'premium-courses-feed' },
      { id: 2, text: 'Transcript', routeName: 'transcripts' },
      { id: 3, text: 'My Plan', routeName: 'my-plan' },
      { id: 4, text: 'Profile', routeName: 'profile' },
    ];

    if (process.env.VUE_APP_ENABLE_PREMIUM_COURSE !== 'true') {
      const premiumCoursesMenuIndex = menuItemOptions.findIndex((item) => item.id === 'premium-courses');
      menuItemOptions.splice(premiumCoursesMenuIndex, 1);
    }

    return {
      drawer: null,
      menuItemOptions: menuItemOptions,
      mainBackground: 'main-bg',
      containerBackgroundAndSpacing: 'container-bg-and-spacing',
      currentRouteName: null,
      currentRoutePath: null,
      navBarBackground: 'var(--v-background-base)',
      initial: 0,
      userSubscription: null,
      isFetchedUserSubscription: false,
      finishInitLoad: false,
      currentArticleId: 0,
    };
  },
  computed: {
    ...mapGetters('auth', ['currentUser', 'isAuditor']),
    ...mapGetters('profile', ['currentProfile', 'willPaymentMethodExpire', 'shouldShowCompleteProfileDialog']),
    ...mapGetters('specialRequirements', ['lastVisitedTopicState']),
    ...mapGetters('feed', [
      'isOnboardingFinished',
      'isOnboardingRequestDone',
      'getArticleId',
      'isShowPretestResult',
    ]),
    ...mapGetters('menu', [
      'getTitle',
      'shouldShowBackdrop',
      'getBackIfIsInternalRouting',
      'getCurrentReadingAtArticleId',
      'getIsReportCustom',
      'getIsReportOpen',
      'getHideAllMenu',
      'getTakeQuizButtonDisabledInNavBar',
    ]),
    ...mapGetters('subscriptions', ['hasActiveSubscription']),
    ...mapState('menu', ['hasToShowBackNavigation']),
    fullName() {
      return this.currentProfile ? `${this.currentProfile.firstName} ${this.currentProfile.lastName}` : 'N/A';
    },
    awardedCreditsToShow() {
      return this.currentUser?.awardedCredits ?? 0;
    },
    hasUnawardedCredits() {
      const totalUnawardedCredits = +(this.currentUser?.unawardedCredits ?? 0);

      return totalUnawardedCredits > 0;
    },
    hasToApplyFullWidth() {
      const isPdfRoute = this.currentRoutePath?.includes('pdf');
      const isContentRoute =
        this.currentRouteName === 'course-module-content' || this.currentRouteName === 'course-module-content-preview';

      return isPdfRoute || isContentRoute;
    },
    hasToShowUnawardedBadge() {
      return this.currentUser?.unawardedCredits > 0;
    },
    hasToShowGetCmeBtn() {
      return true;
    },
    hasToRenderNavigationDrawer() {
      return this.isFeedRoute || this.isTopicStateRoute;
    },
    hasToShowFindArticlesImage() {
      return this.$vuetify.breakpoint.width >= 375;
    },
    isUserFreePlan() {
      return this.userSubscription?.stripeId == null || this.userSubscription?.status === 'canceled';
    },
    isFeedRoute() {
      return (
        this.isMainFeedRoute ||
        this.isPremiumCoursesFeedRoute ||
        this.isSpecialRequirementsFeedRoute ||
        this.isTopicStateRoute
      );
    },
    isTopicStateRoute() {
      return this.$route.name === 'special-requirements-topic-states';
    },
    isTopicStateFeedRoute() {
      return this.$route.name === 'special-requirements-topic-state-feed';
    },
    isMainFeedRoute() {
      return this.$route.name === 'home' || this.$route.name === 'main-feed-topic-states';
    },
    isPremiumCoursesFeedRoute() {
      return this.$route.name === 'premium-courses-feed';
    },
    isSpecialRequirementsFeedRoute() {
      return this.$route.name === 'special-requirements';
    },
    isEnabledPremiumCourses() {
      return process.env.VUE_APP_ENABLE_PREMIUM_COURSE === 'true';
    },
    hasToCenterContentToUpdateCard() {
      return this.$route.name === 'update-billing';
    },
    showExpirationAlert() {
      return this.willPaymentMethodExpire && this.routeIncludesExpirationAlert;
    },
    routeIncludesExpirationAlert() {
      return ['transcripts', 'my-plan', 'profile', 'billing', 'home', '/'].includes(this.$route.name);
    },
    shouldShowTopBar() {
      if (this.isTopicStateRoute && this.isTopicStateFeedRoute && !this.currentUser) {
        return false;
      }

      return (
        !this.getHideAllMenu &&
        (!this.isFeedRoute || (this.$vuetify.breakpoint.width < 1264 && !this.getHideAllMenu) || this.currentUser)
      );
    },
    hasToShowOnboarding() {
      return (
        !this.isOnboardingFinished &&
        this.isOnboardingRequestDone &&
        this.currentUser &&
        (this.$route.path === 'home' || this.$route.path === '/')
      );
    },
    hasToShowRegisterUserOnboarding() {
      return this.hasToShowOnboarding && !this.currentUser.is_promo_user;
    },
    hasToShowPromoUserOnboarding() {
      return this.hasToShowOnboarding && this.currentUser.is_promo_user;
    },
    hasToFixBottomHelpLinks() {
      return this.$vuetify.breakpoint.height > 550;
    },
    hasToShowProBadge() {
      return false;
    },
    shouldShowCompleteProfileDialogOnTranscript() {
      return this.hasIncompleteProfile && this.$route.name === 'transcripts';
    },
    shouldShowCompleteProfileDialogOnProfile() {
      return this.hasIncompleteProfile && this.$route.name === 'profile';
    },
  },
  watch: {
    '$route.path': function () {
      this.currentRouteName = this.$route.name;
      this.currentRoutePath = this.$route.path;

      if (this.$route.name === 'home') {
        this.drawer = null;
        this.setIsFromFeedView(false);
        this.hideCreditsInNavBar(false);
      }
    },
    isShowPretestResult: {
      handler: function () {
        if (this.isShowPretestResult) {
          //I added this extra value, because for some reason the getCurrentReadingAtArticleId is empty in the third step
          this.currentArticleId = this.getCurrentReadingAtArticleId;
          this.setGeneralVeil(true);
        }
      },
    },
  },
  async mounted() {
    this.currentRouteName = this.$route.name;
    this.currentRoutePath = this.$route.path;

    if (!this.currentUser) {
      this.loadSubscriptionDone();
      return;
    }

    const response = await this.getUserSubscription();
    if (response.data) {
      this.userSubscription = response.data;
      this.isFetchedUserSubscription = true;
    }

    if (this.userSubscription?.stripeId == null || this.userSubscription?.status === 'canceled') {
      this.setIsUserFreePlan(true);
      return;
    }
    this.setIsUserFreePlan(false);

    this.checkIfPaymentMethodWillExpire();
    this.finishInitLoad = true;
    if (this.isShowPretestResult) {
      this.setGeneralVeil(true);
    }
  },
  methods: {
    ...mapActions('profile', [
      'getProfile',
      'getUserPaymentMethod',
      'checkIfPaymentMethodWillExpire',
      'hideCompleteProfileDialog',
      'showCompleteProfileDialog',
    ]),
    ...mapActions('menu', [
      'hideTopMenu',
      'setTopMenuTitle',
      'setIsInternalRouting',
      'setGeneralVeil',
      'setIsReportCustom',
      'setIsReportOpen',
      'setIsFromFeedView',
      'hideCreditsInNavBar',
      'takeQuizButtonDisabledInNavBar',
    ]),
    ...mapActions('feed', [
      'layoutPretest',
      'showOnboardingPretest',
      'showPretestUser',
      'setArticleIdToPretest',
      'showPretestResult',
      'showPretestOnboarding',
      'hasToShowPretestOnboarding',
    ]),
    ...mapActions('subscriptions', ['getUserSubscription', 'setIsUserFreePlan', 'loadSubscriptionDone']),
    goToLogin() {
      this.$router.push({ name: 'login' });
    },
    goToSignup() {
      this.$router.push({ name: 'register' });
    },
    menuAction(id) {
      if (this.hasIncompleteProfile && id === 4) {
        this.showCompleteProfileDialog();
        return;
      }

      const selectedMenuItem = this.menuItemOptions.find((menuItemOption) => menuItemOption.id === id);
      if (!selectedMenuItem) {
        return;
      }

      const hasToScrollToTop = this.isFeedRoute && this.checkIfMenuIsActive({ routeName: selectedMenuItem.routeName });
      if (hasToScrollToTop) {
        document.documentElement.scrollTop = this.initial;
        return;
      }

      if (this.checkIfMenuIsActive(selectedMenuItem)) {
        return;
      }

      this.$router.push({ name: selectedMenuItem.routeName });
    },
    goBack() {
      const onRouteChanged = () => {
        this.hideCreditsInNavBar(false);
      };

      if (this.$route.name === 'article-pretest') {
        this.layoutPretest(false);
      }

      if (!this.getBackIfIsInternalRouting) {
        if (!this.getTitle) {
          this.$router.go(-1).then(onRouteChanged);
          return;
        }
        if (this.getTitle.toLowerCase() === 'back to quiz') {
          this.$router.push({ name: 'article-quiz', params: { id: this.$route.params.id } }).then(onRouteChanged);
        }
        if (this.getTitle.toLowerCase() === 'back to feed') {
          if (this.topicSlug && this.stateSlug) {
            this.$router
              .push({
                name: 'special-requirements-topic-state-feed',
                params: { topicSlug: this.topicSlug, stateSlug: this.stateSlug },
              })
              .then(onRouteChanged);
            return;
          }

          this.$router.push({ name: 'home' }).then(onRouteChanged);
        }
        if (this.getTitle.toLowerCase() === 'back to profile') {
          this.$router.push({ name: 'profile' }).then(onRouteChanged);
        }
        if (this.getTitle.toLowerCase() === 'back to billing') {
          this.$router.push({ name: 'billing' }).then(onRouteChanged);
        }
        if (this.getTitle.toLowerCase() === 'back to my plan') {
          this.$router.push({ name: 'my-plan' }).then(onRouteChanged);
        }
        if (this.getTitle.toLowerCase() === 'back to transcript') {
          this.$router.push({ name: 'transcripts' }).then(onRouteChanged);
        }
        if (this.getTitle.toLowerCase() === 'back to courses') {
          this.$router.push({ name: 'premium-courses-feed' }).then(onRouteChanged);
        }
        if (this.getTitle.toLowerCase() === 'back to topics') {
          this.$router.push({ name: 'special-requirements' }).then(onRouteChanged);
        }
        if (this.getTitle.toLowerCase() === 'back to topic') {
          const params = {
            topicSlug: this.topicSlug,
            stateSlug: this.stateSlug,
          };
          this.$router.push({ name: 'special-requirements-topic-state-feed', params }).then(onRouteChanged);
        }
        if (this.getTitle.toLowerCase() === 'back to overview') {
          if (this.hasSpecialRequirements) {
            this.$router
              .push({
                name: 'special-requirements-course-enrollment',
                params: {
                  topicSlug: this.topicSlug,
                  stateSlug: this.stateSlug,
                  courseId: this.$route.params.courseId,
                },
              })
              .then(onRouteChanged);
          } else {
            this.$router
              .push({ name: 'course-enrollment', params: { courseId: this.$route.params.courseId } })
              .then(onRouteChanged);
          }
        }
        if (this.isSpecialRequirementAbstractRoute) {
          if (this.isSpecialRequirementFileRoute) {
            this.$router
              .push({
                name: 'special-requirements-article-abstract',
              })
              .then(onRouteChanged);
            return;
          }

          this.$router
            .push({
              name: 'special-requirements-topic-state-feed',
              params: { topicSlug: this.topicSlug, stateSlug: this.stateSlug },
            })
            .then(onRouteChanged);
        }
      }

      if (this.getBackIfIsInternalRouting) {
        if (this.getIsReportCustom) {
          this.setIsReportCustom(false);
          return;
        }

        if (this.getIsReportOpen) {
          this.setIsReportOpen(false);
        }

        this.setIsInternalRouting(false);
      }
    },
    checkIfMenuIsActive({ routeName }) {
      const matchRoute = this.$route.name === routeName;
      if (!matchRoute) {
        return false;
      }

      return true;
    },
    handleClickFeedAsGuestUser() {
      const hasToScrollToTop = this.isMainFeedRoute;
      if (!hasToScrollToTop) {
        return;
      }

      this.scrollToTop();
    },
    handleClickPremiumCoursesAsGuestUser() {
      const hasToScrollToTop = this.isPremiumCoursesFeedRoute;
      if (!hasToScrollToTop) {
        return;
      }

      this.scrollToTop();
    },
    scrollToTop() {
      document.documentElement.scrollTop = this.initial;
    },
  },
};
</script>

<style scoped lang="scss">
#logo-container {
  padding-right: 10px;
}

.v-app-bar.v-app-bar--fixed {
  z-index: 3 !important;
}

.text.text-l-large-regular {
  font-size: 1rem !important;
}

#app-drawer {
  height: 100% !important;
}

.pro-chip {
  margin-left: 27px;
  height: 24px;
  width: 35px;
  padding: 0;
  padding-left: 8px;
}

.pro-chip-special-requirements {
  height: 24px;
  width: 53px;
  padding: 4px 8px;
  cursor: pointer;
}

.list-item-group-active {
  background-color: var(--v-primary-surface-base);
}

.center-content-update-card {
  text-align: -webkit-center !important;
}

/* 
  Remove default gray background color from v-list-item when active
  https://forum.vuejs.org/t/v-list-item-change-v-list-item-active-background-color-vuetify/112507/5 
*/
.theme--light.v-list-item--active:before,
.theme--light.v-list-item--active:hover:before,
.theme--light.v-list-item:focus:before {
  opacity: 0;
}

.menu-item {
  min-height: 56px;
}

.sidebar-border.v-navigation-drawer >>> .v-navigation-drawer__border {
  display: none;
}

.container-back {
  align-items: center;
  flex: min-content;
}

.fixed-back-text {
  width: 150px;
}
</style>
