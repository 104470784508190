import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import store from '@/store/index';
import router from './router/router';
import './filters';
import './toasts';
import { axiosResponseErrorInterceptor } from '@/api/interceptors';

import debounce from 'lodash.debounce';
Object.defineProperty(Vue.prototype, '$_debounce', { value: debounce });

if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
  Vue.config.devtools = true;
}

import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

import VueSocialAuth from 'vue-social-auth';
const googleRedirectUri = process.env.VUE_APP_SOCIALITE_GOOGLE_REDIRECT_URL || `${window.location.origin}/callback`;
Vue.use(VueSocialAuth, {
  providers: {
    google: {
      clientId: process.env.VUE_APP_SOCIALITE_GOOGLE_CLIENT_ID,
      redirectUri: googleRedirectUri,
    },
  },
});

import VueMask from 'v-mask';
Vue.use(VueMask);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies, { expire: '7d' });

import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

import { getFeatureFlagProvider, FEATURE_FLAGS } from '@/lib/featureFlag';

// It is important to initialize the feature flag provider before mounting the app,
// as failing to do so would result in the feature flags being unavailable within the app.
// By default, the feature flags will be turned off, and if they are later turned on,
// it could lead to improper functionality. To ensure consistent behavior,
// make sure to initialize the feature flag provider before mounting the app.
const featureFlagProvider = new getFeatureFlagProvider();

axios.interceptors.request.use(
  function (config) {
    const token = store.getters['auth/authToken'];
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // TODO: Address technical debt related to setting feature flags as headers
    // Currently, feature flags are set as headers for easy integration without backend changes.
    // However, this approach introduces a limitation where the header values could be modified by users.
    // Implementing Split.io on the backend would provide a more robust solution.
    // However, it requires significant additional work, including modifying the infrastructure and introducing Redis as a dependency.
    // Given the time constraints, the decision was made to proceed with the current approach.
    // It is crucial to prioritize resolving this limitation in the future to ensure optimal functionality.
    // Please note that this implementation should be reviewed and enhanced accordingly.
    setFeatureFlagsAsHeader(config);

    return config;
  },
  function (err) {
    return Promise.reject(err);
  },
);

const setFeatureFlagsAsHeader = (axiosRequestConfig) => {
  FEATURE_FLAGS.forEach((flag) => {
    const formattedFlagName = flag.toLowerCase().replace(/_/g, '-');

    axiosRequestConfig.headers[`x-${formattedFlagName}`] = featureFlagProvider.isFeatureEnabled(flag);
  });
};

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return Promise.resolve(response);
}, axiosResponseErrorInterceptor);

router.beforeResolve(async (to, from, next) => {
  if (
    from.name === 'home' &&
    (to.name === 'article' ||
      to.name === 'article-quiz' ||
      to.name === 'article-file-view' ||
      to.name === 'course-abstract' ||
      to.name === 'article-disclosure' ||
      to.name === 'course-disclosure' ||
      to.name === 'special-requirements-article-abstract' ||
      to.name === 'special-requirements-article-pdf' ||
      to.name === 'special-requirements-article-quiz' ||
      to.name === 'special-requirements-course-quiz')
  ) {
    router.$homeScrollTopPosition = window.document.documentElement.scrollTop;
  }

  const user = store.getters['auth/currentUser'];
  let profile = store.getters['profile/currentProfile'];

  if (user) {
    if (!to.meta.usesNewLayout) {
      if (to.name !== 'home' || (to.name === 'home' && !profile)) {
        // we want to stop fetching the profile for every route change
        // we will do this progressively, starting with some routes
        // Note: removing fetch profile entirely from home breaks the badges due that the calcs come from there
        await store.dispatch('profile/getProfile');
      }
    }
  }

  // here we also handle role auditor authorized access
  // possibly the auditor for some reason might type in manually the URL and make a mistake, this will redirect to the 404 page
  // ideally we should have a middleware for the guard, but a TODO will be for now
  if (
    to.name === 'auditor-article-disclosure' ||
    to.name === 'auditor-article-file-view' ||
    to.name === 'auditor-article-quiz' ||
    to.name === 'auditor-article-pretest'
  ) {
    if (!user.is_independent_auditor) {
      next({ name: 'error', params: { statusCode: 403, message: 'The action you attempted was forbidden' } });
      return;
    }

    const response = await store.dispatch('article/fetchArticle', { id: to.params.id });

    if (response.data == null || response.data.success == false) {
      next({ name: 'error' });
    }
  }

  if (user && ['/', 'home'].includes(to.name)) {
    await store.dispatch('feed/getOnboardingStatus');
  }

  if (
    ![
      'article',
      'article-file-view',
      'article-disclosure',
      'special-requirements-article-abstract',
      'special-requirements-topic-states-article-abstract',
      'special-requirements-article-pdf',
    ].includes(to.name)
  ) {
    store.dispatch('menu/setCurrentReadingAtArticleId', null);
  }

  if (to.meta.usesNewLayout) {
    if (to.meta.isPublic) {
      next();
      return;
    } else if (!user) {
      const isMailCtaRedirect = to.name === 'update-billing' || to.name === 'my-plan' || to.name === 'billing';
      const params = { data: { nextRoute: to.name } };

      if (to.name === 'my-plan') {
        params.upgrade = true;
      }

      if (isMailCtaRedirect) {
        next({ name: 'login', params });
        return;
      } else {
        const nextRoute = to.name;
        const nextParams = to.params;
        const params = { data: { nextRoute, nextParams } };

        next({ name: 'login', params });
        return;
      }
    }
  }

  /**
   * WARNING: Do not add more URLs to this list. If a new page is added to the project,
   * it should use the 'isPublic' metadata along with the new Layout components.
   */
  const pathsWithoutRedirection = [
    'login',
    'register',
    'home',
    'article',
    'password-reset',
    'password-create-fluorosafety',
    'article-quiz',
    'article-pretest',
    'privacy',
    'terms',
    'error',
    'article-file-view',
    'article-disclosure',
    'article-disclosure-pdf',
    'auditor-article-pretest',
    'auditor-article-file-view',
    'auditor-article-quiz',
    'premium-courses-feed',
    'course-abstract',
    'course-disclosure-pdf',
    'course-module-content-preview',
    'socialCallback',
    'special-requirements-topic-states-article-abstract',
    'special-requirements-topic-states-course-abstract',
    'special-requirements-topic-states',
    'special-requirements-topic-state-feed',
    'special-requirements',
    'special-requirements-poc',
    'special-requirements-article-abstract',
    'special-requirements-course-abstract',
    'special-requirements-article-quiz',
    'special-requirements-article-pdf',
    'single-feed',
    'main-feed-topic-states',
  ];
  const currentPathHasRedirection = !pathsWithoutRedirection.includes(to.name);
  const hasToRedirectToLogin = currentPathHasRedirection && !user;

  let isMailCtaRedirect = to.name === 'update-billing' || to.name === 'my-plan' || to.name === 'billing';
  let params = { data: { nextRoute: to.name } };

  if (hasToRedirectToLogin && to.name === 'my-plan') {
    params.upgrade = true;
  }

  if (isMailCtaRedirect && !user) {
    next({ name: 'login', params });
    return;
  } else {
    if (hasToRedirectToLogin) {
      next({ name: 'login' });
    } else {
      next();
    }
  }
});

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', '*.acapedia.com', /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE || 0,
  logErrors: true,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT || 'local',
  // TODO: we are going to remove the 'v' prefix to tags
  release: `${process.env.PACKAGE_VERSION}`,
});

import BreakpointPlugin from '@/plugins/breakpoint.plugin';
Vue.use(BreakpointPlugin);

import DisablePaymentsPlugin from '@/plugins/disable-payments.plugin';
Vue.use(DisablePaymentsPlugin);

import EventNotifierPlugin from './plugins/event-notifier.plugin';
Vue.use(EventNotifierPlugin);

import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query';

const myClient = new QueryClient();
const vueQueryPluginOptions = {
  queryClient: myClient,
};
Vue.use(VueQueryPlugin, vueQueryPluginOptions);

import { pinia, PiniaVuePlugin } from './store-v2';

Vue.use(pinia);
Vue.use(PiniaVuePlugin);

if (!featureFlagProvider) {
  console.error('Failed to initialize the feature flag provider');
  Sentry.captureMessage('Failed to initialize the feature flag provider');

  initVue();
} else {
  featureFlagProvider
    .init()
    .then(() => {
      console.log('Feature flag provider initialized successfully');
    })
    .catch((error) => {
      console.error('Failed to initialize the feature flag provider -', error);
    })
    .finally(() => {
      initVue();
    });
}

const loadInitialData = async () => {
  try {
    await store.dispatch('auth/bootstrapUserData');
  } catch (error) {
    console.error('Failed to load initial data', error);
    Sentry.captureException(error);
  }
};

const initVue = async () => {
  await loadInitialData();
  new Vue({
    vuetify,
    render: (h) => h(App),
    router,
    store,
    pinia,
  }).$mount('#app');
};
