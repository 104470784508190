<template>
  <div>
    <v-row no-gutters>
      <v-col
        cols="12"
        class="pb-0"
      >
        <AcaSelect
          id="degree"
          v-model="degree"
          name="degree"
          :items="computedDegreeItems"
          placeholder="Select your degree"
          label="Degree *"
          required
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AcaSelect from '@components/atoms/AcaSelect';

import { featureFlagsMixin, MILESTONE_IMPROVED_SIGNUP_ENABLED } from '@/mixins/featureFlagsMixin';

export default {
  components: {
    AcaSelect,
  },
  mixins: [
    featureFlagsMixin({
      [MILESTONE_IMPROVED_SIGNUP_ENABLED]: 'milestoneImprovedSignupEnabled',
    }),
  ],
  props: {
    degreeName: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    degree: null,
    degreeItems: ['M.D.', 'D.O.', 'M.D., Ph.D.', 'M.D., MPH', 'P.A.', 'N.P.', 'B.S.', 'B.A.', 'M.B.B.S', 'DPM'],
  }),
  computed: {
    computedDegreeItems() {
      const items = [...this.degreeItems];

      if (this.milestoneImprovedSignupEnabled) {
        items.push('R.N.');
        items.push('Other');
      } else {
        items.push('Other');
      }

      return items;
    },
  },
  watch: {
    degree: function () {
      this.$emit('degree', this.degree);
    },
  },
  mounted() {
    this.degree = this.degreeName;
  },
};
</script>
