<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 102 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M87.1936 43.647V65.0987C87.1949 65.7777 86.9755 66.4388 86.5686 66.9821C83.9198 70.5092 72.745 83.4126 51.0201 83.4126C29.2953 83.4126 18.1205 70.5092 15.4717 66.9821C15.0647 66.4388 14.8454 65.7777 14.8467 65.0987V43.647"
      fill="#263684"
    />
    <path
      d="M4.52038 38.1363C4.22887 37.9807 4.22887 37.5629 4.52038 37.4073L50.8256 12.6942C50.9472 12.6294 51.0931 12.6294 51.2147 12.6942L97.52 37.4073C97.8115 37.5629 97.8115 37.9807 97.52 38.1363L51.2147 62.8494C51.0931 62.9143 50.9472 62.9143 50.8256 62.8494L4.52038 38.1363Z"
      fill="url(#paint0_linear_698_555095)"
    />
    <path
      d="M74.6119 94.4302V50.3628L51.0205 37.7721"
      stroke="white"
      stroke-width="1.90287"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_698_555095"
        x1="3.83956"
        y1="37.7714"
        x2="98.2042"
        y2="37.7714"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#80B1FF" />
        <stop
          offset="1"
          stop-color="#21409A"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: '111px',
      type: String,
    },
    height: {
      default: '111px',
      type: String,
    },
  },
};
</script>
