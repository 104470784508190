import Vue from 'vue';
import Toasted from 'vue-toasted';

Vue.use(Toasted);

// register the toast with the custom message
let optionsError = {
  type: 'error',
  position: 'top-center',
  singleton: true,
  duration: 5000,
  fullWidth: true,
  containerClass: 'text text-l-large-regular',
  onComplete: toastFinished,
  className: 'custom-toast custom-toast-error',
  iconPack: 'callback',
  icon: (el) => {
    el.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="24" height="24" fill="var(--v-error-base)" class="icon-style-boundaries"> <g><line x1="128" y1="80" x2="128" y2="136" fill="none" stroke="var(--v-error-base)" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"></line> <path d="M164.5,32h-73a7.9,7.9,0,0,0-5.6,2.3L34.3,85.9A7.9,7.9,0,0,0,32,91.5v73a7.9,7.9,0,0,0,2.3,5.6l51.6,51.6a7.9,7.9,0,0,0,5.6,2.3h73a7.9,7.9,0,0,0,5.6-2.3l51.6-51.6a7.9,7.9,0,0,0,2.3-5.6v-73a7.9,7.9,0,0,0-2.3-5.6L170.1,34.3A7.9,7.9,0,0,0,164.5,32Z" fill="none" stroke="var(--v-error-base)" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"></path> <circle cx="128" cy="172" r="8"></circle></g></svg>';
    return el;
  },
};

Vue.toasted.register(
  'showError',
  (payload) => {
    ensureMaxAllowedToasts();

    // if there is no message passed show default message
    if (!payload.message) {
      return 'Oops.. Something Went Wrong..';
    }

    // if there is a message show it with the message
    return payload.message;
  },
  optionsError,
);

let optionsSuccess = {
  type: 'success',
  position: 'top-center',
  singleton: true,
  duration: 3000,
  fullWidth: true,
  containerClass: 'text text-l-large-regular',
  onComplete: toastFinished,
  className: 'custom-toast custom-toast-success',
  iconPack: 'callback',
  icon: (el) => {
    el.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="24" height="24" fill="var(--v-success-base)" class="icon-style-boundaries"> <g><polyline points="172 104 113.3 160 84 132" fill="none" stroke="var(--v-success-base)" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"></polyline> <circle cx="128" cy="128" r="96" fill="none" stroke="var(--v-success-base)" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"></circle></g></svg>';
    return el;
  },
};

Vue.toasted.register(
  'showSuccess',
  (payload) => {
    ensureMaxAllowedToasts();

    // if there is no message passed show default message
    if (!payload.message) {
      return 'Success!';
    }

    // if there is a message show it with the message
    return payload.message;
  },
  optionsSuccess,
);

function toastFinished() {
  ensureMaxAllowedToasts();
}

function ensureMaxAllowedToasts() {
  if (Vue.toasted.toasts.length >= 3) {
    Vue.toasted.toasts[0].goAway(100);
    Vue.toasted.toasts.shift();
  }
}
