<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 33 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3675 13.2002L16.2311 0L13.0936 13.2002L0.893555 16.3056L13.0936 19.4096L16.2311 33.3092L19.3675 19.4096L32.6317 16.3056L19.3675 13.2002Z"
      :fill="fill"
    />
    <defs>
      <linearGradient
        :id="linear"
        x1="32.6319"
        y1="16.3338"
        x2="0.893555"
        y2="16.3338"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFB018" />
        <stop
          offset="1"
          stop-color="#FFB018"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    id: {
      default: 'courses',
      type: String,
    },
    width: {
      default: '35px',
      type: String,
    },
    height: {
      default: '37px',
      type: String,
    },
  },
  computed: {
    fill() {
      return `url(#paint0_linear_911_108392_${this.id})`;
    },
    linear() {
      return `paint0_linear_911_108392_${this.id}`;
    }
  }
};
</script>
