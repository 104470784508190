import { mapGetters } from 'vuex';

export const articleBtnMixin = {
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('subscriptions', ['hasActiveSubscription']),
    ...mapGetters('menu', ['getCurrentReadingAtArticleId']),
    mainAction() {
      if (!this.currentUser && !this.hasActiveSubscription && this.$route.name === 'special-requirements-article-pdf') {
        return true;
      }

      if (!this.currentUser && !this.hasActiveSubscription && this.$route.name === 'special-requirements-article-pdf') {
        return true;
      }

      if (this.$route.name === 'article-file-view' && this.$route.query.quizRedirect) {
        return false;
      }

      return (
        this.getCurrentReadingAtArticleId &&
        (this.$route.name === 'article' ||
          this.$route.name === 'article-file-view' ||
          this.$route.name === 'auditor-article-file-view' ||
          this.$route.name === 'special-requirements-article-abstract' ||
          this.$route.name === 'special-requirements-article-pdf') &&
        !this.$route.params.itShouldBackToQuiz
      );
    },
    mainActionText() {
      if (this.$route.name === 'special-requirements-article-abstract') {
        return 'Read article';
      }

      if (this.$route.name === 'article') {
        return 'Get CME';
      }

      return 'Take quiz';
    },
    hasToShowMainAction() {
      if (!this.$vuetify.breakpoint.smAndUp) {
        return false;
      }

      return this.mainAction;
    },
    hasToShowMainActionForMobile() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return false;
      }

      return this.mainAction;
    },
  },
};
