<!-- TODO: make it molecule -->
<template>
  <v-menu
    ref="menu"
    class="aca-birthday-picker"
    :value="menuModel"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
    @input="$emit('update:menuModel', $event)"
  >
    <template #activator="{ on, attrs }">
      <AcaTextField
        id="dateOfBirth"
        :value="textFieldModel"
        :data-testid="textFieldDataTestid"
        :disabled="disabled"
        type="text"
        :name="name"
        placeholder="DD/MM/YY"
        :rules="rules"
        required
        :label="label"
        :autocomplete="'off'"
        readonly
        :class="[
          {
            'hide-details': hideDetails,
            'aca-birthday-picker-text-field-disabled-new': disabled,
          },
        ]"
        :hide-details="hideDetails"
        v-bind="attrs"
        v-on="on"
        @input="$emit('update:textFieldModel', $event)"
      />
    </template>
    <v-date-picker
      ref="datePicker"
      :value="datePickerModel"
      :active-picker.sync="activePicker"
      :picker-date.sync="pickerDateSelection"
      :max="maxPickerDate"
      :min="minPickerDate"
      no-title
      @input="$emit('update:datePickerModel', $event)"
      @change="save"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import * as Sentry from '@sentry/browser';

const DEFAULT_YEARS_AGO = 30;
const MAX_YEARS_AGO = 100;
const MIN_YEARS_AGO = 18;
const HAS_TO_SEND_ERROR_TO_SENTRY = true;

const convertDateToPickerDate = (date) => {
  const convertedDate = new Date(date);

  return convertedDate.toISOString().substring(0, 10);
};

const elementIsVisibleInParentViewport = (el, parent) => {
  const elRect = el.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();

  // Sometimes these numbers are the same, kind of the dom not being ready
  if (elRect.top === parentRect.top && elRect.bottom === parentRect.bottom) {
    throw new Error('The element and the parent have the same top and bottom');
  }

  return elRect.top >= parentRect.top && elRect.bottom <= parentRect.bottom;
};

const findElementByText = (text, elements) => {
  for (const element of elements) {
    const isTargetElement = element.innerText === text;
    if (!isTargetElement) continue;

    return element;
  }

  return null;
};

const goBackFromToday = ({ years }) => {
  const backDate = new Date();
  backDate.setFullYear(backDate.getFullYear() - years);

  return backDate;
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const sendErrorToSentry = (message, { hasRetry }) => {
  if (!HAS_TO_SEND_ERROR_TO_SENTRY) return;

  Sentry.captureMessage(`AcaBirthdayPicker Issue - ${message}`, (scope) => {
    scope.setContext('AcaBirthdayPicker', {
      hasRetry,
    });
  });
};

import AcaTextField from '@components/atoms/AcaTextField.vue';

export default {
  name: 'AcaBirthdayPicker',
  components: { AcaTextField },
  props: {
    datePickerModel: {
      type: String,
      default: '',
    },
    defaultDate: {
      type: Date,
      default: () => {
        return goBackFromToday({ years: DEFAULT_YEARS_AGO });
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    maxDate: {
      type: Date,
      default: function () {
        return goBackFromToday({ years: MIN_YEARS_AGO });
      },
    },
    menuModel: {
      type: Boolean,
      required: false,
      default: false,
    },
    minDate: {
      type: Date,
      default: function () {
        return goBackFromToday({ years: MAX_YEARS_AGO });
      },
    },
    name: {
      type: String,
      required: true,
      default: '',
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    textFieldDataTestid: {
      type: String,
      required: false,
      default: '',
    },
    textFieldModel: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activePicker: null,
      // Represents the date that the user is selecting in the datepicker, it could be a partial or a complete date
      pickerDateSelection: null,
      hasRetriedToScrollToDefaultYear: false,
    };
  },
  computed: {
    defaultPickerDate() {
      return convertDateToPickerDate(this.defaultDate);
    },
    defaultYear() {
      return this.defaultDate.getFullYear();
    },
    maxPickerDate() {
      return convertDateToPickerDate(this.maxDate);
    },
    minPickerDate() {
      return convertDateToPickerDate(this.minDate);
    },
    selectedYear() {
      return this.datePickerModel ? new Date(this.datePickerModel).getFullYear() : null;
    },
  },
  watch: {
    datePickerModel: {
      immediate: true,
      handler(newValue) {
        const isValidDate = !!newValue && !isNaN(new Date(newValue));
        if (!isValidDate) return;

        // We want the selection to be initially the one from the user
        this.pickerDateSelection = convertDateToPickerDate(newValue);
      },
    },
    defaultPickerDate: {
      immediate: true,
      handler(newValue) {
        if (!newValue) return;
        if (this.datePickerModel) return;

        // In case the user has not selected a date, we want the default date to be the one from the user
        this.pickerDateSelection = convertDateToPickerDate(newValue);
      },
    },
    menuModel(isOpenedMenu) {
      isOpenedMenu ? this.handleOpenedMenu() : this.handleClosedMenu();
    },
  },
  methods: {
    handleClosedMenu() {
      const isPartialDateSelection = !/^\d{4}-\d{2}-\d{2}$/.test(this.pickerDateSelection);

      // When the user selects a partial date,
      // we want to reset the selection to the value it had before opening the menu
      if (isPartialDateSelection) {
        this.pickerDateSelection = this.datePickerModel ?? this.defaultPickerDate;
      }
    },
    handleOpenedMenu() {
      // Using a timeout is the proposed solution by vuetify in their docs
      setTimeout(() => {
        this.activePicker = 'YEAR';

        this.scrollToLastSelectedYear();
      }, 1000);
    },
    retryScrollToDefaultYear(year) {
      if (this.hasRetriedToScrollToDefaultYear) return;

      this.hasRetriedToScrollToDefaultYear = true;
      this.scrollToYearWithDelay(year);
    },
    save(date) {
      // Closes the datepicker when the user selects the day
      this.$refs.menu.save(date);
    },
    // In case a year was never selected, this scrolls to the default year
    scrollToLastSelectedYear() {
      // Using a setTimeout was the only way I found to scroll to the default year
      // I tried with next tick, and checking if the html was present, but they didn't work
      setTimeout(() => {
        const targetYear = this.selectedYear ?? this.defaultYear;
        this.scrollToYear(targetYear);
      }, 100);
    },
    scrollToYear(year) {
      const $datePicker = this.$refs.datePicker?.$el;
      const $ul = $datePicker?.getElementsByClassName('v-date-picker-years')?.[0];
      if (!$ul) {
        console.warn('Was not possible to scroll to the default year, because ul elem was not found');
        sendErrorToSentry('Was not possible to scroll to the default year, because ul elem was not found', {
          hasRetry: this.hasRetriedToScrollToDefaultYear,
        });

        this.retryScrollToDefaultYear(year);
        return;
      }

      const $lis = $ul.getElementsByTagName('li');
      if (!$lis) {
        console.warn('Was not possible to scroll to the default year, because li elems were not found');
        sendErrorToSentry('Was not possible to scroll to the default year, because li elems were not found', {
          hasRetry: this.hasRetriedToScrollToDefaultYear,
        });

        this.retryScrollToDefaultYear(year);
        return;
      }

      const targetElement = findElementByText(year.toString(), $lis);
      if (!targetElement) {
        console.warn('Was not possible to scroll to the default year, because the target element was not found');
        sendErrorToSentry('Was not possible to scroll to the default year, because the target element was not found', {
          hasRetry: this.hasRetriedToScrollToDefaultYear,
        });

        this.retryScrollToDefaultYear(year);
        return;
      }

      try {
        if (elementIsVisibleInParentViewport(targetElement, $ul)) return;

        targetElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
      } catch (error) {
        console.warn('Was not possible to scroll to the default year, because of the following error', error);
        sendErrorToSentry('Was not possible to scroll to the default year, because of the viewport error', {
          hasRetry: this.hasRetriedToScrollToDefaultYear,
        });

        this.retryScrollToDefaultYear(year);
      }
    },
    async scrollToYearWithDelay(year, { delayTimeInMs } = { delayInMs: 1000 }) {
      await delay(delayTimeInMs);
      this.scrollToYear(year);
    },
  },
};
</script>

<style lang="scss" scoped>
.aca-birthday-picker-text-field-disabled {
  ::v-deep {
    .v-input__slot,
    input {
      cursor: default !important;
    }

    fieldset {
      border: 1px solid var(--v-grey-lighten1) !important;
      background-color: var(--v-background-base) !important;
    }
  }
}

.aca-birthday-picker-text-field-disabled-new {
  ::v-deep {
    .v-input__slot,
    input {
      cursor: default !important;
    }
  }
}

.aca-birthday-picker .hide-details ::v-deep .v-input__slot {
  margin-bottom: 0px;
}
</style>
