import axios from 'axios';
import { toastHandler } from '@/api/utilities';
import { responseHandler, getResponseDataNotWrapped, getResponseDataVOld } from '@/api/utilities';

const doLogin = async (form) => {
  return responseHandler(axios.post('/login', form));
};

const doLoginWithSocialProvider = async ({ provider, providerResponse }) => {
  return responseHandler(axios.post('/socialite/' + provider, providerResponse));
};

const getUser = () => {
  return axios.get('/user');
};

const doLogout = async () => {
  return responseHandler(axios.post('/logout'));
};

const doCheckPassword = async (params) => {
  return responseHandler(axios.post('/check-password', params), {
    responseDataGetter: getResponseDataNotWrapped,
    hasToShowSuccessToasts: false,
  });
};

const doChangePassword = async (params) => {
  return responseHandler(axios.post('/confirm-password', params), {
    responseDataGetter: getResponseDataVOld,
    hasToShowToasts: false,
  });
};

const doSendPasswordReset = async (params) => {
  return axios.post('/send-password-reset', params);
};

const doCheckEmailExistence = async (params) => {
  return responseHandler(axios.post('/email-exists', params), {
    responseDataGetter: getResponseDataVOld,
  });
};

const doCheckEmailForResetPassword = async (params) => {
  return axios.post('/email-exists-reset-password', params);
};

const doVerifyResetPasswordToken = async (params) => {
  return responseHandler(axios.post('/password-reset/token', params), {
    responseDataGetter: getResponseDataVOld,
    hasToShowToasts: false,
  });
};

const doSendConfirmationCode = async (params) => {
  return responseHandler(axios.post('/send-confirmation-code', params), {
    responseDataGetter: getResponseDataNotWrapped,
    hasToShowSuccessToasts: true,
  });
};

const doVerifyPhoneNumber = async (params) => {
  return toastHandler(axios.post('/verify-phone-number', params), true);
};

const doRegister = async (params) => {
  return responseHandler(axios.post('/register', params), {
    responseDataGetter: getResponseDataVOld,
  });
};

const doSendDeleteCode = async () => {
  return toastHandler(axios.post('/user/email/code/delete'), true);
};

const doConfirmDeleteCode = async (params) => {
  return toastHandler(axios.post('/user/email/code/delete/verify', params), true);
};

const doSendCodeToPhone = async () => {
  return toastHandler(axios.post('/user/profile/phone/code'), true);
};

const doVerifyPhoneCode = async (params) => {
  return toastHandler(axios.post('/user/profile/phone/code/verify', params), true);
};

const doSendCodeToEmail = async () => {
  return toastHandler(axios.post('/user/email/code'), true);
};

const doVerifyEmailCode = async (params) => {
  return toastHandler(axios.post('/user/email/code/verify', params), true);
};

const doGetStates = async () => {
  return responseHandler(axios.get('/states'), {
    responseDataGetter: getResponseDataNotWrapped,
    hasToShowSuccessToasts: false,
  });
};

export {
  doLogin,
  doLoginWithSocialProvider,
  getUser,
  doLogout,
  doCheckPassword,
  doChangePassword,
  doSendPasswordReset,
  doCheckEmailExistence,
  doCheckEmailForResetPassword,
  doVerifyResetPasswordToken,
  doSendConfirmationCode,
  doVerifyPhoneNumber,
  doRegister,
  doSendDeleteCode,
  doConfirmDeleteCode,
  doSendCodeToPhone,
  doVerifyPhoneCode,
  doSendCodeToEmail,
  doVerifyEmailCode,
  doGetStates,
};
