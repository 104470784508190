var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.finishInitLoad,
      expression: "finishInitLoad"
    }],
    staticClass: "px-sm-2 px-md-4",
    attrs: {
      "no-gutters": _vm.$route.name !== 'home' || _vm.$route.name === 'home' && _vm.$vuetify.breakpoint.xsOnly,
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "card-alert border-radius-general pa-4 mt-6 mt-sm-12",
    class: _vm.getWidthAccordingToRoute
  }, [_c('div', {
    staticClass: "d-flex justify-space-between",
    class: [{
      'flex-wrap': _vm.$vuetify.breakpoint.xsOnly
    }]
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "d-flex flex-start",
    class: [{
      'align-center': _vm.$vuetify.breakpoint.lgAndUp || _vm.$route.name === 'home' && _vm.$vuetify.breakpoint.smAndUp
    }]
  }, [_c('ph-warning-octagon', {
    staticClass: "clickable",
    attrs: {
      "weight": "light",
      "size": "28",
      "color": "var(--v-error-base)"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-center pl-2 pl-md-3 pr-sm-4"
  }, [_c('h3', {
    staticClass: "text text-l-large-regular error--text text--error--base"
  }, [_vm._v(" " + _vm._s(_vm.getMsgForAlertExpire) + " ")])])]), _c('div', {
    staticClass: "d-flex align-center",
    class: [{
      'pt-6': _vm.$vuetify.breakpoint.xsOnly
    }, {
      'flex-grow-1': _vm.$route.name !== 'home' || _vm.$vuetify.breakpoint.xsOnly
    }]
  }, [_c('v-btn', {
    staticClass: "heading heading-s-small error--text text--error--base shadow-dark-button flex-grow-1",
    attrs: {
      "ripple": "",
      "depresseds": "",
      "height": "36px",
      "color": "var(--v-error-surface-base)"
    },
    on: {
      "click": _vm.updateCard
    }
  }, [_vm._v(" Update card ")])], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }