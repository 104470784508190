import {
  doGetProfileDetails,
  doUpdateEmail,
  doGetUserPaymentMethod,
  doUpdateBillingAddress,
  doDeletePaymentMethod,
  doPostPaymentMethod,
  doGetPaymentHistory,
  doGetPaymentReceiptPdf,
  doUpdateProfileNames,
  doRequestNamesChange,
} from '@/api/profile';
import { isSuccessfulResponse } from '@/api/utilities';
import Vue from 'vue';

import { getFeatureFlagProvider, MILESTONE_COMPLETE_PROFILE_CERTIFICATES_ENABLED } from '@/lib/featureFlag';

const featureFlagProvider = getFeatureFlagProvider();

const degreesNeedingFullBoardInformation = ['M.D.', 'D.O.', 'M.D., Ph.D.', 'M.D., MPH'];
const medicalBoardsWithOptionalBoardInformation = ['Self-Report to My Medical Board', 'I do not see my board'];

export default {
  namespaced: true,
  state: () => ({
    profile: null,
    paymentMethod: null,
    paymentMethodWillExpire: false,
    paymentMethodExpired: false,
    isLoadingSubmitPayment: false,
    isPaymentFieldsUncomplete: false,
    stripeFieldsCompleted: false,
    hasToShowDefaultPaymentMethod: true,
    msgForAlertExpire: '',
    shouldShowCompleteProfileDialog: false,
  }),
  mutations: {
    setProfile(state, data) {
      state.profile = data;
    },
    setPaymentMethod(state, data) {
      state.paymentMethod = data;
    },
    setPaymentMethodWillExpire(state, data) {
      state.paymentMethodWillExpire = data;
    },
    setPaymentMethodExpired(state, data) {
      state.paymentMethodExpired = data;
    },
    setIsLoadingSubmitPayment(state, data) {
      state.isLoadingSubmitPayment = data;
    },
    setIsPaymentFieldsUncomplete(state, data) {
      state.isPaymentFieldsUncomplete = data;
    },
    setStripeFieldsCompleted(state, data) {
      state.stripeFieldsCompleted = data;
    },
    setHasToShowDefaultPaymentMethod(state, data) {
      state.hasToShowDefaultPaymentMethod = data;
    },
    setMsgForAlertExpire(state, data) {
      state.msgForAlertExpire = data;
    },
    setShouldShowCompleteProfileDialog(state, data) {
      state.shouldShowCompleteProfileDialog = data;
    },
  },
  actions: {
    async getProfile(context) {
      try {
        const response = await doGetProfileDetails();

        if (isSuccessfulResponse(response)) {
          Vue.prototype.$eventNotifier.setUser({
            currentPlanName: response.data.currentPlan?.planName ?? 'Free Trial',
          });

          context.commit('setProfile', response.data);
        }
      } catch (error) {
        context.commit('setProfile', null);
      }
    },
    async updateEmail(context, payload) {
      let response = await doUpdateEmail(payload);

      if (isSuccessfulResponse(response)) {
        await context.dispatch('auth/getUser', null, { root: true });
      }

      return response;
    },
    async getUserPaymentMethod(context) {
      let response = await doGetUserPaymentMethod();

      if (isSuccessfulResponse(response)) {
        context.commit('setPaymentMethod', response.data);

        if (!response.data?.id) {
          context.commit('setHasToShowDefaultPaymentMethod', false);
        }
        return response.data;
      }

      return response.error;
    },
    async updateBillingAddress(context, payload) {
      let response = await doUpdateBillingAddress(payload);

      if (isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    },
    async deletePaymentMethod(context) {
      let response = await doDeletePaymentMethod();

      if (isSuccessfulResponse(response)) {
        context.commit('setPaymentMethod', null);
        return response.data;
      }

      return response.error;
    },
    async addPaymentMethod(context, payload) {
      let response = await doPostPaymentMethod(payload);

      if (isSuccessfulResponse(response)) {
        context.commit('setPaymentMethod', response.data);
        return response.data;
      }

      return response.error;
    },
    async getPaymentHistory() {
      let response = await doGetPaymentHistory();

      if (isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    },
    async getPaymentReceiptPdf(context, payload) {
      let response = await doGetPaymentReceiptPdf(payload);

      if (isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    },
    async updateProfileNames(context, payload) {
      let response = await doUpdateProfileNames(payload);

      if (isSuccessfulResponse(response)) {
        await context.dispatch('getProfile');
        return response;
      }

      return response.error;
    },
    async checkIfPaymentMethodWillExpire(context) {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      const paymentMethod = await context.dispatch('getUserPaymentMethod');
      const YEAR_CYCLE = 12;

      if (paymentMethod.card) {
        const expirationMonth = paymentMethod.card.exp_month;
        const expirationYear = paymentMethod.card.exp_year;
        const expirationYearsToCurrent = expirationYear - currentYear;
        const expirationMonthsToCurrent = expirationMonth - currentMonth;

        // works for cases with current date july 2028 with expiration date december 2024
        // works for cases with currrent date january 2025 with expiration date december 2024
        // works for cases with current date August 2024 with expiration date July 2024
        if (currentYear > expirationYear || (currentYear === expirationYear && currentMonth > expirationMonth)) {
          context.commit('setPaymentMethodWillExpire', true);
          context.commit('setPaymentMethodExpired', true);
          context.commit('setMsgForAlertExpire', 'Your payment method has already expired');
          return;
        }

        // works for cases with current date april 2024 with expiration date december 2024
        // works for cases with current date april 2024 with expiration date may 2024
        // works for cases with current date april 2024 with expiration date april 2024
        if (expirationYearsToCurrent <= 0 && expirationMonthsToCurrent < YEAR_CYCLE) {
          context.commit('setPaymentMethodWillExpire', true);
          context.commit('setMsgForAlertExpire', 'Your payment method will expire next billing cycle');
          return;
        }

        // works for cases with current date december 2024 with expiration date december 2025
        if (expirationYearsToCurrent <= 1 && expirationMonthsToCurrent <= 0) {
          context.commit('setPaymentMethodWillExpire', true);
          context.commit('setMsgForAlertExpire', 'Your payment method will expire next billing cycle');
          return;
        }

        context.commit('setPaymentMethodWillExpire', false);
      } else {
        context.commit('setPaymentMethodWillExpire', false);
      }
    },
    async requestNamesChange(context, payload) {
      let response = await doRequestNamesChange(payload);

      if (isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    },
    unsetProfile(context) {
      context.commit('setProfile', null);
    },
    showCompleteProfileDialog(context) {
      context.commit('setShouldShowCompleteProfileDialog', true);
    },
    /**
     * Shows the complete profile dialog if the user has an incomplete profile.
     *
     * @param {Object} context - The Vuex store context object.
     * @returns {boolean} - Returns `true` if the complete profile dialog should be shown, otherwise `false`.
     */
    showCompleteProfileDialogIfNeeded(context) {
      if (!featureFlagProvider.isFeatureEnabled(MILESTONE_COMPLETE_PROFILE_CERTIFICATES_ENABLED)) return false;
      if (!context.getters.hasIncompleteProfile) return false;

      context.dispatch('showCompleteProfileDialog');
      return true;
    },
    hideCompleteProfileDialog(context) {
      context.commit('setShouldShowCompleteProfileDialog', false);
    },
    unsetPaymentWillExpire(context) {
      context.commit('setPaymentMethodWillExpire', false);
      context.commit('setMsgForAlertExpire', '');
    },
    loadingSubmitPayment(context, payload) {
      context.commit('setIsLoadingSubmitPayment', payload);
    },
    paymentFieldsUncomplete(context, payload) {
      context.commit('setIsPaymentFieldsUncomplete', payload);
    },
    stripeFieldsCompleted(context, payload) {
      context.commit('setStripeFieldsCompleted', payload);
    },
    setShowDefaultPaymentMethod(context, payload) {
      context.commit('setHasToShowDefaultPaymentMethod', payload);
    }
  },
  getters: {
    currentProfile: (state) => {
      return state.profile;
    },
    currentPaymentMethod: (state) => {
      return state.paymentMethod;
    },
    hasPaymentMethod: (state, { currentPaymentMethod }) => {
      return (
        currentPaymentMethod !== null &&
        currentPaymentMethod !== undefined &&
        currentPaymentMethod.billing_details !== undefined
      );
    },
    willPaymentMethodExpire: (state) => {
      return state.paymentMethodWillExpire;
    },
    isPaymentMethodExpired: (state) => {
      return state.paymentMethodExpired;
    },
    isLoadingSubmitPayment: (state) => {
      return state.isLoadingSubmitPayment;
    },
    isPaymentFieldsUncomplete: (state) => {
      return state.isPaymentFieldsUncomplete;
    },
    isStripeFieldsCompleted: (state) => {
      return state.stripeFieldsCompleted;
    },
    showDefaultPaymentMethod: (state) => {
      return state.hasToShowDefaultPaymentMethod;
    },
    getMsgForAlertExpire: (state) => {
      return state.msgForAlertExpire;
    },
    hasIncompleteProfile: (
      state,
      { userDegreeNeedsFullBoardInformation, userMedicalBoardNeedsFullBoardInformation, hasIncompleteBoardInformation },
    ) => {
      if (!userDegreeNeedsFullBoardInformation) return false;
      if (!userMedicalBoardNeedsFullBoardInformation) return false;

      return hasIncompleteBoardInformation;
    },
    userDegreeNeedsFullBoardInformation: (state, { currentProfile }) => {
      return degreesNeedingFullBoardInformation.includes(currentProfile?.degree);
    },
    userMedicalBoardNeedsFullBoardInformation: (state, { currentProfile }) => {
      return !medicalBoardsWithOptionalBoardInformation.includes(currentProfile?.medicalBoard?.name);
    },
    hasIncompleteBoardInformation: (state, { hasBoardId, hasDateOfBirth }) => {
      return !hasBoardId || !hasDateOfBirth;
    },
    hasBoardId(state, { currentProfile }) {
      return !!currentProfile?.medicalBoard?.board_number;
    },
    hasDateOfBirth(state, { currentProfile }) {
      const dateOfBirth = currentProfile?.dateOfBirth;
      return !!dateOfBirth && dateOfBirth !== 'Unknown';
    },
    shouldShowCompleteProfileDialog: (state) => {
      return state.shouldShowCompleteProfileDialog;
    },
  },
};
