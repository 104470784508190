import { doGetBoards, doUpdateMedicalBoard } from '@/api/boards';

export default {
  namespaced: true,
  state: () => ({
    boards: [],
  }),
  mutations: {
    setBoards(state, boards) {
      state.boards = boards;
    },
  },
  actions: {
    async getBoards(context, payload) {
      if (context.state.boards.length > 0) return context.state.boards;

      const { boards } = await doGetBoards(payload);
      context.commit('setBoards', boards);

      return boards;
    },
    updateMedicalBoard(_context, payload) {
      return doUpdateMedicalBoard(payload);
    },
  },
};
