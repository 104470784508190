var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-left"
  }, [_vm.milestoneImprovedSignupEnabled ? _c('DegreeComponent', {
    attrs: {
      "degree-name": _vm.degree
    },
    on: {
      "degree": _vm.onChangeDegree
    }
  }) : _vm._e(), _vm.hasToShowMedicalBoard ? _c('AcaSelect', {
    attrs: {
      "id": "medical-board",
      "disabled": !_vm.enabledMedicalBoard,
      "items": _vm.medicalBoardItems,
      "item-text": "name",
      "item-value": "id",
      "placeholder": "Select medical board",
      "label": "Select medical board",
      "required": ""
    },
    on: {
      "input": _vm.changeMedicalBoard
    },
    model: {
      value: _vm.medicalBoard,
      callback: function ($$v) {
        _vm.medicalBoard = $$v;
      },
      expression: "medicalBoard"
    }
  }) : _vm._e(), _c('div', {
    class: {
      'profile-dialog-board-id': _vm.isDialog
    }
  }, [_vm.hasToShowBoardId ? _c('AcaTextField', {
    attrs: {
      "id": "boardId",
      "disabled": !_vm.enabledBoardId,
      "type": "text",
      "name": "boardId",
      "placeholder": "Enter Board ID",
      "rules": [],
      "required": "",
      "label": 'Board ID ' + (_vm.showRequiredAsterisk ? '*' : ''),
      "autocomplete": 'off'
    },
    model: {
      value: _vm.localBoardNumber,
      callback: function ($$v) {
        _vm.localBoardNumber = $$v;
      },
      expression: "localBoardNumber"
    }
  }) : _vm._e(), _vm.hasToShowDoB ? _c('AcaBirthdayPicker', {
    key: _vm.customKey + _vm.counterKeyDateOfBirth,
    attrs: {
      "date-picker-model": _vm.localDateOfBirth,
      "disabled": !_vm.enabledDateOfBirth,
      "menu-model": _vm.menuForDate,
      "label": _vm.showRequiredAsterisk ? 'Date of Birth *' : 'Date of Birth',
      "name": "boardDateOfBird",
      "rules": [],
      "text-field-data-testid": "boardDateOfBirdInput",
      "text-field-model": _vm.computedDateFormatted
    },
    on: {
      "update:datePickerModel": function ($event) {
        _vm.localDateOfBirth = $event;
      },
      "update:date-picker-model": function ($event) {
        _vm.localDateOfBirth = $event;
      },
      "update:menuModel": function ($event) {
        _vm.menuForDate = $event;
      },
      "update:menu-model": function ($event) {
        _vm.menuForDate = $event;
      },
      "update:textFieldModel": function ($event) {
        _vm.computedDateFormatted = $event;
      },
      "update:text-field-model": function ($event) {
        _vm.computedDateFormatted = $event;
      }
    }
  }) : _vm._e()], 1)], 1)])], 1), !_vm.isProfile && !_vm.isDialog ? _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "container-actions",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-actions', {
    staticClass: "item-action pa-0"
  }, [_c('v-btn', {
    staticClass: "heading heading-s-small white--text primary shadow-dark-button",
    attrs: {
      "ripple": "",
      "block": "",
      "large": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('submit-form');
      }
    }
  }, [_vm._v(" Continue ")])], 1), _c('v-card-actions', {
    staticClass: "item-action pa-0"
  }, [_c('v-btn', {
    staticClass: "heading heading-s-small primary--text shadow-light-button",
    attrs: {
      "ripple": "",
      "block": "",
      "large": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('go-back');
      }
    }
  }, [_vm._v(" Go back ")])], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }