var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasToShowAlert ? _c('div', {
    staticClass: "alert-container"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_vm.hasToShowIcon ? _c('ph-info', {
    attrs: {
      "size": "24",
      "weight": "light"
    }
  }) : _vm._e(), _c('span', {
    staticClass: "text",
    class: [{
      'alert-text--ml': _vm.hasToShowIcon
    }, _vm.$breakpoint.tabletAndUp ? 'text-l-large-regular' : 'text-m-large-regular']
  }, [_vm._v(" You need a paid account to access premium courses ")]), _vm.isGuestUser ? _c('v-btn', {
    staticClass: "heading heading-s-small primary--text shadow-light-button",
    class: {
      'ml-2 px-4': _vm.$breakpoint.mobileAndDown,
      'ml-4 px-6': _vm.$breakpoint.tabletAndUp
    },
    attrs: {
      "ripple": "",
      "depressed": "",
      "height": "36px",
      "width": "auto"
    },
    on: {
      "click": _vm.handleClickSignup
    }
  }, [_vm.hasToShowCrownIcon ? _c('crown-filled-yellow-icon', {
    staticClass: "mr-2"
  }) : _vm._e(), _vm._v(" Go Pro ")], 1) : _vm._e(), _vm.isLoggedInUser ? _c('v-btn', {
    staticClass: "heading heading-s-small primary--text shadow-light-button",
    class: {
      'ml-2 px-4': _vm.$breakpoint.mobileAndDown,
      'ml-4 px-6': _vm.$breakpoint.tabletAndUp
    },
    attrs: {
      "ripple": "",
      "depressed": "",
      "height": "36px",
      "width": "auto"
    },
    on: {
      "click": _vm.handleClickUpgrade
    }
  }, [_vm.hasToShowCrownIcon ? _c('crown-filled-yellow-icon', {
    staticClass: "mr-2"
  }) : _vm._e(), _vm._v(" Upgrade " + _vm._s(_vm.$breakpoint.tabletAndUp ? 'plan' : '') + " ")], 1) : _vm._e()], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }