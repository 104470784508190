import store from '@/store';

export const ensureActiveSubscriptionGuard = async (to, from, next) => {
  const hasFreeTrial = store.getters['subscriptions/hasFreeTrial'];

  hasFreeTrial ? next({ name: 'home' }) : next();
};

export const ensureUserIsGuest = async (to, from, next) => {
  const isGuestUser = store.getters['auth/isGuestUser'];

  isGuestUser ? next() : next({ name: 'home' });
};

export const ensurePlanSelectedFromPricingPage = async (to, from, next) => {
  const planSelected = store.getters['subscriptions/planSelected'];

  const isPlanSelectedFromPricingPage = planSelected && from.name === 'register';

  if (!isPlanSelectedFromPricingPage) {
    store.dispatch('subscriptions/selectPlanForCheckout', null);
  }

  next();
};
