<!-- DEPRECATED: Use the src/pages/layouts/app-bar/AvatarBadgeComponent.vue instead -->
<template>
  <div class="d-flex align-center">
    <a
      v-if="
        !hasToDisabledQuizButton &&
        ($route.name === 'article-file-view' || $route.name === 'special-requirements-article-pdf')
      "
      class="clickable d-flex align-center text-decoration-none"
      :class="[$vuetify.breakpoint.xsOnly ? '' : 'pr-8']"
      :href="currentDisclosurePdfUrl"
      target="_blank"
      rel="noopener"
    >
      <ph-file-pdf
        class="pr-2"
        size="24"
        color="var(--v-primary-base)"
        weight="light"
      />
      <h3 class="heading heading-s-small primary--text">Disclosure</h3>
    </a>
    <div
      v-if="currentUser && !isAuditor && !getHideCreditsInMenu"
      class="px-2 border-radius-small shadow-badge"
      :class="{
        'mr-4': hasToShowMainAction,
      }"
    >
      <v-row no-gutters>
        <v-col class="col-12 d-flex align-items-center">
          <router-link
            class="text-decoration-none"
            to="/transcripts"
          >
            <div class="d-flex align-center py-1">
              <ph-graduation-cap
                v-if="!hasToShowUnawardedBadge"
                weight="light"
                :size="$vuetify.breakpoint.smAndUp ? 28 : 24"
                class="white--text circle-icon-avatar cold-gradient"
              />
              <ph-warning
                v-if="hasToShowUnawardedBadge"
                weight="light"
                :size="$vuetify.breakpoint.smAndUp ? 28 : 24"
                class="white--text circle-icon-avatar hot-gradient"
              />
              <h3 class="text text-m-large-bold grey--text text--darken-4 pl-1 mb-0">
                {{ awardedCreditsToShow | creditsDecimalNotation }}
              </h3>
              <h3
                v-if="$vuetify.breakpoint.smAndUp"
                class="text text-l-small-regular grey--text text--darken-4 pl-1 mb-0"
              >
                CME
              </h3>
              <h3
                v-if="$breakpoint.mobileAndUp"
                class="text text-l-small-regular grey--text text--darken-4 pl-1 mb-0"
              >
                {{ awardedCreditsToShow === 1 ? 'credit' : 'credits' }}
              </h3>
            </div>
          </router-link>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="!currentUser"
      class="d-flex clickable align-center"
    >
      <router-link
        v-if="hasToShowLoginButton"
        :to="{ name: 'login' }"
        class="heading heading-s-small primary--text mr-sm-8 text-decoration-none"
        :class="[{ 'pr-3': $vuetify.breakpoint.width < 768 }]"
        @click.native="handleClickLoginLink"
      >
        Log in
      </router-link>
      <v-btn
        v-if="hasToShowSignUpButton"
        :to="{ name: 'register' }"
        :width="'104px'"
        height="36px"
        ripple
        depressed
        color="primary white--text"
        class="shadow-dark-button px-5 mr-2"
        :class="[$vuetify.breakpoint.smAndUp ? 'heading heading-s-small' : 'text text-m-large-regular']"
      >
        Sign up
      </v-btn>
    </div>

    <!-- for article actions like get cme, take quiz -->
    <div v-if="hasToShowMainAction">
      <v-btn
        :disabled="hasToDisabledQuizButton"
        ripple
        block
        width="auto"
        height="36px"
        class="heading heading-s-small white--text shadow-dark-button"
        :class="{ 'cold-gradient': !hasToDisabledQuizButton }"
        data-testid="submit"
        @click="handleClickMainAction"
      >
        {{ mainActionText }}
      </v-btn>
    </div>
    <!-- this is four courses only -->
    <abstract-header-actions></abstract-header-actions>
  </div>
</template>

<script>
import { PhGraduationCap, PhWarning, PhFilePdf } from 'phosphor-vue';
import { mapGetters, mapActions } from 'vuex';
import AbstractHeaderActions from '@/components/premiumCourses/abstract/AbstractHeaderActions';
import { specialReqsMixin } from '@/mixins/specialReqsMixin';
import { articleBtnMixin } from '@/mixins/articleBtnMixin';

export default {
  name: 'AvatarBadge',
  components: {
    AbstractHeaderActions,
    PhGraduationCap,
    PhWarning,
    PhFilePdf,
  },
  mixins: [specialReqsMixin, articleBtnMixin],
  props: ['hasToShowUnawardedBadge', 'awardedCreditsToShow', 'isAuditor', 'hasToDisabledQuizButton'],
  computed: {
    ...mapGetters('feed', ['isOnboardingPretestToShowAgain']),
    ...mapGetters('subscriptions', ['hasActiveSubscription']),
    ...mapGetters('menu', ['getHideCreditsInMenu']),
    hasToShowLoginButton() {
      return (
        this.$route.name === 'article' ||
        this.$route.name === 'article-quiz' ||
        this.$route.name === 'home' ||
        this.$route.name === 'premium-courses-feed' ||
        this.$route.name === 'course-abstract' ||
        this.$route.name === 'special-requirements' ||
        this.$route.name === 'special-requirements-topic-state-feed' ||
        this.$route.name === 'special-requirements-article-abstract' ||
        this.$route.name === 'special-requirements-course-abstract'
      );
    },
    hasToShowSignUpButton() {
      const isArticleQuizRoute = this.$route.name === 'article-quiz';
      const isPremiumCourseRouteWithSignupButton = this.$route.name === 'course-abstract';

      if (!isArticleQuizRoute && !isPremiumCourseRouteWithSignupButton) {
        return false;
      }

      return this.$vuetify.breakpoint.smAndUp;
    },
    currentDisclosurePdfUrl() {
      if (this.getCurrentReadingAtArticleId) {
        return `/article/${this.getCurrentReadingAtArticleId}/disclosure/pdf`;
      }

      return null;
    },
  },
  methods: {
    ...mapActions('menu', [
      'hideTopMenu',
      'setTopMenuTitle',
      'setIsFromFeedView',
      'hideCreditsInNavBar',
      'takeQuizButtonDisabledInNavBar',
    ]),
    ...mapActions('feed', ['showOnboardingPretest', 'showPretestUser']),
    ...mapActions('article', ['fetchArticleQuizForPretest']),
    goToLogin() {
      this.$emit('go-to-login');
    },
    goToSignup() {
      this.$emit('go-to-signup');
    },
    handleClickMainAction() {
      if (this.$route.name === 'article' || this.$route.name === 'special-requirements-article-abstract') {
        this.goToReadArticlePdf();
        return;
      }

      if (this.isAuditor) {
        this.$router.push({ name: 'auditor-article-quiz', params: { id: this.getCurrentReadingAtArticleId } });
        return;
      }

      if (this.$route.name === 'special-requirements-article-pdf') {
        this.prepareLayoutForQuiz();
        this.$router.push({
          name: 'special-requirements-article-quiz',
          params: { id: this.getCurrentReadingAtArticleId },
        });
        return;
      }

      if (this.$route.name === 'article-file-view') {
        this.prepareLayoutForQuiz();
        this.$router.push({ name: 'article-quiz', params: { id: this.getCurrentReadingAtArticleId } });
        return;
      }
    },
    prepareLayoutForQuiz() {
      this.hideCreditsInNavBar(false);
      this.takeQuizButtonDisabledInNavBar(false);
      document.querySelector(':root').classList.remove('no-scroll');
    },
    async goToReadArticlePdf() {
      this.showArticleFileView();
    },
    showArticleFileView() {
      this.setIsFromFeedView(true);
      this.hideTopMenu(true);

      if (this.hasSpecialRequirements) {
        this.$router.push({
          name: 'special-requirements-article-pdf',
          params: { id: this.getCurrentReadingAtArticleId },
        });
        return;
      }
      this.setTopMenuTitle('Back to Feed');
      this.$router.push({ name: 'article-file-view', params: { id: this.getCurrentReadingAtArticleId } });
    },
    handleClickLoginLink() {
      // TODO: refactor to re use it
      this.$eventNotifier.notify('click_login', {
        eventCategory: 'Login',
        eventLabel: 'Navbar Login Link',
      });
    },
  },
};
</script>

<style scoped>
.credit-box {
  display: flex;
  align-items: center;
  border-radius: 4px;
  height: 34px;
}

.credit-box-unawarded {
  background-color: #f0f0f3;
}

.credit-box-awarded {
  background-color: #90a4ae;
}

.credit-image {
  line-height: 1;
}

div.credit-box div.credit-number-state-awarded {
  line-height: 1 !important;
  color: white !important;
}

div.credit-box div.credit-number-state-unawarded {
  line-height: 1 !important;
  color: #fb8c00 !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.circle-icon-avatar {
  background-color: var(--v-primary-base);
  border-radius: 50%;
  padding: 3.5px;
  padding-left: 4px;
  height: 24px;
}

@media (min-width: 768px) {
  .circle-icon-avatar {
    height: 28px;
  }
}
</style>
