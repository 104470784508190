<template>
  <!-- TODO: we need to refactor this conditional -->
  <div
    v-if="hasToShowAlert"
    class="alert-container"
  >
    <div class="d-flex align-center">
      <ph-info
        v-if="hasToShowIcon"
        size="24"
        weight="light"
      />
      <span
        class="text"
        :class="[
          { 'alert-text--ml': hasToShowIcon },
          $breakpoint.tabletAndUp ? 'text-l-large-regular' : 'text-m-large-regular',
        ]"
      >
        You need a paid account to access premium courses
      </span>
      <v-btn
        v-if="isGuestUser"
        class="heading heading-s-small primary--text shadow-light-button"
        :class="{
          'ml-2 px-4': $breakpoint.mobileAndDown,
          'ml-4 px-6': $breakpoint.tabletAndUp,
        }"
        ripple
        depressed
        height="36px"
        width="auto"
        @click="handleClickSignup"
      >
        <crown-filled-yellow-icon
          v-if="hasToShowCrownIcon"
          class="mr-2"
        ></crown-filled-yellow-icon>
        Go Pro
      </v-btn>
      <v-btn
        v-if="isLoggedInUser"
        class="heading heading-s-small primary--text shadow-light-button"
        :class="{
          'ml-2 px-4': $breakpoint.mobileAndDown,
          'ml-4 px-6': $breakpoint.tabletAndUp,
        }"
        ripple
        depressed
        height="36px"
        width="auto"
        @click="handleClickUpgrade"
      >
        <crown-filled-yellow-icon
          v-if="hasToShowCrownIcon"
          class="mr-2"
        ></crown-filled-yellow-icon>
        Upgrade {{ $breakpoint.tabletAndUp ? 'plan' : '' }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { PhInfo } from 'phosphor-vue';
import CrownFilledYellowIcon from '@/assets/CrownFilledYellowIcon.vue';
import { upgradeAlertsMixin } from '@/mixins/upgradeAlertsMixin';

export default {
  name: 'PremiumAlert',
  components: {
    PhInfo,
    CrownFilledYellowIcon,
  },
  mixins: [upgradeAlertsMixin],
  computed: {
    /**
     * WARNING: Stop adding new routes here. Instead, migrate the page using the new Layout components
     * and use the PremiumCourseUpgradeNotificationBanner instead.
     */
    hasToShowAlert() {
      return (
        (this.isCourseAbstractRoute ||
          (this.isCourseRoute && this.isFreeUser) ||
          this.isSpecialRequirementCourseAbstractRoute) &&
        this.hasToShowProIndicator
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-container {
  position: sticky;
  top: 56px;
  z-index: 1;

  min-height: 44px;
  padding: 8px 24px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--v-secondary-base);
  color: white;
  font-weight: 400;
  line-height: 1.25rem;
}

@media #{map-get($display-breakpoints, 'small-desktop-and-up')} {
  .alert-container {
    top: 60px;
  }
}

.alert-text--ml {
  margin-left: 11px;
}
</style>
