<template>
  <div class="illustrations-container">
    <div v-if="$breakpoint.tabletAndUp">
      <StarComponent
        class="star"
        :class="[starClass]"
        :width="widthStar"
        :height="heightStar"
        :id="id"
      />
      <SmallStarComponent
        class="small-star"
        :class="[starSmallClass]"
        :width="widthStarSmall"
        :height="heightStarSmall"
        :id="id"
      />
      <SpinningStaticCirclesComponent
        class="spinning-static"
        :class="[spinningStaticClass]"
        :width="widthSpinningStatic"
        :height="heightSpinningStatic"
        :id="id"
      />
      <WrapperSpinningGraduationCapComponent v-if="isLifetime" :id="id" />
      <WrapperSpinningGraduationCapComponent v-else :id="id" />
    </div>
  </div>
</template>

<script>
import StarComponent from './StarComponent.vue';
import SmallStarComponent from './SmallStarComponent.vue';
import WrapperSpinningGraduationCapComponent from './WrapperSpinningGraduationCapComponent.vue';
import SpinningStaticCirclesComponent from './SpinningStaticCirclesComponent.vue';
export default {
  props: {
    id: {
      type: String,
      default: 'courses'
    },
    isLifetime: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WrapperSpinningGraduationCapComponent,
    StarComponent,
    SmallStarComponent,
    SpinningStaticCirclesComponent,
  },
  computed: {
    starClass() {
      if (this.$breakpoint.desktopAndUp) {
        return 'star-desktop-and-up';
      }

      if (this.$breakpoint.smallDesktopOnly) {
        return 'star-desktop-small';
      }

      return 'star-tablet-only';
    },
    widthStar() {
      if (this.starClass === 'star-desktop-and-up') {
        return '79px';
      }

      if (this.starClass === 'star-desktop-small') {
        return '72px';
      }

      return '59px';
    },
    heightStar() {
      if (this.starClass === 'star-desktop-and-up') {
        return '83px';
      }

      if (this.starClass === 'star-desktop-small') {
        return '75px';
      }

      return '62px';
    },
    starSmallClass() {
      if (this.$breakpoint.desktopAndUp) {
        return 'star-small-desktop-and-up';
      }

      if (this.$breakpoint.smallDesktopOnly) {
        return 'star-small-desktop-small';
      }

      return 'star-small-tablet-only';
    },
    widthStarSmall() {
      if (this.starSmallClass === 'star-small-desktop-and-up') {
        return '35px';
      }

      if (this.starSmallClass === 'star-small-desktop-small') {
        return '32px';
      }

      return '26px';
    },
    heightStarSmall() {
      if (this.starSmallClass === 'star-small-desktop-and-up') {
        return '37px';
      }

      if (this.starSmallClass === 'star-small-desktop-small') {
        return '33px';
      }

      return '27px';
    },
    spinningStaticClass() {
      if (this.$breakpoint.desktopAndUp) {
        return 'spinning-static-desktop-and-up';
      }

      if (this.$breakpoint.smallDesktopOnly) {
        return 'spinning-static-desktop-small';
      }

      return 'spinning-static-tablet-only';
    },
    widthSpinningStatic() {
      if (this.spinningStaticClass === 'spinning-static-desktop-and-up') {
        return '404px';
      }

      if (this.spinningStaticClass === 'spinning-static-desktop-small') {
        return '364px';
      }

      return '298px';
    },
    heightSpinningStatic() {
      if (this.spinningStaticClass === 'spinning-static-desktop-and-up') {
        return '425px';
      }

      if (this.spinningStaticClass === 'spinning-static-desktop-small') {
        return '383px';
      }

      return '314px';
    },
  },
};
</script>

<style scoped lang="scss">
.star {
  position: absolute;
  z-index: 2;
  animation: fadeinout 3.6s infinite;
}
.small-star {
  position: absolute;
  z-index: 2;
  animation: fadeinout 3s infinite;
}
.spinning-static {
  position: absolute;
  z-index: 2;
}

.star-desktop-and-up {
  top: 50px;
  right: 266px;
}

.star-desktop-small {
  top: 37px;
  right: 260px;
}

.star-tablet-only {
  top: 56px;
  right: 196px;
}

.star-small-desktop-and-up {
  bottom: 26px;
  right: 250px;
}

.star-small-desktop-small {
  bottom: 25px;
  right: 246px;
}

.star-small-tablet-only {
  bottom: 62px;
  right: 184px;
}

.spinning-static-desktop-and-up {
  top: -56px;
  right: -58px;
}

.spinning-static-desktop-small {
  top: -45px;
  right: -35px;
}

.spinning-static-tablet-only {
  top: -30px;
  right: -45px;
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media #{map-get($display-breakpoints, 'mobile-and-down')} {
  .illustrations-container {
    position: relative;
  }
}

@media (min-width: 1905px) {
  .star {
    right: 426px;
  }
  .small-star {
    bottom: 54px;
    right: 400px;
  }
  .spinning-static {
    top: -50px;
    right: 95px;
  }
}
</style>
