var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BannerComponent', {
    attrs: {
      "banner-resource": _vm.bannerResource,
      "lifetime-is-active": _vm.shouldShowLifetimePromoBanner
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }