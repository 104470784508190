<template>
  <div
    v-show="shouldShowCompleteProfileBannerNotification"
    id="completeProfileAlert"
    class="alert-container warning"
    :class="{ 'alert-container-sticky': hasToBeSticky }"
  >
    <div class="d-flex align-center">
      <span
        class="text"
        :class="{
          'text-m-large-bold': $breakpoint.mobileAndDown,
          'text-l-large-bold': $breakpoint.tabletAndUp,
        }"
        >Provide your medical board to report your MOC and CME credits.</span
      >
      <v-btn
        class="heading heading-s-small warning--text shadow-light-button"
        :class="{
          'ml-2': $breakpoint.mobileAndDown,
          'ml-4': $breakpoint.tabletAndUp,
        }"
        ripple
        depressed
        height="36px"
        width="auto"
        @click="showCompleteProfileDialog"
      >
        Complete profile
      </v-btn>
    </div>
  </div>
</template>

<script>
import { completeProfileAlertMixin } from '@/mixins/completeProfileAlertMixin';
import { mapActions } from 'vuex';

export default {
  name: 'CompleteProfileAlert',
  mixins: [completeProfileAlertMixin],
  computed: {
    hasToBeSticky() {
      return (
        (this.$route.name !== 'special-requirements' &&
          this.$route.name !== 'special-requirements-topic-states' &&
          this.$breakpoint.tabletAndUp) ||
        this.$breakpoint.mobileAndDown
      );
    },
  },
  watch: {
    '$breakpoint.mobileAndDown'(newValue) {
      this.moveAlertGuest(newValue);
    },
  },
  async mounted() {
    this.moveAlertGuest(this.$breakpoint.mobileAndDown);
  },
  methods: {
    ...mapActions('profile', ['showCompleteProfileDialog']),
    moveAlertGuest(isMobile) {
      const alertCompleteProfileEl = document.querySelector('#completeProfileAlert');
      const headerEl = document.querySelector('#headerBanner');
      if (isMobile) {
        headerEl.parentNode.insertBefore(alertCompleteProfileEl, headerEl);
      } else {
        headerEl.parentNode.insertBefore(alertCompleteProfileEl, headerEl.nextSibling);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-container-sticky {
  position: sticky;
}
.alert-container {
  top: 56px;
  z-index: 1;

  min-height: 44px;
  padding: 8px 24px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-weight: 400;
}

@media #{map-get($display-breakpoints, 'mobile-and-down')} {
  .alert-container {
    padding: 8px 16px;
  }
}

@media #{map-get($display-breakpoints, 'small-desktop-and-up')} {
  .alert-container {
    top: 60px;
  }
}
</style>
