<template>
  <div>
    <v-row no-gutters>
      <v-col
        cols="12"
        class="pb-0"
      >
        <div class="text-left">
          <DegreeComponent
            v-if="milestoneImprovedSignupEnabled"
            :degree-name="degree"
            @degree="onChangeDegree"
          />
          <AcaSelect
            v-if="hasToShowMedicalBoard"
            id="medical-board"
            v-model="medicalBoard"
            :disabled="!enabledMedicalBoard"
            :items="medicalBoardItems"
            item-text="name"
            item-value="id"
            placeholder="Select medical board"
            label="Select medical board"
            required
            @input="changeMedicalBoard"
          />
          <div :class="{ 'profile-dialog-board-id': isDialog }">
            <AcaTextField
              v-if="hasToShowBoardId"
              id="boardId"
              v-model="localBoardNumber"
              :disabled="!enabledBoardId"
              type="text"
              name="boardId"
              placeholder="Enter Board ID"
              :rules="[]"
              required
              :label="'Board ID ' + (showRequiredAsterisk ? '*' : '')"
              :autocomplete="'off'"
            />
            <AcaBirthdayPicker
              v-if="hasToShowDoB"
              :key="customKey + counterKeyDateOfBirth"
              :date-picker-model.sync="localDateOfBirth"
              :disabled="!enabledDateOfBirth"
              :menu-model.sync="menuForDate"
              :label="showRequiredAsterisk ? 'Date of Birth *' : 'Date of Birth'"
              name="boardDateOfBird"
              :rules="[]"
              text-field-data-testid="boardDateOfBirdInput"
              :text-field-model.sync="computedDateFormatted"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="!isProfile && !isDialog"
      no-gutters
    >
      <v-col
        cols="12"
        class="container-actions"
      >
        <v-card-actions class="item-action pa-0">
          <v-btn
            ripple
            block
            large
            class="heading heading-s-small white--text primary shadow-dark-button"
            @click="$emit('submit-form')"
          >
            Continue
          </v-btn>
        </v-card-actions>
        <v-card-actions class="item-action pa-0">
          <v-btn
            ripple
            block
            large
            class="heading heading-s-small primary--text shadow-light-button"
            @click="$emit('go-back')"
          >
            Go back
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DegreeComponent from '@/components/common/DegreeComponent';
import ValidatorComponent from '@/components/common/ValidatorComponent';
import AcaBirthdayPicker from '@components/atoms/AcaBirthdayPicker';

import AcaTextField from '@components/atoms/AcaTextField.vue';

import { mapActions } from 'vuex';
import { featureFlagsMixin, MILESTONE_IMPROVED_SIGNUP_ENABLED } from '@/mixins/featureFlagsMixin';

import { completeProfileAlertMixin } from '@/mixins/completeProfileAlertMixin';
import AcaSelect from '@components/atoms/AcaSelect';

const iDoNotSeeMyBoardOptionText = 'I do not see my board';

export default {
  components: { AcaBirthdayPicker, DegreeComponent, AcaTextField, AcaSelect },
  mixins: [
    completeProfileAlertMixin,
    featureFlagsMixin({
      [MILESTONE_IMPROVED_SIGNUP_ENABLED]: 'milestoneImprovedSignupEnabled',
    }),
  ],
  props: ['type', 'id', 'boardName', 'boardNumber', 'degree', 'dateOfBirth', 'customKey', 'showRequiredAsterisk'],
  data: () => ({
    counterKeyBoardNumber: 0,
    counterKeyDateOfBirth: 9999,
    menuForDate: false,
    medicalBoard: '',
    localDegree: null,
    localBoardNumber: null,
    localDateOfBirth: '',
    medicalBoardItems: [],
    enabledMedicalBoard: true,
    enabledBoardId: true,
    enabledDateOfBirth: true,
    degreeAllowBoards: ['M.D.', 'D.O.', 'M.D., Ph.D.', 'M.D., MPH'],
    rules: ValidatorComponent.data().rules,
    selfReportBoard: 'Self-Report to My Medical Board',
    otherDegree: 'Other',
  }),
  computed: {
    isProfile() {
      return this.type === 'profile';
    },
    isDialog() {
      return this.type === 'dialog';
    },
    isInRouteEditProfile() {
      return this.$route.name === 'profile-edit';
    },
    isInRouteSignup() {
      return this.$route.name === 'register';
    },
    computedDateFormatted() {
      return this.localDateOfBirth ? this.formatDate(this.localDateOfBirth) : '';
    },
    disabledTextFieldColorProfile() {
      return this.$route.name === 'profile-edit' && !this.enabledBoardId;
    },
    disabledTextFieldSignUp() {
      return this.$route.name === 'register' && !this.enabledBoardId;
    },
    selfReportId() {
      return this.medicalBoardItems.find(({ name }) => name === this.selfReportBoard)?.id;
    },
    iDoNotSeeMyBoardId() {
      return this.medicalBoardItems.find(({ name }) => name === iDoNotSeeMyBoardOptionText)?.id;
    },
    hasToShowMedicalBoard() {
      if (this.isInRouteEditProfile) {
        return this.enabledMedicalBoard || this.degree === this.otherDegree;
      }

      return true;
    },
    hasToShowBoardId() {
      if (this.isInRouteEditProfile) {
        return this.enabledBoardId;
      }

      if (this.isInRouteSignup) {
        return true;
      }

      return this.hasToShowControls;
    },
    hasToShowDoB() {
      if (this.isInRouteEditProfile) {
        return this.enabledDateOfBirth;
      }

      if (this.isInRouteSignup) {
        return true;
      }

      return this.hasToShowControls;
    },
    hasToShowControls() {
      const board = this.medicalBoardItems.find(({ id }) => id === this.medicalBoard)?.name;
      const hasSelfReportBoard = board === this.selfReportBoard;
      const hasIDoNotSeeMyBoard = board === iDoNotSeeMyBoardOptionText;
      return !hasIDoNotSeeMyBoard && !hasSelfReportBoard;
    },
  },
  watch: {
    medicalBoard: function (newMedicalBoard) {
      this.$emit('medical-board', newMedicalBoard);
      this.$emit('has-to-required-board-information', this.hasToShowControls);
    },
    localBoardNumber: function (newLocalBoardNumber) {
      this.$emit('board-number', newLocalBoardNumber);
    },
    boardNumber(newVal) {
      this.localBoardNumber = newVal;
    },
    dateOfBirth(newVal) {
      this.localDateOfBirth = newVal === 'Unknown' ? null : newVal;
    },
    localDateOfBirth: function (newDateOfBirth) {
      this.$emit('date-of-birth', newDateOfBirth);
    },
    degree: function (newDegree) {
      this.handleBoardsToggle(newDegree);
    },
  },
  async mounted() {
    const boards = await this.getBoards();

    // This change is made to accommodate the new implementation in the backend where the boards API has undergone changes.
    this.medicalBoardItems = this.reOrderSelfReportBoardInList(boards);

    const editProfileRoute = this.$route.name === 'profile-edit';
    const enabledBoard = this.degreeAllowBoards.includes(this.degree);
    const board = this.medicalBoardItems.find(({ id }) => id === this.medicalBoard)?.name;
    const hasOtherDegree = this.degree === this.otherDegree;
    const hasSelfReportBoard = board === this.selfReportBoard;
    const hasIDoNotSeeMyBoard = board === iDoNotSeeMyBoardOptionText;

    this.medicalBoard = this.id;
    this.localBoardNumber = this.boardNumber ?? null;
    this.localDateOfBirth = this.dateOfBirth && this.dateOfBirth != 'Unknown' ? this.dateOfBirth : null;
    this.changeMedicalBoard();

    // We need to set the medical board so the submit button works
    // Edge case when the user selects the degree "Other" on the register
    if (editProfileRoute && hasOtherDegree) {
      this.medicalBoard = this.selfReportId;
      this.resetBoardFields();
      this.$emit('allow-submit-without-full-details', true);
      return;
    }

    if (editProfileRoute && (hasOtherDegree || hasSelfReportBoard || hasIDoNotSeeMyBoard)) {
      this.enabledMedicalBoard = false;
      this.enabledBoardId = false;
      return;
    }

    if (editProfileRoute && !enabledBoard) {
      this.handleBoardSelectionForEditProfile(this.degree, enabledBoard);
      return;
    }
  },
  methods: {
    ...mapActions('boards', ['getBoards']),
    handleBoardsToggle(newDegree) {
      const enabledBoard = this.degreeAllowBoards.includes(newDegree);
      const editProfileRoute = this.$route.name === 'profile-edit';
      if (editProfileRoute) {
        this.handleBoardSelectionForEditProfile(newDegree, enabledBoard);
        return;
      }

      if (!editProfileRoute) {
        this.medicalBoard = enabledBoard ? this.medicalBoard : 0;
        this.localBoardNumber = enabledBoard ? this.localBoardNumber : null;
        this.enabledMedicalBoard = enabledBoard || !newDegree;
        this.enabledBoardId = enabledBoard || !newDegree;
      }
    },
    reOrderSelfReportBoardInList(boards) {
      let selfReportSingleElement = boards?.filter((board) => board.name === this.selfReportBoard);

      if (selfReportSingleElement) {
        let boardsWithoutSelfReport = boards?.filter((board) => board.name !== this.selfReportBoard);
        return [...selfReportSingleElement, ...boardsWithoutSelfReport];
      }

      return boards;
    },
    handleBoardSelectionForEditProfile(newDegree, enabledBoard) {
      if (!newDegree || newDegree === 'Unknown') return;

      const board = this.medicalBoardItems.find(({ id }) => id === this.medicalBoard)?.name;
      const selfReportId = this.selfReportId;
      if (newDegree === this.otherDegree) {
        this.medicalBoard = selfReportId;
        this.resetBoardFields();
        this.$emit('allow-submit-without-full-details', true);
        return;
      }

      if ((board === this.selfReportBoard || board === iDoNotSeeMyBoardOptionText) && enabledBoard) {
        if (board === this.selfReportBoard) {
          this.medicalBoard = selfReportId;
        } else if (board === iDoNotSeeMyBoardOptionText) {
          this.medicalBoard = this.iDoNotSeeMyBoardId;
        }
        this.enabledMedicalBoard = enabledBoard;
        this.$emit('allow-submit-without-full-details', true);
        return;
      }

      if (!enabledBoard) {
        this.medicalBoard = null;
        this.resetBoardFields();
        this.$emit('allow-submit-without-full-details', true);
        return;
      }

      if (enabledBoard) {
        this.medicalBoard = enabledBoard ? this.medicalBoard : 0;
        this.localBoardNumber = enabledBoard ? this.localBoardNumber : null;
        this.enabledMedicalBoard = enabledBoard;
        this.$emit('allow-submit-without-full-details', false);
      }
    },
    resetBoardFields() {
      this.localBoardNumber = null;
      this.enabledMedicalBoard = false;
      this.enabledBoardId = false;
      this.enabledDateOfBirth = false;
      this.localDateOfBirth = null;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');

      let shortYear = year.slice(-2);

      return `${month}/${day}/${shortYear}`;
    },
    onChangeDegree(newDegree) {
      this.localDegree = newDegree;
      this.$emit('degree', newDegree);
      this.handleBoardsToggle(newDegree);
    },
    changeMedicalBoard() {
      const board = this.medicalBoardItems.find(({ id }) => id === this.medicalBoard)?.name;
      const isRoute = this.$route.name === 'register' || this.$route.name === 'profile-edit';

      if ((board === this.selfReportBoard || board === iDoNotSeeMyBoardOptionText) && isRoute) {
        this.enabledMedicalBoard = true;
        this.enabledBoardId = false;
        this.enabledDateOfBirth = false;
        this.localBoardNumber = null;
        this.localDateOfBirth = null;
        this.$emit('allow-submit-without-full-details', true);
        return;
      }

      this.enabledMedicalBoard = true;
      this.enabledBoardId = true;
      this.enabledDateOfBirth = true;
      this.$emit('allow-submit-without-full-details', false);
    },
  },
};
</script>
<style scoped>
.text-field-profile.v-text-field--outlined >>> fieldset {
  border: 1px solid var(--v-grey-lighten1) !important;
  background-color: var(--v-background-base) !important;
}

.text-field-profile.v-text-field >>> .v-input__control {
  background-color: var(--v-card-base) !important;
}

.text-field-sign-up.v-text-field--outlined >>> fieldset {
  border: 1px solid var(--v-grey-lighten1) !important;
  background-color: var(--v-background-base) !important;
}

.text-field-sign-up.v-text-field >>> .v-input__control {
  background-color: var(--v-background-base) !important;
}
.non-clickable >>> .v-input__control > .v-input__slot > .v-text-field__slot > input {
  cursor: default !important;
}
.profile-dialog-board-id {
  display: flex;
  gap: 24px;
}
</style>
<style scoped lang="scss">
@media #{map-get($display-breakpoints, 'mobile-and-down')} {
  .profile-dialog-board-id {
    display: block;
  }
}
</style>
