// IMPORTANT: Please remember to update this file whenever a new feature flag is added.
// Add the new feature flag as a constant by defining it here and include it in the featureFlags array below.
// Also, make sure to remove any feature flags from this file that are no longer being used in the code.

export const MILESTONE_IMPROVED_SIGNUP_ENABLED = 'MILESTONE_IMPROVED_SIGNUP_ENABLED';
export const MILESTONE_ONE_FEED_ENABLED = 'MILESTONE_ONE_FEED_ENABLED';
export const MILESTONE_COMPLETE_PROFILE_CERTIFICATES_ENABLED = 'MILESTONE_COMPLETE_PROFILE_CERTIFICATES_ENABLED';
export const FEAT_CHECKOUT_PAGE_ENABLED = 'FEAT_CHECKOUT_PAGE_ENABLED';
export const FEAT_PRO_TO_LIFETIME_UPGRADE_ENABLED = 'FEAT_PRO_TO_LIFETIME_UPGRADE_ENABLED';
export const MILESTONE_FLUORO_SAFETY_PURCHASE_IMPROVEMENTS_ENABLED =
  'MILESTONE_FLUORO_SAFETY_PURCHASE_IMPROVEMENTS_ENABLED';
export const FEAT_PROMO_UPSELL_ENABLED = 'FEAT_PROMO_UPSELL_ENABLED';
export const FEAT_FS_UPSELL_ENABLED = 'FEAT_FS_UPSELL_ENABLED';

// Migration of pages using the new layout
export const MIGRATE_PAGE_MAIN_FEED_ENABLED = 'MIGRATE_PAGE_MAIN_FEED_ENABLED';
export const MIGRATE_PAGE_ARTICLE_ABSTRACT_ENABLED = 'MIGRATE_PAGE_ARTICLE_ABSTRACT_ENABLED';
export const MIGRATE_COURSE_MODULE_QUIZ_PAGE_ENABLED = 'MIGRATE_COURSE_MODULE_QUIZ_PAGE_ENABLED';
export const MIGRATE_PROFILE_PAGE_ENABLED = 'MIGRATE_PROFILE_PAGE_ENABLED';
export const MIGRATE_TERMS_OF_USE_PAGE_ENABLED = 'MIGRATE_TERMS_OF_USE_PAGE_ENABLED';
export const MIGRATE_PROFILE_EDIT_PAGE_ENABLED = 'MIGRATE_PROFILE_EDIT_PAGE_ENABLED';
export const MIGRATE_ARTICLE_PDF_PAGE_ENABLED = 'MIGRATE_ARTICLE_PDF_PAGE_ENABLED';
export const MIGRATE_PRIVACY_PAGE_ENABLED = 'MIGRATE_PRIVACY_PAGE_ENABLED';
export const MIGRATE_LOGIN_PAGE_ENABLED = 'MIGRATE_LOGIN_PAGE_ENABLED';
export const MIGRATE_SIGNUP_PAGE_ENABLED = 'MIGRATE_SIGNUP_PAGE_ENABLED';
export const MIGRATE_TRANSCRIPT_DETAILS_PAGE_ENABLED = 'MIGRATE_TRANSCRIPT_DETAILS_PAGE_ENABLED';
export const MIGRATE_SR_ARTICLE_ABSTRACT_PAGE_ENABLED = 'MIGRATE_SR_ARTICLE_ABSTRACT_PAGE_ENABLED';
export const MIGRATE_REFERRALS_PAGE_ENABLED = 'MIGRATE_REFERRALS_PAGE_ENABLED';
export const MIGRATE_ARTICLE_DISCLOSURE_PDF_PAGE = 'MIGRATE_ARTICLE_DISCLOSURE_PDF_PAGE';
export const MIGRATE_SR_ARTICLE_QUIZ_PAGE_ENABLED = 'MIGRATE_SR_ARTICLE_QUIZ_PAGE_ENABLED';
export const MIGRATE_BILLING_UPDATE_PAGE_ENABLED = 'MIGRATE_BILLING_UPDATE_PAGE_ENABLED';

// Migration of sections using the new text field
export const MIGRATE_TEXT_FIELD_PAYMENT_ENABLED = 'MIGRATE_TEXT_FIELD_PAYMENT_ENABLED';

// Every feature flag should be added to this array, to be considered by the feature flag service.
export const FEATURE_FLAGS = [
  MILESTONE_IMPROVED_SIGNUP_ENABLED,
  MILESTONE_ONE_FEED_ENABLED,
  MIGRATE_TEXT_FIELD_PAYMENT_ENABLED,
  MILESTONE_COMPLETE_PROFILE_CERTIFICATES_ENABLED,
  FEAT_CHECKOUT_PAGE_ENABLED,
  MIGRATE_ARTICLE_PDF_PAGE_ENABLED,
  FEAT_PRO_TO_LIFETIME_UPGRADE_ENABLED,
  MILESTONE_FLUORO_SAFETY_PURCHASE_IMPROVEMENTS_ENABLED,
  FEAT_PROMO_UPSELL_ENABLED,
  FEAT_FS_UPSELL_ENABLED
];

// These feature flags are always off, regardless of the feature flag service status.
export const DISABLED_FEATURE_FLAGS = [
  MILESTONE_IMPROVED_SIGNUP_ENABLED,
  MILESTONE_ONE_FEED_ENABLED,
  // FEAT_PRO_TO_LIFETIME_UPGRADE_ENABLED,
];

// These feature flags are always on, regardless of the feature flag service status.
export const ALWAYS_ON_FEATURE_FLAGS = [
  MIGRATE_PAGE_MAIN_FEED_ENABLED,
  MIGRATE_PAGE_ARTICLE_ABSTRACT_ENABLED,
  MIGRATE_COURSE_MODULE_QUIZ_PAGE_ENABLED,
  MIGRATE_PROFILE_EDIT_PAGE_ENABLED,
  MIGRATE_PROFILE_PAGE_ENABLED,
  MIGRATE_TERMS_OF_USE_PAGE_ENABLED,
  MIGRATE_PRIVACY_PAGE_ENABLED,

  // DO NOT DISABLE THIS FEATURE FLAG OTHERWISE THE PDF CANNOT BE SHOWN IN PRODUCTION
  MIGRATE_ARTICLE_PDF_PAGE_ENABLED,
  MIGRATE_LOGIN_PAGE_ENABLED,
  MIGRATE_SIGNUP_PAGE_ENABLED,
  MIGRATE_TRANSCRIPT_DETAILS_PAGE_ENABLED,
  MIGRATE_SR_ARTICLE_ABSTRACT_PAGE_ENABLED,
  MIGRATE_REFERRALS_PAGE_ENABLED,
  MIGRATE_ARTICLE_DISCLOSURE_PDF_PAGE,
  MIGRATE_SR_ARTICLE_QUIZ_PAGE_ENABLED,
  MIGRATE_BILLING_UPDATE_PAGE_ENABLED,
];
