var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('document-section', {
    class: {
      'max-height': _vm.shouldShowBackdrop
    },
    attrs: {
      "tag": "div"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shouldShowBackdrop,
      expression: "shouldShowBackdrop"
    }],
    staticClass: "black-veil",
    attrs: {
      "data-testid": "veil"
    }
  }), _vm.hasToShowRegisterUserOnboarding ? _c('WelcomeRegisteredUserComponent', {
    attrs: {
      "current-route": _vm.$route.path,
      "current-user": _vm.currentUser
    }
  }) : _vm.hasToShowPromoUserOnboarding ? _c('WelcomePromoUserComponent', {
    attrs: {
      "current-route": _vm.$route.path,
      "current-user": _vm.currentUser
    }
  }) : _vm._e(), _vm.hasToRenderNavigationDrawer ? _c('v-navigation-drawer', {
    staticClass: "shadow-sidebar sidebar-border px-1",
    attrs: {
      "id": "app-drawer",
      "color": "var(--v-card-base)",
      "width": "280px",
      "app": "",
      "touchless": "",
      "permanent": _vm.$breakpoint.isSidebarFixed
    },
    scopedSlots: _vm._u([_vm.hasToFixBottomHelpLinks ? {
      key: "append",
      fn: function () {
        return [_c('v-list-item-group', {
          attrs: {
            "color": "primary"
          }
        }, [_c('BottomLinksSectionComponent', {
          attrs: {
            "current-user": _vm.currentUser
          }
        })], 1)];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list-item', {
    attrs: {
      "id": "logo-container"
    }
  }, [_c('v-list-item-content', {
    staticClass: "pt-7"
  }, [_c('v-list-item-title', {
    staticClass: "title d-flex justify-space-between"
  }, [_c('LogoMenuComponent', {
    key: 2,
    staticClass: "clickable",
    attrs: {
      "id": '673_11252',
      "has-custom-size-for-mobile": false
    }
  }), _vm.$vuetify.breakpoint.mdAndDown ? _c('ph-x', {
    attrs: {
      "color": "var(--v-grey-darken4)",
      "size": 28,
      "weight": "thin"
    },
    on: {
      "click": function ($event) {
        _vm.drawer = !_vm.drawer;
      }
    }
  }) : _vm._e()], 1)], 1)], 1), _c('v-list', {
    class: {
      'mt-3': _vm.$breakpoint.tabletAndUp
    },
    attrs: {
      "nav": "",
      "dense": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "color": "primary",
      "active-class": "list-item-group-active"
    }
  }, [_c('v-list-item', {
    staticClass: "no-border-radius pr-3 menu-item mb-0",
    attrs: {
      "to": "/"
    }
  }, [_c('v-list-item-icon', {
    staticClass: "mr-2 mt-4"
  }, [_c('ph-newspaper', {
    attrs: {
      "color": "var(--v-primary-base)",
      "size": 24,
      "weight": "light"
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text text-m-large-regular grey--text text--darken-4",
    on: {
      "click": _vm.handleClickFeedAsGuestUser
    }
  }, [_vm._v(" Feed ")])], 1)], 1), _vm.isEnabledPremiumCourses ? _c('v-list-item', {
    staticClass: "no-border-radius pr-3 menu-item mb-0 mt-0",
    attrs: {
      "to": "/premium-courses"
    },
    on: {
      "click": _vm.handleClickPremiumCoursesAsGuestUser
    }
  }, [_c('v-list-item-icon', {
    staticClass: "mr-2 mt-4"
  }, [_c('ph-book-bookmark', {
    attrs: {
      "color": "var(--v-primary-base)",
      "size": 24,
      "weight": "light"
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "d-flex justify-space-between align-center text-m-large-regular text grey--text text--darken-4",
    staticStyle: {
      "white-space": "normal"
    }
  }, [_vm._v(" FluoroSafety "), _c('v-chip', {
    staticClass: "text text-m-small-regular pro-chip-special-requirements",
    attrs: {
      "color": "#FFAB18",
      "dark": "",
      "label": ""
    }
  }, [_c('CrownFilledIconComponent', {
    staticClass: "mr-1",
    attrs: {
      "size": 14
    }
  }), _vm._v(" Pro ")], 1)], 1)], 1)], 1) : _vm._e(), _c('v-list-item', {
    staticClass: "no-border-radius pr-3 menu-item mb-0 mt-0",
    attrs: {
      "to": "/special-requirements"
    }
  }, [_c('v-list-item-icon', {
    staticClass: "mr-2 mt-4"
  }, [_c('ph-clipboard-text', {
    attrs: {
      "color": "var(--v-primary-base)",
      "size": 24,
      "weight": "light"
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "d-flex align-center justify-space-between text text-m-large-regular grey--text text--darken-4",
    staticStyle: {
      "white-space": "normal"
    }
  }, [_vm._v(" Special requirements "), _vm.hasToShowProBadge ? _c('v-chip', {
    staticClass: "text text-m-small-regular pro-chip-special-requirements",
    attrs: {
      "color": "#FFAB18",
      "dark": "",
      "label": ""
    }
  }, [_c('CrownFilledIconComponent', {
    staticClass: "mr-1",
    attrs: {
      "size": 14
    }
  }), _vm._v(" Pro ")], 1) : _vm._e()], 1)], 1)], 1)], 1), _vm.currentUser ? _c('v-list-group', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c('v-list-item', {
          staticClass: "menu-item mb-0 pl-0"
        }, [_c('v-list-item-icon', {
          staticClass: "mr-2 mt-4"
        }, [_c('ph-student', {
          attrs: {
            "color": "var(--v-primary-base)",
            "size": 24,
            "weight": "light"
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          staticClass: "d-flex text text-m-large-regular justify-space-between grey--text text--darken-4"
        }, [_vm._v(" Account ")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 3023171845)
  }, _vm._l(_vm.menuItemOptions.slice(2), function (item, i) {
    return _c('v-list-item', {
      key: i,
      staticClass: "no-border-radius pl-10 menu-item mb-0",
      class: [{
        'primary-surface': _vm.checkIfMenuIsActive(item)
      }],
      on: {
        "click": function ($event) {
          return _vm.menuAction(item.id);
        }
      }
    }, [_c('v-list-item-icon', {
      staticClass: "mr-2 mt-4"
    }, [item.text === 'Transcript' ? _c('ph-trophy', {
      attrs: {
        "color": "var(--v-primary-base)",
        "size": 24,
        "weight": "light"
      }
    }) : _vm._e(), item.text === 'My Plan' ? _c('ph-rocket', {
      attrs: {
        "color": "var(--v-primary-base)",
        "size": 24,
        "weight": "light"
      }
    }) : _vm._e(), _vm.hasIncompleteProfile && item.text === 'Profile' ? _c('ph-warning', {
      attrs: {
        "color": "var(--v-warning-base)",
        "size": 24,
        "weight": "light"
      }
    }) : item.text === 'Profile' ? _c('ph-user-circle', {
      attrs: {
        "color": "var(--v-primary-base)",
        "size": 24,
        "weight": "light"
      }
    }) : _vm._e()], 1), _c('v-list-item-content', [_vm.hasIncompleteProfile && item.text === 'Profile' ? _c('v-list-item-title', {
      staticClass: "d-flex text text-m-large-regular justify-space-between warning--text"
    }, [_vm._v(" Complete profile ")]) : item.text === 'Premium Courses' ? _c('v-list-item-title', [_vm._v(" FluoroSafety ")]) : _c('v-list-item-title', {
      staticClass: "d-flex text text-m-large-regular justify-space-between grey--text text--darken-4"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])], 1)], 1);
  }), 1) : _vm._e()], 1), !_vm.hasToFixBottomHelpLinks ? _c('v-list', {
    staticClass: "px-0",
    attrs: {
      "nav": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "color": "primary"
    }
  }, [_c('BottomLinksSectionComponent', {
    staticClass: "mt-7",
    attrs: {
      "current-user": _vm.currentUser
    }
  })], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.shouldShowTopBar ? _c('v-app-bar', {
    staticClass: "app-bar",
    attrs: {
      "app": "",
      "height": _vm.$breakpoint.tabletAndUp ? '60px' : '56px',
      "color": _vm.navBarBackground,
      "flat": ""
    }
  }, [_vm.hasToRenderNavigationDrawer ? _c('v-app-bar-nav-icon', {
    staticClass: "d-inline d-lg-none",
    attrs: {
      "data-testid": "menu-drawer-btn"
    },
    on: {
      "click": function ($event) {
        _vm.drawer = !_vm.drawer;
      }
    }
  }, [_c('ph-list', {
    attrs: {
      "color": "var(--v-grey-darken1)",
      "size": 32,
      "weight": "thin"
    }
  })], 1) : _vm._e(), !_vm.hasToShowBackNavigation && _vm.$route.name === 'home' && _vm.$vuetify.breakpoint.mdAndDown ? _c('div', {
    staticClass: "d-block mr-auto mx-lg-0 clickable",
    on: {
      "click": function ($event) {
        return _vm.menuAction(0);
      }
    }
  }, [_c('LogoMenuComponent', {
    key: 1,
    attrs: {
      "id": '673_11253',
      "is-auditor": _vm.isAuditor
    }
  })], 1) : _vm._e(), !_vm.hasToShowBackNavigation && (_vm.$route.name === 'article-file-view' || _vm.$route.name === 'article-disclosure-pdf' || _vm.$route.name === 'course-disclosure-pdf' || _vm.$route.name === 'course-module-content-preview' || _vm.$route.name === 'auditor-article-file-view') ? _c('div', {
    staticClass: "d-block mr-auto mx-lg-0 clickable",
    on: {
      "click": function ($event) {
        return _vm.menuAction(0);
      }
    }
  }, [_c('LogoMenuComponent', {
    key: 2,
    attrs: {
      "id": '673_11254'
    }
  })], 1) : _vm._e(), _vm.hasToShowBackNavigation ? _c('div', {
    staticClass: "d-flex container-back"
  }, [_c('ph-caret-left', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getTitle,
      expression: "getTitle"
    }],
    staticClass: "clickable",
    attrs: {
      "weight": "thin",
      "size": "24",
      "color": "var(--v-grey-darken4)"
    },
    on: {
      "click": _vm.goBack
    }
  }), _c('h6', {
    staticClass: "text text-l-large-regular grey--text text--darken-3 ml-2 clickable",
    class: {
      'text-truncate fixed-back-text': _vm.$breakpoint.mobileAndDown
    },
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v(" " + _vm._s(_vm.getTitle) + " ")])], 1) : _vm._e(), _c('v-spacer', {
    staticClass: "d-block"
  }), _c('AvatarBadgeComponent', {
    attrs: {
      "has-to-show-unawarded-badge": _vm.hasToShowUnawardedBadge,
      "awarded-credits-to-show": _vm.awardedCreditsToShow,
      "full-name": _vm.fullName,
      "is-auditor": _vm.isAuditor,
      "has-to-disabled-quiz-button": _vm.getTakeQuizButtonDisabledInNavBar
    },
    on: {
      "go-to-login": _vm.goToLogin,
      "go-to-signup": _vm.goToSignup
    }
  })], 1) : _vm._e(), _c('v-main', {
    class: [_vm.shouldShowBackdrop || !_vm.currentUser && _vm.isFeedRoute ? 'pt-0' : 'pt-14 pt-sm-15', _vm.mainBackground]
  }, [_c('CompleteProfileAlert', {
    on: {
      "show-complete-profile-dialog": _vm.showCompleteProfileDialog
    }
  }), _vm.shouldShowCompleteProfileDialog || _vm.shouldShowCompleteProfileDialogOnTranscript || _vm.shouldShowCompleteProfileDialogOnProfile ? _c('CompleteProfileDialog', {
    on: {
      "on-close": _vm.hideCompleteProfileDialog
    }
  }) : _vm._e(), !_vm.hasIncompleteProfile ? _c('FreeTrialReachedLimitsAlert') : _vm._e(), !_vm.hasIncompleteProfile ? _c('premium-alert') : _vm._e(), _vm.currentUser ? _c('special-requirements-alert') : _vm._e(), _c('header', {
    class: {
      'pt-14 pt-sm-15 pt-md-15 pt-lg-0': !_vm.currentUser && _vm.isFeedRoute
    },
    attrs: {
      "id": "headerBanner"
    }
  }, [!_vm.currentUser && _vm.isFeedRoute ? _c('WrapperBannersComponent') : _vm._e()], 1), _c('v-container', {
    class: [_vm.containerBackgroundAndSpacing, {
      'container-full-width': _vm.hasToApplyFullWidth
    }]
  }, [_vm.showExpirationAlert ? _c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify-sm": "center"
    }
  }, [_c('v-col', {
    staticClass: "px-sm-11 px-lg-0 px-6",
    class: {
      'mb-4': !_vm.hasToApplyFullWidth
    },
    attrs: {
      "cols": "12",
      "sm": "12",
      "lg": "12"
    }
  }, [_c('ExpirationAlertCardComponent', {
    attrs: {
      "finish-init-load": _vm.finishInitLoad
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    class: [{
      'center-content-update-card': _vm.hasToCenterContentToUpdateCard
    }],
    attrs: {
      "no-gutters": "",
      "justify-sm": "center"
    }
  }, [_c('v-col', {
    staticClass: "px-sm-12 px-lg-0 px-6",
    class: {
      'mb-4': !_vm.hasToApplyFullWidth,
      'px-md-11': _vm.isMainFeedRoute
    },
    attrs: {
      "cols": "12",
      "sm": "12",
      "lg": "12"
    }
  }, [_vm._t("content")], 2)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }