import {
  doPostIncrementShareCounter,
  doGetMostPopular,
  doGetMyFeed,
  doSearchArticlesByText,
  doFinishOnboarding,
  getOnboarding,
  doShowPretestUser,
  doGetPretestOnboarding,
  doShowEvaluation,
} from '@/api/feed';
import { isSuccessfulResponse } from '@/api/utilities';
import UtilitiesComponent from '@/components/common/UtilitiesComponent';
import { useFeedStore } from '@/store-v2/feed';

export default {
  namespaced: true,
  state: () => ({
    onboardingFinished: null,
    hasToReloadMyFeedAfterOnboarding: false,
    checkIfRequestFromOnboardingIsDone: false,
    showPretest: false,
    articleId: 0,
    checkIfHasToShowPretestOnboarding: true,
    showPretestResult: false,
    layoutPretest: false,
    showPretestOnboarding: true,
    articlesTakenToRemoveFromKeepAliveFeed: [],
    articlesWithAbtractExpanded: [],
  }),
  mutations: {
    setOnboardingFinish(state, data) {
      state.onboardingFinished = data;

      const { setIsOnboardingFinished } = useFeedStore();
      setIsOnboardingFinished(data);
    },
    setHasToReloadMyFeedAfterOnboarding(state) {
      state.hasToReloadMyFeedAfterOnboarding = true;
    },
    setCheckIfRequestFromOnboardingIsDone(state, data) {
      state.checkIfRequestFromOnboardingIsDone = data;
    },
    setOnboardingPretestShow(state, data) {
      state.showPretest = data;
    },
    setArticleId(state, data) {
      state.articleId = data;
    },
    setHasToShowPretestOnboarding(state, data) {
      state.checkIfHasToShowPretestOnboarding = data;
    },
    setShowPretestResult(state, data) {
      state.showPretestResult = data;
    },
    setLayoutPretest(state, data) {
      state.layoutPretest = data;
    },
    setShowPretestOnboarding(state, data) {
      state.showPretestOnboarding = data;
    },
    setArticlesTakenToRemoveFromKeepAliveFeed(state, data) {
      state.articlesTakenToRemoveFromKeepAliveFeed = Array.from(
        new Set([...state.articlesTakenToRemoveFromKeepAliveFeed, data]),
      );
    },
    resetArticlesTakenFromKeepAliveFeedMemory(state) {
      state.articlesTakenToRemoveFromKeepAliveFeed = [];
    },
    appendArticleWithAbtractExpanded(state, articleId) {
      state.articlesWithAbtractExpanded.push(articleId);
    },
    deleteArticleWithAbstractExpanded(state, articleId) {
      const elementIndex = state.articlesWithAbtractExpanded.indexOf(articleId);
      if (elementIndex !== -1) {
        state.articlesWithAbtractExpanded.splice(elementIndex, 1);
      }
    },
  },
  actions: {
    onBoardingDefaultValues(context) {
      context.commit('setOnboardingFinish', null);
      context.commit('setCheckIfRequestFromOnboardingIsDone', false);
    },
    increaseShareCounter(context, submissionId) {
      return doPostIncrementShareCounter(submissionId);
    },
    async getMostPopular(context, nextPagination) {
      let response = await doGetMostPopular(nextPagination);

      if (isSuccessfulResponse(response)) {
        response = await context.dispatch('handleGuestQuizzes', response.data);

        // TODO: to be removed when the single feed is ready
        response = await context.dispatch('handleVisitedArticles', response);
      }

      return response;
    },
    async getMyFeed(context, nextPagination) {
      let response = await doGetMyFeed(nextPagination);

      if (isSuccessfulResponse(response)) {
        response = await context.dispatch('handleGuestQuizzes', response.data);
        response = await context.dispatch('handleVisitedArticles', response);
      }

      return response;
    },
    async searchArticlesByText(context, params) {
      let response = await doSearchArticlesByText(params);

      if (isSuccessfulResponse(response)) {
        response = await context.dispatch('handleGuestQuizzes', response.data);
        response = await context.dispatch('handleVisitedArticles', response);
      }

      return response;
    },
    async finishOnboarding(context) {
      const response = await doFinishOnboarding();
      if (isSuccessfulResponse(response)) {
        context.commit('setOnboardingFinish', true);
        context.commit('setHasToReloadMyFeedAfterOnboarding');
      }
      return response;
    },
    async getOnboardingStatus({ commit, getters, rootGetters }) {
      const isGuestUser = await rootGetters['auth/isGuestUser'];
      if (isGuestUser) return;

      const { isOnboardingFinished } = getters;
      if (isOnboardingFinished) return;

      const response = await getOnboarding();
      if (isSuccessfulResponse(response)) {
        if (response.data.onboarding === UtilitiesComponent.data().onboardingEnum.DONE) {
          commit('setOnboardingFinish', true);
        } else {
          commit('setOnboardingFinish', false);
        }

        commit('setCheckIfRequestFromOnboardingIsDone', true);
      }
      return response;
    },
    handleGuestQuizzes(context, articles) {
      let tempArticles = articles.items;
      let guestQuizzes = JSON.parse(localStorage.getItem('acapedia_guest_quizzes')) ?? [];

      if (guestQuizzes.length) {
        guestQuizzes = guestQuizzes.map((e) => parseInt(e.articleId));
        tempArticles = tempArticles?.filter((article) => {
          return guestQuizzes.indexOf(article.id) < 0;
        });
      }

      return {
        items: tempArticles ?? [],
        previousPagination: articles.previousPagination,
        nextPagination: articles.nextPagination,
        total: articles.total,
      };
    },
    handleVisitedArticles(context, articles) {
      let tempArticles = articles.items;
      let visitedArticles = JSON.parse(localStorage.getItem('acapedia_visited_articles')) ?? [];

      if (visitedArticles.length) {
        tempArticles = tempArticles?.map((article) => {
          article.visited = visitedArticles.includes(article.id);

          return article;
        });
      }

      return {
        items: tempArticles ?? [],
        previousPagination: articles.previousPagination,
        nextPagination: articles.nextPagination,
        total: articles.total,
      };
    },
    showOnboardingPretest(context, isShowed) {
      context.commit('setOnboardingPretestShow', isShowed);
    },
    showPretestUser() {
      return doShowPretestUser();
    },
    setArticleIdToPretest(context, id) {
      context.commit('setArticleId', id);
    },
    async hasToShowPretestOnboarding(context) {
      const response = await doGetPretestOnboarding();
      if (isSuccessfulResponse(response)) {
        if (response.data.onboarding === '1') {
          context.commit('setHasToShowPretestOnboarding', true);
          return;
        }
        context.commit('setHasToShowPretestOnboarding', false);
      }
    },
    showPretestResult(context, isShowed) {
      context.commit('setShowPretestResult', isShowed);
    },
    layoutPretest(context, isShowed) {
      context.commit('setLayoutPretest', isShowed);
    },
    showPretestOnboarding(context, isShowed) {
      context.commit('setShowPretestOnboarding', isShowed);
    },
    reloadMyFeedAfterOnboarding(context) {
      context.commit('setHasToReloadMyFeedAfterOnboarding');
    },
    removeArticleFromKeepAliveFeed(context, articleId) {
      context.commit('setArticlesTakenToRemoveFromKeepAliveFeed', parseInt(articleId));
    },
    resetArticlesTakenFromKeepAliveFeedMemory(context) {
      context.commit('resetArticlesTakenFromKeepAliveFeedMemory');
    },
    showEvaluation() {
      return doShowEvaluation();
    },
    addArticleWithAbtractExpanded(context, articleId) {
      context.commit('appendArticleWithAbtractExpanded', articleId);
    },
    removeArticleWithAbstractExpanded(context, articleId) {
      context.commit('deleteArticleWithAbstractExpanded', articleId);
    },
    async checkIfHasToShowPretest({ dispatch, rootGetters }, { articleId, articleSlug }) {
      const isLoggedInUser = rootGetters['auth/authToken'] !== null;
      if (!isLoggedInUser) return false;

      const response = await doShowPretestUser();
      const isTimeToShowPretest = isSuccessfulResponse(response) && response.data?.showPretest;
      if (!isTimeToShowPretest) return false;

      const quiz = await dispatch('article/fetchArticleQuizForPretest', { articleId, articleSlug }, { root: true });
      const articleHasPretest = quiz.questions?.some((question) => question.pretest);
      if (!articleHasPretest) return false;

      return true;
    },
  },
  getters: {
    isOnboardingFinished: (state) => {
      return state.onboardingFinished;
    },
    isReloadMyFeedAfterOnboarding: (state) => {
      return state.hasToReloadMyFeedAfterOnboarding;
    },
    isOnboardingRequestDone: (state) => {
      return state.checkIfRequestFromOnboardingIsDone;
    },
    isOnboardingPretestShow: (state) => {
      return state.showPretest;
    },
    getArticleId: (state) => {
      return state.articleId;
    },
    isOnboardingPretestToShowAgain: (state) => {
      return state.checkIfHasToShowPretestOnboarding;
    },
    isShowPretestResult: (state) => {
      return state.showPretestResult;
    },
    isLayoutPretest: (state) => {
      return state.layoutPretest;
    },
    isShowPretestOnboarding: (state) => {
      return state.showPretestOnboarding;
    },
    getArticlesTakenToRemoveFromKeepAliveFeed: (state) => {
      return state.articlesTakenToRemoveFromKeepAliveFeed;
    },
    articlesWithAbtractExpanded: (state) => {
      return state.articlesWithAbtractExpanded;
    },
  },
};
