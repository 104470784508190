import {
  doGetUserSubscription,
  doGetPlans,
  doGetPaymentIntent,
  doPostConfirmSubscription,
  doPostConfirmSingleCharge,
  doGetIfLifetimeIsActive,
  doPostConfirmDowngradeSubscription,
  doGetSubscriptionChangePreview,
  doPostConfirmFreeSubscription,
  doGetDiscountByPromoCode,
  doGetReceipt,
  doGetProfessionalReceiptDetails,
  doGetLifetimeReceiptDetails,
} from '@/api/subscriptions';
import { isSuccessfulResponse } from '@/api/utilities';

import {
  getFeatureFlagProvider,
  FEAT_PRO_TO_LIFETIME_UPGRADE_ENABLED,
  FEAT_PROMO_UPSELL_ENABLED,
  FEAT_FS_UPSELL_ENABLED,
} from '@/lib/featureFlag';

// We need this to be a function in order to have the feature flags ready
export const getPlanNames = () => {
  const PlanNames = {
    FREE: 'Free',
    PRO: 'Pro',
    LIFETIME: 'Lifetime',
  };

  return PlanNames;
};

const PAST_DUE_STATUS = 'past_due';
const TRIALING_STATUS = 'trialing';

export default {
  namespaced: true,
  state: () => ({
    userFreePlan: false,
    userSubscription: null,
    selectedPricingPlanPage: null,
    attemptToLoadSubscriptionDone: false,
    plans: null,
    couponFormIsOpen: false,
    isShowingInternalPageChangePlan: false,
  }),
  mutations: {
    setCouponFormIsOpen(state) {
      state.couponFormIsOpen = !state.couponFormIsOpen;
    },
    setUserFreePlan(state, data) {
      state.userFreePlan = data;
    },
    setUserSubscription(state, userSubscription) {
      state.userSubscription = userSubscription;
    },
    setSelectedPricingPlanPage(state, selectedPricingPlanPage) {
      state.selectedPricingPlanPage = selectedPricingPlanPage;
    },
    setAttemptToLoadSubscriptionDone(state) {
      state.attemptToLoadSubscriptionDone = true;
    },
    setPlans(state, plans) {
      state.plans = plans;
    },
    setIsShowingInternalPageChangePlan(state, data) {
      state.isShowingInternalPageChangePlan = data;
    },
    unsetPlans(state) {
      state.plans = null;
    },
    unsetPlanSelected(state) {
      state.selectedPricingPlanPage = null;
    },
  },
  actions: {
    toggleCouponForm({ commit }) {
      commit('setCouponFormIsOpen');
    },
    async getUserSubscription({ commit }) {
      const response = await doGetUserSubscription();
      if (response.data) {
        commit('setUserSubscription', response.data);
      }

      commit('setAttemptToLoadSubscriptionDone');

      return response;
    },
    async removeUserSubscription({ commit }) {
      commit('setUserSubscription', null);
    },
    async getPlans({ commit, getters }, { useCache = true } = {}) {
      if (useCache && getters.plans) {
        return getters.plans;
      }

      const plans = await doGetPlans();
      commit('setPlans', plans);

      return plans;
    },
    getPaymentIntent() {
      return doGetPaymentIntent();
    },
    postConfirmSubscription(context, payload) {
      return doPostConfirmSubscription(payload);
    },
    postConfirmSingleCharge(context, payload) {
      return doPostConfirmSingleCharge(payload);
    },
    getIfLifetimeIsActive() {
      return doGetIfLifetimeIsActive();
    },
    async postConfirmDowngradeSubscription(context, payload) {
      let response = await doPostConfirmDowngradeSubscription(payload);

      if (isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    },
    async getSubscriptionChangePreview(context, payload) {
      let response = await doGetSubscriptionChangePreview(payload);

      if (isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    },
    setIsUserFreePlan(context, data) {
      context.commit('setUserFreePlan', data);
    },
    postConfirmFreeSubscription(context, payload) {
      return doPostConfirmFreeSubscription(payload);
    },
    getDiscountByPromoCode(context, payload) {
      return doGetDiscountByPromoCode(payload);
    },
    getReceipt(context, payload) {
      return doGetReceipt(payload);
    },
    getProfessionalReceiptDetails(context, payload) {
      return doGetProfessionalReceiptDetails(payload);
    },
    getLifetimeReceiptDetails(context, payload) {
      return doGetLifetimeReceiptDetails(payload);
    },
    loadSubscriptionDone(context) {
      context.commit('setAttemptToLoadSubscriptionDone');
    },
    selectPlanForCheckout(context, payload) {
      context.commit('setSelectedPricingPlanPage', payload);
    },
    setIsShowingInternalPageChangePlan({ commit }, payload) {
      commit('setIsShowingInternalPageChangePlan', payload);
    },
    unsetPlans({ commit }) {
      commit('unsetPlans');
    },
    unsetPlanSelected({ commit }) {
      commit('unsetPlanSelected');
    },
  },
  getters: {
    isCouponFormOpen: (state) => {
      return state.couponFormIsOpen;
    },
    canUpgradeSubscription: (
      state,
      {
        hasProSubscription,
        hasLifetimeSubscription,
        isSubscriptionInTrialingStatus,
        isPromoUserWithActiveTrial,
        isFsUserWithActiveTrial,
      },
    ) => {
      const featureFlagProvider = getFeatureFlagProvider();

      if (isPromoUserWithActiveTrial || isFsUserWithActiveTrial) {
        return true;
      }

      if (featureFlagProvider.isFeatureEnabled(FEAT_PRO_TO_LIFETIME_UPGRADE_ENABLED) && hasProSubscription) {
        return !isSubscriptionInTrialingStatus;
      }

      return !(hasProSubscription || hasLifetimeSubscription);
    },
    // TODO: this value can be undefined if the list of plans is not loaded
    // we should improve this that could be prone to errors
    currentPlan: (state, { freePlan, hasActiveSubscription, plans, isGuestUser }) => {
      if (isGuestUser) return null;

      if (!hasActiveSubscription) return freePlan;

      return plans?.find((plan) => {
        return plan.title === state.userSubscription.planName;
      });
    },
    currentPlanName: (state, { currentPlan }) => {
      return currentPlan?.title;
    },
    isGuestUser: (state, getters, rootState, rootGetters) => {
      return rootGetters['auth/isGuestUser'];
    },
    /**
     * @deprecated - use hasFreeSubscription instead
     */
    isUserFreePlan: (state) => {
      return state.userFreePlan;
    },
    hasActiveSubscription: (state) => {
      return state.userSubscription?.hasActivePaidSubscription;
    },
    /**
     * @deprecated - use hasFreeSubscription instead
     */
    hasFreeTrial: (state, getters) => {
      return !getters.hasActiveSubscription;
    },
    getAttemptToLoadSubscriptionDone: (state) => {
      return state.attemptToLoadSubscriptionDone;
    },
    hasFreeSubscription: (state, { hasActiveSubscription }) => {
      return !hasActiveSubscription;
    },
    hasLifetimeSubscription: (state) => {
      return !!state.userSubscription?.hasLifetimeSubscription;
    },
    hasProSubscription: (state, { hasActiveSubscription }) => {
      if (!hasActiveSubscription) return false;

      return state.userSubscription?.planName === getPlanNames().PRO;
    },
    isSubscriptionInGracePeriod: (state) => {
      return !!state.userSubscription?.cancelledAt;
    },
    isSubscriptionInPastDueStatus: (state) => {
      return state.userSubscription?.status === PAST_DUE_STATUS;
    },
    isSubscriptionInTrialingStatus: (state) => {
      return state.userSubscription?.status === TRIALING_STATUS;
    },
    isPromoUserWithActiveTrial: (
      state,
      { hasProSubscription, isSubscriptionInTrialingStatus },
      rootState,
      rootGetters,
    ) => {
      const featureFlagProvider = getFeatureFlagProvider();
      const isPromoUser = rootGetters['auth/isPromoUser'];
      const isFluoroSafetyUser = rootGetters['auth/isFluoroSafetyUser'];

      return (
        featureFlagProvider.isFeatureEnabled(FEAT_PROMO_UPSELL_ENABLED) &&
        hasProSubscription &&
        isSubscriptionInTrialingStatus &&
        isPromoUser &&
        !isFluoroSafetyUser
      );
    },
    isFsUserWithActiveTrial: (
      state,
      { hasProSubscription, isSubscriptionInTrialingStatus },
      rootState,
      rootGetters,
    ) => {
      const featureFlagProvider = getFeatureFlagProvider();
      const isPromoUser = rootGetters['auth/isPromoUser'];
      const isFluoroSafetyUser = rootGetters['auth/isFluoroSafetyUser'];

      return (
        featureFlagProvider.isFeatureEnabled(FEAT_FS_UPSELL_ENABLED) &&
        hasProSubscription &&
        isSubscriptionInTrialingStatus &&
        isFluoroSafetyUser &&
        !isPromoUser
      );
    },
    plans: (state) => {
      return state.plans;
    },
    subscriptionEndDate: (state) => {
      return state.userSubscription?.endAt;
    },
    hasPreviouslyRedeemedFluoroSafetyUpsellCoupon: (state) => {
      return state.userSubscription?.hasPreviouslyRedeemedFluoroSafetyUpsellCoupon ?? false;
    },
    // When the user is in the trial period, we also include the current plan
    availablePlansSummaryForUpsell: (
      state,
      {
        canUpgradeSubscription,
        currentPlanName,
        freePlan,
        hasActiveSubscription,
        plans,
        hasProSubscription,
        isSubscriptionInTrialingStatus,
        isPromoUserWithActiveTrial,
        isFsUserWithActiveTrial,
      },
      rootState,
      rootGetters,
    ) => {
      const featureFlagProvider = getFeatureFlagProvider();

      if (canUpgradeSubscription && plans) {
        const planNames = getPlanNames();
        let plansToShow = plans;

        if (!featureFlagProvider.isFeatureEnabled(FEAT_PRO_TO_LIFETIME_UPGRADE_ENABLED)) {
          if (hasActiveSubscription) {
            // upgrade of lifetime plan is not allowed until we work on the feature
            plansToShow = plans.filter((plan) => plan.title !== planNames.LIFETIME) ?? [];
          }
        }

        // TODO: delete the professional plan from the api response
        plansToShow = plans.filter((plan) => plan.title !== 'Professional') ?? [];

        // it means is being accesed from the public pricing page
        if (!currentPlanName) {
          return plansToShow;
        }

        if (isPromoUserWithActiveTrial || isFsUserWithActiveTrial) {
          return plansToShow;
        }

        return plansToShow.filter((plan) => plan.title !== currentPlanName);
      } else {
        return [freePlan];
      }
    },
    availablePlansToChange: (
      state,
      { canUpgradeSubscription, currentPlanName, freePlan, hasActiveSubscription, plans },
    ) => {
      const featureFlagProvider = getFeatureFlagProvider();

      if (canUpgradeSubscription && plans) {
        const planNames = getPlanNames();
        let plansToShow = plans;

        if (!featureFlagProvider.isFeatureEnabled(FEAT_PRO_TO_LIFETIME_UPGRADE_ENABLED)) {
          if (hasActiveSubscription) {
            // upgrade of lifetime plan is not allowed until we work on the feature
            plansToShow = plans.filter((plan) => plan.title !== planNames.LIFETIME) ?? [];
          }
        }

        // TODO: delete the professional plan from the api response
        plansToShow = plans.filter((plan) => plan.title !== 'Professional') ?? [];

        // it means is being accesed from the public pricing page
        if (!currentPlanName) {
          return plansToShow;
        }

        return plansToShow.filter((plan) => plan.title !== currentPlanName);
      } else {
        return [freePlan];
      }
    },
    availablePlansToUpgrade: (
      state,
      { availablePlansToChange, availablePlansSummaryForUpsell, isSubscriptionInTrialingStatus },
    ) => {
      const availablePlans = isSubscriptionInTrialingStatus ? availablePlansSummaryForUpsell : availablePlansToChange;

      return availablePlans.filter((plan) => plan.title !== getPlanNames().FREE);
    },
    // TODO: move to a plans store
    freePlan: (state, { plans }) => {
      return plans?.find((plan) => {
        return plan.isFree;
      });
    },
    proPlan: (state, { plans }) => {
      return plans?.find((plan) => {
        return plan.title === getPlanNames().PRO;
      });
    },
    lifetimePlan: (state, { plans }) => {
      return plans?.find((plan) => {
        return plan.title === getPlanNames().LIFETIME;
      });
    },
    planSelected: (state) => {
      return state.selectedPricingPlanPage;
    },
    /**
     * Calculates the total payment amount for the selected plan without applying any user-submitted coupon.
     * The total is adjusted by the current auto discount applied to the selected plan.
     */
    planSelectedTotalPaymentWithoutUserCoupon: (state, { planSelected, planSelectedCurrentAutoDiscount }) => {
      let amount = planSelected?.currentPlanPriceHash
        ? planSelected?.totalAmount
        : planSelected?.priceWithDiscountByDefault;

      return amount - planSelectedCurrentAutoDiscount;
    },
    /**
     * Calculates the current auto discount applied to the selected plan.
     * This includes any remaining discounts or discounts due to promo periods.
     * In such cases, the discount is taken from the plan and not from the user-submitted coupon.
     */
    planSelectedCurrentAutoDiscount: (state, { planSelected }) => {
      let amount = 0;

      amount += planSelected?.remainingAmount ?? 0;
      amount += planSelected?.discountByFluorosafety ?? 0;

      return amount;
    },
    isShowingInternalPageChangePlan: (state) => {
      return state.isShowingInternalPageChangePlan;
    },
    canUpdateBillingInUpsellPage: (state, { isPromoUserWithActiveTrial, isFsUserWithActiveTrial, planSelected }) => {
      return (isPromoUserWithActiveTrial || isFsUserWithActiveTrial) && planSelected?.title === 'Pro';
    },
  },
};
