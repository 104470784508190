import { SplitFactory } from '@splitsoftware/splitio';

const SDK_KEY = process.env.VUE_APP_SPLIT_IO_SDK_KEY;

export class FeatureFlagSplitIoImpl {
  #cli = null;
  #isReady = false;

  async init({ deviceId }) {
    const factory = SplitFactory({
      debug: false,
      core: {
        authorizationKey: SDK_KEY,
        key: deviceId,
      },
    });

    this.#cli = factory.client();

    await new Promise((resolve, reject) => {
      this.#cli.on(this.#cli.Event.SDK_READY, () => {
        console.info('FeatureFlagSplitIoImpl is ready');
        this.#isReady = true;
        resolve();
      });

      this.#cli.on(this.#cli.Event.SDK_READY_TIMED_OUT, () => {
        this.#isReady = false;
        reject(new Error('Split SDK timed out'));
      });
    });
  }

  isReady() {
    return this.#isReady;
  }

  isFeatureEnabled(featureFlag) {
    return this.#cli.getTreatment(featureFlag) === 'on';
  }
}
