var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasToShowActions ? _c('div', {
    staticClass: "d-flex"
  }, [_vm.hasAcceptedActivityDisclosure ? _c('router-link', {
    staticClass: "clickable d-flex align-center text-decoration-none",
    attrs: {
      "target": "_blank",
      "to": _vm.courseDisclosurePdfRoute
    }
  }, [_c('ph-file-pdf', {
    attrs: {
      "size": "18",
      "color": "var(--v-primary-base)",
      "weight": "light"
    }
  }), _c('span', {
    staticClass: "ml-2 heading heading-s-small primary--text"
  }, [_vm._v("Disclosure")])], 1) : _vm._e(), _vm.hasToShowMainAction ? _c('div', {
    class: _vm.hasAcceptedActivityDisclosure ? 'ml-4' : ''
  }, [_c('v-btn', {
    staticClass: "heading heading-s-small white--text shadow-dark-button",
    class: {
      'cold-gradient': !_vm.hasToDisableMainAction
    },
    attrs: {
      "ripple": "",
      "width": "142px",
      "height": "36px",
      "disabled": _vm.hasToDisableMainAction
    },
    on: {
      "click": _vm.handleClickMainAction
    }
  }, [_vm._v(" " + _vm._s(_vm.mainActionText) + " ")])], 1) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }