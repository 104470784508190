<template>
  <div class="badge-container text-end">
    <GraduationCapBackgroundComponent
      class="background-badge-animation"
      :class="[badgeAnimationClass]"
      :width="widthBackground"
      :height="heightBackground"
      :id="id"
    />
    <GraduationCapComponent
      v-if="id === 'courses'"
      class="badge"
      :class="[badgeClass]"
      :width="widthBadge"
      :height="heightBadge"
    />
    <LimitedOfferTextComponent
      v-if="id === 'lifetime'"
      class="badge"
      :class="[badgeCircularTextClass]"
      :width="widthTextCircleBadge"
      :height="heightTextCircleBadge"
    />
    <div v-if="id === 'lifetime'" class="badge" :class="[badgeInnerTextClass, 'text-center']">
      <div class="heading white--text heading-offer-number">40%</div>
      <div class="text white--text heading-off">OFF</div>
    </div>
  </div>
</template>

<script>
import GraduationCapBackgroundComponent from './GraduationCapBackgroundComponent.vue';
import GraduationCapComponent from './GraduationCapComponent.vue';
import LimitedOfferTextComponent from './LimitedOfferTextComponent.vue';
export default {
  props: {
    id: {
      type: String,
      default: 'courses'
    },
  },
  components: { GraduationCapBackgroundComponent, GraduationCapComponent, LimitedOfferTextComponent },
  computed: {
    badgeAnimationClass() {
      if (this.$breakpoint.desktopAndUp) {
        return 'badge-animation-desktop-and-up';
      }

      if (this.$breakpoint.smallDesktopOnly) {
        return 'badge-animation-desktop-small';
      }

      return 'badge-animation-tablet-only';
    },
    badgeClass() {
      if (this.$breakpoint.desktopAndUp) {
        return 'badge-desktop-and-up';
      }

      if (this.$breakpoint.smallDesktopOnly) {
        return 'badge-desktop-small';
      }

      return 'badge-tablet-only';
    },
    badgeCircularTextClass() {
      if (this.$breakpoint.desktopAndUp) {
        return 'badge-circular-text-desktop-and-up';
      }

      if (this.$breakpoint.smallDesktopOnly) {
        return 'badge-circular-text-desktop-small';
      }

      return 'badge-circular-text-tablet-only';
    },
    badgeInnerTextClass() {
      if (this.$breakpoint.desktopAndUp) {
        return 'badge-inner-text-desktop-and-up';
      }

      if (this.$breakpoint.smallDesktopOnly) {
        return 'badge-inner-text-desktop-small';
      }

      return 'badge-inner-text-tablet-only';
    },
    widthBackground() {
      if (this.badgeAnimationClass === 'badge-animation-desktop-and-up') {
        return '174px';
      }

      if (this.badgeAnimationClass === 'badge-animation-desktop-small') {
        return '159px';
      }

      return '131px';
    },
    heightBackground() {
      if (this.badgeAnimationClass === 'badge-animation-desktop-and-up') {
        return '174px';
      }

      if (this.badgeAnimationClass === 'badge-animation-desktop-small') {
        return '159px';
      }

      return '131px';
    },
    widthBadge() {
      if (this.badgeClass === 'badge-desktop-and-up') {
        return '111px';
      }

      if (this.badgeClass === 'badge-desktop-small') {
        return '101px';
      }

      return '83px';
    },
    heightBadge() {
      if (this.badgeClass === 'badge-desktop-and-up') {
        return '111px';
      }

      if (this.badgeClass === 'badge-desktop-small') {
        return '101px';
      }

      return '83px';
    },
    widthTextCircleBadge() {
      if (this.badgeCircularTextClass === 'badge-circular-text-desktop-and-up') {
        return '122px';
      }

      if (this.badgeCircularTextClass === 'badge-circular-text-desktop-small') {
        return '111px';
      }

      return '91px';
    },
    heightTextCircleBadge() {
      if (this.badgeCircularTextClass === 'badge-circular-text-desktop-and-up') {
        return '122px';
      }

      if (this.badgeCircularTextClass === 'badge-circular-text-desktop-small') {
        return '111px';
      }

      return '91px';
    },
  },
};
</script>

<style scoped lang="scss">
.badge-container {
  position: absolute;
  z-index: 2;
  top: 50px;
  right: 55px;
}

.background-badge-animation {
  animation: spin 6s linear infinite;
}

.badge {
  position: absolute;
  z-index: 2;
}

.badge-desktop-and-up {
  top: calc(174px / 2 - 111px / 2);
  right: calc(174px / 2 - 111px / 2);
}

.badge-desktop-small {
  top: calc(159px / 2 - 101px / 2);
  right: calc(159px / 2 - 101px / 2);
}

.badge-tablet-only {
  top: calc(131px / 2 - 83px / 2);
  right: calc(131px / 2 - 83px / 2);
}

.badge-circular-text-desktop-and-up {
  top: calc(174px / 2 - 122px / 2);
  right: calc(174px / 2 - 122px / 2);
}

.badge-circular-text-desktop-small {
  top: calc(159px / 2 - 111px / 2);
  right: calc(159px / 2 - 111px / 2);
}

.badge-circular-text-tablet-only {
  top: calc(131px / 2 - 91px / 2);
  right: calc(131px / 2 - 91px / 2);
}

.heading-offer-number {
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.8px;
}

.heading-off {
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 3.9px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media #{map-get($display-breakpoints, 'desktop-and-down')} {
  .badge-container {
    right: 55px;
  }
}
@media #{map-get($display-breakpoints, 'small-desktop-and-down')} {
  .badge-container {
    right: 68px;
  }
}
@media #{map-get($display-breakpoints, 'tablet-and-down')} {
  .badge-container {
    right: 37px;
  }
  .heading-offer-number {
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.5px;
  }
  .heading-off {
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3.3px;
  }
}

@media (min-width: 1905px) {
  .badge-container {
    top: 50px;
    right: 195px;
  }
}

.badge-inner-text-desktop-and-up {
  top: calc(246px / 2 - 122px / 2);
  right: calc(222px / 2 - 122px / 2);
}

.badge-inner-text-desktop-small {
  top: calc(220px / 2 - 111px / 2);
  right: calc(196px / 2 - 111px / 2);
}

.badge-inner-text-tablet-only {
  top: calc(176px / 2 - 91px / 2);
  right: calc(162px / 2 - 91px / 2);
}
</style>
