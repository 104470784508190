var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "illustrations-container"
  }, [_vm.$breakpoint.tabletAndUp ? _c('div', [_c('StarArticleComponent', {
    staticClass: "star",
    class: [_vm.starClass],
    attrs: {
      "id": 'star',
      "width": _vm.widthStar,
      "height": _vm.heightStar
    }
  }), _c('StarArticleComponent', {
    staticClass: "small-star",
    class: [_vm.starSmallClass],
    attrs: {
      "id": 'star_small',
      "width": _vm.widthStarSmall,
      "height": _vm.heightStarSmall
    }
  }), _c('PlanetsComponent', {
    staticClass: "planets",
    class: [_vm.planetsClass],
    attrs: {
      "width": _vm.widthPlanets,
      "height": _vm.heightPlanets
    }
  })], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }