<template>
  <label
    :for="id ? id : null"
    class="d-block text text-left"
    :class="[
      {
        'grey--text text--lighten-1': disabled,
        'error--text': error,
        'grey--text text--darken-3': !error && !disabled,
      },
      {
        'text-m-small-bold': enableBold,
        'text-m-small-regular': !enableBold,
      },
    ]"
  >
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'AcaLabel',
  props: {
    id: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    enableBold: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
