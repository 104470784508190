<template>
  <div class="aca-dialog-container bottom-card-shadow">
    <header class="aca-dialog-header">
      <ph-x
        v-if="isMobile"
        class="clickable mr-1"
        weight="light"
        size="24"
        @click="handleClose"
      />

      <document-heading
        class="grey--text"
        :class="isTabletAndUp ? 'heading heading-s-small text--darken-2' : 'text text-l-large-regular text--darken-3'"
      >
        {{ title }}
      </document-heading>
      <ph-x
        v-if="isTabletAndUp && showXButton"
        class="clickable aca-dialog-closable-action-fixed"
        weight="light"
        size="24"
        @click="handleClose"
      />
    </header>
    <p
      class="text grey--text text--darken-3"
      :class="isTabletAndUp ? 'text-m-large-regular px-8 mb-0' : 'text-l-large-regular px-6 pb-4 pt-6'"
    >
      {{ description }}
    </p>
    <main :class="isTabletAndUp ? 'px-8 py-6' : 'px-6 pt-2'">
      <slot name="append"> </slot>
    </main>
    <footer class="aca-dialog-footer">
      <v-row
        v-if="showClose"
        justify="end"
      >
        <v-col :class="isTabletAndUp ? 'col-auto' : 'col-12'">
          <v-btn
            :disabled="!confirmEnabled"
            ripple
            block
            :height="isTabletAndUp ? '36px' : '44px'"
            class="heading heading-s-small white--text primary shadow-dark-button"
            :class="isTabletAndUp ? 'px-6' : ''"
            :loading="confirmIsLoading"
            @click="handleConfirm"
          >
            Confirm information
          </v-btn>
        </v-col>
      </v-row>
    </footer>
  </div>
</template>

<script>
import { PhX } from 'phosphor-vue';
import { DocumentHeading } from 'vue-headings';
import { mapActions } from 'vuex';

export default {
  name: 'AcaDialog',
  components: { DocumentHeading, PhX },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    confirmEnabled: {
      type: Boolean,
      default: true,
    },
    confirmIsLoading: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showXButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isMobile() {
      return this.$breakpoint.mobileAndDown;
    },
    isTabletAndUp() {
      return this.$breakpoint.tabletAndUp;
    },
  },
  mounted() {
    this.setUp();
  },
  destroyed() {
    this.cleanUp();
  },
  methods: {
    ...mapActions('menu', ['showBackdrop', 'hideBackdrop']),
    handleClose() {
      this.$emit('on-close');
    },
    handleConfirm() {
      this.$emit('on-confirm');
    },
    setUp() {
      this.showBackdrop();
      document.querySelector(':root').classList.add('no-scroll');
    },
    cleanUp() {
      this.hideBackdrop();
      document.querySelector(':root').classList.remove('no-scroll');
    },
  },
};
</script>

<style scoped>
.aca-dialog-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  z-index: 10;
  border-radius: 4px;
  background-color: var(--v-card-base) !important;
  overflow-y: auto;
}

.aca-dialog-closable-action-fixed {
  position: absolute;
  top: 16px;
  right: 16px;
}
</style>

<style scoped lang="scss">
@media #{map-get($display-breakpoints, 'desktop-and-up')} {
  .aca-dialog-container {
    width: 588px;
  }
}

@media #{map-get($display-breakpoints, 'small-desktop-only')} {
  .aca-dialog-container {
    width: 514px;
  }
}

@media #{map-get($display-breakpoints, 'tablet-only')} {
  .aca-dialog-container {
    width: 440px;
  }
}

@media #{map-get($display-breakpoints, 'mobile-and-down')} {
  .aca-dialog-container {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .aca-dialog-header {
    display: flex;
    border-bottom: 1px solid var(--v-grey-lighten3);
    padding: 16px;
  }

  .aca-dialog-footer {
    border-top: 1px solid var(--v-grey-lighten3);
    padding: 16px 24px;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
  }
}

@media #{map-get($display-breakpoints, 'tablet-and-up')} {
  .aca-dialog-header {
    padding: 32px 32px 8px 32px;
  }

  .aca-dialog-footer {
    padding: 0 32px 32px 32px;
  }
}
</style>
