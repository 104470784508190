/**
 * useAtomFieldState - A Vue composition function to track the focus and error states of a form field.
 *
 * This function is designed to observe changes to the class attribute of a field reference. It uses
 * MutationObserver to detect these changes, particularly focusing on focus and error states indicated
 * by specific CSS classes. This is especially useful in scenarios where the UI framework (like Vuetify)
 * does not provide a direct way to handle such states, such as detecting errors on form submission.
 *
 * By abstracting this logic, the function helps to keep the component state management agnostic of
 * the underlying UI framework, making it easier to adapt to different frameworks in the future.
 *
 * @param {Object} param - The parameter object.
 * @param {Object} param.fieldRef - A Vue ref object pointing to the form field DOM element.
 *
 * @returns {Object} - An object containing two reactive references:
 *                     - isFocused {Ref<Boolean>} - Indicates if the field is focused.
 *                     - hasError {Ref<Boolean>} - Indicates if the field has an error.
 *
 * @example
 * // In a Vue component setup function
 * import { ref } from 'vue';
 * import { useAtomFieldState } from './path/to/your/composable';
 *
 * export default {
 *   setup() {
 *     const fieldRef = ref(null);
 *     const { isFocused, hasError } = useAtomFieldState({ fieldRef });
 *
 *     return { isFocused, hasError };
 *   }
 * }
 */

import { onMounted, onUnmounted, ref } from 'vue';
import { useMutationObserver } from '@vueuse/core';

const FOCUS_CLASS = 'v-input--is-focused';
const ERROR_CLASS = 'error--text';

export function useAtomFieldState({ fieldRef }) {
  const isFocused = ref(false);
  const hasError = ref(false);
  let mutationObserver = null;

  const observeMutations = () => {
    if (!fieldRef.value) return;

    return useMutationObserver(
      fieldRef,
      (mutationList) => {
        for (const mutation of mutationList) {
          const className = mutation.target.getAttribute(mutation.attributeName) ?? '';
          const classAttrValues = className.split(' ');

          isFocused.value = classAttrValues.includes(FOCUS_CLASS);

          // We use a MutationObserver to monitor changes to the input element's class list.
          // This allows us to detect errors resulting from form submissions.
          // The Vuetify API doesn't offer a direct way (or I couldn't find one) to handle these errors,
          // and this implementation avoids prop drilling for error handling from each parent component using this atom.
          hasError.value = classAttrValues.includes(ERROR_CLASS);
        }
      },
      {
        attributes: true,
        attributeFilter: ['class'],
      },
    );
  };

  onMounted(() => {
    mutationObserver = observeMutations();
  });

  onUnmounted(() => {
    mutationObserver?.stop();
  });

  return {
    isFocused,
    hasError,
  };
}
