var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": _vm.width,
      "height": _vm.height,
      "viewBox": "0 0 33 34",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M19.3675 13.2002L16.2311 0L13.0936 13.2002L0.893555 16.3056L13.0936 19.4096L16.2311 33.3092L19.3675 19.4096L32.6317 16.3056L19.3675 13.2002Z",
      "fill": _vm.fill
    }
  }), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": _vm.linear,
      "x1": "32.6319",
      "y1": "16.3338",
      "x2": "0.893555",
      "y2": "16.3338",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#FFB018"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FFB018",
      "stop-opacity": "0"
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }