var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "aca-select",
    class: {
      'w-100': _vm.fullWidth
    }
  }, [_vm.label ? _c('AcaLabel', {
    staticClass: "mb-1",
    attrs: {
      "id": _vm.id,
      "enable-bold": _vm.isFocused,
      "disabled": _vm.disabled
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]) : _vm._e(), _c('v-select', _vm._g({
    ref: "acaSelect",
    staticClass: "text",
    class: [_vm.isMobile ? 'text-l-large-regular' : 'text-m-large-regular', {
      'placeholder-as-value': _vm.stylePlaceholderAsValue,
      'hide-details': _vm.hideDetails
    }],
    attrs: {
      "id": _vm.id,
      "items": _vm.items,
      "item-text": _vm.itemText,
      "item-value": _vm.itemValue,
      "persistent-hint": "",
      "solo": "",
      "hide-details": _vm.hideDetails,
      "autocapitalize": "sentences",
      "placeholder": _vm.placeholder,
      "disabled": _vm.disabled,
      "height": _vm.height,
      "label": null,
      "menu-props": {
        contentClass: 'aca-select-menu'
      }
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_vm._t("append", function () {
          return [_c('PhCaretDown', {
            attrs: {
              "weight": "light",
              "size": "24"
            }
          })];
        })];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, _vm.$listeners))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }