<template>
  <div
    v-if="hasToShowOnboarding"
    ref="onboardingPanel"
    class="onboarding-panel onboarding-panel-size border-radius-small d-flex"
  >
    <v-row
      no-gutters
      class="justify-space-around fix-last-step-overflow pa-6 pa-sm-10 mb-0"
      :class="{ 'align-content-start': $vuetify.breakpoint.xsOnly }"
    >
      <v-col
        class="col-sm-4 col-lg-auto text-center"
        :class="[
          { 'adjust-images-for-tablet-and-up': $vuetify.breakpoint.smAndUp },
          hasToAdjustImagesHeadingForSEPhones ? 'mb-4 mt-2' : 'col-12',
        ]"
      >
        <OnboardingImagesHandlerComponent
          :current-step-index="currentStepIndex"
          :enum-steps="enumOnboarding"
        />
      </v-col>
      <v-col
        v-if="hasToAdjustImagesHeadingForSEPhones"
        class="align-self-center"
      >
        <v-row
          no-gutters
          align="center"
        >
          <h3
            class="heading primary--text"
            :class="titleClassName"
          >
            {{ titleForSteps }}
          </h3>
        </v-row>
      </v-col>
      <v-col class="content-onboarding col-12 col-sm-8 col-lg pl-sm-3 py-0">
        <v-row
          v-if="!hasToAdjustImagesHeadingForSEPhones"
          no-gutters
          align="center"
        >
          <h3
            class="heading primary--text mt-1 mt-sm-0"
            :class="titleClassName"
          >
            {{ titleForSteps }}
          </h3>
        </v-row>
        <v-row no-gutters>
          <p
            ref="descriptions"
            class="descriptions mt-2 mb-4 text text-l-large-regular word-wrap-all"
            v-html="items[currentStepIndex].desc"
          />
        </v-row>
        <v-row
          no-gutters
          class="mt-sm-0 pl-sm-3"
          justify-sm="space-between"
          :class="[
            {
              'fixed-bottom-mobile': $vuetify.breakpoint.xsOnly,
              'fixed-actions-nonmobile': !$vuetify.breakpoint.xsOnly,
            },
          ]"
        >
          <v-col
            class="col-12 col-sm-12 mb-sm-1 text-right align-self-center"
            :class="{
              'buttons-disposition-mobile': $vuetify.breakpoint.xsOnly,
            }"
          >
            <v-btn
              ripple
              depressed
              :block="$vuetify.breakpoint.xsOnly"
              :height="$vuetify.breakpoint.xsOnly ? '44px' : '36px'"
              color="primary white--text"
              class="heading heading-s-small align-self-center unaltered-text-cap shadow-light-button px-6"
              @click="nextStep"
            >
              {{
                items[currentStepIndex].useBtnPrimaryAlternative
                  ? items[currentStepIndex].btnPrimaryAlternative
                  : items[currentStepIndex].btnPrimary
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OnboardingImagesHandlerComponent from '@/components/onboarding/images/OnboardingImagesHandlerComponent';
import { PhCaretLeft } from 'phosphor-vue';

export default {
  components: {
    OnboardingImagesHandlerComponent,
    PhCaretLeft,
  },
  props: ['currentRoute', 'currentUser'],
  data: () => ({
    currentStepIndex: 0,
    items: [
      {
        id: '0',
        title: 'Tell us about yourself and start learning!',
        desc: 'Explore your feed and find interesting articles or courses and start earning CME credit.',
        btnPrimary: 'Complete profile',
      },
    ],
    enumOnboarding: {
      FINAL: 0,
    },
    descriptionClasses: 'descriptions mt-2 mb-4 text text-l-large-regular',
    mountedDone: false,
    touchendX: 0,
    touchstartX: 0,
    touchendY: 0,
    touchstartY: 0,
    touchEventsDone: false,
    PHONE_WIDTH: 320,
    isLoading: true,
  }),
  computed: {
    ...mapGetters('feed', ['isOnboardingFinished', 'isOnboardingRequestDone']),
    hasToShowOnboarding() {
      if (this.isLoading) {
        return false;
      }

      if (
        !this.isOnboardingFinished &&
        this.isOnboardingRequestDone &&
        this.currentUser &&
        (this.currentRoute === 'home' || this.currentRoute === 'premium-courses-feed' || this.currentRoute === '/')
      ) {
        this.setGeneralVeil(true);
        document.querySelector(':root').classList.add('no-scroll');
        return true;
      } else {
        this.setGeneralVeil(false);
        document.querySelector(':root').classList.remove('no-scroll');
      }

      return false;
    },
    hasToAdjustImagesHeadingForSEPhones() {
      return this.currentStepIndex < this.enumOnboarding.POST_CHECKOUT && this.$vuetify.breakpoint.width <= 320;
    },
    titleForSteps() {
      return this.items[this.currentStepIndex].title;
    },
    titleClassName() {
      const isSE = this.$vuetify.breakpoint.width <= this.PHONE_WIDTH;
      if (isSE) {
        return this.buildTitleClassNameForSE();
      }

      const isPhone = this.$vuetify.breakpoint.width > this.PHONE_WIDTH && this.$vuetify.breakpoint.xsOnly;
      if (isPhone) {
        return this.buildTitleCLassNameForPhone();
      }

      const isTabletAndUp = this.$vuetify.breakpoint.smAndUp;
      if (isTabletAndUp) {
        return this.buildTitleClassNameForTabletAndUp();
      }

      return '';
    },
  },
  updated() {
    if (this.$refs.onboardingPanel && !this.touchEventsDone) {
      const that = this;
      this.$refs.onboardingPanel.addEventListener(
        'touchstart',
        function (event) {
          that.touchstartX = event.changedTouches[0].screenX;
          that.touchstartY = event.changedTouches[0].screenY;
        },
        false,
      );

      this.$refs.onboardingPanel.addEventListener(
        'touchend',
        function (event) {
          that.touchendX = event.changedTouches[0].screenX;
          that.touchendY = event.changedTouches[0].screenY;

          that.handleGesture();
        },
        false,
      );

      this.touchEventsDone = true;
    }

    if (this.$refs.descriptions && !this.mountedDone) {
      this.$refs.descriptions.addEventListener('animationend', () => {
        this.$refs.descriptions.className = this.descriptionClasses;
      });

      this.mountedDone = true;
    }
  },
  async mounted() {
    this.$eventNotifier.notify('view_welcome_onboarding', {
      eventCategory: 'Welcome Onboarding',
    });

    this.currentStepIndex = this.enumOnboarding.FINAL;
    this.isLoading = false;
  },
  destroyed() {
    this.setGeneralVeil(false);
  },
  methods: {
    ...mapActions('menu', ['setGeneralVeil']),
    nextStep() {
      if (this.hasToShowLastLearnMoreStep) {
        this.$eventNotifier.notify('complete_learn_more_onboarding', {
          eventCategory: 'Welcome Onboarding',
        });
      }

      if (this.currentStepIndex === this.enumOnboarding.FINAL) {
        this.finishOnboardingFlow();
        this.$router.push({
          name: 'profile-edit',
          params: { field: 'medicalBoard', onboarding: true },
        });
      }
    },
    closeOnboarding() {
      this.finishOnboardingFlow();
    },
    finishOnboardingFlow() {
      this.setGeneralVeil(false);
      document.querySelector(':root').classList.remove('no-scroll');
    },
    handleGesture() {
      const passThresholdTolerance =
        Math.abs(this.touchendY - this.touchstartY) < 100 && Math.abs(this.touchendX - this.touchstartX) > 50;

      if (this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly) {
        if (this.touchendX < this.touchstartX && passThresholdTolerance) {
          this.nextStep();
        }

        if (this.touchendX > this.touchstartX && passThresholdTolerance) {
          this.goStepBack();
        }
      }
    },
    buildTitleClassNameForSE() {
      return 'heading-m-small onboarding-title-regular-mobile';
    },
    buildTitleCLassNameForPhone() {
      return `heading-l-large onboarding-title-regular-mobile`;
    },
    buildTitleClassNameForTabletAndUp() {
      return 'heading-m-large onboarding-title-regular-mobile';
    },
  },
};
</script>

<style scoped>
.onboarding-panel {
  position: fixed;
  background-color: var(--v-background-base);
  z-index: 99;
  box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.07);
}
.content-onboarding {
  flex-direction: column;
  display: flex;
}
.onboarding-panel p.descriptions {
  height: 72px;
}
.buttons-disposition-mobile {
  display: flex;
  flex-direction: column-reverse;
}
.fix-last-step-overflow {
  overflow-x: hidden;
}
.adjust-images-for-tablet-and-up {
  display: flex;
  align-items: center;
}
.fixed-actions-nonmobile {
  position: absolute;
  z-index: 5;
  bottom: 0px;
  right: 0px;
  left: 0px;
}
@media (max-width: 600px) {
  .onboarding-panel {
    top: 0px;
    left: 0px;
  }
  .onboarding-panel-size {
    width: 100%;
    height: 100%;
  }
  .onboarding-panel-size > .row {
    background-color: var(--v-background-base);
  }
  .content-onboarding {
    margin-bottom: 100px;
  }
}
@media (min-width: 600px) and (max-width: 1023px) {
  .onboarding-panel-size {
    height: 27vh;
    min-height: 292px;
    width: 87.5%;
    left: 0;
    right: 0;
    margin: auto;
    top: calc(50% - 146px);
  }
}
@media (min-width: 1024px) and (max-width: 1263px) {
  .onboarding-panel-size {
    height: 30vh;
    min-height: 260px;
    width: 75%;
    left: 0;
    right: 0;
    margin: auto;
    top: calc(50% - 130px);
  }
}
@media (min-width: 1264px) {
  .onboarding-panel-size {
    height: 25vh;
    min-height: 268px;
    width: 55%;
    max-width: 792px;
    left: 0;
    right: 0;
    margin: auto;
    top: calc(50% - 134px);
  }
}
@media (max-width: 320px) {
  .onboarding-title-regular-mobile {
    flex: 80%;
  }
}
@media (min-width: 321px) and (max-width: 600px) {
  .onboarding-title-regular-mobile {
    flex: 100%;
  }
}
/* Default adjust */
@media (min-width: 600px) {
  .content-onboarding {
    display: block;
    position: relative;
  }
}
.fixed-bottom-mobile {
  position: absolute;
  z-index: 5;
  width: calc(100vw - 48px);
  margin-left: 0;
  margin-right: 0;
  bottom: 24px;
}
</style>
