import { responseHandler } from '@/api/utilities';
import axios from 'axios';

const listTopics = async () => {
  const topics = await responseHandler(axios.get('/special-requirements/topics'));

  return topics.map(mapFromResponseToTopicList);
};

const mapFromResponseToTopicList = (topic) => {
  return {
    id: topic.id,
    slug: topic.slug,
    name: topic.name,
    description: topic.description,
    numberOfStates: topic.numberOfStates,
    imageUrl: topic.imageUrl,
    imageAlt: topic.imageAlt,
  };
};

const doGetFeed = async ({ topicSlug, stateSlug }) => {
  const feed = await responseHandler(axios.get(`/special-requirements/topics/${topicSlug}/states/${stateSlug}/feed`));

  return mapFromResponseToFeed(feed);
};

function mapFromResponseToFeed(resource) {
  return {
    topic: {
      id: resource.topic.id,
      slug: resource.topic.slug,
      name: resource.topic.name,
      imageUrl: resource.topic.imageUrl,
      imageAlt: resource.topic.imageAlt,
    },
    state: {
      id: resource.state.id,
      slug: resource.state.slug,
      name: resource.state.name,
    },
    validFromDate: resource.validFromDate,
    validToDate: resource.validToDate,
    metaTitle: resource.metaTitle,
    metaDescription: resource.metaDescription,
    numberOfRequiredCredits: resource.numberOfRequiredCredits,
    numberOfRequiredCmeExperiences: resource.numberOfRequiredCmeExperiences,
    userProgress: resource.userProgress
      ? {
          startedAt: resource.userProgress.startedAt,
          completedAt: resource.userProgress.completedAt,
          numberOfRequiredCredits: resource.userProgress.numberOfRequiredCredits,
          numberOfRequiredCmeExperiences: resource.userProgress.numberOfRequiredCmeExperiences,
          numberOfCreditsCompleted: resource.userProgress.numberOfCreditsCompleted,
          numberOfRequiredCmeExperiencesCompleted: resource.userProgress.numberOfRequiredCmeExperiencesCompleted,
        }
      : null,
    description: resource.description,
    requiredCmeExperiences: resource.requiredCmeExperiences.map(mapCmeExperienceFeedResource),
    recommendedCmeExperiences: resource.recommendedCmeExperiences.map(mapCmeExperienceFeedResource),
    optionalCmeExperiences: resource.optionalCmeExperiences.map(mapCmeExperienceFeedResource),
  };
}

function mapCmeExperienceFeedResource(resource) {
  const type = resource.type;
  const contentPrioritization = resource.contentPrioritization;
  const cmeExperience = resource.cmeExperience;

  return {
    type,
    contentPrioritization,
    cmeExperience:
      type === 'article'
        ? mapCmeExperienceFeedArticleResource(cmeExperience)
        : mapCmeExperienceFeedCourseResource(cmeExperience),
  };
}

const mapCmeExperienceFeedArticleResource = (article) => {
  return {
    id: article.id,
    createdAt: article.createdAt,
    updatedAt: article.updatedAt,
    slug: article.slug,
    title: article.title,
    authors: article.authors,
    keywords: article.keywords,
    credits: article.credits,
    creditTypes: article.creditTypes,
    boards: article.boards,
    abstract: article.abstract,
    upVotes: article.upVotes,
    downVotes: article.downVotes,
    voteStatus: article.voteStatus,
    journal: article.journal,
  };
};

const mapCmeExperienceFeedCourseResource = (course) => {
  return {
    id: course.id,
    slug: course.slug,
    title: course.title,
    publishedAt: course.publishedAt,
    authors: course.authors,
    keywords: course.keywords,
    numberOfCredits: course.numberOfCredits,
    creditTypes: course.creditTypes,
    boards: course.boards,
    briefSummary: course.briefSummary,
    summary: course.summary,
    totalUpVotes: course.totalUpVotes,
    totalDownVotes: course.totalDownVotes,
    modules: course.modules,
    moduleEnrollments: course.enrollments,
    enrollmentStatus: course.enrollmentStatus,
    userVote: course.userVote,
  };
};

const mapFromResponseToUserSpecialRequirement = (response) => {
  return {
    state: {
      id: response.state.id,
      slug: response.state.slug,
      name: response.state.name,
    },

    topic: {
      id: response.topic.id,
      slug: response.topic.slug,
      name: response.topic.name,
    },

    validFromDate: response.validFromDate,
    validToDate: response.validToDate,
    numberOfCreditsCompleted: response.numberOfCreditsCompleted,
    numberOfRequiredCredits: response.numberOfRequiredCredits,
    numberOfRequiredCmeExperiencesCompleted: response.numberOfRequiredCmeExperiencesCompleted,
    numberOfRequiredCmeExperiences: response.numberOfRequiredCmeExperiences,
    wasCompletedWithAllCreditsAwarded: response.wasCompletedWithAllCreditsAwarded,
    status: response.status,
  };
};

const doGetFeedInProgress = async () => {
  try {
    const feed = await responseHandler(axios.get('/special-requirements/feed/in-progress'));

    return feed.map((item) => mapFromResponseToUserSpecialRequirement(item));
  } catch (error) {
    console.log(error);
  }
};

const doGetFeedCompleted = async () => {
  try {
    const feed = await responseHandler(axios.get('/special-requirements/feed/completed'));

    return feed.map((item) => mapFromResponseToUserSpecialRequirement(item));
  } catch (error) {
    console.log(error);
  }
};

const doGetTopicStatesForCmeExperience = async ({ topicSlug, cmeExperienceSlug, cmeExperienceType }) => {
  let response = await responseHandler(
    axios.get(
      `/special-requirements/topics/${topicSlug}/cme-experiences/${cmeExperienceSlug}/states/type/${cmeExperienceType}`,
      { params: { topicSlug, cmeExperienceSlug, cmeExperienceType } },
    ),
  );

  return mapFromResponseToTopicStatesForCmeExperience(response);
};

const mapFromResponseToTopicStatesForCmeExperience = (cmeExperience) => {
  return {
    id: cmeExperience.id,
    slug: cmeExperience.slug,
    type: cmeExperience.type,
    states: cmeExperience.states.map((state) => ({
      id: state.id,
      slug: state.slug,
      name: state.name,
    })),
    topic: {
      id: cmeExperience.topic.id,
      slug: cmeExperience.topic.slug,
      name: cmeExperience.topic.name,
      imageUrl: cmeExperience.topic.imageUrl,
      imageAlt: cmeExperience.topic.imageAlt,
    },
  };
};

/**
 * Lists the topic states.
 * @async
 * @param {Object} options - The options object.
 * @param {string} options.topicSlug - The topic slug.
 * @returns {Promise<Array<{id: string, slug: string, name: string}>>} - The list of topic states.
 */
const listTopicStates = async ({ topicSlug }) => {
  const topicStates = await responseHandler(axios.get(`/special-requirements/topics/${topicSlug}/states`));

  return topicStates?.map((state) => {
    return {
      id: state.id,
      slug: state.slug,
      name: state.name,
      topicName: state.topicName,
    };
  });
};

const getTopicStateCmeExperienceInProgress = async ({ topicSlug, stateSlug }) => {
  const topicStateCmeExperienceToResume = await responseHandler(
    axios.get(`/special-requirements/topics/${topicSlug}/states/${stateSlug}/cme-experience/in-progress`),
    { hasToShowToasts: false },
  );
  return mapFromResponseToTopicStateCmeExperienceInProgress(topicStateCmeExperienceToResume);
};

const mapFromResponseToTopicStateCmeExperienceInProgress = (response) => {
  return {
    type: response.type,
    slug: response.slug,
    id: response.id,
    title: response.title,
    numberOfCredits: response.numberOfCredits,
    specialCredits: {
      hasMoc: response.specialCredits.hasMoc,
      hasPatientSafety: response.specialCredits.hasPatientSafety,
    },
    numberOfUnits: response.numberOfUnits,
    numberOfCompletedUnits: response.numberOfCompletedUnits,
    hasPendingEvaluation: response.hasPendingEvaluation,
  };
};

const doPostStartJourney = async (params) => {
  return responseHandler(
    axios.post(
      `/special-requirements/topics/${params.topicSlug}/states/${params.stateSlug}/cme-experiences/start-journey`,
      params,
    ),
  );
};

const doPostResetCmeExperience = async (params) => {
  return responseHandler(
    axios.post(
      `/special-requirements/topics/${params.topicSlug}/states/${params.stateSlug}/cme-experiences/${params.cmeExperienceSlug}/reset`,
      params,
    ),
  );
};

const doPostResetGuestCmeExperience = async (params) => {
  return responseHandler(
    axios.post(
      `/special-requirements/topics/${params.topicSlug}/states/${params.stateSlug}/cme-experiences/${params.cmeExperienceSlug}/reset-guest`,
      params,
    ),
  );
};

const showSpecialRequirementCertificate = async (params) => {
  return responseHandler(
    axios.get(`/special-requirements/topics/${params.topicSlug}/states/${params.stateSlug}/certificate`),
  );
};

const checkIfUserHasSpecialRequirementInProgress = async () => {
  return responseHandler(axios.get('/user/special-requirements/in-progress'), {
    hasToShowToasts: false,
  });
};

export {
  listTopics,
  doGetFeed,
  doGetFeedInProgress,
  doGetFeedCompleted,
  doGetTopicStatesForCmeExperience,
  listTopicStates,
  getTopicStateCmeExperienceInProgress,
  doPostStartJourney,
  doPostResetCmeExperience,
  doPostResetGuestCmeExperience,
  showSpecialRequirementCertificate,
  checkIfUserHasSpecialRequirementInProgress,
};
