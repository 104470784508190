var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "aca-dialog-container bottom-card-shadow"
  }, [_c('header', {
    staticClass: "aca-dialog-header"
  }, [_vm.isMobile ? _c('ph-x', {
    staticClass: "clickable mr-1",
    attrs: {
      "weight": "light",
      "size": "24"
    },
    on: {
      "click": _vm.handleClose
    }
  }) : _vm._e(), _c('document-heading', {
    staticClass: "grey--text",
    class: _vm.isTabletAndUp ? 'heading heading-s-small text--darken-2' : 'text text-l-large-regular text--darken-3'
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _vm.isTabletAndUp && _vm.showXButton ? _c('ph-x', {
    staticClass: "clickable aca-dialog-closable-action-fixed",
    attrs: {
      "weight": "light",
      "size": "24"
    },
    on: {
      "click": _vm.handleClose
    }
  }) : _vm._e()], 1), _c('p', {
    staticClass: "text grey--text text--darken-3",
    class: _vm.isTabletAndUp ? 'text-m-large-regular px-8 mb-0' : 'text-l-large-regular px-6 pb-4 pt-6'
  }, [_vm._v(" " + _vm._s(_vm.description) + " ")]), _c('main', {
    class: _vm.isTabletAndUp ? 'px-8 py-6' : 'px-6 pt-2'
  }, [_vm._t("append")], 2), _c('footer', {
    staticClass: "aca-dialog-footer"
  }, [_vm.showClose ? _c('v-row', {
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    class: _vm.isTabletAndUp ? 'col-auto' : 'col-12'
  }, [_c('v-btn', {
    staticClass: "heading heading-s-small white--text primary shadow-dark-button",
    class: _vm.isTabletAndUp ? 'px-6' : '',
    attrs: {
      "disabled": !_vm.confirmEnabled,
      "ripple": "",
      "block": "",
      "height": _vm.isTabletAndUp ? '36px' : '44px',
      "loading": _vm.confirmIsLoading
    },
    on: {
      "click": _vm.handleConfirm
    }
  }, [_vm._v(" Confirm information ")])], 1)], 1) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }