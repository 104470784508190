<template>
  <div
    class="image-onboarding-container d-flex"
    :class="{
      'diff-position-images': sizeImages.width === SIZE_FOR_MOBILE && currentStepIndex !== enumSteps.POST_CHECKOUT,
    }"
  >
    <IntroStepComponent
      v-show="currentStepIndex === enumSteps.INTRO"
      :size="sizeImages"
    />
    <FirstStepComponent
      v-show="currentStepIndex === enumSteps.FIRST"
      :size="sizeImages"
    />
    <SecondStepComponent
      v-show="currentStepIndex === enumSteps.SECOND"
      :size="sizeImages"
    />
    <ThirdStepComponent
      v-show="currentStepIndex === enumSteps.THIRD"
      :size="sizeImages"
    />
    <PostCheckoutStepComponent
      v-show="currentStepIndex === enumSteps.POST_CHECKOUT"
      :class="[
        { 'diff-position-images-post-checkout-image': $vuetify.breakpoint.xsOnly && $vuetify.breakpoint.width > 320 },
        {
          'diff-position-images-post-checkout-image-se': $vuetify.breakpoint.xsOnly && $vuetify.breakpoint.width <= 320,
        },
      ]"
      :size="sizeImages"
    />
    <FinalStepComponent
      v-show="currentStepIndex === enumSteps.FINAL"
      :class="[
        { 'diff-position-image-final-image': $vuetify.breakpoint.xsOnly && $vuetify.breakpoint.width >= 375 },
        { 'diff-position-image-final-image-se': $vuetify.breakpoint.xsOnly && $vuetify.breakpoint.width < 375 },
      ]"
      :size="sizeImages"
    />
  </div>
</template>
<script>
export default {
  components: {
    IntroStepComponent: () => import('./IntroStepComponent.vue'),
    FirstStepComponent: () => import('./FirstStepComponent.vue'),
    SecondStepComponent: () => import('./SecondStepComponent.vue'),
    ThirdStepComponent: () => import('./ThirdStepComponent.vue'),
    PostCheckoutStepComponent: () => import('./PostCheckoutStepComponent.vue'),
    FinalStepComponent: () => import('./FinalStepComponent.vue'),
  },
  props: {
    currentStepIndex: {
      type: Number,
      default: 0,
    },
    enumSteps: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    SIZE_FOR_MOBILE: 386,
    SIZE_FOR_SE_MOBILE: 115,
    SIZE_FOR_TABLETS_AND_UP: 188,
  }),
  computed: {
    sizeImages() {
      //Values for only one image that its not the same viewbox as the others
      if (this.currentStepIndex === this.enumSteps.POST_CHECKOUT) {
        if (this.$vuetify.breakpoint.xsOnly && this.$vuetify.breakpoint.width > 320) {
          return { width: 569, height: 481 };
        }

        if (this.$vuetify.breakpoint.width <= 320) {
          return { width: 320, height: 271 };
        }

        return { width: 188, height: 160 };
      }

      //Handle sizes for both final alternative images
      if (this.currentStepIndex === this.enumSteps.FINAL) {
        if (this.$vuetify.breakpoint.smAndUp) {
          return { width: 188, height: 180 };
        }

        if (this.$vuetify.breakpoint.width < 375) {
          return { width: 320, height: 307 };
        }

        return { width: 405, height: 387 };
      }

      if (this.$vuetify.breakpoint.xsOnly && this.$vuetify.breakpoint.width > 320) {
        return { width: this.SIZE_FOR_MOBILE, height: this.SIZE_FOR_MOBILE };
      }

      if (this.$vuetify.breakpoint.width <= 320) {
        return { width: this.SIZE_FOR_SE_MOBILE, height: this.SIZE_FOR_SE_MOBILE };
      }

      return { width: this.SIZE_FOR_TABLETS_AND_UP, height: this.SIZE_FOR_TABLETS_AND_UP };
    },
  },
};
</script>

<style scoped>
.diff-position-images {
  margin-left: -60px;
  margin-top: -115px;
}
.diff-position-images-se {
  margin-left: -95px;
  margin-top: -85px;
}
.diff-position-images-post-checkout-image {
  margin-left: -24px;
  margin-top: -125px;
  margin-right: -300px;
}
.diff-position-images-post-checkout-image-se {
  margin-left: -20px;
  margin-top: -99px;
  margin-right: -30px;
}
.diff-position-image-final-image {
  margin-right: -40px;
  margin-top: -30px;
  margin-left: -40px;
}
.diff-position-image-final-image-se {
  margin-right: -40px;
  margin-top: -90px;
  margin-left: -20px;
}
@media (min-width: 1024px) and (min-height: 1024px) and (orientation: landscape) {
  .image-onboarding-container {
    height: 188px;
    align-items: center;
  }
}
</style>
