<template>
  <BannerComponent
    :banner-resource="bannerResource"
    :lifetime-is-active="shouldShowLifetimePromoBanner"
  />
</template>

<script>
import { mapActions } from 'vuex';

import BannerComponent from '@/components/common/BannerComponent';

const ENABLE_LIFETIME_PROMO_BANNER = false;

export default {
  components: {
    BannerComponent,
  },
  data: () => ({
    loaded: false,
    lifetimeIsActive: false,
    lifetimeInfo: {
      type: 'lifetime',
      title: '',
      titleMobile: '',
      paragraph: 'One place to meet all CME requirements for the rest of your career!',
      btnColor: 'grey lighten-5 primary--text',
      btnText: 'Get the lifetime plan',
      btnSecondaryText: 'Sign up with other plans',
      btnSize: '201px',
      bgClass: 'mobile-lifetime-banner-bg',
    },
    bannerInfo: [
      {
        type: 'articles',
        title: 'Fast. Relevant. All in one place.',
        titleMobile: 'Fast. Relevant. All in one place.',
        paragraph: 'Stay up-to-date and meet all of your CME and MOC requirements effortlessly.',
        paragraphMobile: 'Stay up-to-date and meet all of your CME and MOC requirements effortlessly.',
        btnColor: 'cold-gradient white--text',
        btnText: 'Join Acapedia',
        btnSize: '150px',
        bgClass: 'mobile-articles-banner-bg',
      },
      {
        type: 'courses',
        title: 'Boost your career, knowledge and learning with our exclusive content',
        titleMobile: 'Boost your career, knowledge and learning with our exclusive content',
        paragraph: `Join our community as a <strong>paid subscriber</strong> and get access to our premium courses
                        to fulfill all of your continuing education requirements.`,
        paragraphMobile: `Join our community as a <strong>paid subscriber</strong> and get access to premium courses.`,
        btnColor: 'accent white--text',
        btnText: 'Become a Pro member',
        btnSize: '221px',
        bgClass: 'mobile-courses-banner-bg',
      },
      {
        type: 'special-requirements',
        title: 'Complete your special requirements with our exclusive content',
        titleMobile: 'Complete your special requirements with our exclusive content',
        paragraph: `We help busy physicians meet their requirements efficiently with smart suggestions of articles and premium courses.`,
        paragraphMobile: `We help busy physicians meet their requirements with smart suggestions of articles and premium courses.`,
        btnColor: 'accent white--text',
        btnText: 'Become a Pro member',
        btnSize: '221px',
        bgClass: 'mobile-special-requirements-banner-bg',
      },
    ],
  }),
  computed: {
    isFeedRoute() {
      return (
        this.isMainFeedRoute ||
        this.isPremiumCoursesFeedRoute ||
        this.isSpecialRequirementsFeedRoute ||
        this.isMainFeedTopicStatesRoute
      );
    },
    isMainFeedRoute() {
      return this.$route.name === 'home';
    },
    isPremiumCoursesFeedRoute() {
      return this.$route.name === 'premium-courses-feed';
    },
    isSpecialRequirementsFeedRoute() {
      return this.$route.name === 'special-requirements' || this.$route.name === 'special-requirements-topic-states';
    },
    isMainFeedTopicStatesRoute() {
      return this.$route.name === 'main-feed-topic-states';
    },
    bannerResource() {
      if (!this.isFeedRoute) {
        return;
      }

      return this.shouldShowLifetimePromoBanner
        ? [this.updatedBannerInfo[0], this.updatedBannerInfo[1]]
        : this.updatedBannerInfo[0];
    },
    updatedBannerInfo() {
      const updatedBanner = [...this.bannerInfo];
      const indexToUpdate = this.shouldShowLifetimePromoBanner ? 1 : 0;

      updatedBanner[indexToUpdate] = {
        ...updatedBanner[indexToUpdate],
        btnColor: 'accent white--text',
        btnText: 'Start for Free',
      };

      return updatedBanner;
    },
    shouldShowLifetimePromoBanner() {
      return this.lifetimeIsActive && ENABLE_LIFETIME_PROMO_BANNER;
    },
  },
  async mounted() {
    const responseLifetime = await this.getIfLifetimeIsActive();
    this.lifetimeIsActive = responseLifetime.isActive;

    if (this.shouldShowLifetimePromoBanner) {
      this.lifetimeInfo.title = `Pay <span class="text text-xxl-large-regular text-decoration-line-through">$${responseLifetime.price}</span> <span class="accent--text">$${responseLifetime.priceWithDiscountByDefault}</span> once and access our exclusive content for <span class="accent--text">LIFE</span>.`;
      this.lifetimeInfo.titleMobile = `Pay <span class="text text-xxl-large-regular text-decoration-line-through">$${responseLifetime.price}</span> <span class="accent--text">$${responseLifetime.priceWithDiscountByDefault}</span> and meet all CME requirements for the rest of your career.`;
      this.lifetimeInfo.saveMoney = responseLifetime.saveMoney;
      this.bannerInfo.unshift(this.lifetimeInfo);
    }

    this.loaded = true;
  },
  methods: {
    ...mapActions('subscriptions', ['getIfLifetimeIsActive']),
  },
};
</script>
