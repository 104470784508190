var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": _vm.width,
      "height": _vm.height,
      "viewBox": "0 0 73 76",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M42.1399 29.8612L35.045 0L27.9473 29.8612L0.348633 36.8863L27.9473 43.908L35.045 75.3513L42.1399 43.908L72.146 36.8863L42.1399 29.8612Z",
      "fill": _vm.fill
    }
  }), _c('defs', [_c('linearGradient', {
    attrs: {
      "id": _vm.linear,
      "x1": "71.9752",
      "y1": "36.7567",
      "x2": "0.348632",
      "y2": "36.7567",
      "gradientUnits": "userSpaceOnUse"
    }
  }, [_c('stop', {
    attrs: {
      "stop-color": "#FFB018"
    }
  }), _c('stop', {
    attrs: {
      "offset": "1",
      "stop-color": "#FFB018",
      "stop-opacity": "0"
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }