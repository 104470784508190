<script>
import { formatToCompactNotation } from '@/utils/number.utils';

export default {
  data: () => ({
    phone: {
      wellFormedPhoneNumber: (phoneNumber) => {
        return phoneNumber.replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '');
      },
    },
    number: {
      shrinkNumberAmount: (number) => {
        if (number >= 1000) {
          return (number / 1000).toFixed(1) + 'K';
        }

        return number;
      },
      formatNumberWithDecimals(number, ignoreDecimalsInCaseOfIntegerNumber) {
        let fixedNumber = number;

        if (typeof number !== 'number') {
          fixedNumber = Number(number);
        }

        return fixedNumber.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
          minimumFractionDigits: fixedNumber % 1 === 0 && ignoreDecimalsInCaseOfIntegerNumber ? 0 : 2,
        });
      },
    },
    parse: {
      richText(unformatedText) {
        let specialCharacters = unformatedText.replace(/START#SUP#START/g, '<sup>');
        specialCharacters = specialCharacters.replace(/END%SUP%END/g, '</sup>');

        specialCharacters = specialCharacters.replace(/START#SUB#START/g, '<sub>');
        specialCharacters = specialCharacters.replace(/END%SUB%END/g, '</sub>');

        specialCharacters = specialCharacters.replace(/START#I#START/g, '<i>');
        specialCharacters = specialCharacters.replace(/END%I%END/g, '</i> ');

        specialCharacters = specialCharacters.replace(/START#B#START/g, '<br><br><b>');
        specialCharacters = specialCharacters.replace(/END%B%END/g, '</b>');

        let firstEolTagToBeRemoved = '<br><br>';
        specialCharacters = specialCharacters.replace(firstEolTagToBeRemoved, '');

        return `${specialCharacters}`;
      },
    },
    date: {
      billingReadableDateFormat(date, { enableCompatibleDate = true, timezone } = {}) {
        let compatibleDate = date;

        if (enableCompatibleDate) {
          // iOS Safari do not handle dates with '-' character for the format YYYY MM DD
          compatibleDate = date?.replaceAll('-', '/');
        }

        const formatOptions = {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        };

        if (timezone) {
          formatOptions.timeZone = timezone;
        }

        if (compatibleDate) {
          return new Date(compatibleDate)?.toLocaleDateString('en-US', formatOptions);
        }
        return null;
      },
      ordinalSuffix(i) {
        const j = i % 10;
        const k = i % 100;
        if (j == 1 && k != 11) {
          return i + 'st';
        }
        if (j == 2 && k != 12) {
          return i + 'nd';
        }
        if (j == 3 && k != 13) {
          return i + 'rd';
        }
        return i + 'th';
      },
    },
    referral: {
      urlToShareReferralLink(referralCode) {
        return `${process.env.VUE_APP_URL}/register?referralCode=${referralCode}`;
      },
    },
    onboardingEnum: { PENDING: 'pending', DONE: 'done' },
    quiz: {
      minNumberOfCorrectQuestionsToPassTheQuiz(numberOfQuestions) {
        const MIN_PERCENTAGE_TO_PASS_QUIZ = 0.8;
        return Math.ceil(numberOfQuestions * MIN_PERCENTAGE_TO_PASS_QUIZ);
      },
    },
    vote: {
      formatVotesAsReadable(votes) {
        return formatToCompactNotation(votes);
      },
    },
  }),
};
</script>
