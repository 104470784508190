<template>
  <v-app
    v-if="finishInitLoad"
    :class="[applicationBackground, { 'quiz-min-height': checkIsQuizRoute }]"
  >
    <template v-if="$route.meta?.unwrapLayoutEnvelope && $route?.meta?.usesNewLayout">
      <keep-alive v-if="$route.meta?.keepAlive">
        <router-view />
      </keep-alive>
      <router-view v-else />
    </template>
    <template v-else-if="hasToUseOldLayout">
      <LayoutComponent>
        <template #content>
          <keep-alive
            v-if="
              $route.name === 'home' ||
              $route.name === 'main-feed-topic-states' ||
              $route.name === 'article' ||
              $route.name === 'article-quiz' ||
              $route.name === 'article-file-view' ||
              $route.name === 'article-disclosure' ||
              $route.name === 'auditor-article-disclosure' ||
              $route.name === 'auditor-article-pretest' ||
              $route.name === 'special-requirements-article-quiz' ||
              $route.name === 'special-requirements-article-pdf'
            "
          >
            <router-view />
          </keep-alive>
          <router-view v-else />
        </template>
      </LayoutComponent>
    </template>
    <router-view v-else />
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import LayoutComponent from '@/components/LayoutComponent';

export default {
  name: 'App',
  metaInfo: {
    // if no sub-components specify a metaInfo.title, this title will be used
    title: 'Earn CME and MOC with Acapedia',
  },
  components: { LayoutComponent },
  data: () => ({
    finishInitLoad: false,
    applicationBackground: 'background-base',
  }),
  computed: {
    ...mapGetters('feed', ['isLayoutPretest', 'isShowPretestResult']),
    ...mapGetters('menu', ['shouldShowBackdrop']),
    checkIsQuizRoute() {
      return (
        this.$route.name === 'article-quiz' ||
        this.$route.name === 'auditor-article-quiz' ||
        this.$route.name === 'course-module-quiz' ||
        this.$route.name === 'special-requirements-article-quiz'
      );
    },
    canShowLayoutComponent() {
      // When we refresh the page vue router first goes to the home page and then to the current page
      // we want to have the the route meta data to be available before we render the layout component
      // the reason of this is because we load routes in lazy load
      return !!this.$route?.name;
    },
    // Keeping for clarity
    hasToUseOldLayout() {
      return this.canShowLayoutComponent &&
      this.$route.name !== 'login' &&
      this.$route.name !== 'register' &&
      this.$route.name !== 'password-reset' &&
      this.$route.name !== 'password-create-fluorosafety' &&
      this.$route.name !== 'privacy' &&
      this.$route.name !== 'terms' &&
      this.$route.name !== 'error' &&
      this.$route.name !== 'billing-professional-receipt' &&
      this.$route.name !== 'billing-lifetime-receipt';
    }
  },
  watch: {
    isLayoutPretest: {
      handler: function () {
        if (this.isLayoutPretest) {
          this.applicationBackground = 'background-pretest';
          return;
        }
        this.applicationBackground = 'background-base';
      },
    },
    '$route.path': function () {
      if (this.isShowPretestResult) {
        return;
      }

      if (this.$route.name === 'home' || this.$route.name === 'article') {
        this.setGeneralVeil(false);
      }
    },
  },
  async mounted() {
    this.finishInitLoad = true;
  },
  methods: {
    ...mapActions('menu', ['setGeneralVeil']),
  },
};
</script>
<style lang="scss">
@import './sass/main.scss';
.background-pretest .v-application--wrap {
  background-color: transparent !important;
}

.background-base .v-application--wrap {
  background-color: var(--v-background-base) !important;
}
@media #{map-get($display-breakpoints, 'mobile-and-down')} {
  .quiz-min-height body,
  .v-application--wrap {
    min-height: fit-content !important;
  }
}
</style>
