export default {
  namespaced: true,
  state: () => ({
    hasToShowBackNavigation: false,
    title: null,
    veil: false,
    isInternalRouting: false,
    isReportCustom: false,
    isReportOpen: false,
    currentReadingAtArticleId: 0,
    isFromFeedView: false,
    hideAllMenu: false,
    hideCredits: false,
    takeQuizButtonDisabled: false,
  }),
  mutations: {
    // @deprecated - use setHasToShowBackNavigation instead
    setHideMenu(state, data) {
      state.hasToShowBackNavigation = data;
    },
    // This is causing error warnings in console
    setHasToShowBackNavigation(state, hasToShowBackNavigation) {
      state.hasToShowBackNavigation = hasToShowBackNavigation;
    },
    setTitle(state, data) {
      state.title = data;
    },
    setVeil(state, data) {
      state.veil = data;
    },
    setIsInternalRouting(state, data) {
      state.isInternalRouting = data;
    },
    setCurrentReadingAtArticleId(state, data) {
      state.currentReadingAtArticleId = data;
    },
    setIsReportCustom(state, data) {
      state.isReportCustom = data;
    },
    setIsReportOpen(state, data) {
      state.isReportOpen = data;
    },
    setIsFromFeedView(state, data) {
      state.isFromFeedView = data;
    },
    setAllHideMenu(state, data) {
      state.hideAllMenu = data;
    },
    setHideCredits(state, data) {
      state.hideCredits = data;
    },
    setTakeQuizButtonDisabled(state, data) {
      state.takeQuizButtonDisabled = data;
    },
  },
  actions: {
    // @deprecated - use showBackNavigation instead
    hideTopMenu(context, payload) {
      context.commit('setHideMenu', payload);
    },
    showBackNavigation(context, hasToShowBackNavigation) {
      context.commit('setHasToShowBackNavigation', hasToShowBackNavigation);
    },
    setTopMenuTitle(context, payload) {
      context.commit('setTitle', payload);
    },
    // @deprecated - use showBackdrop and hideBackdrop instead
    setGeneralVeil(context, payload) {
      context.commit('setVeil', payload);
    },
    setIsInternalRouting(context, payload) {
      context.commit('setIsInternalRouting', payload);
    },
    setCurrentReadingAtArticleId(context, payload) {
      context.commit('setCurrentReadingAtArticleId', payload);
    },
    setIsReportCustom(context, payload) {
      context.commit('setIsReportCustom', payload);
    },
    setIsReportOpen(context, payload) {
      context.commit('setIsReportOpen', payload);
    },
    setIsFromFeedView(context, payload) {
      context.commit('setIsFromFeedView', payload);
    },
    hideAllTopMenu(context, payload) {
      context.commit('setAllHideMenu', payload);
    },
    showBackNavigationWithTitle({ commit }, title) {
      commit('setHideMenu', true);
      commit('setTitle', title);
    },
    hideCreditsInNavBar(context, payload) {
      context.commit('setHideCredits', payload);
    },
    takeQuizButtonDisabledInNavBar(context, payload) {
      context.commit('setTakeQuizButtonDisabled', payload);
    },
    hideAndUnsetBackSection({ commit }) {
      commit('setHideMenu', false);
      commit('setTitle', null);
    },
    setInternalRoutingWithTitle({ commit }, title) {
      commit('setIsInternalRouting', true);
      commit('setTitle', title);
    },
    showBackdrop({ commit }) {
      commit('setVeil', true);
    },
    hideBackdrop({ commit }) {
      commit('setVeil', false);
    },
  },
  getters: {
    getTitle: (state) => {
      return state.title;
    },
    getBackIfIsInternalRouting: (state) => {
      return state.isInternalRouting;
    },
    getCurrentReadingAtArticleId: (state) => {
      return state.currentReadingAtArticleId;
    },
    getIsReportCustom: (state) => {
      return state.isReportCustom;
    },
    getIsReportOpen: (state) => {
      return state.isReportOpen;
    },
    getIsFromFeedView: (state) => {
      return state.isFromFeedView;
    },
    getHideAllMenu: (state) => {
      return state.hideAllMenu;
    },
    getHideCreditsInMenu: (state) => {
      return state.hideCredits;
    },
    getTakeQuizButtonDisabledInNavBar: (state) => {
      return state.takeQuizButtonDisabled;
    },
    getHasToShowBackNavigation: (state) => {
      return state.hasToShowBackNavigation;
    },
    shouldShowBackdrop: (state) => {
      return state.veil;
    },
  },
};
