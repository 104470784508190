<script>
export default {
  data: () => ({
    rules: {
      required: (v) => !!v || 'This field is required.',
      email: (v) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || 'Invalid e-mail.';
      },
      password: (v) => {
        if (!(v && v.length >= 8)) return 'Password must have at least 8 characters';
        if (v && v.length > 128) return 'Password must have less than 128 characters';
        if (!/(?=.*[A-Z])/.test(v)) return 'Must have one uppercase character';
        if (!/(?=.*[a-z])/.test(v)) return 'Must have one lowercase character';
        if (!/(?=.*\d)/.test(v)) return 'Must have one number';
        if (!/([~`^&*()\-_+={}[\]|/\\:;"'<>,.?!@#$%])/.test(v))
          return 'Must have one special character [~`^&*()-_+={}[]|/\\:;"\'<>,.?!@#$%]';
        else return true;
      },
      phoneNumber: (v) => {
        if (v) {
          if (v && !/^\([2-9][0-9][0-9]\)$/.test(v.split(' ')[0])) return 'Invalid US area code.';
          if (v && !/^\([2-9][0-9][0-9]\) [0-9]{3}-[0-9]{4}$/.test(v)) return 'Invalid phone number.';
          else return true;
        }
        return false;
      },
      onlyOneNumber: (v) => {
        const pattern = /^[0-9]$/;
        return pattern.test(v) || 'Invalid number.';
      },
      fullCode: (v) => {
        if (!(v && v.length == 6)) return 'Code must be 6 characters long';
        else return true;
      },
      names: (v) => {
        if (!(v && v.length <= 30)) return 'Please enter only the first 30 characters of your name.';
        if (v && v.trim().length === 0) return "Can't be only blank spaces.";
        const pattern = /^([a-zA-Z'\s-]+)( [a-zA-Z'-]+)?$/;
        return pattern.test(v) || 'Invalid first name: only letters, simple dash or apostrophe.';
      },
      lastName: (v) => {
         if (!(v && v.length <= 30)) return 'Please enter only the first 30 characters of your name.';
        if (v && v.trim().length === 0) return "Can't be only blank spaces.";
        const pattern = /^([a-zA-Z'\s-]+)( [a-zA-Z'-]+)?$/;
        return pattern.test(v) || 'Invalid last name: only letters, simple dash or apostrophe.';
      },
      cardholder: (v) => {
        if (!(v && v.length <= 60)) return 'Please enter only the first 60 characters of your cardholder name.';
        const pattern = /^[A-Z \-a-z']+$/;
        return pattern.test(v) || 'Invalid cardholder name: only letters, one space, simple dash or apostrophe.';
      },
      pubmedId: (v) => {
        if (v == null) return false;
        if (v && v.length > 30) return 'Please, enter only less than 30 characters';

        const pattern = /[0-9]+$/;
        return pattern.test(v) || 'Invalid ID.';
      },
      notNullUndefinedOrEmpty: (v) => {
        return v !== null && v !== undefined && v !== '';
      },
      arrayNotEmpty: (v) => {
        return Array.isArray(v) && v.length > 0;
      },
      keyword: (v) => {
        if (v !== null && v !== '' && v !== undefined) {
          if (v.length >= 30) return 'Please enter shorter keywords.';
          const pattern = /^[A-Z \-a-z0-9]+$/;
          return pattern.test(v) || 'The keyword contain invalid characters.';
        }
        return "You can't add empty keywords.";
      },
      number: (v, fieldName) => {
        return (!isNaN(parseFloat(v)) && isFinite(v)) || `The ${fieldName} must be a number`;
      },
      zip: (v) => {
        if (v !== null && v !== '' && v !== undefined) {
          if (!(!isNaN(parseInt(v)) && isFinite(v))) return `The ZIP must be a number`;

          if (!isNaN(parseInt(v)) && isFinite(v) && v <= 0) return `The ZIP can't be a negative number or zero.`;

          if (v.toString().length < 5 || v.toString().length > 5)
            return `The ZIP must be 5 digits long, if less add leading zeros.`;

          if (!isNaN(parseFloat(v)) && isFinite(v) && v.indexOf('.') > -1) return `The ZIP can't be a decimal number.`;

          if (!isNaN(parseInt(v)) && isFinite(v) && v.toString().length === 5) {
            return true;
          }
        }
        return false;
      },
      priceLessThanTen: (v) => {
        if (!(v && v < 11)) return "Price can't be bigger than ten dollars";
        else return true;
      },
      priceNumberPositive: (v) => {
        if (v && v <= -1) return "Price can't be less than zero dollars";
        else return true;
      },
      paymentCardField: (v, fieldName) => {
        if (v !== null && v !== '' && v !== undefined) {
          if (v.length > 120) return `Please enter a shorter ${fieldName}.`;
          return true;
        }
        return `${fieldName} can't be empty`;
      },
      maxAllowedCharactersForReport: (v) => {
        if (v !== null && v !== undefined) {
          if (v.length > 250) return 'Please enter a shorter description.';
        }

        if (v?.length === 0 || v === '') return 'Please enter a description.';

        return true;
      },
      maxAllowedCharactersForGeneralEvaluation: (v) => {
        if (v !== null && v !== undefined) {
          if (v.length > 250) return 'Please enter a shorter description.';
        }

        return true;
      },
    },
  }),
};
</script>
