<template>
  <div
    v-if="hasToShowActions"
    class="d-flex"
  >
    <router-link
      v-if="hasAcceptedActivityDisclosure"
      class="clickable d-flex align-center text-decoration-none"
      target="_blank"
      :to="courseDisclosurePdfRoute"
    >
      <ph-file-pdf
        size="18"
        color="var(--v-primary-base)"
        weight="light"
      />
      <span class="ml-2 heading heading-s-small primary--text">Disclosure</span>
    </router-link>
    <div
      v-if="hasToShowMainAction"
      :class="hasAcceptedActivityDisclosure ? 'ml-4' : ''"
    >
      <v-btn
        class="heading heading-s-small white--text shadow-dark-button"
        :class="{ 'cold-gradient': !hasToDisableMainAction }"
        ripple
        width="142px"
        height="36px"
        :disabled="hasToDisableMainAction"
        @click="handleClickMainAction"
      >
        {{ mainActionText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { PhFilePdf } from 'phosphor-vue';
import { abstractMainActionMixin } from '@/components/premiumCourses/abstract/abstractMainActionMixin';
import { mapActions } from 'vuex';

export default {
  name: 'AbstractHeaderActions',
  components: {
    PhFilePdf,
  },
  mixins: [abstractMainActionMixin],
  computed: {
    hasToDisableMainAction() {
      return !this.hasAcceptedActivityDisclosure;
    },
    courseDisclosurePdfRoute() {
      return {
        name: 'course-disclosure-pdf',
        params: {
          courseId: this.$courseId,
        },
      };
    },
  },
  watch: {
    hasToShowActions(hasToShow) {
      if (!hasToShow) {
        return;
      }

      this.hideCreditsInNavBar(true);
    },
  },
  methods: {
    ...mapActions('menu', ['hideCreditsInNavBar']),
  },
};
</script>
