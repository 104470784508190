<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 73 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.1399 29.8612L35.045 0L27.9473 29.8612L0.348633 36.8863L27.9473 43.908L35.045 75.3513L42.1399 43.908L72.146 36.8863L42.1399 29.8612Z"
      :fill="fill"
    />
    <defs>
      <linearGradient
        :id="linear"
        x1="71.9752"
        y1="36.7567"
        x2="0.348632"
        y2="36.7567"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFB018" />
        <stop
          offset="1"
          stop-color="#FFB018"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'courses'
    },
    width: {
      default: '79px',
      type: String,
    },
    height: {
      default: '83px',
      type: String,
    },
  },
  computed: {
    fill() {
      return `url(#paint0_linear_911_4285_${this.id})`;
    },
    linear() {
      return `paint0_linear_911_4285_${this.id}`;
    }
  }
};
</script>
