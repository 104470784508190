import axios from 'axios';
import { responseHandler, getResponseDataNotWrapped } from '@/api/utilities';

const doGetBoards = async () => {
  return responseHandler(axios.get('/boards'), {
    responseDataGetter: getResponseDataNotWrapped,
  });
};

const doUpdateMedicalBoard = async (payload) => {
  return responseHandler(axios.put('/user/boards', payload), {
    responseDataGetter: getResponseDataNotWrapped,
  });
};

export { doGetBoards, doUpdateMedicalBoard };
