var render = function render(){
  var _vm$$route$meta, _vm$$route, _vm$$route$meta2, _vm$$route$meta3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.finishInitLoad ? _c('v-app', {
    class: [_vm.applicationBackground, {
      'quiz-min-height': _vm.checkIsQuizRoute
    }]
  }, [(_vm$$route$meta = _vm.$route.meta) !== null && _vm$$route$meta !== void 0 && _vm$$route$meta.unwrapLayoutEnvelope && (_vm$$route = _vm.$route) !== null && _vm$$route !== void 0 && (_vm$$route$meta2 = _vm$$route.meta) !== null && _vm$$route$meta2 !== void 0 && _vm$$route$meta2.usesNewLayout ? [(_vm$$route$meta3 = _vm.$route.meta) !== null && _vm$$route$meta3 !== void 0 && _vm$$route$meta3.keepAlive ? _c('keep-alive', [_c('router-view')], 1) : _c('router-view')] : _vm.hasToUseOldLayout ? [_c('LayoutComponent', {
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm.$route.name === 'home' || _vm.$route.name === 'main-feed-topic-states' || _vm.$route.name === 'article' || _vm.$route.name === 'article-quiz' || _vm.$route.name === 'article-file-view' || _vm.$route.name === 'article-disclosure' || _vm.$route.name === 'auditor-article-disclosure' || _vm.$route.name === 'auditor-article-pretest' || _vm.$route.name === 'special-requirements-article-quiz' || _vm.$route.name === 'special-requirements-article-pdf' ? _c('keep-alive', [_c('router-view')], 1) : _c('router-view')];
      },
      proxy: true
    }], null, false, 2233686367)
  })] : _c('router-view')], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }