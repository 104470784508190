import axios from 'axios';
import { responseHandler, getResponseDataNotWrapped, getResponseDataVOld } from '@/api/utilities';

const doGetUserSubscription = async () => {
  return responseHandler(axios.get('/subscription/user'), {
    responseDataGetter: getResponseDataVOld,
    customErrorMessage: 'An error occurred while fetching your subscription details. Please contact support.',
  });
};

const doGetPlans = async () => {
  return responseHandler(axios.get('/subscription/plans'));
};

const doGetPaymentIntent = async () => {
  return axios.get('/subscription/payment-intent');
};

const doPostConfirmSubscription = async (payload) => {
  return responseHandler(axios.post('/subscription/confirm-payment', payload), {
    hasToShowToasts: false,
    responseDataGetter: getResponseDataVOld,
  });
};

const doPostConfirmSingleCharge = async (payload) => {
  return responseHandler(axios.post('/single-charge/confirm-payment', payload), {
    hasToShowToasts: false,
  });
};

const doGetIfLifetimeIsActive = async () => {
  return responseHandler(axios.get('/single-charge/plans/lifetime'));
};

const doPostConfirmDowngradeSubscription = async (payload) => {
  return axios.post('/subscription/downgrade', payload);
};

const doGetSubscriptionChangePreview = async (payload) => {
  return axios.get('/subscription/change/preview', payload);
};

const doPostConfirmFreeSubscription = async (payload) => {
  return responseHandler(axios.post('/subscription/free', payload), {
    responseDataGetter: getResponseDataNotWrapped,
    showOnlyErrorMessagesAsToast: false,
  });
};

const doGetDiscountByPromoCode = async (payload) => {
  return responseHandler(axios.get('/subscription/promo/check', payload), {
    responseDataGetter: getResponseDataNotWrapped,
  });
};

const doGetReceipt = async (payload) => {
  return responseHandler(axios.get('/subscription/receipt', payload), {
    responseDataGetter: getResponseDataNotWrapped,
  });
};

const doGetProfessionalReceiptDetails = async (payload) => {
  return responseHandler(axios.post('/user/billing/professional/receipt/pdf', payload), {
    responseDataGetter: getResponseDataNotWrapped,
  });
};

const doGetLifetimeReceiptDetails = async (payload) => {
  return responseHandler(axios.post('/user/billing/lifetime/receipt/pdf', payload), {
    responseDataGetter: getResponseDataNotWrapped,
  });
};

export {
  doGetUserSubscription,
  doGetPlans,
  doGetPaymentIntent,
  doPostConfirmSubscription,
  doGetIfLifetimeIsActive,
  doPostConfirmSingleCharge,
  doPostConfirmDowngradeSubscription,
  doGetSubscriptionChangePreview,
  doPostConfirmFreeSubscription,
  doGetDiscountByPromoCode,
  doGetReceipt,
  doGetProfessionalReceiptDetails,
  doGetLifetimeReceiptDetails,
};
