import { onMounted } from 'vue';

export function useAtomFieldTestId({ fieldRef, fieldType }) {
  onMounted(setTestId);

  function setTestId() {
    const $field = fieldRef.value.$el;
    const $input = $field.querySelector('input');
    const labelText = $field.parentElement.querySelector('label')?.textContent;

    if (!$input) return;

    const existingTestId = $input.getAttribute('data-testid');
    if (existingTestId) return;

    const inputId = $input.id;
    const inputName = $input.name;
    const placeholder = $input.placeholder;

    let dataTestId = '';

    const isAutoGeneratedInputId = inputId.startsWith('input-');
    const hasValidInputId = inputId && !isAutoGeneratedInputId;

    if (hasValidInputId) {
      dataTestId = inputId;
    } else if (inputName) {
      dataTestId = inputName;
    } else if (labelText) {
      dataTestId = transformToKebabCase(labelText);
    } else if (placeholder) {
      dataTestId = transformToKebabCase(placeholder);
    }

    if (!dataTestId) return;

    $input.setAttribute('data-testid', `${dataTestId}-${fieldType}`);
  }

  // TODO: move to a utils file
  function transformToKebabCase(text) {
    return text.replace('*', '').trim().replace(/ /g, '-').toLowerCase();
  }
}
