import Vue from 'vue';
import Vuex from 'vuex';
import feed from '@/store/modules/feed';
import article from '@/store/modules/article';
import auth from '@/store/modules/auth';
import specialties from '@/store/modules/specialties';
import feedCustomizationFilters from '@/store/modules/feedCustomizationFilters';
import issue from '@/store/modules/issue';
import keywords from '@/store/modules/keywords';
import profile from '@/store/modules/profile';
import transcripts from '@/store/modules/transcripts';
import subscriptions from '@/store/modules/subscriptions';
import menu from '@/store/modules/menu';
import referrals from '@/store/modules/referrals';
import courses from '@/store/modules/courses';
import boards from '@/store/modules/boards';
import specialRequirements from '@/store/modules/specialRequirements';

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0',
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion;
    },
  },
  mutations: {},
  modules: {
    feed,
    article,
    auth,
    specialties,
    feedCustomizationFilters,
    issue,
    keywords,
    profile,
    transcripts,
    subscriptions,
    menu,
    referrals,
    courses,
    boards,
    specialRequirements,
  },
});

export default store;
export const useStore = () => store;
