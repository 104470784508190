var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AcaDialog', {
    attrs: {
      "title": "Complete profile to get your credits",
      "description": "To download your transcript or certificate, we need some basic information about you to award you your CME and MOC credits.",
      "confirm-enabled": _vm.canConfirm,
      "confirm-is-loading": _vm.loadingConfirm,
      "persistent": "",
      "show-close": "",
      "show-x-button": _vm.hasToShowCloseBtn,
      "data-testid": "complete-profile-dialog"
    },
    on: {
      "on-close": _vm.handleDialogClose,
      "on-confirm": _vm.handleDialogConfirm
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_vm._t("append", function () {
          return [_c('form', {
            attrs: {
              "id": "profileForm"
            },
            on: {
              "submit": function ($event) {
                $event.preventDefault();
                return _vm.submitProfile.apply(null, arguments);
              }
            }
          }, [_c('BoardComponent', {
            attrs: {
              "id": _vm.boardId,
              "board-name": _vm.boardName,
              "board-number": _vm.boardNumber,
              "degree": _vm.degree,
              "date-of-birth": _vm.dateOfBirth,
              "type": 'dialog',
              "custom-key": _vm.customKey,
              "show-required-asterisk": true
            },
            on: {
              "degree": _vm.onChangeDegree,
              "medical-board": _vm.onChangeMedicalBoard,
              "has-to-required-board-information": _vm.onHasToRequiredBoardInformation,
              "board-number": _vm.onChangeBoardNumber,
              "date-of-birth": _vm.onChangeDateOfBirth,
              "allow-submit-without-full-details": _vm.onBoardDegreeChanges
            }
          })], 1)];
        })];
      },
      proxy: true
    }], null, true)
  });

}
var staticRenderFns = []

export { render, staticRenderFns }