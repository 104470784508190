import store from '@/store/index';
import router from '@/router/router';

import { isContentTypeJsonOfError, isUnauthenticatedError } from './utilities';

export function axiosResponseErrorInterceptor(err) {
  if (!err.response) {
    return Promise.reject(err);
  }

  return isContentTypeJsonOfError(err) ? interceptJsonError(err) : interceptHtmlError(err);
}

const interceptJsonError = async (err) => {
  if (isExpiredTokenError(err)) {
    logoutAndRedirectToLogin();
  }

  throw err;
};

const isExpiredTokenError = (err) => isUnauthenticatedError(err) && !isLoginOrRegisterRoute(router);

const isLoginOrRegisterRoute = (router) => {
  const { name: currentRouteName } = router.currentRoute;
  return currentRouteName === 'login' || currentRouteName === 'register';
};

const logoutAndRedirectToLogin = () => {
  store.dispatch('auth/localLogout');

  const redirectRoute = {
    name: 'login',
  };

  if (router.currentRoute.name === 'premium-courses-feed') {
    redirectRoute.query = { nextRoute: router.currentRoute.name };
  }

  router.push(redirectRoute).catch(() => {});
};

const interceptHtmlError = (err) => {
  // In case the response comes from an actual render page then we proceed as follows
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  const statusCode = err.response?.status ?? 500;
  let message = null;

  // don't show the 429 error, about too many requests
  if (statusCode === 429) {
    return;
  }

  switch (statusCode) {
    case 400:
      message = "We couldn't process your request.";
      break;

    case 401:
      message = 'The page you are looking for requires special authorization';
      break;

    case 403:
      message = 'The action you attempted was forbidden';
      break;

    case 404:
      message = 'The page you are looking for is not available';
      break;

    case 422:
      message = "We couldn't process your request";
      break;

    case 500:
      message = 'Internal server error';
      break;

    default:
      message = 'This is an unknown error';
      break;
  }

  // Finally we show the error page with the specific error code and message
  if (statusCode) {
    router.push({ name: 'error', params: { statusCode: statusCode, message: message } }).catch(() => {});
  }

  // we have to reject the promise here, otherwise the promise will be resolved with undefined
  return Promise.reject(err);
};
