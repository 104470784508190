<template>
  <div
    v-if="hasToShowAlert"
    class="alert-container"
  >
    <div class="d-flex align-center">
      <span
        class="text"
        :class="$breakpoint.tabletAndUp ? 'text-l-large-bold' : 'text-m-large-bold'"
      >
        You have used up your free credits.
      </span>
      <v-btn
        class="heading heading-s-small primary--text shadow-light-button"
        :class="{
          'ml-2': $breakpoint.mobileAndDown,
          'ml-4': $breakpoint.tabletAndUp,
        }"
        ripple
        depressed
        height="36px"
        width="auto"
        :to="{ name: 'my-plan', params: { upgrade: true } }"
      >
        <CrownFilledYellowIcon class="mr-2" />
        Upgrade
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CrownFilledYellowIcon from '@/assets/CrownFilledYellowIcon';

export default {
  name: 'FreeTrialReachedLimitsAlert',
  components: {
    CrownFilledYellowIcon,
  },
  computed: {
    ...mapGetters('auth', ['currentUser', 'hasUnAwardedCredits']),
    ...mapGetters('subscriptions', ['hasActiveSubscription']),
    isLoggedInUser() {
      return !!this.currentUser;
    },
    isFreeTrialUser() {
      return this.isLoggedInUser && !this.hasActiveSubscription;
    },
    hasToShowAlert() {
      return this.isFreeTrialUser && this.hasUnAwardedCredits && this.routes;
    },
    /**
     * WARNING: Stop adding new routes here. Instead, migrate the page using the new Layout components
     * and use the CreditLimitReachedNotificationBanner instead.
     */
    routes() {
      return (
        this.IsFeedRoute ||
        this.isSpecialRequirementsRoute ||
        this.isSpecialRequirementsTopicFeedRoute ||
        this.isSpecialRequirementAbstract ||
        this.isSpecialRequirementArticlePDF ||
        this.isSpecialRequirementQuizArticle ||
        this.isTranscriptRoute
      );
    },
    IsFeedRoute() {
      return this.$route.name === 'home';
    },
    isSpecialRequirementsRoute() {
      return this.$route.name === 'special-requirements';
    },
    isSpecialRequirementsTopicFeedRoute() {
      return this.$route.name === 'special-requirements-topic-state-feed';
    },
    isSpecialRequirementAbstract() {
      return this.$route.name === 'special-requirements-article-abstract';
    },
    isSpecialRequirementArticlePDF() {
      return this.$route.name === 'special-requirements-article-pdf';
    },
    isSpecialRequirementQuizArticle() {
      return this.$route.name === 'special-requirements-article-quiz';
    },
    isTranscriptRoute() {
      return this.$route.name === 'transcripts';
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-container {
  position: sticky;
  top: 56px;
  z-index: 1;

  min-height: 44px;
  padding: 8px 24px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--v-accent-base);
  color: white;
  font-weight: 400;
}

@media #{map-get($display-breakpoints, 'small-desktop-and-up')} {
  .alert-container {
    top: 60px;
  }
}
</style>
