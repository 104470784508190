var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "illustrations-container"
  }, [_vm.$breakpoint.tabletAndUp ? _c('div', [_c('CheckListAnimationComponent', {
    staticClass: "check-list-animation-container",
    class: _vm.checkListClass,
    attrs: {
      "width": _vm.widthCheckList,
      "height": _vm.heightCheckList
    }
  })], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }