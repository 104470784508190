import Vue from 'vue';
import * as Sentry from '@sentry/browser';

export const isSuccessfulResponse = (response) => response?.status >= 200 && response?.status <= 299;
const NETWORK_ERROR_MESSAGE = 'Network error. Please check your internet connection';

// TODO: Handle when the server responds with an html with the error (such as non existing endpoint)
// Note: the error handler force the upper levels to use a try-catch due that is throwing the error again and not catching it
export const responseHandler = async (
  promise,
  {
    hasToShowToasts = true,
    hasToShowSuccessToasts = false,
    hasToShowErrorToasts = true,
    isPaginated = false,
    responseDataGetter = null,
    customErrorMessage = null,
  } = {},
) => {
  try {
    const response = await promise;

    const successApiMessage = response.data.message;
    showSuccessMessage(successApiMessage, { hasToShowToasts, hasToShowSuccessToasts });

    responseDataGetter ??= isPaginated ? getPaginatedResponseData : getResponseData;
    return responseDataGetter(response);
  } catch (error) {
    handleErrorResponse(error, { customErrorMessage, hasToShowToasts, hasToShowErrorToasts });

    throw error;
  }
};

const showSuccessMessage = (successMessage, { hasToShowToasts, hasToShowSuccessToasts }) => {
  if (!hasToShowToasts || !hasToShowSuccessToasts) return;

  Vue.toasted.global.showSuccess({ message: successMessage });
};

function handleErrorResponse(error, { customErrorMessage, hasToShowToasts, hasToShowErrorToasts }) {
  console.error(error);
  Sentry.captureException(error);

  if (isContentTypeJsonOfError(error)) {
    handleJsonError(error, { customErrorMessage, hasToShowToasts, hasToShowErrorToasts });
  } else if (isNetworkError(error)) {
    handleNetworkError();
  }
}

function handleJsonError(error, { customErrorMessage, hasToShowToasts, hasToShowErrorToasts }) {
  if (isInternalServerError(error)) {
    handleInternalServerError(error);
  } else {
    const errorMessage = customErrorMessage ? customErrorMessage : getErrorMessage(error);

    showErrorMessage(errorMessage, { hasToShowToasts, hasToShowErrorToasts });
  }
}

function handleNetworkError() {
  showErrorMessage(NETWORK_ERROR_MESSAGE, {
    hasToShowToasts: true,
    hasToShowErrorToasts: true,
  });
}

function handleInternalServerError() {
  const internalServerErrorPath = '/error/500';

  // We don't use the router here
  // because we get a circular dependency error
  if (window.location.pathname === internalServerErrorPath) return;

  window.location.href = internalServerErrorPath;
}

const getErrorMessage = (error) => {
  let firstSpecificApiErrorMessage = null;
  const errors = error.response?.data?.errors;

  if (errors) {
    const errorMessages = Object.values(errors);
    firstSpecificApiErrorMessage = errorMessages.flat()?.[0];
  }

  const errorApiMessage = firstSpecificApiErrorMessage ?? error.response?.data?.message;
  const otherErrorMessage = error.message;

  return errorApiMessage ?? otherErrorMessage;
};

const showErrorMessage = (errorMessage, { hasToShowToasts, hasToShowErrorToasts }) => {
  if (!hasToShowToasts || !hasToShowErrorToasts) return;

  if (errorMessage === NETWORK_ERROR_MESSAGE) return showNetworkErrorMessage(errorMessage);

  showToastedError(errorMessage);
};

const showNetworkErrorMessage = (errorMessage) => {
  const FIVE_SECONDS_IN_MS = 5000;
  const currentTime = new Date().getTime();
  const timeSinceLastNetworkError = currentTime - (Vue.toasted.global.lastTimeOfLastNetworkError ?? 0);
  const isLongEnoughSinceLastNetworkError = timeSinceLastNetworkError > FIVE_SECONDS_IN_MS;

  if (!isLongEnoughSinceLastNetworkError) return;

  // To avoid showing the same network error message multiple times
  Vue.toasted.global.lastTimeOfLastNetworkError = currentTime;
  showToastedError(errorMessage);
};

// Simple Facade around the toasted lib
const showToastedError = (errorMessage) => {
  Vue.toasted.global.showError({ message: errorMessage });
};

/**
 * @deprecated This function is no longer supported and should not be used.
 * Please use the `responseHandler` function instead.
 */
export const toastHandler = (
  promise,
  showToast = true,
  showOnlyToastErrors = false,
  responseDataGetter = getResponseDataVOld,
) => {
  return promise
    .then((response) => {
      if (showToast && !showOnlyToastErrors) {
        Vue.toasted.global.showSuccess({ message: response.data.message });
      }

      return responseDataGetter(response);
    })
    .catch((error) => {
      const customErrorMsg = error.response?.data;

      if (showToast || showOnlyToastErrors) {
        Vue.toasted.global.showError({ message: customErrorMsg ? customErrorMsg.message : error.message });
      }

      if (customErrorMsg) {
        return error.response;
      }

      return error;
    });
};

/**
 * @deprecated This function is no longer supported and should not be used.
 * Please use `getResponseData` function instead or `getPaginatedResponseData` if it is paginated.
 * Remember to wrap the api response in a envelope from the backend side using a "data" key.
 */
export const getResponseDataVOld = (response) => response;
export const getResponseDataNoContent = (response) => response;
export const getResponseDataNotWrapped = ({ data }) => data;

export const getResponseData = ({ data }) => data.data;
export const getPaginatedResponseData = ({ data }) => {
  const nextCursorPagination = data.links.next?.split('cursor=')[1];
  const previousCursorPagination = data.links.prev?.split('cursor=')[1];

  return {
    data: data.data,
    nextCursorPagination,
    previousCursorPagination,
  };
};

export const getContentTypeOfError = (error) => {
  if (!error.response?.headers) {
    return [];
  }

  return error.response.headers['content-type'];
};

export const isContentTypeJsonOfError = (error) => getContentTypeOfError(error).indexOf('application/json') !== -1;
export const isInternalServerError = (error) => error.response?.status === 500;
export const isNetworkError = (error) => !error.response;
export const isUnauthenticatedError = (error) => error.response?.status === 401;
