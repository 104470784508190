var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasToShowSpecialReqAlertPro ? _c('div', {
    staticClass: "alert-container",
    class: [!_vm.isSpecialRequirementCourseAbstractRoute && _vm.isFreeTrialUser ? 'alert-container-warning' : 'alert-container-special-requirement-course-abstract']
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_vm.hasToShowIcon && (!_vm.isFreeTrialUser || _vm.isSpecialRequirementCourseAbstractRoute) ? _c('ph-info', {
    staticClass: "mr-2",
    attrs: {
      "size": "24",
      "weight": "light"
    }
  }) : _vm._e(), _c('span', {
    staticClass: "text",
    class: _vm.$breakpoint.tabletAndUp ? 'text-l-large-regular' : 'text-m-large-regular'
  }, [_vm._v(_vm._s(_vm.alertText))]), _vm.isGuestUser ? _c('v-btn', {
    staticClass: "heading heading-s-small primary--text shadow-light-button",
    class: {
      'ml-2': _vm.$breakpoint.mobileAndDown,
      'ml-4': _vm.$breakpoint.tabletAndUp
    },
    attrs: {
      "ripple": "",
      "depressed": "",
      "height": "36px",
      "width": "auto",
      "to": {
        name: 'register'
      }
    }
  }, [_vm.hasToShowCrownIcon ? _c('crown-filled-yellow-icon', {
    staticClass: "mr-2"
  }) : _vm._e(), _vm._v(" Go Pro ")], 1) : _vm._e(), _vm.isLoggedInUser ? _c('v-btn', {
    staticClass: "heading heading-s-small primary--text shadow-light-button",
    class: {
      'ml-2': _vm.$breakpoint.mobileAndDown,
      'ml-4': _vm.$breakpoint.tabletAndUp
    },
    attrs: {
      "ripple": "",
      "depressed": "",
      "height": "36px",
      "width": "auto",
      "to": {
        name: 'my-plan',
        params: {
          upgrade: true
        }
      }
    }
  }, [_vm.hasToShowCrownIcon ? _c('crown-filled-yellow-icon', {
    staticClass: "mr-2"
  }) : _vm._e(), _vm._v(" Upgrade " + _vm._s('') + " ")], 1) : _vm._e()], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }