<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 45 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4001 18.9577L22.7507 0L27.1031 18.9577L44.0269 23.4176L27.1031 27.8754L22.7507 47.8375L18.4001 27.8754L9.15527e-05 23.4176L18.4001 18.9577Z"
      :fill="fill"
    />
    <defs>
      <linearGradient
        :id="idLinear"
        x1="44.0258"
        y1="23.9183"
        x2="-0.000512771"
        y2="23.9183"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#80B1FF" />
        <stop
          offset="1"
          stop-color="#88B5FE"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: '79px',
      type: String,
    },
    height: {
      default: '83px',
      type: String,
    },
    id: {
      default: '',
      type: String,
    },
  },
  computed: {
    fill() {
      return `url(#${this.id}paint0_linear_9409_73605)`;
    },
    idLinear() {
      return `${this.id}paint0_linear_9409_73605`;
    },
  },
};
</script>

<style></style>
