<template>
  <div class="max-height">
    <WrapperLifetimeIllustrationsComponent
      v-if="isLifetime"
      :id="'lifetime'"
    />
    <WrapperCourseIllustrationsComponent
      v-if="isCourses"
      :id="'courses'"
    />
    <WrapperArticleIllustrationsComponent v-if="isArticles" />
    <WrapperSpecialReqsIllustrationsComponent v-if="isSpecialRequirements" />
    <v-container class="max-height d-flex align-center">
      <v-row justify-sm="center">
        <v-col
          class="px-lg-11"
          cols="12"
        >
          <v-row
            row
            justify="center"
          >
            <v-col class="col-12 col-sm-8 col-lg-7 col-xl-7">
              <v-row>
                <v-col class="pr-3 pr-sm-0 pr-md-10 pr-lg-3">
                  <document-heading
                    v-if="$breakpoint.tabletAndUp"
                    level="1"
                    class="heading white--text mb-2"
                    :class="[
                      { 'heading-m-small': $breakpoint.smallDesktopAndDown && !isNewHeaderTitle },
                      { 'heading-t-small-new-header': $breakpoint.smallDesktopAndDown && isNewHeaderTitle },
                      {
                        'heading-l-small':
                          $breakpoint.desktopOnly && $vuetify.breakpoint.width < 1905 && !isNewHeaderTitle,
                      },
                      {
                        'heading-l-small-new-header':
                          $breakpoint.desktopOnly && $vuetify.breakpoint.width < 1905 && isNewHeaderTitle,
                      },
                      { 'heading-l-large': $vuetify.breakpoint.width >= 1905 && !isNewHeaderTitle },
                      { 'heading-l-large-new-header': $vuetify.breakpoint.width >= 1905 && isNewHeaderTitle },
                    ]"
                    v-html="title"
                  >
                  </document-heading>
                  <document-heading
                    v-else
                    level="1"
                    class="heading white--text mb-2"
                    :class="[
                      { 'heading-m-large-new-header': isNewHeaderTitle },
                      { 'heading-m-small': !isNewHeaderTitle },
                    ]"
                    v-html="titleMobile"
                  >
                  </document-heading>
                  <p
                    v-if="$vuetify.breakpoint.width >= 1905"
                    class="text text-xl-large-regular white--text mb-4 mb-md-6 mb-xl-8"
                    v-html="paragraph"
                  />
                  <p
                    v-else-if="$breakpoint.tabletAndUp"
                    class="text white--text mb-4 mb-md-6 mb-xl-8"
                    :class="[
                      { 'heading-m-small-new-header': isNewHeaderTitle, 'text-l-large-regular': !isNewHeaderTitle },
                    ]"
                    v-html="paragraph"
                  />
                  <p
                    v-else-if="paragraphMobile"
                    class="text text-l-large-regular white--text mb-4 mb-md-6"
                    :class="[
                      { 'heading-m-small-new-header': isNewHeaderTitle, 'text-l-large-regular': !isNewHeaderTitle },
                    ]"
                    v-html="paragraphMobile"
                  />
                  <div
                    v-if="bannerResource?.btnSecondaryText"
                    class="text-center text-sm-left"
                  >
                    <v-btn
                      :to="{ name: 'register' }"
                      :width="$breakpoint.tabletAndUp ? btnSize : '100%'"
                      height="40px"
                      ripple
                      depressed
                      :color="btnColor"
                      class="px-5 heading heading-s-small mt-2 mb-6 my-sm-0"
                      @click.native="handleClickSignupWithLifetimeLink"
                    >
                      {{ btnText }}
                    </v-btn>
                    <span
                      v-if="$breakpoint.tabletAndUp"
                      class="text text-l-large-regular white--text mx-4"
                      >or</span
                    >
                    <router-link
                      :to="{ name: 'register', params: { preselectFreeTrial: true } }"
                      class="text text-l-large-regular-link white--text"
                      @click.native="handleClickSignupWithOtherLink"
                    >
                      {{ this.bannerResource?.btnSecondaryText }}
                    </router-link>
                  </div>
                  <v-btn
                    v-else
                    :to="{ name: 'register' }"
                    :width="$breakpoint.tabletAndUp ? btnSize : '100%'"
                    height="40px"
                    ripple
                    depressed
                    :color="btnColor"
                    class="px-5 mr-2 heading heading-s-small"
                    :class="{ 'mb-5': $breakpoint.mobileAndDown }"
                    @click.native="handleClickSignupWithRegularPlanLink"
                  >
                    {{ btnText }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="col-12 col-sm-4 col-lg-3 col-xl-4 px-sm-10 px-xl-13 d-none d-sm-flex"> </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import WrapperLifetimeIllustrationsComponent from '@/assets/banner/WrapperLifetimeIllustrationsComponent';
import WrapperCourseIllustrationsComponent from '@/assets/banner/WrapperCourseIllustrationsComponent';
import WrapperArticleIllustrationsComponent from '@/assets/banner/WrapperArticleIllustrationsComponent';
import WrapperSpecialReqsIllustrationsComponent from '@/assets/banner/WrapperSpecialReqsIllustrationsComponent';
import { DocumentHeading } from 'vue-headings';
export default {
  components: {
    DocumentHeading,
    WrapperLifetimeIllustrationsComponent,
    WrapperCourseIllustrationsComponent,
    WrapperArticleIllustrationsComponent,
    WrapperSpecialReqsIllustrationsComponent,
  },
  props: {
    bannerResource: {
      default: null,
      type: Object,
    },
  },
  computed: {
    title() {
      return this.bannerResource?.title;
    },
    titleMobile() {
      return this.bannerResource?.titleMobile;
    },
    paragraph() {
      return this.bannerResource?.paragraph;
    },
    paragraphMobile() {
      return this.bannerResource?.paragraphMobile;
    },
    btnSize() {
      return this.bannerResource?.btnSize;
    },
    btnColor() {
      return this.bannerResource?.btnColor;
    },
    btnText() {
      return this.bannerResource?.btnText;
    },
    isArticles() {
      return this.bannerResource?.type === 'articles';
    },
    isCourses() {
      return this.bannerResource?.type === 'courses';
    },
    isSpecialRequirements() {
      return this.bannerResource?.type === 'special-requirements';
    },
    isLifetime() {
      return this.bannerResource?.type === 'lifetime';
    },
    isNewHeaderTitle() {
      return this.title === 'Fast. Relevant. All in one place.';
    },
  },
  methods: {
    handleClickSignupWithLifetimeLink() {
      this.$eventNotifier.notify('click_login', {
        eventCategory: 'Signup banner',
        eventLabel: 'Banner lifetime signup link',
      });
    },
    handleClickSignupWithOtherLink() {
      this.$eventNotifier.notify('click_login', {
        eventCategory: 'Signup banner',
        eventLabel: 'Banner other signup link',
      });
    },
    handleClickSignupWithRegularPlanLink() {
      this.$eventNotifier.notify('click_login', {
        eventCategory: 'Signup banner',
        eventLabel: 'Banner regular signup link',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
  z-index: 2;
  padding: 0px;
}

.display-small-new-header {
  font-size: 2;
  line-height: 2.75rem;
}

.heading-l-large-new-header {
  font-size: 1.9rem;
  line-height: 2.75rem;
}

.heading-l-small-new-header {
  font-size: 2.25rem;
  line-height: 2.25rem;
}

.heading-t-large-new-header {
  font-size: 1.9rem;
  line-height: 2.75rem;
}

.heading-t-small-new-header {
  font-size: 1.9rem;
  line-height: 2.25rem;
}

.heading-m-large-new-header {
  font-size: 1.75rem;
  line-height: 2.25rem;
}

.heading-m-small-new-header {
  font-size: 16px;
  line-height: 1.4rem;
}
</style>
