import {
  doGetUserKeywords,
  doGetAllKeywords,
  doSubscribeKeywordsToUser,
  doUnsubscribeKeywordsToUser,
  doSearchByTerm,
} from '@/api/keywords';
import { isSuccessfulResponse } from '@/api/utilities';

export default {
  namespaced: true,
  state: () => ({
    /**
     * @deprecated Use the composable `useUserKeywords` instead.
     */
    userKeywords: null,
    hasToShowInterestsMobileDialog: false,
  }),
  mutations: {
    setUserKeywords(state, data) {
      state.userKeywords = data;
    },
    setShowingInterestsMobileDialog(state, data) {
      state.hasToShowInterestsMobileDialog = data;
    },
  },
  actions: {
    /**
     * @deprecated Please use the composable `useUserKeywords` instead.
     */
    async getUserKeywords(context) {
      const response = await doGetUserKeywords();

      if (isSuccessfulResponse(response)) {
        context.commit('setUserKeywords', response.data);
      }

      return response;
    },
    showInterestsMobileDialog(context, payload) {
      context.commit('setShowingInterestsMobileDialog', payload);
    },
    async getAllKeywords(context, payload) {
      return doGetAllKeywords(payload);
    },

    /**
     * @deprecated Use the composable `useUserKeywords` instead.
     */
    async subscribeKeywordsToUser(context, payload) {
      const response = await doSubscribeKeywordsToUser(payload, payload.showToast);

      if (isSuccessfulResponse(response)) {
        await context.dispatch('getUserKeywords');
      }

      return response;
    },

    /**
     * @deprecated Use the composable `useUserKeywords` instead.
     */
    async unsubscribeKeywordsToUser(context, payload) {
      const response = await doUnsubscribeKeywordsToUser(payload, payload.showToast);

      if (isSuccessfulResponse(response)) {
        await context.dispatch('getUserKeywords');
      }

      return response;
    },
    searchByTerm(context, payload) {
      return doSearchByTerm(payload);
    },
  },
  getters: {
    /**
     * @deprecated Use the composable `useUserKeywords` instead.
     */
    currentUserKeywords: (state) => {
      return state.userKeywords;
    },
    getHasToShowInterestsMobileDialog: (state) => {
      return state.hasToShowInterestsMobileDialog;
    },
  },
};
