import { doGetReferrals } from '@/api/referrals';
import { isSuccessfulResponse } from '@/api/utilities';

export default {
  namespaced: true,
  actions: {
    async getReferrals() {
      let response = await doGetReferrals();

      if (isSuccessfulResponse(response)) {
        return response.data;
      }

      return response.error;
    },
  },
};
