import { getFeatureFlagProvider } from '@/lib/featureFlag';

// We do this to allow to import the feature flags directly from the mixin.
export * from '@/lib/featureFlag/featureFlags';

/**
 * Creates a Vue mixin for managing reactive feature flags.
 *
 * @param {Object} featureFlags - An object containing feature flags and their corresponding attribute names.
 * @returns {Object} A Vue mixin object.
 *
 * @example
 *
 * import { featureFlagsMixin, MILESTONE_NEW_SIGNUP_ENABLED, MILESTONE_NEW_FEED_ENABLED } from '@/mixins/featureFlagsMixin';
 * mixins: [featureFlagsMixin({
 *   [MILESTONE_NEW_SIGNUP_ENABLED]: 'milestoneNewSignupEnabled',
 *   [MILESTONE_NEW_FEED_ENABLED]: 'milestoneNewFeedEnabled',
 * })],
 *
 * // The resulting data attribute will be:
 * // {
 * //   milestoneNewSignupEnabled: true|false,
 * //   milestoneNewFeedEnabled: true|false,
 * // }
 *
 * // In this example, the feature flags are defined using an object. Each key-value pair represents a feature flag,
 * // where the key is the imported feature flag, and the value is the attribute name to be used in the Vue data.
 * // The imported feature flag `MILESTONE_NEW_SIGNUP_ENABLED` is associated with the attribute name `milestoneNewSignupEnabled`,
 * // and `MILESTONE_NEW_FEED_ENABLED` is associated with `milestoneNewFeedEnabled`.
 * // This means that the Vue's reactive state will have data properties named `milestoneNewSignupEnabled` and `milestoneNewFeedEnabled`.
 * // These data properties can be used in the component's template to conditionally render sections based on the feature flags' values.
 * // For instance:
 * // <template>
 * //   <div v-if="milestoneNewSignupEnabled">
 * //     This section is displayed when the `MILESTONE_NEW_SIGNUP_ENABLED` feature flag is enabled.
 * //   </div>
 * //   <div v-else>
 * //     This section is displayed when the `MILESTONE_NEW_SIGNUP_ENABLED` feature flag is disabled.
 * //   </div>
 * //
 * //   <div v-if="milestoneNewFeedEnabled">
 * //     This section is displayed when the `MILESTONE_NEW_FEED_ENABLED` feature flag is enabled.
 * //   </div>
 * //   <div v-else>
 * //     This section is displayed when the `MILESTONE_NEW_FEED_ENABLED` feature flag is disabled.
 * //   </div>
 * // </template>
 */
export const featureFlagsMixin = (featureFlags) => {
  return {
    async created() {
      this._calculateValueOfReactiveFeatureFlags();
    },
    data() {
      return this._mapFeatureFlagsToReactiveFields();
    },
    methods: {
      _mapFeatureFlagsToReactiveFields() {
        const reactiveFields = {};

        Object.values(featureFlags).forEach((dataAttribute) => {
          reactiveFields[dataAttribute] = false;
        });

        return reactiveFields;
      },
      _calculateValueOfReactiveFeatureFlags() {
        const featureFlagProvider = getFeatureFlagProvider();

        Object.entries(featureFlags).forEach(([featureFlag, dataAttribute]) => {
          this[dataAttribute] = featureFlagProvider.isFeatureEnabled(featureFlag);
        });
      },
    },
  };
};
