<template>
  <div class="aca-text-field">
    <AcaLabel
      v-if="label"
      :id="id"
      class="mb-1"
      :enable-bold="isFocused"
      :error="hasError"
      :disabled="disabled"
    >
      {{ label }}
    </AcaLabel>
    <!-- TODO: we need to remove v-bind="$attrs", otherwise we loose control on how is used -->
    <v-text-field
      :id="id"
      ref="acaTextField"
      v-bind="$attrs"
      v-model="inputValue"
      solo
      class="text"
      :class="[
        isMobile ? 'text-l-large-regular' : 'text-m-large-regular',
        {
          'hide-details': hideDetails,
        },
      ]"
      :disabled="disabled"
      :rules="rules"
      :hide-details="hideDetails"
      :height="localHeight"
      :label="null"
      v-on="$listeners"
    >
      <template #append>
        <slot
          name="append"
          append-class="append-container"
          size="24"
          weight="light"
        >
          <PhWarningOctagon
            v-if="hasError"
            weight="light"
            size="24"
          />
        </slot>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { ref } from 'vue';
import { PhWarningOctagon } from 'phosphor-vue';
import AcaLabel from '@components/atoms/AcaLabel.vue';
import { useAtomFieldState } from '@components/atoms/composables/useAtomFieldState';
import { useAtomFieldTestId } from '@components/atoms/composables/useAtomFieldTestId.js';

export default {
  name: 'AcaTextField',
  components: {
    AcaLabel,
    PhWarningOctagon,
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    height: {
      type: String,
      required: false,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup() {
    const acaTextField = ref(null);
    useAtomFieldTestId({ fieldRef: acaTextField, fieldType: 'input' });
    
    const { isFocused, hasError } = useAtomFieldState({ fieldRef: acaTextField });

    return {
      isFocused,
      hasError,
      acaTextField,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isMobile() {
      return this.$breakpoint.mobileAndDown;
    },
    localHeight() {
      if (this.height) return this.height;

      if (this.$breakpoint.mobileAndDown) {
        return '48';
      } else {
        return '42';
      }
    },
  },
};
</script>

<!-- NOTE: If any styles of this base component change (including base, error, or focus states), 
  update the Stripe Elements appearance configuration (stripeElementsAppearance.js) to match. -->
<style scoped lang="scss">
@import '@/sass/texts.scss';

.aca-text-field {
  // Adjusting line-height to compensate for extra space between the main div and the label
  line-height: calc(1rem - 4px);
  text-align: left;
  width: 100%;
}

.aca-text-field ::v-deep .v-input__slot {
  background: var(--v-card-base) !important;
  box-shadow: inset -3px -3px 3px rgba(255, 255, 255, 0.7), inset 3px 3px 3px rgba(174, 174, 192, 0.2) !important;
  border-radius: 4px;
  min-height: auto !important;
  margin-bottom: 4px;
  padding: 0 16px !important;

  &:hover {
    background: var(--v-background-base) !important;
  }
}

.aca-text-field .hide-details ::v-deep .v-input__slot {
  margin-bottom: 0px;
}

.aca-text-field ::v-deep .v-input__control {
  min-height: auto;
}

@media #{map-get($display-breakpoints, 'tablet-and-up')} {
  .aca-text-field ::v-deep .v-input__slot {
    padding: 0 12px !important;
  }
}

.aca-text-field ::v-deep .v-input {
  // used by the svg with the currentColor
  color: var(--v-grey-lighten1) !important;
}

.aca-text-field ::v-deep .v-text-field__details {
  margin-bottom: 0;
  padding-left: 0;
  // don't reduce this, otherwise you will have layout shift
  // because the line-height of the child .v-messages__message is 1 rem
  min-height: 1rem;
}

.aca-text-field ::v-deep .v-messages {
  min-height: 0px;
}

.aca-text-field ::v-deep .v-messages__message {
  @extend .text, .text-m-small-regular;
}

.aca-text-field ::v-deep input::placeholder {
  color: var(--v-grey-darken1) !important;
}

.aca-text-field ::v-deep input {
  color: var(--v-grey-darken4) !important;
}

.aca-text-field ::v-deep .append-container {
  cursor: pointer;
  color: var(--v-grey-lighten1) !important;
}
</style>

<!-- Disabled styles -->
<style scoped lang="scss">
.aca-text-field ::v-deep .v-input--is-disabled .v-input__slot {
  background: var(--v-grey-lighten3) !important;
  box-shadow: none !important;
}

.aca-text-field ::v-deep .v-input--is-disabled input::placeholder {
  color: var(--v-grey-lighten1) !important;
  opacity: 1;
}
</style>

<!-- Focus state styles -->
<style scoped lang="scss">
.aca-text-field ::v-deep .v-input.v-input--is-focused {
  // used by the svg with the currentColor
  color: var(--v-grey-lighten1) !important;
}

.aca-text-field ::v-deep .v-input--is-focused .v-input__slot {
  box-shadow: inset 0 0 0 2px var(--v-secondary-border-base) !important;
  background: var(--v-card-base) !important;
}
</style>

<!-- Error status styles -->
<style scoped lang="scss">
.aca-text-field ::v-deep .error--text.v-input {
  // used by the svg with the currentColor
  color: var(--v-error-base) !important;
}

.aca-text-field .error--text ::v-deep .v-input__slot {
  box-shadow: inset 0 0 0 2px var(--v-error-border-base) !important;
  background: var(--v-error-surface-base) !important;

  &:hover {
    background: var(--v-error-new-hover-base) !important;
  }
}

// TODO: we are mixing error with focus, but we have a block with focus styles
.aca-text-field .error--text.v-input--is-focused ::v-deep .v-input__slot {
  box-shadow: inset 0 0 0 2px var(--v-error-base) !important;
  background: var(--v-error-surface-base) !important;
}

.aca-text-field .error--text ::v-deep .v-input__control input {
  color: var(--v-error-base) !important;
}
</style>
