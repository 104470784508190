<template>
  <div class="illustrations-container">
    <div v-if="$breakpoint.tabletAndUp">
      <CheckListAnimationComponent
        class="check-list-animation-container"
        :class="checkListClass"
        :width="widthCheckList"
        :height="heightCheckList"
      />
    </div>
  </div>
</template>

<script>
import CheckListAnimationComponent from '@/assets/banner/CheckListAnimationComponent';
export default {
  components: {
    CheckListAnimationComponent,
  },
  computed: {
    checkListClass() {
      if (this.$vuetify.breakpoint.xlOnly) {
        return 'check-list-large-desktop';
      }

      if (this.$breakpoint.desktopAndUp) {
        return 'check-list-regular-desktop-and-up';
      }

      if (this.$breakpoint.smallDesktopOnly) {
        return 'check-list-small-desktop';
      }

      return 'check-list-tablet-only';
    },
    widthCheckList() {
      if (this.checkListClass === 'check-list-large-desktop') {
        return '447';
      }

      if (this.checkListClass === 'check-list-regular-desktop-and-up') {
        return '447';
      }

      if (this.checkListClass === 'check-list-small-desktop') {
        return '410';
      }

      return '360';
    },
    heightCheckList() {
      if (this.checkListClass === 'check-list-large-desktop') {
        return '294';
      }

      if (this.checkListClass === 'check-list-regular-desktop-and-up') {
        return '294';
      }

      if (this.checkListClass === 'check-list-small-desktop') {
        return '294';
      }

      return '294';
    },
  },
};
</script>

<style scoped lang="scss">
.check-list-animation-container {
  position: absolute;
}

.check-list-large-desktop {
  right: 0px;
  bottom: 0px;
}

.check-list-regular-desktop-and-up {
  right: -80px;
  bottom: 0px;
}

.check-list-small-desktop {
  right: -46px;
  bottom: -25px;
}

.check-list-tablet-only {
  right: -76px;
  bottom: -32px;
}

@media #{map-get($display-breakpoints, 'mobile-and-down')} {
  .illustrations-container {
    position: relative;
  }
}
</style>
