import { doPostReportQuiz, reportCourseModuleQuiz as doReportCourseModuleQuiz } from '@/api/issue';

export default {
  namespaced: true,
  actions: {
    postReportQuiz(_context, payload) {
      return doPostReportQuiz(payload);
    },
    reportCourseModuleQuiz(_context, payload) {
      return doReportCourseModuleQuiz(payload);
    },
  },
};
