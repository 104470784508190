export default {
  primary: {
    base: '#263684',
  },
  'primary-surface': {
    base: '#EDEDF7',
  },
  'primary-border': {
    base: '#AEBDDB',
  },
  'primary-focus': {
    base: '#C2C6D9',
  },
  'primary-pressed': {
    base: '#161F4B',
  },
  secondary: {
    base: '#76AAFF',
  },
  'secondary-surface': {
    base: '#F0F3FF',
  },
  'secondary-hover': {
    base: '#6B92D2',
  },
  'secondary-border': {
    base: '#B8D2FF',
  },
  'secondary-pressed': {
    base: '#475F85',
  },
  accent: {
    base: '#FFAB18',
  },
  'accent-surface': {
    base: '#FFFCF0',
  },
  'accent-border': {
    base: '#FFD68F',
  },
  error: {
    base: '#EB5953',
  },
  'error-surface': {
    base: '#FEF7F6',
  },
  'error-border': {
    base: '#F5B0AD',
  },
  // Deprecated in favor of error-new-hover
  'error-hover': {
    base: '#D93730',
  },
  'error-new-hover': {
    base: '#F7EBEC',
  },
  warning: {
    base: '#EA780E',
  },
  'warning-surface': {
    base: '#FFF8F2',
  },
  'warning-border': {
    base: '#FFB470',
  },
  info: {
    base: '#0B5CD7',
  },
  'info-border': {
    base: '#B6CFF3',
  },
  success: {
    base: '#13B577',
  },
  grayGranite: {
    base: '#616161',
  },
  'success-surface': {
    base: '#F6FEFB',
  },
  'success-border': {
    base: '#8FD6BB',
  },
  background: {
    base: '#F0F0F3',
  },
  card: {
    base: '#F7F7F8',
  },
  grey: {
    base: '#FFFFFF',
    lighten6: '#B9B9B9',
    lighten5: '#F5F5F5',
    lighten4: '#EDEDED',
    lighten3: '#E0E0E0',
    lighten2: '#C2C2C2',
    lighten1: '#9E9E9E',
    darken1: '#757575',
    darken2: '#616161',
    darken3: '#424242',
    darken4: '#0A0A0A',
  },
  nightgradient: {
    base: '#263684',
    darken4: '#060F3D',
  },
  coldgradient: {
    base: '#8BB8FF',
    darken4: '#4C70DF',
  },
  hotgradient: {
    base: '#FFB018',
    darken4: '#FF8718',
    darken3: '#FD6B51',
  },
};
