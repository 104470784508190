var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "illustrations-container"
  }, [_vm.$breakpoint.tabletAndUp ? _c('div', [_c('StarComponent', {
    staticClass: "star",
    class: [_vm.starClass],
    attrs: {
      "width": _vm.widthStar,
      "height": _vm.heightStar,
      "id": _vm.id
    }
  }), _c('SmallStarComponent', {
    staticClass: "small-star",
    class: [_vm.starSmallClass],
    attrs: {
      "width": _vm.widthStarSmall,
      "height": _vm.heightStarSmall,
      "id": _vm.id
    }
  }), _c('SpinningStaticCirclesComponent', {
    staticClass: "spinning-static",
    class: [_vm.spinningStaticClass],
    attrs: {
      "width": _vm.widthSpinningStatic,
      "height": _vm.heightSpinningStatic,
      "id": _vm.id
    }
  }), _vm.isLifetime ? _c('WrapperSpinningGraduationCapComponent', {
    attrs: {
      "id": _vm.id
    }
  }) : _c('WrapperSpinningGraduationCapComponent', {
    attrs: {
      "id": _vm.id
    }
  })], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }