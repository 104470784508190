<template>
  <router-link
    class="aca-link"
    :class="[`aca-link--color-${color}`, `aca-link--size-${size}`]"
    :to="to"
    :href="href"
    v-on="$listeners"
  >
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: 'AcaLink',
  props: {
    color: {
      type: String,
      required: false,
      default: 'primary',
      validator: (value) => {
        return ['primary', 'grey'].includes(value);
      },
    },
    to: {
      type: [String, Object],
      default: '',
    },
    href: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    size() {
      if (this.$breakpoint.mobileAndDown) {
        return 'large';
      } else {
        return 'small';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/texts.scss';

.aca-link {
  @extend .text;

  border-radius: 4px;

  &:hover {
    opacity: 0.7;
  }

  &:focus-visible {
    outline: 0;

    // We use box-shadow instead of outline because when hovering
    // and at the same time focusing, the outline is not visible
    box-shadow: 0px 0px 0px 2px var(--v-secondary-border-base);
  }
}

.aca-link--color-primary {
  color: var(--v-primary-base);
}

.aca-link--color-grey {
  color: var(--v-grey-darken1);
}

.aca-link--size-small {
  @extend .text, .text-m-large-regular-link;
}

.aca-link--size-large {
  @extend .text, .text-l-large-regular-link;
}
</style>
