var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "image-onboarding-container d-flex",
    class: {
      'diff-position-images': _vm.sizeImages.width === _vm.SIZE_FOR_MOBILE && _vm.currentStepIndex !== _vm.enumSteps.POST_CHECKOUT
    }
  }, [_c('IntroStepComponent', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentStepIndex === _vm.enumSteps.INTRO,
      expression: "currentStepIndex === enumSteps.INTRO"
    }],
    attrs: {
      "size": _vm.sizeImages
    }
  }), _c('FirstStepComponent', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentStepIndex === _vm.enumSteps.FIRST,
      expression: "currentStepIndex === enumSteps.FIRST"
    }],
    attrs: {
      "size": _vm.sizeImages
    }
  }), _c('SecondStepComponent', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentStepIndex === _vm.enumSteps.SECOND,
      expression: "currentStepIndex === enumSteps.SECOND"
    }],
    attrs: {
      "size": _vm.sizeImages
    }
  }), _c('ThirdStepComponent', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentStepIndex === _vm.enumSteps.THIRD,
      expression: "currentStepIndex === enumSteps.THIRD"
    }],
    attrs: {
      "size": _vm.sizeImages
    }
  }), _c('PostCheckoutStepComponent', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentStepIndex === _vm.enumSteps.POST_CHECKOUT,
      expression: "currentStepIndex === enumSteps.POST_CHECKOUT"
    }],
    class: [{
      'diff-position-images-post-checkout-image': _vm.$vuetify.breakpoint.xsOnly && _vm.$vuetify.breakpoint.width > 320
    }, {
      'diff-position-images-post-checkout-image-se': _vm.$vuetify.breakpoint.xsOnly && _vm.$vuetify.breakpoint.width <= 320
    }],
    attrs: {
      "size": _vm.sizeImages
    }
  }), _c('FinalStepComponent', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentStepIndex === _vm.enumSteps.FINAL,
      expression: "currentStepIndex === enumSteps.FINAL"
    }],
    class: [{
      'diff-position-image-final-image': _vm.$vuetify.breakpoint.xsOnly && _vm.$vuetify.breakpoint.width >= 375
    }, {
      'diff-position-image-final-image-se': _vm.$vuetify.breakpoint.xsOnly && _vm.$vuetify.breakpoint.width < 375
    }],
    attrs: {
      "size": _vm.sizeImages
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }