<template>
  <div
    class="aca-select"
    :class="{ 'w-100': fullWidth }"
  >
    <AcaLabel
      v-if="label"
      :id="id"
      class="mb-1"
      :enable-bold="isFocused"
      :disabled="disabled"
    >
      {{ label }}
    </AcaLabel>
    <v-select
      :id="id"
      ref="acaSelect"
      v-model="selected"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      persistent-hint
      solo
      :hide-details="hideDetails"
      autocapitalize="sentences"
      class="text"
      :class="[
        isMobile ? 'text-l-large-regular' : 'text-m-large-regular',
        {
          'placeholder-as-value': stylePlaceholderAsValue,
          'hide-details': hideDetails,
        },
      ]"
      :placeholder="placeholder"
      :disabled="disabled"
      :height="height"
      :label="null"
      :menu-props="{
        contentClass: 'aca-select-menu',
      }"
      @click="$emit('click')"
      v-on="$listeners"
    >
      <template #append>
        <slot name="append">
          <PhCaretDown
            weight="light"
            size="24"
          />
        </slot>
      </template>
    </v-select>
  </div>
</template>

<script>
import { ref } from 'vue';
import { PhCaretDown } from 'phosphor-vue';
import AcaLabel from '@components/atoms/AcaLabel.vue';
import { useAtomFieldState } from '@components/atoms/composables/useAtomFieldState';
import { useAtomFieldTestId } from '@components/atoms/composables/useAtomFieldTestId.js';

export default {
  name: 'AcaSelect',
  components: {
    AcaLabel,
    PhCaretDown,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    value: {
      type: [Number, String],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    // A boolean that determines whether the placeholder should be displayed as if it were the current value of the field.
    // Helping users recognize that the placeholder represents a previously made selection.
    // This property is useful when you want to visually represent a selected value using the placeholder text,
    // especially in contexts where the select is used only for read-only.
    // TODO: in the future would be great to implement a better solution, I find it too hacky
    stylePlaceholderAsValue: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const acaSelect = ref(null);
    useAtomFieldTestId({ fieldRef: acaSelect, fieldType: 'select' });

    const { isFocused } = useAtomFieldState({ fieldRef: acaSelect });

    return {
      isFocused,
      acaSelect,
    };
  },
  computed: {
    height() {
      if (this.isMobile) {
        return '48';
      } else {
        return '42';
      }
    },
    isMobile() {
      return this.$breakpoint.mobileAndDown;
    },
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/sass/texts.scss';

.aca-select ::v-deep .v-input__slot {
  background: var(--v-card-base) !important;
  box-shadow: inset -3px -3px 3px rgba(255, 255, 255, 0.7), inset 3px 3px 3px rgba(174, 174, 192, 0.2) !important;
  border-radius: 4px;
  min-height: auto !important;
  margin-bottom: 4px;
  padding: 0 16px !important;

  &:hover {
    background: var(--v-background-base) !important;
  }
}

.aca-select .hide-details ::v-deep .v-input__slot {
  margin-bottom: 0px;
}

.aca-select ::v-deep .v-input__control {
  min-height: auto;
}

.aca-select ::v-deep input {
  cursor: pointer;
}

.aca-select ::v-deep .v-input {
  // used by the svg with the currentColor
  color: var(--v-grey-lighten1) !important;
}

.aca-select ::v-deep .v-text-field__details {
  margin-bottom: 0;
  padding-left: 0;
  // don't reduce this, otherwise you will have layout shift
  // because the line-height of the child .v-messages__message is 1 rem
  min-height: 1rem;
}

.aca-select ::v-deep .v-messages {
  min-height: 0px;
}

.aca-select ::v-deep .v-messages__message {
  @extend .text, .text-m-small-regular;
}

.aca-select ::v-deep input::placeholder {
  color: var(--v-grey-darken1) !important;
}

.aca-select ::v-deep .placeholder-as-value input::placeholder {
  color: var(--v-grey-darken4) !important;
}

.aca-select ::v-deep [placeholder] {
  text-overflow: ellipsis !important;
}

.aca-select ::v-deep .v-select__selections {
  color: var(--v-grey-darken4) !important;
}

.aca-select ::v-deep .v-select__selections .v-select__selection {
  margin-right: 0px !important;
}

.aca-select ::v-deep .v-input__append-inner {
  padding-left: 8px;
}

@media #{map-get($display-breakpoints, 'tablet-and-up')} {
  .aca-select ::v-deep .v-input__slot {
    padding: 0 12px !important;
  }
}
</style>

<!-- Disabled styles -->
<style scoped lang="scss">
.aca-select ::v-deep .v-input--is-disabled .v-input__slot {
  background-color: var(--v-grey-lighten3) !important;
  box-shadow: none !important;
}

.aca-select ::v-deep .v-input--is-disabled input::placeholder {
  color: var(--v-grey-darken1) !important;
  opacity: 1;
}

.aca-select ::v-deep .v-input--is-disabled .v-select__selection--disabled {
  color: var(--v-grey-darken1) !important;
  opacity: 1;
}
</style>

<!-- Focus state styles -->
<style scoped lang="scss">
.aca-select ::v-deep .v-input.v-input--is-focused {
  // used by the svg with the currentColor
  color: var(--v-grey-darken1) !important;
}

.aca-select ::v-deep .v-input--is-focused .v-input__slot {
  box-shadow: inset 0 0 0 2px var(--v-secondary-border-base) !important;
  background: var(--v-card-base) !important;
}
</style>

<!-- Global Styles for the select options -->
<style lang="scss">
.aca-select-menu {
  // It's the height for 6 options with bottom padding
  max-height: 272px !important;
}

.aca-select-menu .v-list.v-select-list.v-sheet.theme--light.theme--light {
  padding-block: 4px !important;
}

.aca-select-menu .v-list-item.v-list-item--link.theme--light {
  min-height: 44px;
}

.aca-select-menu .theme--light.v-list-item.v-list-item--highlighted::before {
  opacity: 0.12;
}

.aca-select-menu .theme--light.v-list-item.v-list-item--active::before {
  opacity: 0.08;
}

.aca-select-menu .theme--light.v-list-item.v-list-item--active.v-list-item--highlighted::before {
  opacity: 0.12;
}

.aca-select-menu .v-list-item__content {
  padding-block: 8px;
}

.aca-select-menu .v-list-item__content + .v-ripple__container .v-ripple__animation--in {
  opacity: 0.16;
}

.aca-select-menu .v-list-item .v-list-item__title {
  line-height: 24px;
  font-size: 16px;

  @media #{map-get($display-breakpoints, 'tablet-and-up')} {
    font-size: 14px;
  }
}

.aca-select-menu .v-list-item:not(.primary--text) .v-list-item__title {
  color: var(--v-grey-darken4);
}
.aca-select-menu.menuable__content__active {
  box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.07) !important;
}
</style>
