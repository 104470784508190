var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "d-block text text-left",
    class: [{
      'grey--text text--lighten-1': _vm.disabled,
      'error--text': _vm.error,
      'grey--text text--darken-3': !_vm.error && !_vm.disabled
    }, {
      'text-m-small-bold': _vm.enableBold,
      'text-m-small-regular': !_vm.enableBold
    }],
    attrs: {
      "for": _vm.id ? _vm.id : null
    }
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }