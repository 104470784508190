import { toastHandler } from '@/api/utilities';
import axios from 'axios';

export const doPostReportQuiz = async (payload) => {
  return toastHandler(axios.post('issue/quiz', payload));
};

export const reportCourseModuleQuiz = async (payload) => {
  return toastHandler(axios.post('issue/course-module-quiz', payload));
};
