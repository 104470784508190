var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-1",
    on: {
      "click": function ($event) {
        return _vm.$emit('clicked', _vm.index);
      }
    }
  }, [_c('svg', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.index === _vm.currentStepIndex,
      expression: "index === currentStepIndex"
    }],
    attrs: {
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "5",
      "cy": "5",
      "r": "5",
      "fill": "#76AAFF"
    }
  })]), _c('svg', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.index !== _vm.currentStepIndex,
      expression: "index !== currentStepIndex"
    }],
    attrs: {
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "5",
      "cy": "5",
      "r": "5",
      "fill": "#E0E0E0"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }