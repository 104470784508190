import { vuetifyBreakpointCalculator } from '@/plugins/vuetifyBreakpointCalculator';

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        $breakpoint() {
          const width = this.$vuetify.breakpoint.width;
          return {
            ...vuetifyBreakpointCalculator(width),
            width,
            isSidebarFixed: this.$vuetify.breakpoint.lgAndUp,
          };
        },
      },
    });
  },
};
