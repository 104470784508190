var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', _vm._g({
    staticClass: "aca-link",
    class: [`aca-link--color-${_vm.color}`, `aca-link--size-${_vm.size}`],
    attrs: {
      "to": _vm.to,
      "href": _vm.href
    }
  }, _vm.$listeners), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }