import { ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { defineStore, storeToRefs } from 'pinia';
import * as Sentry from '@sentry/browser';

import { useAuthStore } from '@/store-v2/auth';

// We are transitioning from an older storage key that holds more comprehensive details about the article.
// The old key ('acapedia_guest_quizzes') remains for backward compatibility.
// However, the new key 'acapedia_taken_quizzes_by_guest_user' is introduced with a focused purpose:
// It exclusively tracks the articles that have been taken as quizzes and should hence be hidden from the feed.
const GUEST_USER_TAKEN_QUIZZES_STORAGE_OLD_KEY = 'acapedia_guest_quizzes';
const TAKEN_QUIZZES_BY_GUEST_USER_STORAGE_KEY = 'acapedia_taken_quizzes_by_guest_user';
const USER_VISITED_ARTICLES_STORAGE_KEY = 'acapedia_user_visited_articles';

const migrateGuestUserTakenQuizzes = ({ guestUserTakenQuizzesRef }) => {
  const oldGuestUserTakenQuizzes = JSON.parse(localStorage.getItem(GUEST_USER_TAKEN_QUIZZES_STORAGE_OLD_KEY) || '[]');

  const newSetIsEmpty = Object.keys(guestUserTakenQuizzesRef.value).length === 0;
  const oldSetHasKeys = oldGuestUserTakenQuizzes?.length > 0;

  const hasToMigrateGuestUserTakenQuizzes = newSetIsEmpty && oldSetHasKeys;
  if (!hasToMigrateGuestUserTakenQuizzes) return;

  guestUserTakenQuizzesRef.value = oldGuestUserTakenQuizzes.reduce((acc, quiz) => {
    return {
      ...acc,
      [quiz.articleId]: true,
    };
  }, {});
};

export const useFeedStore = defineStore('feed', () => {
  const takenQuizzesByGuestGroupedByArticleId = useStorage(TAKEN_QUIZZES_BY_GUEST_USER_STORAGE_KEY, {});
  const visitedArticlesByUser = useStorage(USER_VISITED_ARTICLES_STORAGE_KEY, {});
  const isOnboardingFinished = ref(null);

  try {
    migrateGuestUserTakenQuizzes({ guestUserTakenQuizzesRef: takenQuizzesByGuestGroupedByArticleId });
  } catch (e) {
    console.error(e);
    Sentry.captureMessage('Failed to migrate guest user taken quizzes', {
      extra: {
        error: e,
      },
    });
  }

  const { isLoggedInUser } = storeToRefs(useAuthStore());
  if (isLoggedInUser.value) {
    takenQuizzesByGuestGroupedByArticleId.value = null;
  }

  function addArticleAsTakenByGuest({ articleId }) {
    if (isLoggedInUser.value) return;

    takenQuizzesByGuestGroupedByArticleId.value = {
      ...takenQuizzesByGuestGroupedByArticleId.value,
      [articleId]: true,
    };
  }

  function addArticleAsVisitedByUser({ articleId }) {
    visitedArticlesByUser.value = {
      ...visitedArticlesByUser.value,
      [articleId]: true,
    };
  }

  function setIsOnboardingFinished(value) {
    isOnboardingFinished.value = value;
  }

  return {
    takenQuizzesByGuestGroupedByArticleId,
    visitedArticlesByUser,
    isOnboardingFinished,

    addArticleAsTakenByGuest,
    addArticleAsVisitedByUser,
    setIsOnboardingFinished,
  };
});
