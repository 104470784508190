import axios from 'axios';
import { responseHandler, toastHandler, getResponseDataVOld } from '@/api/utilities';

const doGetProfileDetails = async () => {
  return axios.get('/user/profile');
};

const doUpdateEmail = async (payload) => {
  return toastHandler(axios.post('/user/profile/update-email', payload), true);
};

const doGetUserPaymentMethod = async () => {
  return responseHandler(axios.get('/user/billing/payment-method'), {
    responseDataGetter: getResponseDataVOld,
  });
};

const doUpdateBillingAddress = async (payload) => {
  return axios.post('/user/billing/address/update', payload);
};

const doDeletePaymentMethod = async () => {
  return axios.delete('/user/billing/payment-method');
};

const doPostPaymentMethod = async (payload) => {
  return axios.post('/user/billing/payment-method', payload);
};

const doGetPaymentHistory = async () => {
  return responseHandler(axios.get('/user/billing/payment/history'), {
    hasToShowToasts: false,
    responseDataGetter: getResponseDataVOld,
  });
};

const doGetPaymentReceiptPdf = async (payload) => {
  return axios.get('/user/billing/payment/history/receipt/pdf', payload);
};

const doUpdateProfileNames = async (payload) => {
  return toastHandler(axios.post('/user/profile/update-names', payload), true);
};

const doRequestNamesChange = async (payload) => {
  return toastHandler(axios.post('/user/profile/names/change', payload), true);
};

export {
  doGetProfileDetails,
  doUpdateEmail,
  doGetUserPaymentMethod,
  doUpdateBillingAddress,
  doDeletePaymentMethod,
  doPostPaymentMethod,
  doGetPaymentHistory,
  doGetPaymentReceiptPdf,
  doUpdateProfileNames,
  doRequestNamesChange,
};
