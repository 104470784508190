var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": _vm.width,
      "height": _vm.height,
      "viewBox": "0 0 120 122",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M7.79153 49.8979L0.597067 48.1062L0.881895 46.9372L7.04958 48.4642L7.78044 45.5224L8.80715 45.7794L7.79153 49.8979Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M9.90721 42.5294L9.00087 42.1644L9.37891 41.2232L4.31394 39.1674L3.93591 40.1086L3.03716 39.7436L4.23781 36.7364L5.14421 37.1089L4.76625 38.0578L9.83122 40.1136L10.247 39.1492L11.1534 39.5217L9.90721 42.5294Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.9003 28.0437L10.9008 28.0895L11.6171 29.2185L13.2914 32.1679L9.91356 32.0018L8.58455 31.8696L8.58498 31.9153L13.4161 34.9186L12.8109 35.9001L6.46119 31.9812L7.20836 30.7621L11.4599 31.0495L11.4594 30.9961L9.32286 27.3117L10.0705 26.1459L16.3897 30.0498L15.7845 31.0313L10.9003 28.0437Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M18.8203 26.9398L18.0554 26.2915L18.7158 25.5229L14.6377 21.9179L13.9773 22.6865L13.2124 22.0381L15.3744 19.5935L16.1392 20.2342L15.4712 21.0028L19.5797 24.6152L20.2477 23.8466L21.0126 24.4949L18.8203 26.9398Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M21.6967 15.0284L25.8246 19.8832L24.9139 20.6541L20.7861 15.807L19.1458 17.2557L18.4553 16.4466L22.7225 12.7775L23.413 13.5866L21.6967 15.0284Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M30.4717 16.34L26.6178 9.97331L30.6763 7.49543L31.2312 8.41254L28.2138 10.2555L29.2776 12.0291L32.0311 10.3564L32.5784 11.266L29.8174 12.9388L30.9428 14.8033L33.9602 12.9604L34.5151 13.8699L30.4717 16.34Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M35.4486 5.12564L37.8902 4.17246C38.2989 4.00532 38.7364 3.92086 39.1777 3.92393C39.5885 3.92439 39.993 4.02519 40.3565 4.21765C40.7475 4.42599 41.0892 4.71655 41.3585 5.06954C41.6902 5.50301 41.9509 5.9869 42.1308 6.50294C42.3404 7.01188 42.4684 7.55097 42.5101 8.10016C42.5436 8.54731 42.4859 8.99662 42.3405 9.42055C42.1996 9.80312 41.9716 10.1473 41.6747 10.4255C41.3492 10.7277 40.9672 10.962 40.5508 11.1146L38.1091 12.0678L35.4486 5.12564ZM40.1921 10.0965C40.426 10.017 40.6409 9.88966 40.8234 9.72263C41.0059 9.55561 41.1521 9.35246 41.2527 9.12595C41.3451 8.86441 41.3853 8.58713 41.371 8.30997C41.3566 8.0328 41.288 7.7612 41.1691 7.51068L40.7415 6.41703C40.6606 6.15104 40.5281 5.90378 40.3516 5.68956C40.1751 5.47534 39.958 5.29842 39.713 5.16901C39.4862 5.07098 39.2415 5.02156 38.9946 5.0239C38.7477 5.02625 38.504 5.0803 38.2791 5.18262L36.9487 5.69837L38.862 10.6504L40.1921 10.0965Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M54.3572 1.33992L55.0621 7.66791L53.8646 7.80125L53.137 1.47347L50.939 1.71541L50.8531 0.656632L56.4313 0.0319493L56.5476 1.09044L54.3572 1.33992Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M60.5382 7.43348L60.5745 6.4574L61.584 6.49355L61.7976 1.01824L60.7881 0.974461L60.8319 -0.00931668L64.0577 0.12014L64.0215 1.10385L62.9968 1.06021L62.7832 6.54315L63.8079 6.58678L63.7642 7.5858L60.5382 7.43348Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M74.4465 3.52034L74.3934 3.52084L73.5688 4.58064L71.4004 7.13967L70.3908 3.88663L70.0674 2.58617L70.0219 2.58661L68.838 8.16261L67.7204 7.92166L69.2597 0.619496L70.651 0.918828L71.8129 5.03944L71.8736 5.03887L74.5968 1.76563L75.9272 2.05029L74.3877 9.32959L73.2626 9.09634L74.4465 3.52034Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M78.4292 10.7165L81.2851 3.82872L85.6652 5.67L85.2572 6.65724L82.0045 5.29312L81.2108 7.20639L84.1816 8.45122L83.7737 9.43846L80.7801 8.18622L79.9418 10.199L83.2173 11.5705L82.8321 12.5576L78.4292 10.7165Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M92.8401 19.0338C92.4856 18.7505 92.1952 18.3946 91.988 17.9899C91.7834 17.6184 91.6653 17.2051 91.6427 16.7811C91.629 16.3215 91.7099 15.864 91.8804 15.4373C92.082 14.9244 92.3616 14.446 92.7091 14.0191C93.0462 13.5726 93.4493 13.1806 93.9045 12.8567C94.283 12.5941 94.7092 12.4088 95.1588 12.3112C95.572 12.2321 95.9978 12.2489 96.4036 12.3604C96.8379 12.4844 97.2426 12.6958 97.5931 12.9818C97.9484 13.2581 98.2456 13.6025 98.4676 13.995C98.6734 14.3628 98.7891 14.7745 98.8052 15.1962C98.8174 15.6575 98.7393 16.1166 98.5752 16.5476C98.3684 17.0705 98.0839 17.559 97.7316 17.9964C97.3901 18.4416 96.9847 18.8333 96.5287 19.1589C96.1512 19.4218 95.7244 19.6049 95.2743 19.6968C94.8617 19.7783 94.4359 19.7641 94.0296 19.6552C93.5955 19.5306 93.1909 19.3193 92.8401 19.0338ZM93.4998 18.1966C93.7063 18.3658 93.945 18.4906 94.2013 18.5635C94.4513 18.6205 94.7111 18.618 94.96 18.5563C95.23 18.5122 95.4877 18.411 95.7159 18.2594C95.9716 18.0838 96.1976 17.8681 96.3851 17.6204L97.135 16.6909C97.3309 16.449 97.4871 16.1774 97.5978 15.8861C97.6812 15.6404 97.7224 15.3822 97.7196 15.1226C97.7018 14.8718 97.6296 14.6279 97.5079 14.4081C97.3773 14.1725 97.1997 13.9666 96.9863 13.8032C96.7773 13.6319 96.5361 13.5045 96.2771 13.4288C96.0295 13.3621 95.7703 13.3515 95.5181 13.3979C95.2487 13.4485 94.9919 13.552 94.7623 13.7023C94.5026 13.8757 94.2738 14.0918 94.0855 14.3415L93.3355 15.2634C93.1417 15.508 92.988 15.7823 92.8804 16.0757C92.7889 16.3193 92.7475 16.579 92.7586 16.8392C92.7738 17.0895 92.8435 17.3334 92.9627 17.5538C93.0983 17.8034 93.2837 18.0223 93.5074 18.1966L93.4998 18.1966Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M98.2497 23.7005L103.772 18.7313L106.839 22.1935L106.049 22.9099L103.749 20.34L102.245 21.7264L104.277 23.994L103.487 24.7104L101.455 22.4428L99.0474 24.6077L98.2497 23.7005Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M103.677 30.2964L109.914 26.2656L112.417 30.1829L111.519 30.7556L109.663 27.8612L107.936 28.9753L109.569 31.544L108.672 32.1242L107.024 29.5481L104.309 31.3043L103.677 30.2964Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M107.995 37.6574L114.796 34.7037L116.681 39.0995L115.706 39.5204L114.294 36.2483L112.405 37.0743L113.685 40.0427L112.711 40.4636L111.431 37.4952L109.443 38.3602L110.848 41.6324L109.873 42.0533L107.995 37.6574Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M111.429 47.0462L111.145 45.875L118.359 44.1218L119.102 47.2478C119.185 47.5269 119.21 47.8207 119.173 48.1099C119.137 48.3992 119.04 48.6775 118.89 48.9268C118.549 49.4202 118.029 49.7592 117.442 49.8706C116.99 49.9972 116.51 49.9805 116.068 49.8226C115.652 49.6509 115.308 49.3388 115.096 48.94L112.44 51.2521L112.124 49.9439L114.691 47.7851L114.344 46.3477L111.429 47.0462ZM115.793 47.9653C115.839 48.2237 115.983 48.4544 116.193 48.6094C116.301 48.6743 116.421 48.7154 116.545 48.73C116.67 48.7446 116.796 48.7323 116.915 48.694L117.407 48.575C117.531 48.5544 117.649 48.5071 117.753 48.4364C117.857 48.3656 117.945 48.2732 118.01 48.1653C118.132 47.93 118.157 47.6558 118.079 47.4023L117.629 45.5313L115.358 46.0865L115.793 47.9653Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M112.449 70.462L119.702 72.0244L119.448 73.2008L113.236 71.8648L112.604 74.8285L111.57 74.602L112.449 70.462Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M110.531 77.9891L111.445 78.3311L111.097 79.2797L116.222 81.1825L116.569 80.2263L117.483 80.5683L116.366 83.6281L115.452 83.2861L115.8 82.3299L110.683 80.427L110.328 81.3909L109.415 81.0489L110.531 77.9891Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M109.881 92.6006L109.88 92.5549L109.111 91.4416L107.361 88.5387L110.738 88.6209L112.075 88.715L112.074 88.6692L107.169 85.8115L107.743 84.815L114.16 88.5656L113.413 89.8L109.155 89.627L109.155 89.6879L111.466 93.3098L110.779 94.4902L104.362 90.7397L104.937 89.7432L109.881 92.6006Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M101.943 94.0025L102.708 94.628L102.063 95.3964L106.254 98.9013L106.899 98.1329L107.664 98.7583L105.593 101.255L104.828 100.622L105.481 99.8538L101.283 96.3414L100.63 97.1099L99.865 96.4768L101.943 94.0025Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M99.2873 106.049L95.0541 101.294L95.9497 100.524L100.19 105.278L101.83 103.799L102.543 104.555L98.3604 108.322L97.6472 107.521L99.2873 106.049Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M90.3937 105.014L94.3682 111.295L90.3636 113.856L89.7936 112.955L92.8104 111.043L91.7013 109.293L88.9863 111.026L88.4164 110.132L91.1389 108.399L89.9758 106.557L86.9591 108.469L86.3891 107.567L90.3937 105.014Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M85.5688 116.387L83.1352 117.386C82.7313 117.563 82.2963 117.658 81.8556 117.665C81.4467 117.671 81.0424 117.578 80.677 117.394C80.2806 117.191 79.9314 116.905 79.6524 116.557C79.3062 116.132 79.0302 115.653 78.8347 115.14C78.6124 114.636 78.474 114.099 78.4251 113.55C78.379 113.105 78.4264 112.655 78.5644 112.23C78.7021 111.845 78.9245 111.497 79.2148 111.21C79.5354 110.903 79.9118 110.662 80.3234 110.498L82.7494 109.499L85.5688 116.387ZM80.7347 111.47C80.5018 111.554 80.2887 111.686 80.1089 111.857C79.929 112.028 79.7863 112.234 79.6895 112.463C79.5141 112.996 79.555 113.576 79.8034 114.078L80.254 115.187C80.4308 115.718 80.8083 116.158 81.305 116.412C81.532 116.51 81.7766 116.559 82.0234 116.556C82.2702 116.554 82.5138 116.5 82.7389 116.398L84.0615 115.86L82.0498 110.939L80.7347 111.47Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M66.4501 120.555L65.6997 114.22L66.8895 114.079L67.6399 120.414L69.8377 120.149L69.9616 121.215L64.3837 121.878L64.2599 120.82L66.4501 120.555Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M60.1273 114.555L60.1366 115.538L59.1276 115.547L58.9898 121.03L59.9989 121.02L60.0082 122.003L56.7828 121.92L56.7735 120.936L57.7977 120.927L57.9354 115.444L56.9185 115.416L56.9092 114.433L60.1273 114.555Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M46.1441 118.637L46.1972 118.636L46.999 117.569L49.1746 114.972L50.1917 118.217L50.5226 119.51L50.5757 119.509L51.6912 113.919L52.8163 114.144L51.368 121.446L49.9693 121.169L48.7696 117.064L48.7013 117.065L46.0165 120.383L44.6863 120.122L46.1345 112.813L47.2595 113.038L46.1441 118.637Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M41.9948 111.464L39.1997 118.351L34.7973 116.556L35.1976 115.569L38.488 116.909L39.2285 114.989L36.2811 113.805L36.6814 112.818L39.6595 114.032L40.4826 112.019L37.1922 110.671L37.5925 109.684L41.9948 111.464Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M27.3331 103.157C27.6943 103.426 27.9948 103.769 28.2151 104.163C28.4221 104.531 28.5427 104.942 28.5679 105.364C28.5852 105.825 28.5069 106.285 28.338 106.715C28.1384 107.241 27.8589 107.732 27.5096 108.171C27.1737 108.617 26.7704 109.006 26.3141 109.326C25.9392 109.597 25.5119 109.785 25.0599 109.879C24.6476 109.965 24.221 109.95 23.8152 109.838C22.9424 109.587 22.2001 109.007 21.7437 108.218C21.539 107.85 21.4209 107.439 21.3985 107.017C21.3826 106.556 21.4609 106.096 21.6285 105.666C21.8272 105.142 22.1068 104.653 22.457 104.217C22.7915 103.766 23.1949 103.371 23.6523 103.047C24.0252 102.778 24.4497 102.59 24.8989 102.494C25.3141 102.412 25.7423 102.427 26.1512 102.536C26.584 102.658 26.9867 102.869 27.3331 103.157ZM26.681 104.002C26.4754 103.831 26.2364 103.706 25.9795 103.635C25.7323 103.566 25.4725 103.555 25.2205 103.604C24.9511 103.655 24.6943 103.758 24.4647 103.908C24.2088 104.087 23.9829 104.305 23.7955 104.555L23.0456 105.485C22.8502 105.728 22.6964 106.003 22.5905 106.297C22.5039 106.542 22.4652 106.801 22.4763 107.06C22.493 107.314 22.5653 107.56 22.688 107.783C22.8208 108.014 22.9981 108.217 23.2096 108.38C23.4207 108.551 23.6647 108.675 23.9263 108.747C24.1739 108.813 24.4331 108.824 24.6853 108.777C24.9569 108.73 25.2148 108.624 25.441 108.465C25.7008 108.292 25.9296 108.076 26.1178 107.826L26.8678 106.897C27.0616 106.652 27.2152 106.378 27.3229 106.084C27.408 105.839 27.4467 105.58 27.4371 105.321C27.4193 105.07 27.347 104.826 27.2254 104.606C27.0927 104.361 26.9066 104.149 26.6809 103.987L26.681 104.002Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M21.8339 98.445L16.335 103.444L13.2219 99.9903L14.0118 99.2739L16.3122 101.829L17.8165 100.442L15.7768 98.1747L16.5667 97.4583L18.6063 99.7258L21.0061 97.5533L21.8339 98.445Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M16.3081 91.7816L10.0794 95.8199L7.5689 91.9103L8.46628 91.33L10.3223 94.232L12.0567 93.1102L10.4011 90.5417L11.2909 89.9616L12.9465 92.53L15.6613 90.7738L16.3081 91.7816Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.9353 84.3072L5.13483 87.2609L3.24192 82.8652L4.22419 82.4366L5.62847 85.7088L7.51788 84.9285L6.24547 81.96L7.22016 81.5315L8.49257 84.5L10.4875 83.6425L9.07561 80.3705L10.0503 79.9419L11.9353 84.3072Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M8.46932 74.8416L8.74597 76.0129L1.50906 77.751L0.796602 74.6248C0.716538 74.3498 0.694713 74.061 0.732534 73.777C0.770356 73.4931 0.866984 73.2202 1.01619 72.9761C1.35818 72.4856 1.87804 72.1495 2.46407 72.04C2.91595 71.9133 3.39574 71.9301 3.83781 72.0879C4.24667 72.2529 4.58693 72.5537 4.80187 72.9402L7.46584 70.628L7.78172 71.9361L5.215 74.1026L5.55494 75.5477L8.46932 74.8416ZM4.12825 73.9147C4.08743 73.6579 3.94929 73.4271 3.74278 73.2704C3.63019 73.2011 3.50405 73.157 3.37297 73.1412C3.24188 73.1253 3.10893 73.138 2.98319 73.1785L2.48357 73.2976C2.36054 73.3209 2.24357 73.3693 2.13986 73.4398C2.03614 73.5103 1.94787 73.6013 1.88047 73.7073C1.76401 73.9445 1.74217 74.2174 1.81941 74.4702L2.26194 76.3336L4.53292 75.7861L4.12825 73.9147Z",
      "fill": "white"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "1.52169",
      "cy": "1.52341",
      "rx": "1.52169",
      "ry": "1.52341",
      "transform": "matrix(0.989955 0.141384 -0.141057 0.990001 115.497 59.0938)",
      "fill": "#FFFCF0"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "1.52169",
      "cy": "1.52341",
      "rx": "1.52169",
      "ry": "1.52341",
      "transform": "matrix(0.989955 0.141384 -0.141057 0.990001 2.08301 59.0938)",
      "fill": "#FFFCF0"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }