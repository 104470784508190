import { doGetTranscripts, doGetTranscriptsQuizResults, doGetTranscriptByTopic } from '@/api/transcripts';

export default {
  namespaced: true,
  state: () => ({
    creditDetails: null,
  }),
  mutations: {
    setTranscriptCreditDetails(state, data) {
      state.creditDetails = data;
    },
  },
  actions: {
    getTranscripts(context, payload) {
      return doGetTranscripts(payload);
    },
    getTranscriptsQuizResults(context, payload) {
      return doGetTranscriptsQuizResults(payload);
    },
    setCreditDetails(context, data) {
      context.commit('setTranscriptCreditDetails', data);
    },
    getTranscriptByTopic(context, payload) {
      return doGetTranscriptByTopic(payload);
    },
  },
  getters: {
    getCreditDetails: (state) => {
      return state.creditDetails;
    },
  },
};
