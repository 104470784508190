var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    ref: "menu",
    staticClass: "aca-birthday-picker",
    attrs: {
      "value": _vm.menuModel,
      "close-on-content-click": false,
      "nudge-right": 40,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('update:menuModel', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('AcaTextField', _vm._g(_vm._b({
          class: [{
            'hide-details': _vm.hideDetails,
            'aca-birthday-picker-text-field-disabled-new': _vm.disabled
          }],
          attrs: {
            "id": "dateOfBirth",
            "value": _vm.textFieldModel,
            "data-testid": _vm.textFieldDataTestid,
            "disabled": _vm.disabled,
            "type": "text",
            "name": _vm.name,
            "placeholder": "DD/MM/YY",
            "rules": _vm.rules,
            "required": "",
            "label": _vm.label,
            "autocomplete": 'off',
            "readonly": "",
            "hide-details": _vm.hideDetails
          },
          on: {
            "input": function ($event) {
              return _vm.$emit('update:textFieldModel', $event);
            }
          }
        }, 'AcaTextField', attrs, false), on))];
      }
    }])
  }, [_c('v-date-picker', {
    ref: "datePicker",
    attrs: {
      "value": _vm.datePickerModel,
      "active-picker": _vm.activePicker,
      "picker-date": _vm.pickerDateSelection,
      "max": _vm.maxPickerDate,
      "min": _vm.minPickerDate,
      "no-title": ""
    },
    on: {
      "update:activePicker": function ($event) {
        _vm.activePicker = $event;
      },
      "update:active-picker": function ($event) {
        _vm.activePicker = $event;
      },
      "update:pickerDate": function ($event) {
        _vm.pickerDateSelection = $event;
      },
      "update:picker-date": function ($event) {
        _vm.pickerDateSelection = $event;
      },
      "input": function ($event) {
        return _vm.$emit('update:datePickerModel', $event);
      },
      "change": _vm.save
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }