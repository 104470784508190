export default {
  namespaced: true,
  state: () => ({
    specialties: [],
  }),
  mutations: {
    setSpecialties(state, data) {
      state.specialties = data;
    },
    updateSpecialtyProperty(state, { index, propName, value }) {
      if (index >= 0 && index < state.specialties.length) {
        state.specialties[index][propName] = value;
      }
    },
    updateSubspecialtyProperty(state, { index, subIndex, propName, subPropName, value }) {
      if (
        index >= 0 &&
        index < state.specialties.length &&
        subIndex >= 0 &&
        subIndex < state.specialties[index]?.subspecialties?.length
      ) {
        state.specialties[index][propName][subIndex][subPropName] = value;
      }
    },
    clearSelection(state) {
      state.specialties.forEach((specialty) => {
        specialty.forceSelection = false;
        specialty.subspecialties?.forEach((subspecialty) => {
          subspecialty.forceSelection = false;
        });
      });
    },
  },
  actions: {
    updateSpecialtiesRender(context, payload) {
      context.commit('setSpecialties', payload);
    },
    updateSelectSpecialty(context, payload) {
      context.commit('updateSpecialtyProperty', {
        index: payload.index,
        propName: payload.propName,
        value: payload.value,
      });
    },
    updateSelectSubSpecialty(context, payload) {
      context.commit('updateSubspecialtyProperty', {
        index: payload.index,
        subIndex: payload.subIndex,
        propName: payload.propName,
        subPropName: payload.subPropName,
        value: payload.value,
      });
    },
    clearSelection(context) {
      context.commit('clearSelection');
    },
  },
  getters: {
    allSpecialties: (state) => {
      return state.specialties;
    },
    getSpecialtiesSelected: (state) => {
      return state.specialties.filter((specialty) => specialty.forceSelection === true);
    },
    getSubSpecialtiesSelected: (state) => {
      const subspecialties = [];
      state.specialties.forEach((specialty) => {
        if (specialty.subspecialties) {
          specialty.subspecialties.forEach((subspecialty) => {
            if (subspecialty.forceSelection) {
              subspecialties.push(subspecialty);
            }
          });
        }
      });
      return subspecialties;
    },
    state(state) {
      return state;
    },
  },
};
