var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('AcaSelect', {
    attrs: {
      "id": "degree",
      "name": "degree",
      "items": _vm.computedDegreeItems,
      "placeholder": "Select your degree",
      "label": "Degree *",
      "required": ""
    },
    model: {
      value: _vm.degree,
      callback: function ($$v) {
        _vm.degree = $$v;
      },
      expression: "degree"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }