import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import light from './../theme';

Vue.use(Vuetify);

// TODO: in the future we would like to set the breakpoints in the Vuetify plugin
// we don't do it now because it is risky as we could break some of the existing UIs
// for now we only create new aliases such us smallMobile, mobile, tablet, smallDesktop, desktop
// and their conditionals such us smallMobileAndUp, mobileAndDown, tabletOnly, etc.
let customTheme = {
  theme: {
    themes: { light },
    options: { customProperties: true },
  },
};

export default new Vuetify(customTheme);
