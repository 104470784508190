var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-center"
  }, [!_vm.hasToDisabledQuizButton && (_vm.$route.name === 'article-file-view' || _vm.$route.name === 'special-requirements-article-pdf') ? _c('a', {
    staticClass: "clickable d-flex align-center text-decoration-none",
    class: [_vm.$vuetify.breakpoint.xsOnly ? '' : 'pr-8'],
    attrs: {
      "href": _vm.currentDisclosurePdfUrl,
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_c('ph-file-pdf', {
    staticClass: "pr-2",
    attrs: {
      "size": "24",
      "color": "var(--v-primary-base)",
      "weight": "light"
    }
  }), _c('h3', {
    staticClass: "heading heading-s-small primary--text"
  }, [_vm._v("Disclosure")])], 1) : _vm._e(), _vm.currentUser && !_vm.isAuditor && !_vm.getHideCreditsInMenu ? _c('div', {
    staticClass: "px-2 border-radius-small shadow-badge",
    class: {
      'mr-4': _vm.hasToShowMainAction
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "col-12 d-flex align-items-center"
  }, [_c('router-link', {
    staticClass: "text-decoration-none",
    attrs: {
      "to": "/transcripts"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center py-1"
  }, [!_vm.hasToShowUnawardedBadge ? _c('ph-graduation-cap', {
    staticClass: "white--text circle-icon-avatar cold-gradient",
    attrs: {
      "weight": "light",
      "size": _vm.$vuetify.breakpoint.smAndUp ? 28 : 24
    }
  }) : _vm._e(), _vm.hasToShowUnawardedBadge ? _c('ph-warning', {
    staticClass: "white--text circle-icon-avatar hot-gradient",
    attrs: {
      "weight": "light",
      "size": _vm.$vuetify.breakpoint.smAndUp ? 28 : 24
    }
  }) : _vm._e(), _c('h3', {
    staticClass: "text text-m-large-bold grey--text text--darken-4 pl-1 mb-0"
  }, [_vm._v(" " + _vm._s(_vm._f("creditsDecimalNotation")(_vm.awardedCreditsToShow)) + " ")]), _vm.$vuetify.breakpoint.smAndUp ? _c('h3', {
    staticClass: "text text-l-small-regular grey--text text--darken-4 pl-1 mb-0"
  }, [_vm._v(" CME ")]) : _vm._e(), _vm.$breakpoint.mobileAndUp ? _c('h3', {
    staticClass: "text text-l-small-regular grey--text text--darken-4 pl-1 mb-0"
  }, [_vm._v(" " + _vm._s(_vm.awardedCreditsToShow === 1 ? 'credit' : 'credits') + " ")]) : _vm._e()], 1)])], 1)], 1)], 1) : _vm._e(), !_vm.currentUser ? _c('div', {
    staticClass: "d-flex clickable align-center"
  }, [_vm.hasToShowLoginButton ? _c('router-link', {
    staticClass: "heading heading-s-small primary--text mr-sm-8 text-decoration-none",
    class: [{
      'pr-3': _vm.$vuetify.breakpoint.width < 768
    }],
    attrs: {
      "to": {
        name: 'login'
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.handleClickLoginLink.apply(null, arguments);
      }
    }
  }, [_vm._v(" Log in ")]) : _vm._e(), _vm.hasToShowSignUpButton ? _c('v-btn', {
    staticClass: "shadow-dark-button px-5 mr-2",
    class: [_vm.$vuetify.breakpoint.smAndUp ? 'heading heading-s-small' : 'text text-m-large-regular'],
    attrs: {
      "to": {
        name: 'register'
      },
      "width": '104px',
      "height": "36px",
      "ripple": "",
      "depressed": "",
      "color": "primary white--text"
    }
  }, [_vm._v(" Sign up ")]) : _vm._e()], 1) : _vm._e(), _vm.hasToShowMainAction ? _c('div', [_c('v-btn', {
    staticClass: "heading heading-s-small white--text shadow-dark-button",
    class: {
      'cold-gradient': !_vm.hasToDisabledQuizButton
    },
    attrs: {
      "disabled": _vm.hasToDisabledQuizButton,
      "ripple": "",
      "block": "",
      "width": "auto",
      "height": "36px",
      "data-testid": "submit"
    },
    on: {
      "click": _vm.handleClickMainAction
    }
  }, [_vm._v(" " + _vm._s(_vm.mainActionText) + " ")])], 1) : _vm._e(), _c('abstract-header-actions')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }